import { Component, Mixins } from "vue-property-decorator";
import { DEBUG, Platform, servers, toaster, DocumentSelect, busy } from '@/loader';

@Component
export class PlatformSelect extends Mixins<DocumentSelect<Platform>>(DocumentSelect) {
	public loadOptions() {
		this.$pouch.allDocs({
			startkey: Platform.STARTKEY,
			endkey: Platform.ENDKEY,
			include_docs: true
		}, servers.selected?.name).then(result => {
			DEBUG && console.log("PlatformSelect->allDocs?platform:", result);
			this.options = result.rows.map(r => <Platform>r.doc);
		}).catch(error => {
			console.error("PlatformSelect->allDocs?platform:", error);
			toaster.error({ error });
		});
	}

	public mounted() {
		this.loadOptions();
	}

	public get editRoute(): string {
		return "platform";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public createOption(option: any): any {
		busy.start(`Création de la plateforme "${option.name}" ...`);
		let item = Platform.create(option.name);
		this.pushItem(item, option);
	}
}
