








































































































































import dayjs from 'dayjs';
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	DocumentList,
	Task,
	Machine,
	Mission,
	TaskAction,
	MissionStatus,
	MachineGame,
	CouchDBDocument,
	User,
	createListFilter,
	Brand,
	Game,
	BvTableFieldArray,
	PropertyNames,
	ReferenceTask,
	LocationTask
} from "@/loader";

type TaskExtended = Task & {
	mission: Mission | null
	machine: Machine | null
	games: Game[]
	name: string
	ref: string | null
	location: string | null
	serial: string
};

function checkTaskExtended(data: Task & TaskExtended): TaskExtended {
	Task.check(data);
	data.machine = null;
	data.mission = null;
	data.games = [];
	data.searchable = "";
	data.name = "";
	data.serial = "";
	data.ref = ReferenceTask.instanceOf(data.data) && data.data.ref || "";
	data.location = LocationTask.instanceOf(data.data) && data.data.location || "";
	return data;
}

@Component
export default class TasksTable extends Mixins<DocumentList<TaskExtended>>(DocumentList) {
	@Prop({ type: Object, default: null })
	public readonly mission!: Mission | null;

	@Prop({ type: Object, default: null })
	public readonly machine!: Machine | null;

	public filterLocation: string = "";
	public filterRef: string = "";
	public filterSerial: string = "";
	public users: User[] = [];
	public TaskAction = TaskAction;
	public MissionStatus = MissionStatus;
	public selected = [];
	public filters = {
		status: createListFilter<Brand, Task>("status"),
		action: createListFilter<Brand, Task>("action"),
	};

	public get fields(): BvTableFieldArray {
		return this.mission ? [
			{
				key: "selected",
				label: "Sélection",
			},
			{
				key: "action",
				label: "Action",
				formatter: TaskAction.toString,
				sortByFormatted: true,
			},
			{
				key: "name",
				label: "Nom",
				sortable: true,
			},
			{
				key: "location",
				label: "Socle",
				sortable: true,
			},
			{
				key: "ref",
				label: "N°MAS",
				sortable: true,
				sortByFormatted: true,
				formatter: (value: string) => value,
			},
			{
				key: "serial",
				label: "N°Série",
				sortable: true,
			},
		] : [
				{
					key: "status",
					label: "Statut",
				},
				{
					key: "action",
					label: "Action",
					formatter: TaskAction.toString,
					sortByFormatted: true,
				},
				{
					key: "mission.name",
					label: "Mission",
				},
				{
					key: "mission.start",
					label: "Début",
					formatter(value) {
						const d = dayjs(value || "");
						return d.isValid() ? d.format("DD/MM/YYYY") : "A définir";
					}
				},
				{
					key: "mission.end",
					label: "Fin",
					formatter(value, key, item) {
						const d = dayjs(value || item.start || "");
						return d.isValid() ? d.format("DD/MM/YYYY") : "A définir";
					}
				},
				{
					key: "mission.techs",
					label: "Techniciens",
					formatter: (value) => this.users
						.filter(u => value.includes(u._id))
						.map(u => User.toString(u))
						.join(", ")
				},
			];
	}

	public get startkey(): string {
		return Task.STARTKEY;
	}

	public get endkey(): string {
		return Task.ENDKEY;
	}

	public get type(): string {
		return Task.TYPE;
	}

	public get checkDocFunction(): Function | null {
		return checkTaskExtended;
	}

	public get statusQuery(): string {
		return this.machine ? "task/machines_status"
			: this.mission ? "task/missions_status"
				: "task/status";
	}

	public get actionQuery(): string {
		return this.machine ? "task/machines_actions"
			: this.mission ? "task/missions_actions"
				: "task/actions";
	}

	public get queryOptions(): PouchDB.Query.Options<{}, {}> {
		return this.machine ? { group: true, startkey: [this.machine._id, null], endkey: [this.machine._id, {}] }
			: this.mission ? { group: true, startkey: [this.mission._id, null], endkey: [this.mission._id, {}] }
				: { group: true };
	}

	public get preparedItems() {
		// @ts-ignore
		let sort: PropertyNames<T> = this.sort || "_id";
		let filter = this.filter.removeDiacritics().toLowerCase();
		return this.items
			.filter(item =>
				Object.values(this.filters).every(f => !f.selection.length || f.match(item, f.selection))
				&& (!filter || (item.searchable || "").includes(filter))
				&& (!this.filterLocation || (item.machine?.location || "").includes(this.filterLocation))
				&& (!this.filterRef || (item.machine?.ref || "").includes(this.filterRef))
				&& (!this.filterSerial || (item.machine?.serial || "").includes(this.filterSerial))
			).sort((a, b) => this.defaultSorter(sort, a, b));
	}

	public loadQuery(): (() => Promise<TaskExtended[]>) | null {
		if (this.machine) {
			return () => <Promise<TaskExtended[]>>this.$db.queryTasksByMachine(this.machine?._id || "");
		} else if (this.mission) {
			return () => <Promise<TaskExtended[]>>this.$db.queryTasksByMission(this.mission?._id || "");
		} else {
			return null;
		}
	}

	public prepareItems(items: Task[]): TaskExtended[] {
		let results = items
			.filter(item => item?.type === this.type)
			.map(item => {
				let result: TaskExtended = this.checkDocFunction ? this.checkDocFunction(item) : item;

				if (this.mission) {
					result.mission = this.mission;
				} else {
					this.$db.getMission(item.missionId).then(mission => {
						result.mission = mission;
					});
				}

				if (this.machine) {
					result.machine = this.machine;
					// this.setTaskName(result);
				} else {
					this.$db.getMachine(item.machineId).then(machine => {
						if (!machine) {
							return;
						}
						result.machine = machine;
						result.ref = result.ref || machine.ref;
						result.location = result.location || machine.location;
						result.serial = machine.serial;
						this.setTaskName(result);
					});
				}

				return result;
			});
		return results;
	}

	public setTaskName(task: TaskExtended) {
		let gamesIds = MachineGame.getRepresentingGames(task.machine?.games || []);
		this.$db.allGames({ keys: gamesIds }).then(games => {
			task.games = games;
			task.name = task.games.map(game => game.name).join(" - ");
			task.searchable = this.getSearchableString(task);
		});
	}

	public getSearchableString(value: TaskExtended): string {
		return this.mission ? [
			value?.name,
			value?.machine?.serial || "",
			value?.machine?.location || "",
			value?.machine?.ref || "",
		].join(CouchDBDocument.ENDKEY_SUFFIX) : [
			value?.mission?.name || "",
			value?.mission?.techs?.map(t => this.users.find(u => u._id === t)?.name).join(", ") || "",
		].join(CouchDBDocument.ENDKEY_SUFFIX);
	}

	public async mounted() {
		this.users = await this.$db.allUsers();
	}

	public selectItem() {
		if (!this.preparedItems.length) {
			return;
		}
		const task = this.preparedItems[0];
		this.$router.push({ name: "task", params: { id: task._id } });
	}
}
