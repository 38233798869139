import { DocIdentifiable } from "@/loader";

export interface Deletable extends DocIdentifiable {
	_deleted?: boolean;
}

export namespace Deletable {
	export function instanceOf(value: any): value is Deletable {
		return value && typeof value === "object" && "_deleted" in value;
	}
}
