import { Component, Mixins } from "vue-property-decorator";
import { FlagSelect, TaskAction, BvListItem } from "@/loader";

@Component
export class MissionActionSelect extends Mixins<FlagSelect<TaskAction>>(FlagSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return TaskAction.LIST;
	}
}
