import { BvListItem } from "@/loader";


export enum SoftwareType {
	none = 0,
	os = 1,
	driver = 2,
	bios = 3,
	legal = 4
}

export namespace SoftwareType {
	export function toString(softwaretype: SoftwareType): string {
		switch (softwaretype) {
			case SoftwareType.none: return "Non défini";
			case SoftwareType.os: return "OS";
			case SoftwareType.driver: return "Driver";
			case SoftwareType.bios: return "BIOS";
			case SoftwareType.legal: return "Juridiction";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(SoftwareType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<SoftwareType>value) }));
}
