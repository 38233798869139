import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import VueScrollTo from "vue-scrollto";
import VueDraggable from "vuedraggable";
// @ts-ignore: no definition
import * as VueGoogleMaps from "./plugin/vue2-google-maps";
// @ts-ignore: no definition
import vSelect from "vue-select";
import VueScreen from "vue-screen";

import PouchDB from "pouchdb-browser";
import PouchFind from "pouchdb-find";
import PouchLiveFind from "pouchdb-live-find";
import PouchAuthentication from "pouchdb-authentication";
import { PouchVue } from "@/plugin/pouch-vue";
import PouchDebug from "pouchdb-debug";
import PouchUpsert from "pouchdb-upsert";
import CryptoPouch from "crypto-pouch";

import {
	ACCOUNT_HEARTBEAT_LENGTH,
	Account,
	AccountResponse,
	AuthResponse,
	checkJwt,
	CivilitySelect,
	CompanySelect,
	CompatibilitySelect,
	ConfigStatusBadge,
	ControllerTypeSelect,
	CoordinateTypeSelect,
	CounterSelect,
	CounterTypeBadge,
	CounterTypeSelect,
	Credentials,
	currency,
	currencyNoDecimal,
	DEBUG,
	DenominationSelect,
	DocumentFilterCard,
	EnumFilterCard,
	FilterCard,
	FilterCardResume,
	FrequencySelect,
	GameCategorySelect,
	GameCategoryTree,
	GameSelect,
	getServer,
	HEARTBEAT_LENGTH,
	initSentry,
	MachineConfigSelect,
	MachineGameSelect,
	MachinePartSelect,
	MachineSelect,
	MachineSoftwareSelect,
	MachineStatusBadge,
	MachineTypeSelect,
	MissionActionSelect,
	MissionStatusBadge,
	number,
	numberWithSign,
	PartCategorySelect,
	PartCategoryTree,
	PartSelect,
	percent,
	PlatformSelect,
	ReadingSelect,
	ReadingStatusBadge,
	ReplacementActionSelect,
	Server,
	servers,
	ServerStatusBadge,
	Settings,
	SoftwareSelect,
	SoftwareTypeBadge,
	SoftwareTypeSelect,
	SoftwareVersionSelect,
	sortable,
	store,
	SyncEvent,
	SyncStatus,
	toaster,
	UserFilterCard,
	UserSelect,
	ValueUnitBadge,
	ValueUnitSelect,
	VolatilitySelect,
} from "@/loader";
import ActionRow from "./component/ActionRow";
import EventCard from "./component/event/EventCard.vue";
import EventsSidebar from "./component/event/EventsSidebar.vue";
import { TitleCard } from "./component/generic/TitleCard";
import ToggleButton from "./component/toggle-button";

PouchDB.plugin(PouchDebug);
PouchDB.plugin(PouchAuthentication);
PouchDB.plugin(CryptoPouch);
PouchDB.plugin(PouchFind);
PouchDB.plugin(PouchLiveFind);
PouchDB.plugin(PouchUpsert);

Vue.use(BootstrapVue, {
	BCalendar: {
		labelCalendar: "Calendrier",
		labelCurrentMonth: "Mois en cours",
		labelHelp: "Utiliser les flèches pour naviguer dans le calendrier",
		labelNav: "Naviguation",
		labelNextDecade: "Décennie suivante",
		labelNextMonth: "Mois suivant",
		labelNextYear: "Année suivante",
		labelNoDateSelected: "Aucune date sélectionnée",
		labelPrevDecade: "Décennie préscédente",
		labelPrevMonth: "Mois précédent",
		labelPrevYear: "Année précédente",
		labelSelected: "Date sélectionnée",
		labelToday: "Aujourd'hui",
		startWeekday: 1,
	},
	BFormDatepicker: {
		labelCloseButton: "Fermer",
		labelResetButton: "Effacer",
		labelTodayButton: "Sélectionner Aujourd'hui",
	},
	BFormFile: {
		browseText: "Parcourir",
		dropPlaceholder: "Déposer le fichier ici ...",
		dropLabel: "Vous pouvez glisser un fichier ci-dessous",
		placeholder: "Choisissez ou déposez un fichier ici ...",
	},
	BTable: {
		emptyFilteredText: "Il n'y a aucun éléments correspondant à votre recherche",
		emptyText: "Il n'y a aucun éléments à afficher",
		labelSortAsc: "Cliquez pour trier dans l'ordre ascendant",
		labelSortDesc: "Cliquez pour trier dans l'ordre descendant",
		labelSortClear: "Cliquez pour annuler le tri",
	}
});
Vue.use(VueGoogleMaps, {
	installComponents: false,
	load: {
		key: "AIzaSyCJjN1vhiHS1e4uIsMTMPK-OUwOXZMAi1U", // "AIzaSyAay2TOT7zIRNbkL648oamtILS4xTW3gSY",
		libraries: "places"
	}
});

Vue.use(VueScreen, "bootstrap");
Vue.use(ToggleButton);
Vue.use(VueScrollTo);
Vue.use(PouchVue, {
	pouch: PouchDB,    // optional if `PouchDB` is available on the global object
	defaultDB: "local",  // this is used as a default connect/disconnect database
	optionsDB: {
		// auto_compaction: true,
		fetch(url: string | Request, options?: RequestInit | undefined): Promise<Response> {
			if (typeof url === "string") {
				let server = servers.list.find(s => url.startsWith(s.url));
				if (server && server.settings.token && options?.headers) {
					// @ts-ignore
					options.headers.set("Authorization", `Bearer ${server.settings.token}`);
				}
			}
			return PouchDB.fetch(url, options);
		}
	}, // this is used to include a custom fetch() method (see TypeScript example)
	debug: "*" // optional - See `https://pouchdb.com/api.html#debug_mode` for valid settings (will be a separate Plugin in PouchDB 7.0)
});
// PouchDB.debug.disable();
// PouchDB.debug.enable("*");
PouchDB.debug.enable("pouchdb:query");


Vue.filter("number", number.format);
Vue.filter("numberWithSign", numberWithSign.format);
Vue.filter("currency", currency.format);
Vue.filter("currencyNoDecimal", currencyNoDecimal.format);
Vue.filter("percent", percent.format);
Vue.filter("capitalize", String.capitalize);
Vue.filter("upper", String.toUpperCase);
Vue.filter("lower", String.toLowerCase);

Vue.directive("sortable", sortable);

Vue.component("v-select", vSelect);
Vue.component("v-draggable", VueDraggable);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.component("action-row", ActionRow);
Vue.component("title-card", TitleCard);
Vue.component("event-card", EventCard);
Vue.component("events-sidebar", EventsSidebar);
Vue.component("filter-card", FilterCard);
Vue.component("enum-filter-card", EnumFilterCard);
Vue.component("document-filter-card", DocumentFilterCard);
Vue.component("filter-card-resume", FilterCardResume);

Vue.component("config-status-badge", ConfigStatusBadge);
Vue.component("machine-status-badge", MachineStatusBadge);
Vue.component("mission-status-badge", MissionStatusBadge);
Vue.component("reading-status-badge", ReadingStatusBadge);
Vue.component("server-status-badge", ServerStatusBadge);
Vue.component("value-unit-badge", ValueUnitBadge);

Vue.component("civility-select", CivilitySelect);
Vue.component("compatibility-select", CompatibilitySelect);
Vue.component("controller-type-select", ControllerTypeSelect);
Vue.component("coordinate-type-select", CoordinateTypeSelect);
Vue.component("counter-type-badge", CounterTypeBadge);
Vue.component("counter-type-select", CounterTypeSelect);
Vue.component("denomination-select", DenominationSelect);
Vue.component("frequency-select", FrequencySelect);
Vue.component("machine-config-select", MachineConfigSelect);
Vue.component("machine-type-select", MachineTypeSelect);
Vue.component("mission-action-select", MissionActionSelect);
Vue.component("replacement-action-select", ReplacementActionSelect);
Vue.component("software-type-badge", SoftwareTypeBadge);
Vue.component("software-type-select", SoftwareTypeSelect);
Vue.component("value-unit-select", ValueUnitSelect);
Vue.component("volatility-select", VolatilitySelect);

Vue.component("company-select", CompanySelect);
Vue.component("counter-select", CounterSelect);
Vue.component("game-select", GameSelect);
Vue.component("game-category-select", GameCategorySelect);
Vue.component("game-category-tree", GameCategoryTree);
Vue.component("part-select", PartSelect);
Vue.component("part-category-select", PartCategorySelect);
Vue.component("part-category-tree", PartCategoryTree);
Vue.component("platform-select", PlatformSelect);
Vue.component("machine-select", MachineSelect);
Vue.component("machine-game-select", MachineGameSelect);
Vue.component("machine-part-select", MachinePartSelect);
Vue.component("machine-software-select", MachineSoftwareSelect);
Vue.component("reading-select", ReadingSelect);
Vue.component("software-select", SoftwareSelect);
Vue.component("software-version-select", SoftwareVersionSelect);
Vue.component("user-select", UserSelect);
Vue.component("user-filter-card", UserFilterCard);

import "./scss/index.scss"
import "@/plugin/fontawesome";
import { router } from "@/route";

import Main from "./Main.vue";

!DEBUG && initSentry("SFMPro", router);
servers.init();
const app = new Vue({
	name: "app",
	el: "#app",
	store,
	router,
	data: function() {
		return {
			initialized: false,
			tutorial: false,
		}
	},
	computed: {

	},
	methods: {
		async sendHeartbeat(serverName: string) {
			let server = getServer(serverName);
			if (server && server.settings.token) {
				try {
					let response = await fetch(server.auth, {
						method: "POST",
						body: JSON.stringify({ action: "refresh" }),
						headers: {
							Authorization: `Bearer ${server.settings.token}`
						}
					});
					let result: AuthResponse = await response.json();
					if (result.code !== 200 || !result.token) {
						throw { "message": "Jeton invalide. Veuillez vous reconnecter.", data: result };
					}

					servers.modifyServer({ ...result, server });
					await this.$db.saveServerSettings(server.settings);

					DEBUG && console.log(serverName, "heartbeat ok");
				} catch (error) {
					DEBUG && console.log(serverName, "heartbeat error", error);
					window.clearInterval(server.heartbeat);
					servers.modifyServer({ heartbeat: 0, user: null, token : null, server });
					await this.$db.saveServerSettings(server.settings);
					await this.startServer(server);
				}
			}
		},
		async startHeartbeat(server: string | Server | null, immediate: boolean = false) {
			server = typeof server === "string" ? getServer(server) : server;
			if (server?.settings.token) {
				DEBUG && console.log(server.name, "start heartbeat");
				let name = server.name;
				let heartbeat = window.setInterval(() => { this.sendHeartbeat(name) }, HEARTBEAT_LENGTH);
				servers.modifyServer({ heartbeat, server });

				if (immediate) {
					await this.sendHeartbeat(name);
				}
			}
		},
		startAllHeartbeats() {
			for (let server of servers.list) {
				this.startHeartbeat(server);
			}
		},
		stopAllHeartbeats() {
			for (let server of servers.list) {
				if (server.heartbeat) {
					DEBUG && console.log(server.name, "stop heartbeat");
					window.clearInterval(server.heartbeat);
					servers.modifyServer({ heartbeat: 0, server: server.name });
				}
			}
		},
		updateOnlineStatus(e: Event) {
			DEBUG && console.log("online status", e.type);
			servers.setOnline(e.type === "online");
			e.type === "online" ? this.startAllHeartbeats() : this.stopAllHeartbeats();
		},
		syncStatusChanged(status: SyncStatus, event: SyncEvent) {
			DEBUG && console.log("sync_event", status, event);
			let server = getServer(event.db);

			if (server) {
				servers.modifyServer({ status, server: server.name });
			}
		},
		syncStatusError(e: SyncEvent) {
			if (e.error?.status === 401) {
				return this.syncStatusChanged(SyncStatus.disconnected, e);
			} else if (e.error?.status === 403) {
				return this.syncStatusChanged(SyncStatus.denied, e);
			}
			return this.syncStatusChanged(SyncStatus.error, e);
		},
		syncStatusPaused(e: SyncEvent) {
			return this.syncStatusChanged(SyncStatus.paused, e);
		},
		async syncStatusChange(e: SyncEvent) {
			return this.syncStatusChanged(SyncStatus.change, e);
		},
		syncStatusActive(e: SyncEvent) {
			return this.syncStatusChanged(SyncStatus.active, e);
		},
		syncStatusDenied(e: SyncEvent) {
			return this.syncStatusChanged(SyncStatus.denied, e);
		},
		syncStatusComplete(e: SyncEvent) {
			return this.syncStatusChanged(SyncStatus.complete, e);
		},
		async startAllServers() {
			for (let s of servers.list) {
				await this.startServer(s);
			}
		},
		async startServer(server: Server, credentials?: Credentials | null) {
			if (!server || !server.name) {
				console.error("invalid server", server);
				return false;
			}
			if (!servers.account?.key) {
				console.error("no decryption key available");
				return false;
			}

			let alreadyConnected = true;
			DEBUG && console.group("startServer", server.name);
			DEBUG && console.log("server", JSON.parse(JSON.stringify(server)));
			try {
				// Create the DB
				let info = await this.$pouch.info(server.name);
				DEBUG && console.log("info", info);
				// Disable encryption for synced databases
				// // Encrypt it
				// await this.$databases[server.name].crypto(servers.account.key);
				// DEBUG && console.log("encrypted");
				// Get server settings
				let settings: Settings | null = await this.$db.getServerSettings(server);
				if (!settings) {
					settings = Settings.create(server.name);
				}
				servers.modifyServer({ server, settings });
				DEBUG && console.log("settings", JSON.parse(JSON.stringify(settings)));

				let remoteLoggedIn = false;
				if (settings.token) {
					try {
						let claims = checkJwt(settings.token);
						DEBUG && console.log("token", claims);
						remoteLoggedIn = true;
					} catch (error) {
						DEBUG && console.warn("token", error);
						servers.modifyServer({ user: null, token : null, server });
						await this.$db.saveServerSettings(server.settings);
					}
				}

				// Get or set credentials
				if (!server.settings.token) {
					if (credentials) {
						let existing_credentials;
						try {
							existing_credentials = await this.$pouch.get(credentials._id, {}, servers.account.name);
							credentials._rev = existing_credentials._rev;
						} catch (error) {
						}
						await this.$pouch.put(credentials, {}, servers.account.name);
						DEBUG && console.log("credentials saved", credentials);
					} else {
						try {
							credentials = await this.$pouch.get(Credentials.getId(servers.account, server), {}, servers.account.name) as Credentials;
							DEBUG && console.log("get credentials", credentials);
						} catch (error) {
							DEBUG && console.warn("no credentials", error);
							credentials = null;
						}
					}

					if (credentials) {
						// Connect to the server
						let response: Response = await fetch(server.auth, {
							method: "POST",
							body: JSON.stringify({
								action: "login",
								username: credentials.login,
								password: credentials.password
							})
						});
						DEBUG && console.log("response", response);

						// Parse the response
						let result: AuthResponse = await response.json();
						DEBUG && console.log("result", result);
						if (result.code !== 200 || !result.user || !result.token) {
							throw result;
						}

						servers.modifyServer({ ...result, server });
						await this.$db.saveServerSettings(server.settings);
						alreadyConnected = false;
						remoteLoggedIn = true;
					}
				}

				if (remoteLoggedIn && (!server.sync || !this.$databases[server.url])) {
					server.sync = this.$pouch.sync(server.name, server.url);
					DEBUG && console.log("sync", JSON.parse(JSON.stringify(server.sync)));
				}

				this.startHeartbeat(server, alreadyConnected);
				DEBUG && console.log("server started");
				DEBUG && console.groupEnd();
				return true;
			} catch (error) {
				console.error(error);
				toaster.error({ error, message: `Echec de la connexion au serveur ${server.label}`});
				servers.disconnectServer(server);
				DEBUG && console.groupEnd();
				return false;
			}
		},
		async login(username: string, password: string) {
			if (!servers.settings) {
				toaster.error({ message: "Les réglages ne sont pas définis. Veuillez recharger la page." });
				return;
			}
			let response: Response = await fetch("/account.php", {
				method: "POST",
				body: JSON.stringify({ action: "login", username, password })
			});
			let result: AccountResponse = await response.json();
			if (result.code !== 200 || !result.account || !result.token) {
				throw result;
			}

			servers.login({ account: result.account, token: result.token });
			await this.$db.saveGlobalSettings(servers.settings);
			this.startAllServers();
			return true;
		},
		async logout() {
			if (!servers.settings) {
				toaster.error({ message: "Les réglages ne sont pas définis. Veuillez recharger la page." });
				return;
			}

			servers.list.forEach(s => {
				if (s.sync) {
					s.sync?.cancel();
					s.sync = null;
				}
				if (this.$databases[s.url]) {
					this.$pouch.disconnect(s.url);
					this.$pouch.close(s.url);
				}
				if (this.$databases[s.name]) {
					this.$databases[s.name].removeCrypto();
					this.$pouch.close(s.name);
				}
				servers.disconnectServer(s);
			});

			servers.stopHeartbeat();
			servers.logout();

			await this.$db.saveGlobalSettings(servers.settings);
		},
		async sendAccountHeartbeat() {
			if (servers.settings && servers.token) {
				try {
					let response = await fetch("/account.php", {
						method: "POST",
						body: JSON.stringify({ action: "refresh" }),
						headers: {
							Authorization: `Bearer ${servers.token}`
						}
					});
					let result: AuthResponse = await response.json();
					if (result.code !== 200 || !result.token) {
						throw { "message": "Jeton invalide. Veuillez vous reconnecter.", data: result };
					}

					servers.refreshToken(result.token);
					await this.$db.saveGlobalSettings(servers.settings);

					DEBUG && console.log("account heartbeat ok");
				} catch (error) {
					DEBUG && console.log("account heartbeat error", error);
					this.logout();
				}
			}
		},
		async startAccountHeartbeat(immediate: boolean = false) {
			if (servers.token) {
				let heartbeat = window.setInterval(() => { this.sendAccountHeartbeat() }, ACCOUNT_HEARTBEAT_LENGTH);
				servers.setHeartbeat(heartbeat);

				if (immediate) {
					await this.sendAccountHeartbeat();
				}
			}
		},
		async updateAccount(account: Account | null) {
			account = account || servers.account;
			if (!account || !servers.settings) {
				return;
			}
			try {
				let response = await fetch("/account.php", {
					method: "POST",
					body: JSON.stringify({
						action: "update",
						account: account
					}),
					headers: {
						Authorization: `Bearer ${servers.token}`
					}
				});

				let result: AccountResponse = await response.json();
				if (result.code !== 200 || !result.account) {
					throw result;
				}

				servers.setAccount(result.account);
				if (result.token) {
					servers.refreshToken(result.token);
				}

				return await this.$db.saveGlobalSettings(servers.settings);
			} catch (error) {
				console.error(error);
				toaster.error({ error });
				return false;
			}
		}
	},
	created() {
		window.addEventListener("online", this.updateOnlineStatus);
		window.addEventListener("offline", this.updateOnlineStatus);

		this.$on("pouchdb-sync-error", this.syncStatusError);
		this.$on("pouchdb-sync-paused", this.syncStatusPaused);
		this.$on("pouchdb-sync-change", this.syncStatusChange);
		this.$on("pouchdb-sync-active", this.syncStatusActive);
		this.$on("pouchdb-sync-denied", this.syncStatusDenied);
		this.$on("pouchdb-sync-complete", this.syncStatusComplete);

		toaster.setToaster(this.$bvToast);
		toaster.setDefaultOptions({
			solid: true,
			title: "SFMPro",
			toaster: "b-toaster-top-center"
		});

		if (DEBUG) {
			// @ts-ignore
			window.getHeartbeats = () => {
				console.log("Account heartbeat", servers.heartbeat);
				servers.list.forEach(s => {
					console.log("Server heartbeat", s.name, s.heartbeat);
				});
			}
		}
	},
	beforeDestroy() {
		this.stopAllHeartbeats();
		window.removeEventListener("online", this.updateOnlineStatus);
		window.removeEventListener("offline", this.updateOnlineStatus);
		this.$off("pouchdb-sync-error", this.syncStatusError);
		this.$off("pouchdb-sync-paused", this.syncStatusPaused);
		this.$off("pouchdb-sync-change", this.syncStatusChange);
		this.$off("pouchdb-sync-active", this.syncStatusActive);
		this.$off("pouchdb-sync-denied", this.syncStatusDenied);
		this.$off("pouchdb-sync-complete", this.syncStatusComplete);
	},
	render(h) {
		return h(Main);
	},
});

if (DEBUG) {
	// @ts-ignore
	window.app = app;
}
