import { LimitMachineTask, LocationTask, ReadingTask, ReferenceTask } from "@/loader";

export interface ControlTask extends ReadingTask, LocationTask, ReferenceTask, LimitMachineTask {
}

export namespace ControlTask {
	export function instanceOf(value: any): value is ControlTask {
		return ReadingTask.instanceOf(value)
			&& LocationTask.instanceOf(value)
			&& ReferenceTask.instanceOf(value)
			&& LimitMachineTask.instanceOf(value);
	}

	export function check(data: any): ControlTask {
		ReadingTask.check(data);
		LocationTask.check(data);
		ReferenceTask.check(data);
		LimitMachineTask.check(data);
		return data;
	}

	export function create(): ControlTask {
		return {
			limitCredit: 0,
			limitInsert: 0,
			limitJackpot: 0,
			limitPayment: 0,
			location: "",
			reading: "",
			ref: "",
		};
	}
}
