
































































































































































import { Component, Watch, Mixins, Ref } from "vue-property-decorator";
import dayjs from "dayjs";
import {
	Company,
	servers,
	EditView,
	missionModal,
	TaskAction,
	Task,
	MachineStatus,
	Address,
	toaster,
	Coordinate,
} from "@/loader";
import AddressesCard from "../../../component/address/AddressesCard.vue";
import CoordinatesCard from "../../../component/coordinate/CoordinatesCard.vue";
import MissionModal from "../../../component/mission/MissionModal.vue";
import MissionsTable from "../../../component/mission/MissionsTable.vue";
import MachinesTable from "../../../component/machine/MachinesTable.vue";

@Component({
	components: {
		AddressesCard,
		CoordinatesCard,
		MissionModal,
		MissionsTable,
		MachinesTable,
	}
})
export default class CompanyView extends Mixins<EditView<Company>>(EditView) {
	@Ref()
	public readonly missionModal!: MissionModal | null;

	public subsidiaries: Company[] = [];

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get itemFactory(): Function | null {
		return Company.create;
	}

	public get cloneFactory(): (base: Company) => Company {
		return Company.clone;
	}

	public get checkDocFunction(): Function | null {
		return Company.check;
	}

	public get routeName(): string {
		return "company";
	}

	public get title(): string {
		if (this.item?.name) {
			return "Casino &mdash; " + this.item.name;
		}
		return "Casino";
	}

	public get erpUpdateLabel() {
		return this.item?.erpId ? dayjs(this.item.erpUpdateDate).format("[Dernière mise à jour le] L [à] LT") : "";
	}

	public removeAddress(address: Address) {
		if (!this.item || !this.canEdit || !address) {
			toaster.invalidOperation();
			return;
		}
		this.item.addresses.remove(address);
		this.changed = true;
	}

	public removeCoordinate(coordinate: Coordinate) {
		if (!this.item || !this.canEdit || !coordinate) {
			toaster.invalidOperation();
			return;
		}
		this.item.coordinates.remove(coordinate);
		this.changed = true;
	}

	public async prepareControl() {
		if (!this.item) {
			return;
		}

		const machines = await this.$db.queryMachinesByOwner(this.item._id);
		const tasks = machines.filter(m => m.status === MachineStatus.installed)
			.map(m => Task.create("", m._id, TaskAction.control));
		if (this.missionModal) {
			this.missionModal.tasks.push(...tasks);
			this.missionModal.show();
		}
	}

	public mounted() {
		this.loadRelations();
	}

	public async loadRelations() {
		this.subsidiaries = await this.$db.queryCompaniesByOwner(this.id);
	}

	@Watch("id")
	public onIdChanged(newValue: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
