import { LocationTask, ReadingTask, ReferenceTask } from "@/loader";

export interface ReactivationTask extends ReadingTask, LocationTask, ReferenceTask {
}

export namespace ReactivationTask {
	export function instanceOf(value: any): value is ReactivationTask {
		return ReadingTask.instanceOf(value)
			&& LocationTask.instanceOf(value)
			&& ReferenceTask.instanceOf(value);
	}

	export function check(data: any): ReactivationTask {
		ReferenceTask.check(data);
		LocationTask.check(data);
		ReadingTask.check(data);
		return data;
	}

	export function create(ref: string = "", location: string = ""): ReactivationTask {
		return {
			ref,
			location,
			reading: "",
		};
	}
}
