import { Component, Mixins, Model, Prop, Ref } from "vue-property-decorator";
import {
	EditComponent,
	Machine,
	MachineControllerTask,
	MachineGameTask,
	MachinePartTask,
	MachineSoftwareTask,
	Mission,
	ReplacementAction,
	Task,
} from "@/loader";
import ReadingTaskEditor from "./ReadingTaskEditor";
import GameReplacementTable from "./GameReplacementTable.vue";
import PartReplacementTable from "./PartReplacementTable.vue";
import ControllerReplacementTable from "./ControllerReplacementTable.vue";
import SoftwareReplacementTable from "./SoftwareReplacementTable.vue";
import { BTabs } from "bootstrap-vue";

@Component({
	components: {
		ReadingTaskEditor,
		GameReplacementTable,
		PartReplacementTable,
		ControllerReplacementTable,
		SoftwareReplacementTable,
	}
})
// @ts-ignore
export class TaskDataEditorMixin<T> extends Mixins<EditComponent<T, Task>>(EditComponent) {
	@Model("input", { default: null })
	public value!: T | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Ref()
	public readonly readingTaskEditor!: ReadingTaskEditor;

	@Ref()
	public readonly tabs!: BTabs;

	public item: T | null = null;
	public tabIndex: number = 0;
	public nextShowOnlyNotEmpty: boolean = true;

	public get toNewReading() {
		return (before: boolean = false) => ({
			name: 'reading',
			params: {
				id: 'nouvelle',
				machineId: this.parent?.machineId || null,
			},
			query: {
				missionId: this.mission?._id || null,
				taskId: this.parent?._id || null,
				before,
			},
		});
	}

	public get gamesCount() {
		return MachineGameTask.instanceOf(this.item) && this.item.games.filter(x => x.action !== ReplacementAction.none).length || 0;
	}

	public get gamesCountVariant() {
		return this.gamesCount ? "info" : "outline-secondary";
	}

	public get partsCount() {
		return MachinePartTask.instanceOf(this.item) && this.item.parts.filter(x => x.action !== ReplacementAction.none).length || 0;
	}

	public get partsCountVariant() {
		return this.partsCount ? "info" : "outline-secondary";
	}

	public get controllersCount() {
		return MachineControllerTask.instanceOf(this.item) && this.item.controllers.filter(x => x.action !== ReplacementAction.none).length || 0;
	}

	public get controllersCountVariant() {
		return this.controllersCount ? "info" : "outline-secondary";
	}

	public get softwaresCount() {
		return MachineSoftwareTask.instanceOf(this.item) && this.item.softwares.filter(x => x.action !== ReplacementAction.none).length || 0;
	}

	public get softwaresCountVariant() {
		return this.softwaresCount ? "info" : "outline-secondary";
	}

	public get tabCount(): number[] {
		return [];
	}

	public saveReading(finish: boolean = false) {
		if (this.readingTaskEditor) {
			this.readingTaskEditor.save(finish);
		}
	}

	public next(event?: Event) {
		if (this.readingTaskEditor) {
			this.readingTaskEditor.focus();
		}
	}

	public focus() {
		if (this.readingTaskEditor) {
			this.readingTaskEditor.focus();
		}
	}

	public nextTab(currentTab?: number) {
		if (!this.tabs) {
			return;
		}
		let newIndex: number | undefined = undefined;
		currentTab = currentTab || this.tabIndex;
		if (this.nextShowOnlyNotEmpty) {
			newIndex = this.tabCount.slice(currentTab + 1).filter(x => x > 0)?.[0]
				|| this.tabCount.slice(0, currentTab).filter(x => x > 0)?.[0];
		}
		if (newIndex === undefined) {
			newIndex = currentTab === this.tabs.tabs.length - 1 ? 0 : currentTab + 1;
		}
		this.tabIndex = newIndex;
	}

	public mounted() {
		this.nextTab(-1);
	}
}
