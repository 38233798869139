import {
	checkValue,
	cloneObject,
	ConfigStatus,
	MachineController,
	MachineGame,
	MachinePart,
	MachineSoftware,
	ReplacementAction,
	UUID,
} from "@/loader";

export interface Replacement {
	action: ReplacementAction
	/**
	 * Represents the item's ID to remove or to be replaced by the new one.
	 *
	 * If the action is move, this ID represents the item's ID to move.
	 */
	oldId: UUID | null
}

export namespace Replacement {
	export function instanceOf(value: any): value is Replacement {
		return value && typeof value === "object" && "oldId" in value && "action" in value;
	}

	export function create(
		oldId: UUID | null = null,
		action: ReplacementAction = ReplacementAction.none,
	): Replacement {
		return {
			oldId,
			action,
		}
	}

	export const clone: (base: Replacement) => Replacement = cloneObject;

	export function check(data: any): Replacement {
		checkValue(data, "oldId", null);
		checkValue(data, "action", ReplacementAction.none);
		return data;
	}
}

export interface PartReplacement extends Replacement, MachinePart {
}

export namespace PartReplacement {
	export function instanceOf(value: any): value is PartReplacement {
		return Replacement.instanceOf(value) && MachinePart.instanceOf(value);
	}

	export function create(
		oldId: UUID | null = null,
		action: ReplacementAction = ReplacementAction.none,
		categoryId: UUID = "",
	): PartReplacement {
		return {
			...Replacement.create(oldId, action),
			...MachinePart.create("", "", categoryId),
		}
	}

	export function clone(base: PartReplacement|MachinePart): PartReplacement {
		let default_data: Partial<PartReplacement> = { status: ConfigStatus.draft };
		if (!PartReplacement.instanceOf(base)) {
			default_data.oldId = base.id;
			default_data.action = ReplacementAction.none;
		}
		return cloneObject(<PartReplacement>base, default_data);
	}

	export function check(data: any): PartReplacement {
		Replacement.check(data);
		MachinePart.check(data);
		return data;
	}
}

export interface GameReplacement extends Replacement, MachineGame {
}

export namespace GameReplacement {
	export function instanceOf(value: any): value is GameReplacement {
		return Replacement.instanceOf(value) && MachineGame.instanceOf(value);
	}

	export function create(
		oldId: UUID | null = null,
		action: ReplacementAction = ReplacementAction.none,
	): GameReplacement {
		return {
			...Replacement.create(oldId, action),
			...MachineGame.create(),
		}
	}

	export function clone(base: GameReplacement|MachineGame): GameReplacement {
		let default_data: Partial<GameReplacement> = { status: ConfigStatus.draft };
		if (!GameReplacement.instanceOf(base)) {
			default_data.oldId = base.id;
			default_data.action = ReplacementAction.none;
		}
		return cloneObject(<GameReplacement>base, default_data);
	}

	export function check(data: any): GameReplacement {
		Replacement.check(data);
		MachineGame.check(data);
		return data;
	}
}

export interface ControllerReplacement extends Replacement, MachineController {
}

export namespace ControllerReplacement {
	export function instanceOf(value: any): value is ControllerReplacement {
		return Replacement.instanceOf(value) && MachineController.instanceOf(value);
	}

	export function create(
		oldId: UUID | null = null,
		action: ReplacementAction = ReplacementAction.none,
	): ControllerReplacement {
		return {
			...Replacement.create(oldId, action),
			...MachineController.create(),
		}
	}

	export function clone(base: ControllerReplacement|MachineController): ControllerReplacement {
		let default_data: Partial<ControllerReplacement> = { name: base.name, status: ConfigStatus.draft };
		if (!ControllerReplacement.instanceOf(base)) {
			default_data.action = ReplacementAction.none;
			default_data.oldId = base.id;
		}
		return cloneObject(<ControllerReplacement>base, default_data);
	}

	export function check(data: any): ControllerReplacement {
		Replacement.check(data);
		MachineController.check(data);
		return data;
	}
}

export interface SoftwareReplacement extends Replacement, MachineSoftware {
}

export namespace SoftwareReplacement {
	export function instanceOf(value: any): value is SoftwareReplacement {
		return Replacement.instanceOf(value) && MachineSoftware.instanceOf(value);
	}

	export function create(
		oldId: UUID | null = null,
		action: ReplacementAction = ReplacementAction.none,
	): SoftwareReplacement {
		return {
			...Replacement.create(oldId, action),
			...MachineSoftware.create(),
		}
	}

	export function clone(base: SoftwareReplacement | MachineSoftware): SoftwareReplacement {
		let default_data: Partial<SoftwareReplacement> = { status: ConfigStatus.draft };
		if (!SoftwareReplacement.instanceOf(base)) {
			default_data.oldId = base.id;
			default_data.action = ReplacementAction.none;
		}
		return cloneObject(<SoftwareReplacement>base, default_data);
	}

	export function check(data: any): SoftwareReplacement {
		Replacement.check(data);
		MachineSoftware.check(data);
		return data;
	}
}
