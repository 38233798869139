import { checkValue, Compatibility } from "@/loader";

export interface Compatible {
	compatible: Compatibility;
}

export namespace Compatible {
	export function instanceOf(value: any): value is Compatible {
		return value && typeof value === "object" && "compatible" in value;
	}

	export function check(data: any): Compatible {
		checkValue(data, "compatible", Compatibility.unknown);
		return data;
	}
}

