import { arrayToFlag, BvListItem, flagToArray, flagToString, flagToStrings } from "@/loader";

export enum MachineConfig {
	none = 0,
	tito = 1,
	cashless = 2,
	bill = 4,
	video = 8,
	roundTop = 16,
	slantTop = 32,
}

function machineConfigToString(machineconfig: MachineConfig): string {
	switch (machineconfig) {
		case MachineConfig.tito: return "Compteurs Tito";
		case MachineConfig.cashless: return "Compteurs Cashless";
		case MachineConfig.bill: return "Lecteur";
		case MachineConfig.video: return "Vidéo";
		case MachineConfig.roundTop: return "Round Top";
		case MachineConfig.slantTop: return "Slant Top";
		default: return "";
	}
}

export namespace MachineConfig {
	export const LIST: BvListItem[] = Object.values(MachineConfig)
		.filter(value => typeof value === "number" && value > 0)
		.map(value => ({ value, text: toString(<MachineConfig>value) }));

	export function toArray(value: MachineConfig): MachineConfig[] {
		return flagToArray(value);
	}

	export function fromArray(values: MachineConfig[]): MachineConfig {
		return arrayToFlag(values);
	}

	export function toStrings(value: MachineConfig): string[] {
		return flagToStrings(value, machineConfigToString);
	}

	export function toString(value: MachineConfig, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, machineConfigToString, separator, last_separator);
	}
}


