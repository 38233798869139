import {
	arrayToFlag,
	flagToArray,
	flagToString,
	flagToStrings,
	BvListItem,
	MachineStatus,
	EventType
} from "@/loader";

export enum TaskAction {
	none = 0,
	/** Entrée en stock */
	stock = 1,
	/** Installation / mIse en service */
	installation = 2,
	/** Mix/modification d'une machine */
	mix = 4,
	/** Réparation */
	repair = 8,
	/** Visite de contrôle */
	control = 16,
	/** Remise à zéro d'un ou plusieurs compteurs */
	reset_counter = 32,
	/** Changement d'un ou plusieurs compteurs */
	change_counter = 64,
	/** Mise en réserve */
	reserve = 128,
	/** Transfert à un autre casino d'un même groupe */
	transfer = 256,
	/** Destruction finale */
	destroy = 512,
	/** Sortie de réserve */
	reactivation = 1024,
	/** Vente à un autre casino */
	sell = 2048,
	/** Fin d'exploitation
	 * @deprecated used in SFMPro v1 DB
	 */
	end_operation = 4096,
}

function taskActionToString(value: TaskAction): string {
	switch (value) {
		case TaskAction.none: return "Non définie";
		case TaskAction.stock: return "Stocker";
		case TaskAction.installation: return "Installer";
		case TaskAction.mix: return "Mixer";
		case TaskAction.repair: return "Réparation";
		case TaskAction.control: return "Visite de contrôle";
		case TaskAction.reset_counter: return "RAZ compteurs";
		case TaskAction.change_counter: return "Changer les compteurs";
		case TaskAction.reserve: return "Mettre en réserve";
		case TaskAction.transfer: return "Transférer";
		case TaskAction.destroy: return "Détruire";
		case TaskAction.reactivation: return "Réactiver";
		case TaskAction.sell: return "Vendre";
		case TaskAction.end_operation: return "Fin d'exploitation";
		default: return "?";
	}
}

export namespace TaskAction {
	export const LIST: BvListItem[] = Object.values(TaskAction)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<TaskAction>value) }));

	export const NEED_COMPANY =
		TaskAction.installation
		+ TaskAction.reserve
		+ TaskAction.transfer
		+ TaskAction.reactivation
		+ TaskAction.sell;

	export function sort(a: TaskAction, b: TaskAction): number {
		if (a !== b) {
			if (a === TaskAction.control) {
				return -1;
			} else if (b === TaskAction.control) {
				return 1;
			}
		}
		return a - b;
	}

	export function toArray(value: TaskAction): TaskAction[] {
		return flagToArray(value);
	}

	export function fromArray(values: TaskAction[]): TaskAction {
		return arrayToFlag(values);
	}

	export function toStrings(value: TaskAction): string[] {
		return flagToStrings(value, taskActionToString);
	}

	export function toString(value: TaskAction, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, taskActionToString, separator, last_separator);
	}

	export function toMachineStatus(value: TaskAction): MachineStatus | null {
		switch (value) {
			case TaskAction.destroy:
				return MachineStatus.destroyed;
			case TaskAction.stock:
			case TaskAction.reserve:
				return MachineStatus.stocked;
			case TaskAction.installation:
			case TaskAction.reactivation:
				return MachineStatus.installed;
		}
		return null;
	}

	export function toEventType(value: TaskAction): EventType | null {
		switch (value) {
			case TaskAction.stock:
			case TaskAction.reserve:
				return EventType.stored;
			case TaskAction.change_counter:
				return EventType.counter_changed;
			case TaskAction.control:
				return EventType.controlled;
			case TaskAction.destroy:
				return EventType.destroyed;
			case TaskAction.installation:
				return EventType.installed;
			case TaskAction.repair:
				return EventType.repaired;
			case TaskAction.mix:
				return EventType.mixed;
			case TaskAction.reactivation:
				return EventType.reactivated;
			case TaskAction.reset_counter:
				return EventType.counter_reset;
			case TaskAction.sell:
				return EventType.sold;
		}
		return null;
	}
}
