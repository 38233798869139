











import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import {
	busy,
	DEBUG,
	toaster,
	servers,
	Account,
	EditObject,
	createAccount,
	AccountResponse,
} from '@/loader';
import AccountEditor from "./AccountEditor.vue";

@Component({
	components: {
		AccountEditor,
	},
})
export default class CreateAccount extends Mixins<EditObject<Account>>(EditObject) {
	@Prop({ default: "div" })
	public tag!: any;

	@Ref()
	public readonly editor!: AccountEditor;

	public get itemFactory(): Function | null {
		return () => {
			let item = createAccount("");
			return item;
		};
	}

	public async createAccount() {
		if (!this.editor?.check()) {
			toaster.invalidData();
			return;
		}

		if (!servers.settings) {
			toaster.error({ message: "Les réglages ne sont pas définis. Veuillez recharger la page." });
			return;
		}

		DEBUG && console.group("CreateAccount");
		busy.start("Création du compte ...");
		try {
			let response: Response = await fetch("/account.php", {
				method: "POST",
				body: JSON.stringify({ action: "create", account: this.item })
			});
			DEBUG && console.log("response", response);
			let result: AccountResponse = await response.json();
			DEBUG && console.log("result", result);
			if (result.code !== 200 || !result.account || !result.token) {
				throw result;
			}

			servers.settings.accounts.push(result.account.name);
			servers.login({ account: result.account, token: result.token });

			await this.$pouch.info(result.account.name);
			await this.$databases[result.account.name].crypto(result.account.key || "", []);

			await this.$db.saveGlobalSettings(servers.settings);
			DEBUG && console.log("settings saved");

			this.$emit("created", result.account);
		} catch (error: any) {
			DEBUG && console.error(error);
			servers.logout();
			toaster.error({ error, noAutoHide: false });
		}
		DEBUG && console.groupEnd();
		busy.stop();
	}

	public mounted() {
		if (this.itemFactory) {
			this.item = this.itemFactory();
		}
	}
}
