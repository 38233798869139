























import { Component, Mixins } from "vue-property-decorator";
import { TutorialComponent } from '@/loader';

@Component
export default class TutorialWelcome extends Mixins(TutorialComponent) {
	public title = "Bienvenue sur SFMPro";
	public icon = "";

	public get host() {
		return window.location.host;
	}
}
