








































import { Vue, Component, Model, Prop, Watch } from "vue-property-decorator";
import { Mission, Company, User } from '@/loader';

@Component
export default class MissionHeader extends Vue {
	@Model("change", { type: Object, required: true })
	public item!: Mission;

	@Prop({ type: String, default: "div" })
	public readonly tag!: string;

	public company: Company | null = null;
	public users: User[] = [];
	public userToString = User.toString;

	public async loadRelations() {
		this.company = await this.$db.getCompany(this.item?.companyId);
		this.users = (await this.$db.allUsers()).filter(u => this.item?.techs.includes(u._id) || false);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue?: Mission, oldValue?: Mission) {
		if (newValue != oldValue) {
			this.loadRelations();
		}
	}
}
