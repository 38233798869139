<template>
	<component :is="tag" v-if="parent">
		<title-card
			class="mb-3"
			title="Versions"
			title-tag="h3"
			title-cols="auto"
			button-cols="auto"
			only-title
			can-create
			@create="createNew"
		></title-card>
		<b-table-simple class="table-edit" striped hover stacked="sm" small>
			<b-thead>
				<b-tr>
					<b-th style="min-width: 80px"></b-th>
					<b-th class="w-100">Version</b-th>
					<b-th></b-th>
				</b-tr>
			</b-thead>
			<v-draggable tag="tbody" v-model="array" handle=".btn-move">
				<b-tr v-for="(version, index) in parent.versions" :key="index">
					<b-td>
						<b-button
							v-if="canEdit"
							class="btn-move"
							variant="secondary"
							size="sm"
						>
							<fa :icon="['fal', 'arrows']" fixed-width></fa>
							#{{ index + 1 }}
						</b-button>
						<span v-else> #{{ index + 1 }} </span>
					</b-td>
					<b-td>
						<b-form-input
							required
							ref="inputs"
							type="text"
							name="version"
							:value="version"
							:disabled="!canEdit"
							:state="states.versions"
							@input="focusOn('versions')"
							@update="setValue($event, index)"
							@keyup.ctrl.enter="createNew"
						></b-form-input>
						<b-form-invalid-feedback>
							{{ invalidFeedbacks.versions }}
						</b-form-invalid-feedback>
					</b-td>
					<b-td class="text-right">
						<action-row
							grouped
							icon-only
							:can-delete="canEdit"
							@delete="remove(version, index)"
						></action-row>
					</b-td>
				</b-tr>
			</v-draggable>
		</b-table-simple>
		<div class="d-flex justify-content-end">
			<p class="text-muted">
				Ctrl+Entrée pour ajouter une nouvelle version lorsque vous êtes
				dans un champ de version
			</p>
			<b-button variant="primary" @click="createNew">
				<fa icon="plus" fixed-width></fa>
				Ajouter
			</b-button>
		</div>
	</component>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { EditList, Software, toaster } from "@/loader";

@Component
export default class SoftwareVersionList extends Mixins<EditList<String, Software>>(EditList) {
	public get array(): string[] {
		return this.parent ? this.parent.versions : [];
	}
	public set array(value: string[]) {
		if (this.parent) {
			this.parent.versions = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return () => "";
	}

	public get cloneFactory(): (base: String) => String {
		return String; //(x) => String(x);
	}

	public setValue(value: string, index: number) {
		this.array[index] = value;
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (Array.isArray(this.$refs.inputs) && this.$refs.inputs.length > 0) {
				const input = this.$refs.inputs?.[this.$refs.inputs.length - 1] as HTMLInputElement;
				if (input) {
					input.focus();
				}
			}
		});
	}

	public remove(item?: String, index?: number) {
		if (!this.parent || index === undefined || index < 0 || index >= this.array.length) {
			toaster.invalidOperation({ operation: "Supprimer" });
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Supprimer" });
			return;
		}
		this.array.splice(index, 1);
		this.$emit("deleted", item, index);
	}
}
</script>
