






















































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { SoftwareCompatibility, Compatibilities, EditComponent, Software, SoftwareSelect } from "@/loader";

@Component
export default class SoftwareCompatibilityRow extends Mixins<EditComponent<SoftwareCompatibility, Compatibilities>>(EditComponent) {
	public selectedSoftware: Software | null = null;

	public invalidFeedbacks = {
		softwareId: "Le compteur ne peut pas être vide",
		definition: "La définition ne peut pas être vide",
		compatible: "La compatibilité ne peut pas être vide",
		minVersion: "",
		maxVersion: "",
	}

	public get softwareId() {
		return this.item?.softwareId;
	}

	public get states() {
		return {
			softwareId: this.item && this.focused.softwareId ? !!this.item.softwareId && !!this.item.definition : null,
			definition: this.item && this.focused.definition ? !!this.item.softwareId && !!this.item.definition : null,
			compatible: this.item && this.focused.compatible ? !!this.item.compatible : null,
			minVersion: this.item && this.focused.minVersion ? true : null,
			maxVersion: this.item && this.focused.maxVersion ? true : null,
		}
	}

	@Watch("softwareId")
	public async softwareIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.selectedSoftware = await this.$db.getSoftware(newValue);
		}
	}

	public async mounted() {
		this.selectedSoftware = await this.$db.getSoftware(this.item?.softwareId);
	}
}
