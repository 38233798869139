































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	DEBUG,
	Brand,
	Company,
	createListFilter,
	DocBrand,
	DocumentList,
	Game,
	Machine,
	Platform,
	servers,
	MachineGame,
	MachineStatus,
	MachineType,
	toaster,
	ValueUnit,
	ConfigStatus,
	MachineConfig,
	Denomination,
	GameRate,
	BvTableFieldArray,
	PropertyNames,
} from "@/loader";
import MachineHeader from "./MachineHeader.vue";

@Component({
	components: {
		MachineHeader
	}
})
export default class MachinesTable extends Mixins<DocumentList<Machine>>(DocumentList) {
	@Prop({ type: String, default: null })
	public companyId!: string | null;

	public MachineConfig = MachineConfig;
	public MachineStatus = MachineStatus;
	public MachineType = MachineType;

	public GAME_STARTKEY = Game.STARTKEY;
	public GAME_ENDKEY = Game.ENDKEY;
	public PLATFORM_STARTKEY = Platform.STARTKEY;
	public PLATFORM_ENDKEY = Platform.ENDKEY;
	public COMPANY_STARTKEY = Company.STARTKEY;
	public COMPANY_ENDKEY = Company.ENDKEY;

	public sort: PropertyNames<Machine> = "serial";
	public filters = {
		status: createListFilter<Brand, Machine>("status"),
		definitions: createListFilter<Brand, Machine>("definition"),
		configs: createListFilter<Brand, Machine>({ valueField: "config", isFlag: true }),
		platforms: createListFilter<DocBrand<Platform>, Machine>("platformId"),
		owners: createListFilter<DocBrand<Company>, Machine>("ownerId"),
		games: createListFilter<DocBrand<Game>, Machine>({
			match(item: Machine, selection: any[]) {
				return item.games.map(mg => mg.gameId).filter(gameId => selection.includes(gameId)).length > 0;
			}
		}),
		packs: createListFilter<DocBrand<Game>, Machine>({
			match(item: Machine, selection: any[]) {
				return item.games.filter(mg => !!mg.packId).map(mg => mg.packId).filter(packId => selection.includes(packId)).length > 0;
			}
		}),
	};

	public get fields(): BvTableFieldArray {
		return [
			{
				key: "name",
				label: "Nom",
				formatter: (value: string, key: string, item: Machine) => {
					if (value) {
						return value;
					}

					let games: Game[] = MachineGame.getRepresentingGames(item.games, [
						...this.filters.games.items.map(x => x.doc),
						...this.filters.packs.items.map(x => x.doc),
					].filter(x => !!x) as Game[]) as Game[];
					return games
						.map(v => v && v.name || null)
						.filter((v, i, a) => !!v && a.indexOf(v) === i) // remove empty and duplicates
						.join(", ")
				},
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "status",
				label: "Statut",
				sortable: true,
				// tdClass: "machine-status",
				// thClass: "machine-status",
			},
			this.companyId ? null : {
				key: "ownerId",
				label: "Casinos",
				formatter: value => this.filters.owners.valueToString(value),
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "serial",
				label: "N°Série",
				sortable: true,
				// tdClass: "machine-serial",
				// thClass: "machine-serial",
			},
			{
				key: "location",
				label: "Socle",
				sortable: true,
				// tdClass: "machine-location",
				// thClass: "machine-location",
			},
			{
				key: "ref",
				label: "N°MAS",
				sortable: true,
			},
			{
				key: "deno",
				label: "Déno",
				formatter: (value: any, key: string, item: Machine) => item.games
					.filter(v => v.status === ConfigStatus.active)
					.map(v => v && v.deno || null)
					.filter((v, i, a) => !!v && a.indexOf(v) === i) // remove empty and duplicates
					.map(v => Denomination.toString(v))
					.join(", "),
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "rate",
				label: "Taux",
				formatter: (value: any, key: string, item: Machine) => item.games
					.filter(v => v.status === ConfigStatus.active)
					.map(v => v && v.rate && GameRate.toString(MachineGame.getRate(v, item.controllers)) || null)
					.filter((v, i, a) => !!v && a.indexOf(v) === i) // remove empty and duplicates
					.join(", "),
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "unit",
				label: "Unité",
				formatter: ValueUnit.toString,
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "bill",
				label: "Lecteur",
				formatter: (value: any, key: string, item: Machine) => (item.config & MachineConfig.bill) === MachineConfig.bill ? "Oui" : "",
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "tito",
				label: "Tito",
				formatter: (value: any, key: string, item: Machine) => (item.config & MachineConfig.tito) === MachineConfig.tito ? "Oui" : "",
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "cashless",
				label: "Cashless",
				formatter: (value: any, key: string, item: Machine) => (item.config & MachineConfig.cashless) === MachineConfig.cashless ? "Oui" : "",
				sortable: true,
				sortByFormatted: true,
			},
			// {
			// 	key: "video",
			// 	label: "Vidéo",
			// 	formatter: (value: any, index: number, item: Machine) => (item.config & MachineConfig.video) ? "Oui" : "",
			// 	sortable: true,
			// 	sortByFormatted: true,
			// },
			// {
			// 	key: "roundTop",
			// 	label: "Round Top",
			// 	formatter: (value: any, index: number, item: Machine) => (item.config & MachineConfig.roundTop) ? "Oui" : "",
			// 	sortable: true,
			// 	sortByFormatted: true,
			// },
			// {
			// 	key: "slantTop",
			// 	label: "Slant Top",
			// 	formatter: (value: any, index: number, item: Machine) => (item.config & MachineConfig.slantTop) ? "Oui" : "",
			// 	sortable: true,
			// 	sortByFormatted: true,
			// },
			{
				key: "platformId",
				label: "Plateformes",
				formatter: value => this.filters.platforms.valueToString(value),
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "action",
				label: "",
				tdClass: "text-right"
			}
		];
	}

	public get startkey(): string {
		return Machine.STARTKEY;
	}

	public get endkey(): string {
		return Machine.ENDKEY;
	}

	public get type(): string {
		return Machine.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get queryOptions(): PouchDB.Query.Options<{}, {}> | undefined {
		return this.companyId ? { group: true, startkey: [this.companyId, null], endkey: [this.companyId, {}] } : undefined;
	}

	public getSearchableString(value: Machine): string {
		if (!value.searchable) {
			Machine.setSearchable(value);
		}
		return value.searchable || "";
	}

	public async loadItems() {
		if (!servers.selected) {
			return;
		}
		if (this.query) {
			this.$pouch.find(this.query, servers.selected.name).then(result => {
				DEBUG && console.log("find?", this.query, result);
				if (DEBUG && result.warning) {
					console.warn(result.warning);
				}
				this.total = result.docs.length;
				this.items = result.docs
					// @ts-ignore
					.filter(d => d?.type === this.type)
					.map(d => ({
						...<Machine>(this.checkDocFunction ? this.checkDocFunction(d) : d),
						searchable: this.getSearchableString(<Machine>d)
					}));
				this.$emit("loaded", this.items);
			}).catch(error => {
				console.error("find?", this.query, error);
				toaster.error({ error });
				this.items = [];
				this.total = 0;
				this.$emit("loaded", this.items);
			});
			return;
		}

		if (this.companyId) {
			this.$pouch.query("machine/by_owner", {
				key: this.companyId,
				include_docs: true
			}, servers.selected.name).then(result => {
				DEBUG && console.log("machine/by_owner?" + this.companyId, result);
				this.total = result.total_rows;
				this.items = result.rows
					// @ts-ignore
					.filter(r => r.doc?.type === this.type)
					.map(r => ({
						...<Machine>(this.checkDocFunction ? this.checkDocFunction(r.doc) : r.doc),
						searchable: this.getSearchableString(<Machine>r.doc)
					}));
				this.$emit("loaded", this.items);
			}).catch(error => {
				console.error("machine/by_owner?" + this.companyId, error);
				toaster.error({ error });
				this.items = [];
				this.total = 0;
				this.$emit("loaded", this.items);
			});
			return;
		}

		this.$pouch.allDocs({
			startkey: this.startkey,
			endkey: this.endkey,
			include_docs: true
		}, servers.selected.name).then(result => {
			DEBUG && console.log("allDocs?" + this.startkey, result);
			this.total = result.total_rows;
			this.items = result.rows
				// @ts-ignore
				.filter(r => r.doc?.type === this.type)
				.map(r => ({
					...<Machine>(this.checkDocFunction ? this.checkDocFunction(r.doc) : r.doc),
					searchable: this.getSearchableString(<Machine>r.doc)
				}));
			this.$emit("loaded", this.items);
		}).catch(error => {
			console.error("allDocs?" + this.startkey, error);
			toaster.error({ error });
			this.items = [];
			this.total = 0;
			this.$emit("loaded", this.items);
		});
	}
}
