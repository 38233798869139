import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataSelect, ParentSelect, Game, GameRate, GameVariant } from '@/loader';

@Component
export default class GameRateSelect extends Mixins<DataSelect, ParentSelect<Game>>(DataSelect, ParentSelect) {
	public localOptions: GameRate[] = [];

	@Prop({ type: Array, default: () => [] })
	public compatibilities!: GameVariant[];

	// @ts-ignore
	public get options(): (GameRate | string)[] {
		let result: (GameRate | string)[] = [];
		if (this.compatibilities.length) {
			let r = this.compatibilities.reduce<GameRate[]>((list, c) => {
				list.push(...c.rates);
				return list;
			}, []).filter((r, i, list) => list.findIndex(x => x.name === r.name) === i)
			r.sort((a, b) => a.name.localeCompare(b.name));
			result = r;
		}

		if (!result.length)  {
			result = this.parent ? this.parent.rates.slice() : [];
			// if (!this.required && !this.multiple) {
			// 	result.unshift(<string>this.emptyItem);
			// }
		}
		return result;
	}

	public getOptionKey(option: GameRate | string) {
		return typeof option === "string" ? option : option.name;
	}

	public getOptionLabel(option: GameRate | string): string {
		return GameRate.instanceOf(option) ? GameRate.toString(option) : this.emptyText;
	}
}
