import { Vue, Component, Prop } from "vue-property-decorator";
import {
	CategoryItem,
	servers,
	DEBUG,
	toaster,
	getCategoryItems,
	getCategoryParents,
	Category
} from "@/loader";

@Component
export class CategoryComponent<T extends Category> extends Vue {
	@Prop({ type: Array, default: () => [] })
	public include!: string[];

	@Prop({ type: Array, default: () => [] })
	public exclude!: string[];

	@Prop({ type: Boolean, default: false })
	public hideDisabled!: boolean;

	@Prop({ type: String, default: "" })
	public filter!: string;

	/** The flat tree */
	public categoriesItems: CategoryItem<T>[] = [];

	public get startkey(): string {
		return "0";
	}

	public get endkey(): string {
		return "0";
	}

	public get query(): string {
		return "";
	}

	public get isFiltered(): boolean {
		return !!this.include.length || !!this.exclude.length || this.hideDisabled || !!this.filter;
	}

	/** Get the tree first level */
	public get categoriesTree(): CategoryItem<T>[] {
		return this.categoriesItems.filter(c => !c.category.parentId);
	}

	public get matchingItems(): CategoryItem<T>[] {
		if (!this.isFiltered) {
			return this.categoriesItems;
		}

		let filter = this.filter.removeDiacritics().toLocaleLowerCase();
		let result = this.categoriesItems.filter(c =>
			(!this.include.length || this.include.includes(c._id))
			&& !this.exclude.includes(c._id)
			&& (!this.hideDisabled || !c.category.disabled)
			&& (!filter
				|| c.category.name.removeDiacritics().toLocaleLowerCase().includes(filter)
			)
		);
		DEBUG && console.log("get matches", {include: this.include, exclude: this.exclude, hideDisabled: this.hideDisabled, filter, result });
		return result;
	}

	public get includeWithParents(): string[] {
		return this.isFiltered ? this.matchingItems.map(getCategoryParents).flat() : [];
	}

	public loadCategories() {
		if (!servers.selected) {
			return;
		}

		let cat: T[] = [];
		let cat_counts: any[] = [];
		this.$pouch.allDocs({
			startkey: this.startkey,
			endkey: this.endkey,
			include_docs: true
		}, servers.selected.name).then(result => {
			DEBUG && console.log("allDocs?" + this.startkey, result);
			cat = result.rows.map(r => <T>r.doc);
			if (servers.selected && this.query) {
				return this.$pouch.query(this.query, { group: true }, servers.selected.name) || { rows: [] };
			} else {
				return { rows: [], offset: 0, total_rows: 0 };
			}
		}).then(result => {
			DEBUG && console.log("query?" + this.query, result);
			cat_counts = result?.rows.map(r => ({ _id: r.key, count: r.value })) || [];
		}).then(() => {
			this.categoriesItems = getCategoryItems(cat, cat_counts);
		}).catch(error => {
			console.error("allDocs?" + this.startkey, error);
			toaster.toast({ title: "Une erreur est survenue", message: `${error}`, variant: "danger" });
		});
	}
}
