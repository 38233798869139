
































import { Vue, Component, Model, Watch, Prop } from "vue-property-decorator";
import { busy, Country, COUNTRY_KEY, DEBUG } from '@/loader';

@Component
export default class CountrySelect extends Vue {
	@Model("input", { default: null })
	public readonly value!: any;

	@Prop({ type: Boolean, default: false })
	public readonly multiple!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly canEdit!: boolean;

	@Prop({ type: Array, default: () => [] })
	public readonly include!: string[];

	@Prop({ type: Array, default: () => [] })
	public readonly exclude!: string[];

	@Prop({ type: Function, default: (option) => option.cca2 })
	public readonly reduce!: Function;

	public get isBusy(): boolean {
		return busy.status;
	}

	public list: Country[] = [];

	public get countries(): Country[] {
		return (this.list || [])
			.filter(c => (!this.include.length || this.include.includes(c.cca2)) && !this.exclude.includes(c.cca2))
			.sort((a, b) => a.translations.fra.common.toString().localeCompare(b.translations.fra.common.toString()));
	}

	public filter(options: Country[], search: string) {
		let search_lower = search.toLowerCase().removeDiacritics();
		let search_upper = search.toUpperCase();
		return options.filter(option =>
			option.cca2.includes(search_upper)
			|| option.cca3.includes(search_upper)
			|| option.translations.fra.common.toLowerCase().removeDiacritics().includes(search_lower));
	}

	public mounted() {
		this.$pouch.allDocs({ startkey: COUNTRY_KEY }).then(response => {
			this.list = response.rows.map(c => c.doc);
		});
	}

	public position(dropdownList, component, { width }) {
		/**
		 * We need to explicitly define the dropdown width since
		 * it is usually inherited from the parent with CSS.
		 */
		dropdownList.style.width = width


		/**
		 * Here we position the dropdownList relative to the $refs.toggle Element.
		 *
		 * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
		 * the dropdownList overlap by 1 pixel.
		 *
		 * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
		 * wrapper so that we can set some styles for when the dropdown is placed
		 * above.
		 */
		const popper = createPopper(component.$refs.toggle, dropdownList, {
			placement: "top",
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, -1],
					},
				},
				{
					name: 'toggleClass',
					enabled: true,
					phase: 'write',
					fn({ state }) {
						component.$el.classList.toggle(
							'drop-up',
							state.placement === 'top'
						);
					},
				},
			],
		});

		/**
		 * To prevent memory leaks Popper needs to be destroyed.
		 * If you return function, it will be called just before dropdown is removed from DOM.
		 */
		return () => popper.destroy()
	}
}
