import { BvListItem } from "@/loader";

export enum Compatibility {
	unknown = 0,
	compatible = 1,
	incompatible = 2,
	required = 3,
}

export namespace Compatibility {
	export function toString(compatibility: Compatibility): string {
		switch (compatibility) {
			case Compatibility.unknown: return "Non définie";
			case Compatibility.compatible: return "Compatible";
			case Compatibility.incompatible: return "Incompatible";
			case Compatibility.required: return "Requis";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(Compatibility)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<Compatibility>value) }));
}
