import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { store } from "@/loader";

@Module({
	name: "settings",
	store,
	dynamic: true,
	namespaced: true,
	preserveState: true,
})
export class SettingsModule extends VuexModule {
	public url!: string;

	@Mutation
	public init() {

	}
}

export const settings = getModule(SettingsModule, store);
