import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	Brand,
	FilterCard,
	PouchResult,
} from "@/loader";

@Component
export class EnumFilterCard extends Mixins(FilterCard) {
	@Prop({ type: Function, default: (r: PouchResult, list: any) => {
		let key = Array.isArray(r.key) ? r.key[r.key.length - 1] : r.key;
		return {
			_id: key,
			name: list?.toString(key) || key,
			count: r.value
		};
	}})
	public itemFactory!: (r: PouchResult, list?: any) => Brand;

	@Prop()
	public list!: any;

	public callItemFactory(r: PouchResult) {
		return this.itemFactory(r, this.list);
	}
}
