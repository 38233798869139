





































import { Vue, Component, Mixins } from "vue-property-decorator";
import { EditList, PartChild, Part } from "@/loader";
import PartChildRow from "./PartChildRow.vue";

@Component({
	components: {
		PartChildRow
	}
})
export default class PartChildTable extends Mixins<EditList<PartChild, Part>>(EditList) {
	public get array(): PartChild[] {
		return this.parent ? this.parent.children : [];
	}

	public get itemFactory(): Function | null {
		return PartChild.create;
	}

	public get cloneFactory(): (base: PartChild) => PartChild {
		return PartChild.clone;
	}
}
