import {
	checkArrayValue,
	checkValue,
	cloneObject,
	CouchDBDocument,
	Deactivable,
	Events,
	generateUUID,
	HasCompatibilities,
	MachineConfig,
	Named,
	SemVer,
	SoftwareType,
	Sortable,
	UUID,
	ValueUnit,
} from "@/loader";

export interface Software extends CouchDBDocument, Events, Named, Deactivable, HasCompatibilities {
	type: "software"
	definition: SoftwareType
	unit: ValueUnit
	disabled: boolean
	versions: SemVer[]
	brand: string | null
	ref: string | null
	name: string
	elecs: SoftwareCounter[]
	mecas: SoftwareCounter[]
	relations: SoftwareCounterRelation[]
}

export interface SoftwareCounter extends Named, Sortable {
	counterId: UUID
	relations: UUID[]
	conditions: MachineConfig
}

export interface SoftwareCounterRelation {
	elecs: UUID[]
	mecas: UUID[]
}

export namespace Software {
	export const TYPE = "software";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Software {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		brand: string | null = null,
		ref: string | null = null,
		definition: SoftwareType = SoftwareType.os,
		unit: ValueUnit = ValueUnit.credit,
		disabled: boolean = false,
	): Software {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			definition,
			unit,
			disabled,
			brand,
			ref,
			name,
			versions: [],
			elecs: [],
			mecas: [],
			relations: [],
			...Events.create(),
			...HasCompatibilities.create(),
		}
	}
	export function clone(base: Software): Software {
		return cloneObject(base, {
			elecs: base.elecs.map(SoftwareCounter.clone),
			mecas: base.mecas.map(SoftwareCounter.clone),
			relations: base.relations.map(SoftwareCounterRelation.clone),
			...HasCompatibilities.clone(base),
		});
	}

	export function check(data: any): Software {
		CouchDBDocument.check(data, TYPE);
		Named.check(data);
		Events.check(data);
		Deactivable.check(data);
		HasCompatibilities.check(data);
		checkValue(data, "unit", ValueUnit.credit);
		checkValue(data, "definition", SoftwareType.os);
		checkValue(data, "brand", null);
		checkValue(data, "ref", null);
		checkValue(data, "name", "");
		checkArrayValue(data, "elecs", SoftwareCounter.check);
		checkArrayValue(data, "mecas", SoftwareCounter.check);
		checkArrayValue(data, "relations", SoftwareCounterRelation.check);
		checkArrayValue(data, "versions");
		if (data.counters !== undefined) {
			delete data.counters;
		}
		return data;
	}
}

export namespace SoftwareCounter {
	export function instanceOf(value: any): value is SoftwareCounter {
		return Named.instanceOf(value) && "counterId" in value;
	}

	export function create(
		counterId: UUID,
		name: string = "",
		order: number = 0,
	): SoftwareCounter {
		return {
			counterId,
			order,
			name,
			relations: [],
			conditions: 0,
		}
	}

	export function clone(base: SoftwareCounter): SoftwareCounter {
		return cloneObject(base, { relations: base.relations.slice() });
	}

	export function check(data: any): SoftwareCounter {
		checkValue(data, "counterId", "");
		checkValue(data, "unit", ValueUnit.credit);
		checkValue(data, "order", 0);
		checkValue(data, "name", "");
		checkValue(data, "conditions", 0);
		checkArrayValue(data, "relations");
		return data;
	}
}

export namespace SoftwareCounterRelation {
	export function create(
		elecs: UUID[] = [],
		mecas: UUID[] = []
	): SoftwareCounterRelation {
		return {
			elecs,
			mecas,
		}
	}

	export function clone(base: SoftwareCounterRelation): SoftwareCounterRelation {
		return cloneObject(base, { elecs: base.elecs.slice(), mecas: base.mecas.slice() });
	}

	export function check(data: any): SoftwareCounterRelation {
		checkArrayValue(data, "elecs");
		checkArrayValue(data, "mecas");
		return data;
	}
}
