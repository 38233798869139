



















































































import { Component, Mixins } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue";
import {
	servers,
	Brand,
	Game,
	GameCategory,
	UUID,
	FilterCard,
	DocumentFilterCard,
	DocumentList,
	createListFilter,
	DocBrand,
	CouchDBDocument
} from "@/loader";

@Component
export default class GamesList extends Mixins<DocumentList<Game>>(DocumentList) {
	public GAME_CATEGORY_STARTKEY = GameCategory.STARTKEY;
	public GAME_CATEGORY_ENDKEY = GameCategory.ENDKEY;

	/** @ts-ignore */
	public sort: string = "name";
	public filters = {
		brands: createListFilter<Brand, Game>("brand"),
		isPack: createListFilter<Brand, Game>({ valueField: "isPack", title: "Pack" }),
		isDisabled: createListFilter<Brand, Game>({ valueField: "disabled", title: "Désactivé" }),
		categories: createListFilter<DocBrand<GameCategory>, Game>("categoryId"),
	};

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "brand",
			label: "Marque",
			sortable: true,
		},
		{
			key: "ref",
			label: "Référence",
			sortable: true,
		},
		{
			key: "categoryId",
			label: "Catégorie",
			formatter: (value) => (this.getCategory(value) || { name: "?" }).name,
			sortable: true,
			sortByFormatted: true,
		},
	];

	public get localStateName(): string {
		return "games";
	}

	public get startkey(): string {
		return Game.STARTKEY;
	}

	public get endkey(): string {
		return Game.ENDKEY;
	}

	public get type(): string {
		return Game.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get checkDocFunction(): Function | null {
		return Game.check;
	}

	public getCategory(id: UUID) {
		return this.filters.categories.items.find(c => c._id === id);
	}

	public getSearchableString(value: Game): string {
		return [
				value.name.removeDiacritics().toLowerCase(),
				value.brand?.removeDiacritics().toLowerCase() || "",
			].filter(x => !!x)
			.join(CouchDBDocument.ENDKEY_SUFFIX);
	}
}
