import { Component, Mixins, Prop } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, CounterType } from "@/loader";

@Component
export class CounterTypeBadge extends Mixins<EnumBadge<CounterType>>(EnumBadge) {
	@Prop({ type: Boolean, default: true })
	public shortText!: boolean;

	public get variant(): BVariant {
		return BVariant.info;
	}

	public get icon(): IconConfig | null {
		switch (this.value) {
			case CounterType.electronic:
				return createIconConfig(['fal', 'bolt']);
			case CounterType.mechanical:
				return createIconConfig(['fal', 'cog']);
			case CounterType.unique:
				return createIconConfig(['fal', 'fingerprint']);
			case CounterType.variable:
				return createIconConfig(['fal', 'waveform-path']);
		}
		return null;
	}

	public get title(): string {
		return this.shortText ? CounterType.toShortString(this.value) : CounterType.toString(this.value);
	}
}
