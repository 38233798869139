import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataSelect, ParentSelect, Game, GAME_LINES, GameVariant } from '@/loader';

@Component
export default class GameLineSelect extends Mixins<DataSelect, ParentSelect<Game>>(DataSelect, ParentSelect) {
	public localOptions: any[] = GAME_LINES;

	@Prop({ type: Array, default: () => [] })
	public compatibilities!: GameVariant[];

	// @ts-ignore
	public get options(): any[] {
		let result = [];
		if (this.compatibilities.length) {
			result = this.compatibilities.reduce((list: string[], c) => {
				list.push(...c.lines);
				return list;
			}, []).filter((x, i, list) => list.indexOf(x) === i);
			result.sort((a: string, b: string) => a.localeCompare(b));
		}

		if (!result.length) {
			result = this.parent ? this.parent.lines.slice() : this.localOptions ? this.localOptions.slice() : [];
			if (!this.required && !this.multiple) {
				result.unshift(this.emptyItem);
			}
		}
		return result;
	}
}
