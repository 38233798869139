import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	Counter,
	servers,
	DocumentSelect,
	busy,
	CounterType,
} from "@/loader";

@Component
export class CounterSelect extends Mixins<DocumentSelect<Counter>>(DocumentSelect) {
	@Prop({ type: Boolean, default: false })
	public onlyElec!: boolean;

	@Prop({ type: Boolean, default: false })
	public onlyMeca!: boolean;

	public async loadOptions() {
		if (!servers.selected) {
			return;
		}
		this.options = (await this.$db.allCounters()).sort((a, b) => a.name.localeCompare(b.name));
	}

	public mounted() {
		this.loadOptions();
	}

	public filterOptions(options: Counter[]): Counter[] {
		if (this.onlyElec) {
			options = options.filter(d => d.definition === CounterType.electronic || (this.showEmpty && d === this.emptyItem));
		}
		if (this.onlyMeca) {
			options = options.filter(d => d.definition === CounterType.mechanical || (this.showEmpty && d === this.emptyItem));
		}
		if ((Array.isArray(this.include) && this.include.length) || !!this.include) {
			let include = Array.isArray(this.include) ? this.include : [this.include];
			options = options.filter(d => include.includes(d._id) || (this.showEmpty && d === this.emptyItem));
		}
		if ((Array.isArray(this.exclude) && this.exclude.length) || !!this.exclude) {
			let exclude = Array.isArray(this.exclude) ? this.exclude : [this.exclude];
			options = options.filter(d => !exclude.includes(d._id) || (this.showEmpty && d === this.emptyItem));
		}
		return options;
	}

	public get editRoute(): string {
		return "counter";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public createOption(option: any): any {
		busy.start(`Création du compteur "${option.name}" ...`);
		let item = Counter.create(CounterType.electronic, option.name);
		this.pushItem(item, option);
	}

	public optionSlot(h: CreateElement, option: Counter): string | VNode | (string | VNode | null)[] {
		return !option._id ? option.name : h("span", [
			option.name,
			h("counter-type-badge", { props: { value: option.definition }, class: "ml-2" })
		]);
	}
}
