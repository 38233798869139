


























































































import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, InstallationTask } from "@/loader";
import CheckMachineTaskEditor from "../common/CheckMachineTaskEditor.vue";

@Component({
	components: {
		CheckMachineTaskEditor
	}
})
export default class InstallationTaskEditor extends Mixins<TaskDataEditorMixin<InstallationTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire après une installation.",
	}

	public get tabCount(): number[] {
		return [this.gamesCount, this.partsCount, this.softwaresCount, this.controllersCount, 1];
	}

	public mounted() {
		if (this.item && !this.item.companyId && this.mission?.companyId) {
			this.item.companyId = this.mission.companyId;
		}
	}
}
