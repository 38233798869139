









































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { toaster, EditList, Game, GameRate } from "@/loader";
import GameRateRow from "./GameRateRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		GameRateRow
	},

})
export default class GameRateTable extends Mixins<EditList<GameRate, Game>>(EditList) {
	public get array(): GameRate[] {
		return this.parent ? this.parent.rates : [];
	}
	public set array(value: GameRate[]) {
		if (this.parent) {
			this.parent.rates = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return GameRate.create;
	}

	public get cloneFactory(): (base: GameRate) => GameRate {
		return GameRate.clone;
	}
}
