import {
	arrayToFlag,
	BvListItem,
	flagToArray,
	flagToString,
	flagToStrings
} from "@/loader";

export enum Denomination {
	c1 = 1,
	c2 = 2,
	c5 = 4,
	c10 = 8,
	c20 = 16,
	c25 = 32,
	c50 = 64,
	e1 = 128,
	e2 = 256,
	e5 = 512,
	e10 = 1024,
	e20 = 2048,
	e50 = 4096
}

function denominationToString(value: Denomination): string {
	switch (value) {
		case Denomination.c1: return "1¢";
		case Denomination.c2: return "2¢";
		case Denomination.c5: return "5¢";
		case Denomination.c10: return "10¢";
		case Denomination.c20: return "20¢";
		case Denomination.c25: return "25¢";
		case Denomination.c50: return "50¢";
		case Denomination.e1: return "1€";
		case Denomination.e2: return "2€";
		case Denomination.e5: return "5€";
		case Denomination.e10: return "10€";
		case Denomination.e20: return "20€";
		case Denomination.e50: return "50€";
		default: return "?";
	}
}

export namespace Denomination {
	export const LIST: BvListItem[] = Object.values(Denomination)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<Denomination>value) }));

	export function toArray(value: Denomination): Denomination[] {
		return flagToArray(value);
	}

	export function fromArray(values: Denomination[]): Denomination {
		return arrayToFlag(values);
	}

	export function toStrings(value: Denomination): string[] {
		return flagToStrings(value, denominationToString);
	}

	export function toString(value: Denomination | null, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, denominationToString, separator, last_separator);
	}
}
