





















































































































































































import { Component, Mixins, Watch, Model, Prop } from "vue-property-decorator";
import { EditObject, Machine, MachineConfig, ValueUnit, MachineStatus, INCOME_COUNTER_STANDARDS } from "@/loader";

@Component
export default class MachineEditor extends Mixins<EditObject<Machine>>(EditObject) {
	@Model("input")
	public value!: Machine | null;

	@Prop({ type: [String, Object], default: "div" })
	public tag!: string | object;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	public valueUnits = ValueUnit.LIST;
	public machineConfigs = MachineConfig.LIST;
	public INCOME_COUNTER_STANDARDS = INCOME_COUNTER_STANDARDS;

	public get config(): MachineConfig[] {
		return MachineConfig.toArray(this.item?.config);
	}
	public set config(value: MachineConfig[]) {
		if (this.item) {
			this.item.config = MachineConfig.fromArray(value);
		}
	}

	public get canEditOwner() {
		return this.canEdit && this.item?.status === MachineStatus.stocked;
	}

	public invalidFeedbacks = {
		name: "",
		serial: "Le N° de série est requis",
		definition: "",
		unit: "",
		incomeCounterStandard: "",
		config: "",
		ownerId: "Le casino est requis",
		osId: "L'OS est requis",
		platformId: "",
		limitCredit: "",
		limitInsert: "",
		limitJackpot: "",
		limitPayment: "",
	};

	public get states() {
		return {
			serial: this.focused.serial ? !!this.item?.serial : null,
			name: this.focused.name ? true : null,
			definition: this.focused.definition ? true : null,
			unit: this.focused.unit ? true : null,
			incomeCounterStandard: this.focused.incomeCounterStandard ? true : null,
			config: this.focused.config ? true : null,
			ownerId: this.focused.ownerId ? !!this.item?.ownerId : null,
			osId: this.focused.osId ? !!this.item?.osId : null,
			platformId: this.focused.platformId ? true : null,
			limitCredit: this.focused.limitCredit ? true : null,
			limitInsert: this.focused.limitInsert ? true : null,
			limitJackpot: this.focused.limitJackpot ? true : null,
			limitPayment: this.focused.limitPayment ? true : null,
		};
	}

	@Watch("value", { immediate: true })
	public valueChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue !== oldValue) {
			this.item = newValue;
		}
	}
}
