














import { Component, Mixins, Ref } from "vue-property-decorator";
import { TutorialComponent, servers } from "@/loader";
import ConnectServer from "../user/ConnectServer.vue";

@Component({
	components: {
		ConnectServer,
	}
})
export default class TutorialConnectServer extends Mixins(TutorialComponent) {
	public title = "Connexion aux serveurs";
	public icon = ["fal", "network-wired"];

	@Ref()
	public readonly connectServer!: ConnectServer;

	public get canPrev(): boolean {
		return false;
	}

	public get servers() {
		return servers.list;
	}

	public get canAdd(): boolean {
		return servers.definitions.some(d => !servers.list.find(s => s.model === d.name));
	}

	public connected() {
		if (this.connectServer) {
			this.connectServer.clean();
		}
	}
}
