import {
	CompanyTask,
	LimitMachineTask,
	LocationTask,
	MachineControllerTask,
	MachineGameTask,
	MachinePartTask,
	MachineSoftwareTask,
	ReadingTask,
	ReferenceTask,
	UUID
} from "@/loader";

export interface InstallationTask extends ReadingTask, CompanyTask, LocationTask, ReferenceTask, LimitMachineTask, MachineGameTask, MachinePartTask, MachineControllerTask, MachineSoftwareTask {
}

export namespace InstallationTask {
	export function instanceOf(value: any): value is InstallationTask {
		return ReadingTask.instanceOf(value)
			&& CompanyTask.instanceOf(value)
			&& LocationTask.instanceOf(value)
			&& ReferenceTask.instanceOf(value)
			&& LimitMachineTask.instanceOf(value)
			&& MachineGameTask.instanceOf(value)
			&& MachinePartTask.instanceOf(value)
			&& MachineControllerTask.instanceOf(value)
			&& MachineSoftwareTask.instanceOf(value);
	}

	export function check(data: any): InstallationTask {
		ReferenceTask.check(data);
		LocationTask.check(data);
		ReadingTask.check(data);
		LimitMachineTask.check(data);
		CompanyTask.check(data);
		MachineGameTask.check(data);
		MachinePartTask.check(data);
		MachineControllerTask.check(data);
		MachineSoftwareTask.check(data);
		return data;
	}

	export function create(
		companyId: UUID = "",
		ref: string = "",
		location: string = "",
	): InstallationTask {
		return {
			companyId,
			ref,
			location,
			reading: "",
			limitCredit: 0,
			limitInsert: 0,
			limitJackpot: 0,
			limitPayment: 0,
			games: [],
			parts: [],
			controllers: [],
			softwares: [],
		};
	}
}
