import { DocIdentifiable, UUID, Events, checkValue, Deletable, ErpObject, Deactivable, Named, Identifiable } from "@/loader"
import { ConfigType } from "dayjs";
import { customAlphabet } from "nanoid";

// Overwrite nanoid standard dictionnary to remove underscore
// as CouchDB and PouchDB will raise an error
// if the generated ID starts with an underscore
const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-";
export const nanoid = customAlphabet(alphabet, 26);

export type SemVer = string;

export interface CouchDBDocument extends DocIdentifiable, Deletable {
	_id: UUID
	_rev: UUID
	type: string
	searchable?: string
}

export namespace CouchDBDocument {
	export const PREFIX_SEPARATOR = ":";
	export const ENDKEY_SUFFIX = PREFIX_SEPARATOR + "\ufff0";

	export function instanceOf(value: any): value is CouchDBDocument {
		return DocIdentifiable.instanceOf(value) && "type" in value && "_rev" in value;
	}

	export function check(data: any, type: string): CouchDBDocument {
		checkValue(data, "_id", "");
		checkValue(data, "_rev", "");
		checkValue(data, "type", type);
		return data;
	}
}

export function cloneObject<T>(base: T, default_data: Partial<T> = {}): T {
	let data: any = Object.assign({}, default_data);
	if (CouchDBDocument.instanceOf(base)) {
		checkValue(data, "_id", () => generateUUID(base.type));
		checkValue(data, "_rev", "");
		checkValue(data, "_deleted", false);
	} else if (Identifiable.instanceOf(base)) {
		checkValue(data, "id", nanoid);
	}
	if (Named.instanceOf(base)) {
		checkValue(data, "name", base.name + " - Copie");
	}
	if (Deactivable.instanceOf(base)) {
		checkValue(data, "disabled", false);
	}
	if (Events.instanceOf(base)) {
		checkValue(data, "events", []);
	}
	if (ErpObject.instanceOf(base)) {
		checkValue(data, "erpId", null);
		checkValue(data, "erpUpdateDate", null);
	}
	return Object.assign({}, base, data);
}

export interface DateSearch {
	date?: ConfigType
	minDate?: ConfigType
	maxDate?: ConfigType
}

/**
 * Generate a unique UUID to be used as ID in PouchDB and CouchDB
 *
 * Use this instead of directly using nanoid() for 2 reasons:
 *
 * 1) Remove underscore from nanoid possible characters
 *      as CouchDB and PouchDB will raise an error
 *      if the generated ID starts with an underscore
 * 2) Create sequential IDs to improve CouchDB B-Tree performance
 *      by having a common prefix
 *      (see [The impact of document IDs on performance of CouchDB](http://blog.inoi.fi/2010/11/impact-of-document-ids-on-performance.html))
 *
 * The prefix is a 26 characters that stays the same for approx. 100 to 1000 subsequent calls,
 * but is unique for each client (ie: user/navigator). Ex: `eUSRiOxQaxs-Mp-FrWVxbl2Hha`
 *
 * The suffix is a sequential integer between 1 and 999 padded left on 5 characters. Ex: `00054`
 *
 * The resulting ID will be 32 characters. Ex: `eUSRiOxQaxs-Mp-FrWVxbl2Hha-00054`
 *
 * The [Nano ID Collision Calculator](https://zelark.github.io/nano-id-cc/) gives a quadrillion years with 1000 IDs/hour
 * needed, in order to have a 1% probability of at least one collision.
 *
 * @returns string|UUID
 */
export function generateUUID(prefix: string = ""): UUID {
	interface UUIDGenerator {
		prefix: string
		increment: number
	}

	function createUUIDGenerator(): UUIDGenerator {
		return {
			prefix: nanoid(),
			increment: 1
		}
	}

	let uuid_generator: UUIDGenerator | null = null
	try {
		uuid_generator = JSON.parse(localStorage.getItem("uuid_generator") || 'null');
	} catch (ex) {
		uuid_generator = null;
	}

	if (!uuid_generator) {
		uuid_generator = createUUIDGenerator();
	} else {
		uuid_generator.increment += Math.round(Math.random() * 10) || 1; // Random between 1 and 9
		if (uuid_generator.increment > 999) {
			uuid_generator = createUUIDGenerator();
		}
	}

	localStorage.setItem("uuid_generator", JSON.stringify(uuid_generator))
	return prefix + ":" + uuid_generator.prefix + "-" + uuid_generator.increment.toString().padStart(5, "0")
}
