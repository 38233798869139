import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DataSelect, MachineSoftware, Software, UUID, Machine } from "@/loader";

interface MachineSoftwareData {
	id: UUID
	data: MachineSoftware | null
	software: Software | null
}

@Component
export class MachineSoftwareSelect extends Mixins(DataSelect) {
	@Prop({ type: String, default: null })
	public machineId!: string | null;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Function, default: (option: MachineSoftware) => option.id })
	public reduce!: Function;

	// @ts-ignore
	public readonly emptyItem: MachineSoftwareData = {
		id: "",
		data: null,
		software: null,
	};

	public softwares: Software[] = [];

	public async loadOptions() {
		let machineSoftwares = (this.machine || (await this.$db.getMachine(this.machineId || "")))?.softwares || [];
		let softwareIds = machineSoftwares.map(ms => ms.softwareId);
		softwareIds = softwareIds.filter((id, i, a) => id && a.indexOf(id) === i);
		this.softwares = await this.$db.allSoftwares({ keys: softwareIds });
		this.options = machineSoftwares.map(mp => this.createMachineSoftwareData(mp)).flat();
	}

	public filterOptions(options: MachineSoftwareData[]): MachineSoftwareData[] {
		let result = options.slice();
		return result;
	}

	public createMachineSoftwareData(ms: MachineSoftware, level: number = 0): MachineSoftwareData[] {
		let result: MachineSoftwareData[] = [];
		result.push({
			id: ms.id,
			data: ms,
			software: this.softwares.find(g => g._id === ms.softwareId) || null
		});
		return result;
	}

	public mounted() {
		this.loadOptions();
	}

	public getOptionKey(option: MachineSoftwareData): string {
		return option.id;
	}

	public getOptionLabel(option: MachineSoftwareData): string {
		return option?.data ? MachineSoftware.toString(option.data, option.software) : this.emptyText;
	}

	public optionSlot(h: CreateElement, option: MachineSoftwareData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: MachineSoftwareData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	@Watch("machineId")
	public onMachineIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	@Watch("machine")
	public onMachineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	// public get editRoute(): string {
	// 	return "machine";
	// }

	// public get canEditOption(): boolean {
	// 	return servers.isAdmin || servers.isDirector || servers.isManager;
	// }
}
