
























import { Component, Mixins } from "vue-property-decorator";
import { TutorialComponent } from '@/loader';

@Component
export default class TutorialWelcome extends Mixins(TutorialComponent) {
	public title = "Données protégées";
	public icon = ["fal", "lock-alt"];
}
