import { BvListItem } from "@/loader";


export enum MachineType {
	none = 0,
	mas = 1,
	table = 2,
	controller = 3,
	server = 4,
	station = 5
}

export namespace MachineType {
	export function toString(machinetype: MachineType): string {
		switch (machinetype) {
			case MachineType.none: return "Non défini";
			case MachineType.mas: return "MAS";
			case MachineType.table: return "Table";
			case MachineType.controller: return "Contrôleur";
			case MachineType.server: return "Serveur";
			case MachineType.station: return "Poste";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(MachineType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<MachineType>value) }));
}
