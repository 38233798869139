
























































































import { Vue, Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import {
	EditComponent,
	Mission,
	Task,
	missionModal,
	LocationTask,
	ReferenceTask,
	CompanyTask,
	MachineGameTask,
	ConfigStatus,
	MachinePartTask,
	MachinePart,
	servers,
	EventType,
	TaskAction
} from "@/loader";

@Component({
	inheritAttrs: true,
})
export default class MissionEditor extends Mixins<EditComponent<Mission, Any>>(EditComponent) {
	@Prop({ default: "div" })
	public tag!: any;

	public invalidFeedbacks = {
		name: "",
		start: "",
		end: "",
		companyId: "",
		techs: "",
	}

	public get states() {
		return {
			name: this.focused.name ? true : null,
			start: this.focused.date ? true : null,
			end: this.focused.date ? true : null,
			companyId: this.focused.companyId ? true : null,
			techs: this.focused.techs ? true : null,
		}
	}

	public get itemFactory(): Function | null {
		return Mission.create;
	}

	public get checkDocFunction(): Function | null {
		return Mission.check;
	}
}
