import { Component, Mixins } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, MachineStatus } from "@/loader";

@Component
export class MachineStatusBadge extends Mixins<EnumBadge<MachineStatus>>(EnumBadge) {
	public get variant(): BVariant {
		switch (this.value) {
			case MachineStatus.installed:
				return BVariant.success;
			case MachineStatus.exported:
				return BVariant.warning;
			case MachineStatus.destroyed:
				return BVariant.danger;
			case MachineStatus.stocked:
			default:
				return BVariant.info;
		}
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case MachineStatus.installed:
				return createIconConfig(['fas', 'check']);
			case MachineStatus.exported:
				return createIconConfig(['fal', 'external-link']);
			case MachineStatus.destroyed:
				return createIconConfig(['fas', 'trash']);
			case MachineStatus.stocked:
				return createIconConfig(['fal', 'box-alt']);
			default:
				return createIconConfig(['fas', 'question-circle']);
		}
	}

	public get title(): string {
		return MachineStatus.toString(this.value);
	}
}
