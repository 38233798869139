import { Vue, Component, Prop, Model } from "vue-property-decorator";

@Component
export class DataList<T> extends Vue {
	@Model("change")
	public readonly value!: T[];

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;
}
