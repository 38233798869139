











































































































































import { Vue, Component, Prop, Watch, Mixins, Ref } from "vue-property-decorator";
import {
	servers,
	Machine,
	MachineStatus,
	MachineType,
	Game,
	Company,
	Platform,
	ConfigStatus,
	MachineConfig,
	AppSettings,
	MachineController,
	Part,
	DEBUG,
	GameRate,
	Denomination,
} from "@/loader";
import GameDisplay from "../Game/GameDisplay";
import MachineControllerDisplay from "./MachineControllerDisplay.vue";
import MachineGameDisplay from "./MachineGameDisplay.vue";
import MachinePartLeaf from "./MachinePartLeaf.vue";

@Component({
	components: {
		GameDisplay,
		MachineControllerDisplay,
		MachineGameDisplay,
		MachinePartLeaf,
	}
})
export default class MachineHeader extends Vue {
	@Prop({ required: true })
	public item!: Machine;

	@Prop({ type: Boolean, default: false })
	public asLink!: boolean;

	@Prop({ type: [String, Object], default: null })
	public redirect!: any;

	@Prop({ type: String, default: null })
	public redirectTitle!: string | null;

	@Prop({ type: [String, Object], default: "h3" })
	public titleTag!: any;

	@Ref()
	public readonly machineGameDisplays!: MachineGameDisplay[];

	@Ref()
	public readonly machinePartLeafs!: MachinePartLeaf[];

	public company: Company | null = null;
	public software: Software | null = null;
	public platform: Platform | null = null;
	public MachineStatus = MachineStatus;
	public MachineType = MachineType;
	public appSettings: AppSettings | null = null;

	public get machineGames(): MachineGame[] {
		return this.item?.games.filter(mg => mg.status === ConfigStatus.active) || [];
	}

	public get controllers(): MachineController[] {
		return this.item?.controllers.filter(mc => mc.status === ConfigStatus.active) || [];
	}

	public get denos(): GameRate | null {
		return Denomination.toString(Machine.getDenos(this.item));
	}

	public get showGlobalRate(): boolean {
		return this.machineGames.filter(mg => mg.status === ConfigStatus.active).length > 1;
	}

	public get rateLabel(): GameRate | null {
		return GameRate.toString(Machine.getRate(this.item));
	}

	public get link() {
		return {
			name: "machine",
			params: { id: this.item._id },
			query: {
				redirect: this.redirect || undefined,
				redirectTitle: this.redirectTitle || undefined,
			}
		}
	}

	public get bill(): boolean {
		return (this.item?.config & MachineConfig.bill) === MachineConfig.bill;
	}

	public get tito(): boolean {
		return (this.item?.config & MachineConfig.tito) === MachineConfig.tito;
	}

	public get cashless(): boolean {
		return (this.item?.config & MachineConfig.cashless) === MachineConfig.cashless;
	}

	public get video(): boolean {
		return (this.item?.config & MachineConfig.video) === MachineConfig.video;
	}

	public get roundTop(): boolean {
		return (this.item?.config & MachineConfig.roundTop) === MachineConfig.roundTop;
	}

	public get slantTop(): boolean {
		return (this.item?.config & MachineConfig.slantTop) === MachineConfig.slantTop;
	}

	public get favoriteParts() {
		let categories = this.appSettings?.favoritePartCategories || "";
		let parts = this.item.parts;
		return Machine.findPartByCategories(categories, parts, ConfigStatus.active);
	}

	public get uniqueGames() {
		let gameIds = this.item?.games.filter(mg => mg.status === ConfigStatus.active && !!mg.packId).map(mg => mg.packId) || [];
		if (!gameIds.length) {
			gameIds = this.item?.games.filter(mg => mg.status === ConfigStatus.active && !!mg.gameId).map(mg => mg.gameId) || [];
		}
		return gameIds.filter((v, i, a) => a.indexOf(v) === i);
	}

	public async loadDependencies() {
		if (!this.$pouch) {
			return;
		}

		this.appSettings = await this.$db.getAppSettings();
		this.company = await this.$db.getCompany(this.item.ownerId);
		this.software = await this.$db.getSoftware(this.item.osId);
		this.platform = await this.$db.getPlatform(this.item.platformId);
	}

	@Watch("item")
	public itemChanged(newValue: Machine, oldValue?: Machine) {
		if (newValue && newValue !== oldValue) {
			this.loadDependencies();
		}
	}

	public mounted() {
		this.loadDependencies();
	}
}
