import { checkValue, UUID } from "@/loader";

export interface ReadingTask {
	reading: UUID
}

export namespace ReadingTask {
	export function instanceOf(value: any): value is ReadingTask {
		return value && typeof value === "object" && "reading" in value;
	}

	export function check(data: any): ReadingTask {
		checkValue(data, "reading", "");
		return data;
	}
}
