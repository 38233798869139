










































































































import { Vue, Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
	MachinePart,
	EditComponent,
	Part,
	Software,
	servers,
	MachinePart,
	PartCategory,
} from "@/loader";

@Component
export default class MachinePartLeaf extends Vue {
	@Prop({ type: Object, required: true })
	public item!: MachinePart;

	@Prop({ type: Object, default: null })
	public parent!: MachinePart | null;

	@Prop({ type: String, default: "li" })
	public tag!: string;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;

	@Prop({ type: Number, default: 0 })
	public level!: number;

	@Prop({ type: Boolean, default: false })
	public canEditSerial!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideChildren!: boolean;

	@Prop({ type: Boolean, default: false })
	public showCategory!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideStatus!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideSerial!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideSoftware!: boolean;

	public part: Part | null = null;
	public software: Software | null = null;
	public category: PartCategory | null = null;
	public expand: boolean = false;
	public dragOptions = {
		group: "machineParts",
		handle: ".btn-move",
		filter: ".no-drag",
	}

	public get array() {
		return this.item?.children || [];
	}
	public set array(value: MachinePart[]) {
		if (this.item) {
			this.item.children = value;
			this.$emit("changed", true);
		}
	}

	public get partTooltip(): string {
		return this.part ? [this.part.brand, this.part.name, this.part.model, this.part.ref].filter(x => !!x).join("<br/>") : "";
	}

	public get softwareTooltip(): string {
		return this.software ? [this.software.brand, this.software.name, this.software.ref].filter(x => !!x).join(" ") : "";
	}

	public async loadRelations(item: MachinePart) {
		item = item || this.item;
		this.part = await this.$db.getPart(item.partId);
		this.software = await this.$db.getSoftware(item.softwareId);
		this.category = this.showCategory ? await this.$db.getPartCategory(item.categoryId) : null;
	}

	public createNew() {
		if (!this.canCreate || !this.item) {
			toaster.forbidden({ operation: "Nouvelle Pièce" });
			return;
		}

		let child = MachinePart.create("");
		this.item.children.push(child);
		this.$emit("created", { item: child, parent: this.item });
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item", { deep: true })
	public onItemChanged(newValue: MachinePart | null, oldValue?: MachinePart | null) {
		if (!newValue) {
			this.software = null;
			this.part = null;
			this.category = null;
			return;
		}

		this.loadRelations(newValue);
	}
}
