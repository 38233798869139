import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";
import dayjs, { Dayjs, ConfigType, QUnitType, OpUnitType, UnitType } from "dayjs";
import dayjs_plugin_customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs_plugin_duration, { Duration, DurationUnitType } from "dayjs/plugin/duration";
import dayjs_plugin_localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs_plugin_isBetween from "dayjs/plugin/isBetween";
import dayjs_plugin_relativeTime from "dayjs/plugin/relativeTime";
import dayjs_plugin_utc from "dayjs/plugin/utc";

import "dayjs/locale/fr";

dayjs.locale("fr");
dayjs.extend(dayjs_plugin_customParseFormat);
dayjs.extend(dayjs_plugin_duration);
dayjs.extend(dayjs_plugin_localizedFormat);
dayjs.extend(dayjs_plugin_isBetween);
dayjs.extend(dayjs_plugin_relativeTime);
dayjs.extend(dayjs_plugin_utc);

// @ts-ignore
window.dayjs = dayjs;

dayjs.extend((o, c, d) => {
	d.nullable = function (config?: ConfigType | null, format?: string): Dayjs | null {
		if (config === null || config === undefined) {
			return null;
		}
		let result = dayjs(config, format);
		return result.isValid() ? result : null;
	};
});

dayjs.extend((o, c, d) => {
	c.prototype.toISODateString = function (): string {
		return this.format("YYYY-MM-DD");
	};
});

dayjs.extend((o, c, d) => {
	c.prototype.toISODateTimeString = function (): string {
		return this.format("YYYY-MM-DD HH:mm:ss");
	};
});

dayjs.extend((o, c, d) => {
	d.orDefault = function (config?: ConfigType | null, format?: string, defaultValue?: ConfigType): Dayjs {
		defaultValue = defaultValue === undefined ? dayjs() : dayjs(defaultValue);
		if (config === null || config === undefined) {
			return defaultValue;
		}
		let result = dayjs(config, format);
		return result.isValid() ? result : defaultValue;
	};
});

export interface IDateRange {
	start: ConfigType;
	end: ConfigType;
}

export function isDateRange(object: any): object is IDateRange {
	return object && object.start && object.end;
}

const modifiers = {
	date: "L",
	datetime: "L LT",
	time: "LT"
};
Vue.directive("dayjs", {
	bind(el: HTMLElement, binding: DirectiveBinding) {
		// @ts-ignore
		let format = Object.keys(binding.modifiers).find(key => modifiers[key]) || "L LT";

		let { value } = binding;
		let res: Dayjs | null = null;
		if (typeof value === "string" && value) {
			res = dayjs(value);
		} else if (dayjs.isDayjs(value)) {
			res = value;
		} else if (typeof value === "object" && value) {
			value = dayjs(value.date);
			format = value.format || format;
		}

		if (res && res.isValid()) {
			if (binding.modifiers.fromNow) {
				el.innerHTML = res.fromNow(binding.modifiers.withoutSuffix || false);
			} else if (binding.modifiers.toNow) {
				el.innerHTML = res.toNow(binding.modifiers.withoutSuffix || false);
			} else {
				el.innerHTML = res.format(format);
			}
		} else {
			el.innerHTML = "";
		}
	}
});

Vue.filter("dayjs", (date: ConfigType, format: string = "L LT", invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.format(format) || invalid;
});

Vue.filter("fromNow", (date: ConfigType, withoutSuffix: boolean = false, invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.fromNow(withoutSuffix) || invalid;
});

Vue.filter("fromX", (date: ConfigType, otherDate: ConfigType, withoutSuffix: boolean = false, invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.from(otherDate, withoutSuffix) || invalid;
});

Vue.filter("toNow", (date: ConfigType, withoutSuffix: boolean = false, invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.toNow(withoutSuffix) || invalid;
});

Vue.filter("toX", (date: ConfigType, otherDate: ConfigType, withoutSuffix: boolean = false, invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.to(otherDate, withoutSuffix) || invalid;
});

Vue.filter("diff", (date: ConfigType, otherDate: ConfigType | null = null, unit?: QUnitType | OpUnitType, float?: boolean, invalid: string = "") => {
	let res = dayjs(date);
	otherDate = otherDate || dayjs();
	return res && res.isValid() && res.diff(otherDate, unit, float) || invalid;
});

Vue.filter("iso", (date: ConfigType, invalid: string = "") => {
	let res = dayjs(date);
	return res && res.isValid() && res.toISODateTimeString() || invalid;
});

Vue.filter("duration", (date: any) => {
	return dayjs.duration(date);
});

Vue.filter("humanize", (duration: Duration, withSuffix: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? duration.humanize(withSuffix) : invalid;
});

Vue.filter("get", (value: ConfigType | Duration, unit: UnitType & DurationUnitType, invalid: string = "") => {
	if (dayjs.isDuration(value)) {
		return value.get(unit);
	}
	let res = dayjs(value);
	return res && res.isValid() && res.get(unit) || invalid;
});

Vue.filter("as", (duration: Duration, unit: DurationUnitType, invalid: string = "") => {
	return dayjs.isDuration(duration) ? duration.as(unit) : invalid;
});

Vue.filter("years", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asYears() : duration.years() : invalid;
});

Vue.filter("months", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asMonths() : duration.months() : invalid;
});

Vue.filter("weeks", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asWeeks() : duration.weeks() : invalid;
});

Vue.filter("days", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asDays() : duration.days() : invalid;
});

Vue.filter("hours", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asHours() : duration.hours() : invalid;
});

Vue.filter("minutes", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asMinutes() : duration.minutes() : invalid;
});

Vue.filter("seconds", (duration: Duration, float: boolean = false, invalid: string = "") => {
	return dayjs.isDuration(duration) ? float ? duration.asSeconds() : duration.seconds() : invalid;
});

Vue.prototype.$dayjs = dayjs;
