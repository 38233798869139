import { Vue, Component } from "vue-property-decorator";

@Component
export class TutorialComponent extends Vue {
	public title: string = "";
	public icon: string | string[] = "";

	public get canPrev(): boolean {
		return true;
	}

	public get canNext(): boolean {
		return true;
	}

	public mounted() {
		this.$emit("init", { title: this.title, icon: this.icon });
	}
}
