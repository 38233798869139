import { Vue, Component, Prop } from "vue-property-decorator";
import { CreateElement, VNode } from "vue";
import { Brand, ListFilter } from "@/loader";

@Component
export class FilterCardResume extends Vue {
	@Prop({ type: Object, default: () => [] })
	public filters!: { [filter: string]: ListFilter<Brand, any> }

	@Prop({ type: String, default: null })
	public search!: string

	public render(h: CreateElement) {
		if (!this.search && Object.values(this.filters).every(f => !f.selection.length)) {
			return null;
		}

		let badges: VNode[] = [];
		if (this.search) {
			badges.push(h(
				"b-badge", {
					class: "d-inline-flex align-items-center",
					props: {
						variant: "outline-info",
						pill: true
					}
				}, [
					h("fa", { props: { icon: ["fal", "text"], fixedWidth: true } }),
					this.search,
					h("b-button", {
						class: "p-0 ml-2",
						props: {
							size: "sm",
							variant: "link"
						},
						on: {
							click: () => {
								this.$emit("clear-search");
							}
						}
					}, [
						h("fa", { props: { icon: "times" } })
					])
				]
			));
		}

		Object.values(this.filters).forEach(filter => {
			filter.selection.forEach(selection => {
				let item = filter.items.find(i => i._id === selection);
				if (item) {
					badges.push(h(
						"b-badge", {
							class: "d-inline-flex align-items-center",
							props: {
								variant: "outline-info",
								pill: true
							}
						}, [
							(typeof item._id === "boolean" ? filter.title + " : " : "") + item.name,
							h("b-button", {
								class: "p-0 ml-2",
								props: {
									size: "sm",
									variant: "link"
								},
								on: {
									click: () => {
										filter.selection.remove(selection);
									}
								}
							}, [
								h("fa", { props: { icon: "times" } })
							])
						]
					));
				}
			});
		});

		return h("b-card", {
			class: "filter-card-resume",
		}, [
			...badges,
			h("b-button", {
				class: "btn-clear-filters",
				props: {
					variant: "outline-secondary",
					size: "sm"
				},
				on: {
					click: () => {
						Object.values(this.filters).forEach(filter => {
							filter.selection = [];
						});
						this.$emit("clear-search");
					}
				}
			}, [
				h("fa", { props: { icon: ["fal", "eraser"], fixedWidth: true } }),
				" Tout effacer"
			])
		]);
	}
}
