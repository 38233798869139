






import { Component, Mixins, Ref } from "vue-property-decorator";
import { TutorialComponent, Account } from '@/loader';
import CreateAccount from "../user/CreateAccount.vue";

@Component({
	components: {
		CreateAccount,
	}
})
export default class TutorialCreateAccount extends Mixins(TutorialComponent) {
	public title = "Création d'un compte";
	public icon = ["fal", "id-card"];
	public account: Account | null = null;

	@Ref()
	public readonly createAccount!: CreateAccount;

	public get canNext(): boolean {
		return !!this.account;
	}

	public goNext(account: Account) {
		this.account = account;
		this.$emit("go-next", account);
	}
}
