import { Component, Mixins } from "vue-property-decorator";
import { PartCategory } from '@/loader';
import CategoryTree from "../category/CategoryTree.vue";

@Component
export class PartCategoryTree extends Mixins<CategoryTree<PartCategory>>(CategoryTree) {
	public get startkey(): string {
		return PartCategory.STARTKEY;
	}

	public get endkey(): string {
		return PartCategory.ENDKEY;
	}

	public get query(): string {
		return "part/categories";
	}
}
