

















































import { Watch, Component, Mixins } from "vue-property-decorator";
import { EditComponent, PartCompatibility, Part } from "@/loader";

@Component
export default class PartCompatibilityRow extends Mixins<EditComponent<PartCompatibility, Part>>(EditComponent) {
	public selectedPart: Part | null = null;

	public invalidFeedbacks = {
		partId: "Le compteur et la catégorie ne peuvent pas être vide",
		categoryId: "Le compteur et la définition ne peuvent pas être vide",
		compatible: "La compatibilité ne peut pas être vide",
	}

	public get partId() {
		return this.item?.partId;
	}

	public get states() {
		return {
			partId: this.item && this.focused.partId ? !!this.item.partId && !!this.item.categoryId : null,
			categoryId: this.item && this.focused.categoryId ? !!this.item.partId && !!this.item.categoryId : null,
			compatible: this.item && this.focused.compatible ? !!this.item.compatible : null,
		}
	}

	@Watch("partId")
	public async partIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.selectedPart = await this.$db.getPart(newValue);
		}
	}

	public async mounted() {
		this.selectedPart = await this.$db.getPart(this.item?.partId);
	}
}
