
























































import { Vue, Component, Mixins, Ref } from "vue-property-decorator";
import { EditList, MachinePart, Machine, MachinePart, ConfigStatus, nanoid, toaster } from "@/loader";
import MachinePartRow from "./MachinePartRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		MachinePartRow
	}
})
export default class MachinePartsTable extends Mixins<EditList<MachinePart, Machine>>(EditList) {
	public dragOptions = {
		group: "machineParts",
		handle: ".btn-move",
		draggale: ".machine-part-list-item",
	}
	public status: ConfigStatus[] = [];

	@Ref()
	public machineParts!: MachinePartRow | MachinePartRow[];

	public get array(): MachinePart[] {
		if (this.status.length) {
			return this.parent?.parts.filter(mp => this.status.includes(mp.status)) || [];
		}
		return this.parent?.parts || [];
	}
	public set array(value: MachinePart[]) {
		if (this.parent && !this.status.length) {
			this.parent.parts = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return MachinePart.create;
	}

	public get cloneFactory(): (base: MachinePart) => MachinePart {
		return MachinePart.clone;
	}

	public get statusOptions() {
		let array = this.parent?.parts || [];
		return Object.values(ConfigStatus)
			.filter(value => typeof value === "number")
			.map(value => ({
				value,
				text: `${ConfigStatus.toString(<ConfigStatus>value)} (${array.filter(x => x.status === <ConfigStatus>value).length})`,
				disabled: !array.some(x => x.status === <ConfigStatus>value)
			}));
	}

	public editAll() {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Modifier" });
			return;
		}
		if (Array.isArray(this.machineParts)) {
			this.machineParts.forEach(mp => mp.editMode = true);
		} else {
			this.machineParts.editMode = true;
		}
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (this.machineParts) {
				if (Array.isArray(this.machineParts)) {
					if (this.machineParts.length) {
						this.machineParts[this.machineParts.length - 1].editMode = true;
					}
				} else {
					this.machineParts.editMode = true;
				}
			}
		});
	}
}
