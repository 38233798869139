



























import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { toaster, EditList, Game, GameController, ValueUnit, ControllerType } from '@/loader';
import GameControllerEdit from "./GameControllerEdit.vue";

@Component({
	components: {
		GameControllerEdit,
	}
})
export default class GameControllerList extends Mixins<EditList<GameController, Game>>(EditList) {
	public types = ControllerType.LIST
	public units = ValueUnit.LIST

	public get array(): GameController[] {
		return this.parent ? this.parent.controllers : [];
	}

	public get itemFactory(): Function | null {
		return () => GameController.create("Nouveau");
	}

	public get cloneFactory(): (base: GameController) => GameController {
		return GameController.clone;
	}
}
