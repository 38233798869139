import { CouchDBDocument, Account, Server, checkValue } from "@/loader";

export interface Credentials extends CouchDBDocument {
	server: string;
	login: string
	password: string
}

export namespace Credentials {
	export const LOCAL = "_local/credentials";
	export const TYPE = "credentials";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function create(
		_id: string = "",
		server: string = "",
		login: string = "",
		password: string = ""
	): Credentials {
		return {
			_id,
			_rev: "",
			type: TYPE,
			server,
			login,
			password,
		};
	}

	export function check(data: any): Credentials {
		CouchDBDocument.check(data, TYPE);
		checkValue(data, "server",  "");
		checkValue(data, "login",  "");
		checkValue(data, "password",  "");
		return data;
	}

	export function getId(account: string | Account, server: string | Server) {
		account = typeof account !==  "string" ? account.name : account;
		server = typeof server !== "string" ? server.model : server;
		return STARTKEY + account + "_" + server;
	}
}
