import { CreateElement, VNode } from "vue";
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import {
	CouchDBDocument,
	Deactivable,
	Named,
	DataSelect,
	VueSelectSelectOptionContainer,
	servers,
	toaster,
	busy,
} from "@/loader";

type NamedDocument = CouchDBDocument & Named;

@Component
export class DocumentSelect<T extends CouchDBDocument> extends Mixins(DataSelect) {
	public localOptions: T[] = [];

	// @ts-ignore
	public readonly emptyItem: T & Named & Deactivable = {
		_id: "",
		_rev: "",
		type: "",
		name: "Aucun",
		disabled: false,
	};

	@Prop({ type: Function, default: (option: CouchDBDocument) => option._id })
	public reduce!: Function;

	@Prop({ type: [String, Array], default: "" })
	public exclude!: string | string[];

	@Prop({ type: [String, Array], default: "" })
	public include!: string | string[];

	@Prop({ type: String, default: "" })
	public redirectTitle!: string;

	@Prop({ type: Boolean, default: false })
	public canRedirect!: boolean;

	public get editRoute(): string {
		return "";
	}

	public get canEditOption(): boolean {
		return this.canRedirect;
	}

	public get viewRoute(): string {
		return "";
	}

	public get canViewOption(): boolean {
		return this.canRedirect;
	}

	public optionEditRoute(option: T) {
		return {
			name: this.editRoute,
			params: { id: option._id },
			query: { redirect: this.$route.fullPath, redirectTitle: this.redirectTitle }
		}
	}

	public optionViewRoute(option: T) {
		return {
			name: this.viewRoute,
			params: { id: option._id },
			query: { redirect: this.$route.fullPath, redirectTitle: this.redirectTitle }
		}
	}

	public get textField(): string {
		return "name";
	}

	public get selectedItem(): T | null {
		if (this.value) {
			return this.options.find((x: T) => x._id === this.value) || null;
		}
		return null;
	}

	public filterOptions(options: T[]): T[] {
		options = this._union(options, this.include, true);
		options = this._union(options, this.exclude, false);
		return options;
	}

	public _union(options: T[], other: string | string[], include: boolean): T[] {
		if ((Array.isArray(other) && other.length) || !!other) {
			other = Array.isArray(other) ? other : [other];
			options = options.filter(d => other.includes(d._id) === include || (this.showEmpty && d === this.emptyItem));
		}
		return options;
	}

	public applyFilter(options: T[], search: string) {
		search = search.toLowerCase().removeDiacritics();
		return options.filter(option => Named.instanceOf(option) && option.name.toLowerCase().removeDiacritics().includes(search));
	}

	public getOptionKey(option: T): string {
		return option._id;
	}

	public getOptionLabel(option: T): string {
		// DEBUG && console.log(option);
		return Named.instanceOf(option) && option.name || "";
	}

	public optionSlot(h: CreateElement, option: T): string | VNode | (string | VNode | null)[] {
		return Named.instanceOf(option) && option.name || "";
	}

	public selectedOptionContainerSlot(h: CreateElement, args: VueSelectSelectOptionContainer<T>): string | VNode | (string | VNode | null)[] {
		return h(
			"span",
			{
				class: "vs__selected",
			},
			[
				this.optionSlot(h, args.option),
				args.option._id && this.editRoute && this.canEditOption && this.canRedirect ? h(
					"b-button",
					{
						class: "m-0 p-0 ml-1",
						props: {
							variant: "link",
							size: "sm",
						},
						on: {
							mousedown: (event: Event) => {
								event.preventDefault();
								this.$router.push(this.optionEditRoute(args.option));
							}
						}
					},
					[
						h("fa", { props: { icon: ["far", "edit"], fixedWidth: true } })
					]
				) : null,
				args.option._id && this.viewRoute && this.canViewOption && this.canRedirect ? h(
					"b-button",
					{
						class: "m-0 p-0 ml-1",
						props: {
							variant: "link",
							size: "sm",
							to: this.optionViewRoute(args.option)
						},
						on: {
							mousedown: (event: Event) => {
								event.preventDefault();
								this.$router.push(this.optionViewRoute(args.option));
							}
						}
					},
					[
						h("fa", { props: { icon: ["fal", "eye"], fixedWidth: true } })
					]
				) : null,
				args.multiple ? h(
					"button",
					{
						ref: "deselectButtons",
						class: "vs__deselect",
						attrs: {
							type: "button",
							disabled: args.disabled,
							title: `Désélectionner ${this.getOptionLabel(args.option)}`,
							ariaLabel: `Désélectionner ${this.getOptionLabel(args.option)}`,
						},
						on: {
							click: (event: Event) => {
								args.deselect(args.option);
							}
						}
					},
					[
						h("fa", { props: { icon: "times", fixedWidth: true }, class: "text-muted" })
					]
				) : null
			]
		);
	}

	public pushItem(item: T, originalOption: any) {
		if (!servers.selected) {
			toaster.noServer();
			busy.stop();
			return;
		}
		if (!this.taggable || !this.canEditOption) {
			toaster.forbidden({ operation: "Création" });
			busy.stop();
			return;
		}

		if (!busy.status) {
			busy.start("Création en cours ...");
		}
		let value = this.value;
		let localOptions = this.localOptions;
		this.$pouch.put(item, {}, servers.selected.name).then(result => {
			if (!result.ok) {
				throw result;
			}
			item._rev = result.rev;
			localOptions.push(item);

			if (this.multiple) {
				if (originalOption) {
					value.remove(originalOption);
				}
				value.push(this.reduce(item));
			} else {
				value = this.reduce(item);
			}
			this.$emit("input", value);
		}).catch(error => {
			console.error(error);
			toaster.error({ error });
		}).finally(() => {
			busy.stop();
		});
	}

	@Watch("emptyText", { immediate: true })
	public onEmptyTextChanged(newValue: string, oldValue: string) {
		if (newValue !== oldValue) {
			this.emptyItem.name = newValue;
		}
	}
}
