


























import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	Game,
	servers,
} from "@/loader";

@Component
export default class MachineSoftwareGameRow extends Vue {
	@Prop({ type: Object, required: true })
	public item!: Game;

	public get route() {
		return this.item ? {
			name: "game",
			params: {
				id: this.item._id,
				serverName: servers.selected?.name,
			},
			query: {
				redirect: this.$route.fullPath,
				redirectTitle: `Machine ${this.parent?.serial || ""}`
			}
		} : undefined;
	}
}
