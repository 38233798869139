import { BvListItem } from "@/loader";


export enum MachineStatus {
	stocked = 0,
	installed = 1,
	exported = 2,
	destroyed = 3
}

export namespace MachineStatus {
	export function toString(machinestatus: MachineStatus): string {
		switch (machinestatus) {
			case MachineStatus.stocked: return "Stockée";
			case MachineStatus.installed: return "Installée";
			case MachineStatus.exported: return "Exportée";
			case MachineStatus.destroyed: return "Détruite";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(MachineStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<MachineStatus>value) }));
}
