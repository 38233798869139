














import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { MachineController, Denomination, ControllerRate, Machine } from "@/loader";

@Component
export default class MachineControllerDisplay extends Vue {
	@Prop({ type: String, default: "div" })
	public tag!: string;

	@Prop({ type: Object, required: true })
	public item!: MachineController;

	@Prop({ type: Object, required: true })
	public machine!: Machine;

	public async loadRelations() {
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue: MachineController, oldValue?: MachineController) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
