










































































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import {
	DEBUG,
	DocumentList,
	Mission,
	Company,
	Brand,
	DocBrand,
	Machine,
	User,
	createListFilter,
	MissionStatus,
	TaskAction,
	USER_STARTKEY,
	USER_ENDKEY,
	servers,
	PouchResult,
	CouchDBDocument,
	toaster,
	Named,
	PropertyNames,
	BvTableFieldArray,
} from "@/loader";
import dayjs from 'dayjs';
import MissionTaskSummary from "./MissionTaskSummary.vue";

@Component({
	components: {
		MissionTaskSummary,
	},
})
export default class MissionsTable extends Mixins<DocumentList<Mission>>(DocumentList) {
	@Prop({ type: String, default: null })
	public machineId!: string | null;

	@Prop({ type: String, default: null })
	public companyId!: string | null;

	@Prop({ type: Boolean, default: false })
	public selectable!: boolean;

	public TaskAction = TaskAction;
	public MissionStatus = MissionStatus;
	public COMPANY_STARTKEY = Company.STARTKEY;
	public COMPANY_ENDKEY = Company.ENDKEY;
	public USER_STARTKEY = USER_STARTKEY;
	public USER_ENDKEY = USER_ENDKEY;

	public date: string = "";
	public sort: PropertyNames<Mission> = "start";
	public sortDesc: boolean = true;
	public filters = {
		status: createListFilter<Brand, Mission>("status"),
		techs: createListFilter<DocBrand<User>, Mission>("techs"),
		companies: createListFilter<DocBrand<Company>, Mission>("companyId"),
	};

	public get fields(): BvTableFieldArray {
		return [
			{
				key: "name",
				label: "Titre",
				sortable: true,
			},
			{
				key: "start",
				label: "Début",
				sortable: true,
				formatter: (value) => value || "A définir",
				sortByFormatted: true,
				filterByFormatted: true,
			},
			{
				key: "end",
				label: "Fin",
				sortable: true,
				formatter: (value, key, item) => value || item.start || "A définir",
				sortByFormatted: true,
				filterByFormatted: true,
			},
			this.companyId ? null : {
				key: "companyId",
				label: "Casino",
				formatter: (value: string) => this.filters.companies.valueToString(value),
				sortable: true,
				sortByFormatted: true,
			},
			{
				key: "techs",
				label: "Techniciens",
				formatter: (value: string[]) => this.filters.techs.valueToString(value),
				sortable: true,
				sortByFormatted: true,
			},
			this.machineId ? {
				key: "tasks",
				label: "Tâches",
			} : null,
		];
	}

	public get startkey(): string {
		return Mission.STARTKEY;
	}

	public get endkey(): string {
		return Mission.ENDKEY;
	}

	public get type(): string {
		return Mission.TYPE;
	}

	public get techId() {
		return servers.user && servers.isOnlyTech ? servers.user._id : null;
	}

	public get statusQuery(): string {
		return this.machineId && this.techId ? "mission/status_by_tech_machine"
			: this.companyId && this.techId ? "mission/status_by_tech_company"
			: this.techId ? "mission/status_by_tech"
			: this.machineId ? "mission/status_by_machine"
			: this.companyId ? "mission/status_by_company"
			: "mission/status";
	}

	public get techsQuery(): string {
		return this.machineId ? "mission/techs_by_machine"
			: this.companyId ? "mission/techs_by_company"
			: "mission/techs";
	}

	public get companiesQuery(): string {
		return this.machineId && this.techId ? "mission/companies_by_tech_machine"
			: this.techId ? "mission/companies_by_tech"
			: this.machineId ? "mission/companies_by_machine"
			: "mission/companies";
	}

	public get statusQueryOptions(): PouchDB.Query.Options<{}, {}> {
		return this.machineId && this.techId ? { group: true, startkey: [this.techId, this.machineId, null], endkey: [this.techId, this.machineId, {}] }
			: this.companyId && this.techId ? { group: true, startkey: [this.techId, this.companyId, null], endkey: [this.techId, this.companyId, {}] }
			: this.techId ? { group: true, startkey: [this.techId, null], endkey: [this.techId, {}] }
			: this.machineId ? { group: true, startkey: [this.machineId, null], endkey: [this.machineId, {}] }
			: this.companyId ? { group: true, startkey: [this.companyId, null], endkey: [this.companyId, {}] }
			: { group: true };
	}

	public get techsQueryOptions(): PouchDB.Query.Options<{}, {}> {
		return this.machineId ? { group: true, startkey: [this.machineId, null], endkey: [this.machineId, {}] }
			: this.companyId ? { group: true, startkey: [this.companyId, null], endkey: [this.companyId, {}] }
			: { group: true };
	}

	public get companiesQueryOptions(): PouchDB.Query.Options<{}, {}> {
		return this.machineId && this.techId ? { group: true, startkey: [this.techId, this.machineId, null], endkey: [this.techId, this.machineId, {}] }
			: this.techId ? { group: true, startkey: [this.techId, null], endkey: [this.techId, {}] }
			: this.machineId ? { group: true, startkey: [this.machineId, null], endkey: [this.machineId, {}] }
			: { group: true };
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get startLabel() {
		return (item: Mission) => item.start ? dayjs(item.start).format("L") : "";
	}

	public get endLabel() {
		return (item: Mission) => item.end ? dayjs(item.end).format("L") : "";
	}

	public machineFactory(r: PouchResult, defs: Machine[]) {
		let doc = defs.find(d => d._id === r.key);
		return {
			_id: r.key,
			name: doc?.serial || "?",
			count: r.value,
			doc
		};
	}

	public getSearchableString(value: Mission): string {
		return [
			value.name?.removeDiacritics().toLowerCase() || "",
		].filter(x => !!x).join(CouchDBDocument.ENDKEY_SUFFIX);
	}

	public async loadItems() {
		if (!servers.selected) {
			return;
		}
		let techId = this.techId;

		DEBUG && console.log("MissionsTable.loadItems");
		if (this.query) {
			if (techId) {
				this.query.selector.techId = techId;
			}
			this.$pouch.find(this.query, servers.selected.name).then(result => {
				DEBUG && console.log("find?", this.query, result);
				if (DEBUG && result.warning) {
					console.warn(result.warning);
				}
				this.total = result.docs.length;
				let items: Mission[] = result.docs
					// @ts-ignore
					.filter(d => d?.type === this.type)
					.map(d => ({
						...(this.checkDocFunction ? this.checkDocFunction(d) : d),
						searchable: this.getSearchableString(d)
					}));
				if (items.length && Named.instanceOf(items[0])) {
					items = items.sort((a, b) => a.name.localeCompare(b.name));
				}
				this.items = items;
				this.$emit("loaded", this.items);
			}).catch(error => {
				console.error("find?", this.query, error);
				toaster.error({ error });
				this.items = [];
				this.total = 0;
				this.$emit("loaded", this.items);
			});
			return;
		} else if (this.machineId || this.companyId || techId) {
			let p: Promise<Mission[]>;
			if (this.machineId && techId) {
				p = this.$db.queryMissionsByTechMachine(techId, this.machineId);
			} else if (this.companyId && techId) {
				p = this.$db.queryMissionsByTechCompany(techId, this.companyId);
			} else if (this.machineId) {
				p = this.$db.queryMissionsByMachine(this.machineId);
			} else if (this.companyId) {
				p = this.$db.queryMissionsByCompany(this.companyId);
			} else {
				p = this.$db.queryMissionsByTech(techId);
			}
			p.then(result => {
				this.total = result.length;
				this.items = result.map(r => ({
					...r,
					searchable: this.getSearchableString(r)
				}));
			}).catch(() => {
				this.total = 0;
				this.items = [];
			});
			return;
		}

		this.$pouch.allDocs({
			startkey: this.startkey,
			endkey: this.endkey,
			include_docs: true
		}, servers.selected.name).then(result => {
			DEBUG && console.log("allDocs?" + this.startkey, result);
			this.total = result.total_rows;
			let items = result.rows
				// @ts-ignore
				.filter(r => r.doc?.type === this.type)
				.map(r => ({
					...<Mission>(this.checkDocFunction ? this.checkDocFunction(r.doc) : r.doc),
					searchable: this.getSearchableString(<Mission>r.doc)
				}));
			if (items.length && Named.instanceOf(items[0])) {
				items = items.sort((a, b) => a.name.localeCompare(b.name));
			}
			this.items = items;
			this.$emit("loaded", this.items);
		}).catch(error => {
			console.error("allDocs?" + this.startkey, error);
			toaster.error({ error });
			this.items = [];
			this.total = 0;
			this.$emit("loaded", this.items);
		});
	}

	@Watch("selector")
	public onSelectorChanged(newValue: any, oldValue: any) {
		if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue || null)) {
			this.loadItems();
		}
	}
}
