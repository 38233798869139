import { CompanyTask, UUID } from "@/loader";

export interface StockTask extends CompanyTask {
}

export namespace StockTask {
	export function instanceOf(value: any): value is StockTask {
		return CompanyTask.instanceOf(value);
	}

	export function check(data: any): StockTask {
		CompanyTask.check(data);
		return data;
	}

	export function create(companyId: UUID = ""): StockTask {
		return {
			companyId,
		};
	}
}
