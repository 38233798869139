import { Component, Mixins } from "vue-property-decorator";
import {
	createCategoryItem,
	CategoryItem,
	PartCategory,
	CategorySelect,
} from "@/loader";

@Component
export class PartCategorySelect extends Mixins<CategorySelect<PartCategory>>(CategorySelect) {
	public readonly emptyItem: CategoryItem<PartCategory> = createCategoryItem(PartCategory.create("Aucune"));

	public get startkey(): string {
		return PartCategory.STARTKEY;
	}

	public get endkey(): string {
		this._liveFeeds
		return PartCategory.ENDKEY;
	}

	public get query(): string {
		return "part/categories";
	}

	public mounted() {
		this.emptyItem._id = "";
	}
}
