import { addBreadcrumb } from "@sentry/browser";
// import { ActionContext } from "vuex";
import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import {
	GlobalSettings,
	Role,
	Server,
	Account,
	Settings,
	store,
	SyncStatus,
	User,
	createServerFromAccountServer,
	ServerData,
	AccountServer,
	ListState,
	TreeState,
	ViewState,
	CouchDBDocument,
} from "@/loader";

import servers_data from "../../config/config.dev.json";
import Vue from "vue";

export interface ServerConnectResponse { user?: User, hasAccess?: boolean, server?: string, ok?: boolean }

export interface SyncEvent {
	db: string
	error?: any
	info?: {
		direction: "push" | "pull",
		change: {
			ok: boolean,
			doc_write_failures: number,
			docs: CouchDBDocument[],
			docs_read: number,
			docs_written: number,
			errors: any[],
			last_seq: string,
			pending: number,
			start_time: string,
		}
	}
	paused?: boolean
	active?: boolean
}

@Module({
	name: "servers",
	store,
	dynamic: true,
	namespaced: true,
})
export class ServersModule extends VuexModule {
	public online: boolean = false;
	public list: Server[] = [];
	public selected: Server | null = null;
	public settings: GlobalSettings | null = null;
	public heartbeat: number = 0;
	public definitions: Readonly<ServerData>[] = servers_data.map(s => Object.freeze(s));

	public get account(): Account | null {
		return this.settings?.account || null;
	}

	public get token(): string | null {
		return this.settings?.token || null;
	}

	public get user(): User | null {
		return this.selected && this.selected.settings.user || null;
	}

	public get isLoggedIn(): boolean {
		return this.selected && !!this.selected.settings.user || false;
	}

	public get isDirector(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.includes(Role.director)
			|| false;
	}

	public get isAccounting(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.includes(Role.accounting)
			|| false;
	}

	public get isManager(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.includes(Role.manager)
			|| false;
	}

	public get isTech(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.includes(Role.tech)
			|| false;
	}

	public get isOnlyTech(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.includes(Role.tech)
			&& this.selected.settings.user.roles.length === 1
			|| false;
	}

	public get isAdmin(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.some(r => Role.ADMIN.includes(r))
			|| false;
	}

	public get isRH(): boolean {
		return this.selected
			&& this.selected.settings.user
			&& this.selected.settings.user.roles.some(r => Role.RH.includes(r))
			|| false;
	}

	public get author(): string {
		return this.selected
			&& this.selected.settings.user
			&& (this.selected.settings.user.firstName + " " + this.selected.settings.user.lastName)
			|| "?";
	}

	@Mutation
	public init() {
		addBreadcrumb({ category: "mutation", message: "ServersModule.init" });
		this.online = navigator.onLine;
		// this.list = servers_data.map(s => createServer(s.name, s.name, s.label, s.url, s.auth, s.img));
	}

	@Mutation
	public setOnline(online: boolean) {
		addBreadcrumb({ category: "mutation", message: "ServersModule.setOnline" });
		this.online = online;
	}

	@Mutation
	public setSelected(server: Server | null) {
		addBreadcrumb({ category: "mutation", message: "ServersModule.setSelected" });
		this.selected = server;
	}

	@Mutation
	public setSettings(settings: GlobalSettings | null) {
		addBreadcrumb({ category: "mutation", message: "ServersModule.setSettings" });
		this.settings = settings;
	}

	@Mutation
	public modifyServer(payload: Partial<Server> & Partial<Settings> & { server: string | Server }) {
		addBreadcrumb({ category: "mutation", message: "ServersModule.modifyServer" });
		if (!payload.server) {
			return;
		}

		let server = getServer(payload.server);
		if (server) {
			if (payload.user !== undefined) {
				server.settings.user = payload.user;
			}
			if (payload.token !== undefined) {
				server.settings.token = payload.token;
			}
			if (payload.active !== undefined) {
				server.active = payload.active;
			}
			if (payload.status !== undefined) {
				server.status = payload.status;
			}
			if (payload.settings) {
				server.settings = payload.settings;
			}
		}
	}

	@Mutation
	public login(payload: { account: Account, token: string }) {
		if (!this.settings) {
			return;
		}
		this.settings.account = payload.account;
		this.settings.token = payload.token;
		if (!this.settings.accounts.includes(payload.account.name)) {
			this.settings.accounts.push(payload.account.name);
		}
		this.list = payload.account.servers.map(s => {
			let model = this.definitions.find(data => data.name === s.model);
			let server = createServerFromAccountServer(s, model);
			return server;
		});
	}

	@Mutation
	public createServersList(payload: AccountServer[]) {
		this.list = payload.map(s => {
			let model = this.definitions.find(data => data.name === s.model);
			let server = createServerFromAccountServer(s, model);
			return server;
		});
	}

	@Mutation
	public logout() {
		if (!this.settings?.account) {
			return;
		}

		this.settings.account = null;
		this.settings.token = null;
		this.list = [];
	}

	@Mutation
	public initAccountServers() {
		if (!this.settings?.account) {
			return;
		}
		this.list = this.settings.account.servers.map(s => {
			let model = this.definitions.find(data => data.name === s.model);
			let server = createServerFromAccountServer(s, model);
			return server;
		});
	}

	@Mutation
	public addServer(accountServer: AccountServer) {
		if (!this.settings?.account) {
			return;
		}
		let model = getModel(accountServer.model);
		let server = createServerFromAccountServer(accountServer, model);
		this.list.push(server);
		this.settings.account.servers.push(accountServer);
	}

	@Mutation
	public disconnectServer(serverName: string | Server) {
		let server = getServer(serverName);
		if (server) {
			server.sync?.cancel();
			server.sync = null;
			server.active = false;
			server.status = SyncStatus.disconnected;
			server.settings.user = null;
			server.settings.token = null;
		}
	}

	@Mutation
	public removeServer(serverName: string | Server) {
		if (!this.settings?.account) {
			return;
		}
		let server = getServer(serverName);
		if (!server) {
			return;
		}
		this.list.remove(server);

		let accountServer = this.settings.account.servers.find(as => as.name === server?.name);
		if (accountServer) {
			this.settings.account.servers.remove(accountServer);
		}
	}

	@Mutation
	public setHeartbeat(heartbeat: number) {
		this.heartbeat = heartbeat;
	}

	@Mutation
	public stopHeartbeat() {
		if (this.heartbeat) {
			window.clearInterval(this.heartbeat);
			this.heartbeat = 0;
		}
	}

	@Mutation
	public refreshToken(token: string) {
		if (!this.settings) {
			return;
		}
		this.settings.token = token;
	}

	@Mutation
	public setAccount(account: Account | null) {
		if (!this.settings) {
			return;
		}
		this.settings.account = account;
	}

	@Mutation
	public setState(payload: { state: string, data: ListState | TreeState | ViewState }) {
		if (!this.selected?.settings) {
			return;
		}
		Vue.set(this.selected.settings.states, payload.state, payload.data);
	}
}

export const servers = getModule(ServersModule, store);

export function getServer(server: string | Server | null): Server | null {
	if (typeof server === "string") {
		return servers.list.find(s => s.name === server || s.model === server) || null;
	} else {
		return server;
	}
}
export function getModel(server: string | ServerData | null): ServerData | null {
	if (typeof server === "string") {
		return servers.definitions.find(s => s.name === server) || null;
	} else {
		return server;
	}
}

// export function createAccount(data: UserData) {
// 	if (!servers.settings) {
// 		toaster.error({ message: "Les réglages généraux ne sont pas chargés, veuillez recharger la page." });
// 		return;
// 	}
// 	if (servers.settings.accounts.find(a => a.name === data.name)) {
// 		toaster.error({ message: "Le nom du compte doit être unique." });
// 		return;
// 	}
// 	servers.settings.accounts.push(data);
// 	servers.settings.currentAccount = data.name;

// }

// export async function login(login: string, password: string) {
// 	if (!servers.settings) {
// 		toaster.error({ message: "Les réglages généraux ne sont pas chargés, veuillez recharger la page." });
// 		return false;
// 	}
// 	if (!login || !password) {
// 		toaster.error({ message: "L'identifiant et le mot de passe ne peuvent pas être vide." });
// 		return false;
// 	}
// 	let account = servers.settings.accounts.find(a => a.name === login);
// 	if (!account) {
// 		toaster.error({ message: "L'identifiant ou le mot de passe ne sont pas valides." });
// 		return false;
// 	}

// 	let hash: PasswordHash = passwordHash(password);
// 	account.password = hash;
// 	return true;
// }

const MINUTE = 60000;

/** Server heartbeat interval time in milliseconds (5m) */
export const HEARTBEAT_LENGTH = 5 * MINUTE;
/** Account heartbeat interval time in milliseconds (5m) */
export const ACCOUNT_HEARTBEAT_LENGTH = 5 * MINUTE;
