import { checkArrayValue, checkValue } from "@/loader";

export interface Address {
	label: string
	favorite: boolean
	place: google.maps.places.PlaceResult
}

export interface Addresses {
	addresses: Address[]
}

export namespace Addresses {
	export function instanceOf(value: any): value is Addresses {
		return value && typeof value === "object" && "addresses" in value && Array.isArray(value.addresses);
	}

	export function check(data: any): Addresses {
		checkArrayValue(data, "addresses", Address.check);
		return data;
	}
}

export namespace Address {
	export function create(
		label: string = "",
		place: google.maps.places.PlaceResult | null = null,
		favorite: boolean = false
	): Address {
		return {
			label,
			place: place || { formatted_address: "" },
			favorite,
		}
	}

	export function check(data: any): Address {
		checkValue(data, "label", "");
		checkValue(data, "place", null);
		checkValue(data, "favorite", false);
		return data;
	}

	export function clone(original: Address): Address {
		return Object.assign({}, original);
	}
}
