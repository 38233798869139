






































































































































import { Component, Mixins } from "vue-property-decorator";
import {
	ControllerReplacement,
	MachineController,
	ReplacementAction,
	ControllerType,
	ReplacementRow
} from "@/loader";

@Component
export default class ControllerReplacementRow extends Mixins<ReplacementRow<ControllerReplacement>>(ReplacementRow) {
	public invalidFeedbacks = {
		action: "L'action est requise",
		oldId: "Un élément doit être sélectionné",
		newId: "Un élément doit être sélectionné",
	}

	public get states(): { [prop in keyof ControllerReplacement]?: boolean | null; } {
		return {
			action: this.item && this.focused.action ? true : null,
			oldId: this.item && this.focused.oldId && ReplacementAction.NEED_OLD.includes(this.item.action) ? !!this.item.oldId : null,
			name: this.item && this.focused.name ? true : null,
			type: this.item && this.focused.type ? !!this.item.type : null,
			increment: this.item && this.focused.increment ? true : null,
			startup: this.item && this.focused.startup ? true : null,
			description: this.item && this.focused.description ? true : null,
			global: this.item && this.focused.global ? true : null,
			games: this.item && this.focused.games ? true : null,
		}
	}

	public get array(): MachineController[] {
		return this.machine?.controllers || [];
	}

	public get oldController(): MachineController | null {
		return this.array.find(x => x.id === this.item?.oldId) || null;
	}

	public get oldTypes(): string {
		return ControllerType.toString(this.oldController?.type || ControllerType.none);
	}

	public get isNameDifferent() {
		return this.oldController && this.item && (this.oldController.name || "") !== (this.item.name || "") || false;
	}

	public get isTypeDifferent() {
		return this.oldController && this.item && this.oldController.type !== this.item.type || false;
	}

	public get isIncrementDifferent() {
		return this.oldController && this.item && this.oldController.increment !== this.item.increment || false;
	}

	public get isStartupDifferent() {
		return this.oldController && this.item && this.oldController.startup !== this.item.startup || false;
	}

	public get isGlobalDifferent() {
		return this.oldController && this.item && this.oldController.global !== this.item.global || false;
	}

	public get isDescriptionDifferent() {
		return this.oldController && this.item && this.oldController.description !== this.item.description || false;
	}
}
