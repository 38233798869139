import {
	checkValue,
	ConfigStatus,
	Denomination,
	Game,
	GameRate,
	Events,
	nanoid,
	UUID,
	MachineController,
	ControllerType,
	cloneObject,
	GameReplacement,
	Identifiable,
} from "@/loader"


export interface MachineGame extends Identifiable, Events {
	gameId: UUID
	packId: UUID
	deno: Denomination
	status: ConfigStatus
	rate: GameRate | null
	antebet: string | null
	maxbet: string | null
	line: string | null
}

export namespace MachineGame {
	export function create(
		gameId: string = "",
		packId: string = "",
		deno: Denomination = Denomination.c1,
		status: ConfigStatus = ConfigStatus.draft,
		rate: GameRate | null = null,
		antebet: string | null = null,
		maxbet: string | null = null,
		line: string | null = null
	): MachineGame {
		return {
			id: nanoid(),
			gameId,
			packId,
			deno,
			status,
			rate,
			antebet,
			maxbet,
			line,
			events: [],
		}
	}

	export function clone(base: MachineGame | GameReplacement): MachineGame {
		let result = cloneObject(base, {
			id: nanoid(),
			status: ConfigStatus.draft,
		});
		if (GameReplacement.instanceOf(result)) {
			// @ts-ignore
			delete result.action;
			// @ts-ignore
			delete result.oldId;
		}
		return result;
	}

	export function check(data: any): MachineGame {
		checkValue(data, "id", nanoid);
		checkValue(data, "gameId", "");
		checkValue(data, "packId", "");
		checkValue(data, "deno", Denomination.c1);
		checkValue(data, "status", ConfigStatus.draft);
		checkValue(data, "rateCode", null);
		checkValue(data, "antebet", null);
		checkValue(data, "maxbet", null);
		checkValue(data, "line", null);
		checkValue(data, "rate", GameRate.create, GameRate.check);
		Events.check(data);
		return data;
	}

	export function getRate(value: MachineGame, controllers?: MachineController[]) {
		let result: GameRate = value.rate ? GameRate.clone(value.rate) : GameRate.create();
		let internal = 0;
		let external = 0;
		if (controllers?.length) {
			controllers.filter(c => c.status === ConfigStatus.active && (c.global || c.games.includes(value.id as string)))
				.forEach(c => {
					if ((c.type & ControllerType.bonus) !== 0) {
						internal += c.increment || 0;
					} else {
						external += c.increment || 0;
					}
				});
			result.rate = Number(result.rate) + internal;
			result.external = external;
		}
		return result;
	}

	export function toString(value: MachineGame, game: Game | null, pack?: Game | null, controllers?: MachineController[]): string {
		let rate = getRate(value, controllers);

		return [
			pack?.name,
			game?.name,
			Denomination.toString(value.deno),
			GameRate.toString(rate),
			`L ${value.line}`,
			`A ${value.antebet}`,
			`M ${value.maxbet}`
		].filter(x => !!x).join(", ");
	}

	export function instanceOf(value: any): value is MachineGame {
		return Identifiable.instanceOf(value) && "gameId" in value;
	}

	/**
	 * Get the representing (active) games or games' ids of a machine without duplicates
	 *
	 * @param machineGames The machine games to analyze
	 * @param games An array of games to use to get the game name
	 *
	 * @returns Games or games' ids representing the machine
	 */
	export function getRepresentingGames(machineGames: MachineGame[], games?: Game[]): UUID[] | Game[] {
		let actives = machineGames.filter(g => g.status === ConfigStatus.active);
		let ids = actives.filter(mg => !!mg.packId).map(mg => mg.packId);
		if (!ids.length) {
			ids = actives.map(mg => mg.gameId);
		}
		ids = ids.filter((v, i, a) => a.indexOf(v) === i);
		return !games || !games.length ? ids : games.filter(g => ids.includes(g._id));
	}

	export function modifyFromReplacement(mg: MachineGame, gr: GameReplacement) {
		mg.packId = gr.packId;
		mg.gameId = gr.gameId;
		mg.deno = gr.deno;
		mg.rate = gr.rate;
		mg.line = gr.line;
		mg.antebet = gr.antebet;
		mg.maxbet = gr.maxbet;
	}
}
