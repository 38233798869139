export type UUID = string

export interface DocIdentifiable {
	_id?: UUID | number;
}

export namespace DocIdentifiable {
	export function instanceOf(value: any): value is DocIdentifiable {
		return value && typeof value === "object" && "_id" in value;
	}
}

export interface Identifiable {
	id: UUID | number;
}

export namespace Identifiable {
	export function instanceOf(value: any): value is Identifiable {
		return value && typeof value === "object" && "id" in value;
	}
}
