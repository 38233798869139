import {
	CouchDBDocument,
	UUID,
	Events,
	generateUUID,
	Named,
	Deactivable,
	checkValue,
	Sortable,
	cloneObject,
} from "@/loader";

export interface Category extends CouchDBDocument, Events, Named, Deactivable, Sortable {
	parentId: UUID;
	favorite: boolean;
}

export interface CategoryItem<T extends Category> extends CouchDBDocument, Named, Deactivable, Sortable {
	category: T;
	parentId: string;
	parent: CategoryItem<T> | null;
	isNew: boolean;
	count: number;
	total: number;
	level: number | null;
	children: CategoryItem<T>[];
}

export function createCategory(
	type: string,
	name: string = "",
	parentId: UUID = "",
	order: number = 0
): Category {
	return {
		_id: generateUUID(type),
		_rev: "",
		type: type,
		name,
		order,
		parentId,
		disabled: false,
		favorite: false,
		events: [],
	}
}

export const cloneCategory: <T extends Category>(base: T, default_data?: Partial<T>) => T = cloneObject;

type CategoryFactory<T extends Category> = (name?: string, parentId?: string) => T;

export function createCategoryItem<T extends Category>(
	category: T | CategoryFactory<T>
): CategoryItem<T> {
	let item = typeof category === "function" ? category("Nouvelle catégorie") : category;
	return {
		category: item,
		_id: item._id,
		_rev: "",
		type: "",
		get name() { return this.category?.name || ""; },
		set name(value: string) { if (this.category) this.category.name = value; },
		get parentId() { return this.category?.parentId || ""; },
		set parentId(value: string) { if (this.category) this.category.parentId = value; },
		get disabled() { return this.category?.disabled || false; },
		set disabled(value: boolean) { if (this.category) this.category.disabled = value; },
		get order() { return this.category?.order || 0; },
		set order(value: number) { if (this.category) this.category.order = value; },
		isNew: typeof category === "function",
		count: 0,
		total: 0,
		level: null,
		parent: null,
		children: [],
	}
}

export function getCategoryItems<T extends Category>(
	categories: T[],
	category_counts: { _id: UUID, count: number }[]
): CategoryItem<T>[] {
	let tmp: { [id: string]: CategoryItem<T> } = {};
	categories.forEach(c => {
		tmp[c._id] = createCategoryItem(c);
	});
	categories.forEach(c => {
		if (c.parentId && c._id in tmp) {
			let child = tmp[c._id];
			child.parent = tmp[c.parentId];
			if (c.parentId in tmp) {
				tmp[c.parentId].children.push(child);
			}
		}
	});
	category_counts.forEach(c => {
		if (c.count > 0 && c._id in tmp) {
			tmp[c._id].count = c.count;
		}
	});

	/**
	 * Recursive function used to determine counts sum of a	given category's children
	 *
	 * Used to see if a parent, who may or may not have a count, have at least a child who as one.
	 *
	 * @param category
	 */
	function calculate_totals(category: CategoryItem<T>) {
		category.children.forEach(calculate_totals);
		category.total = category.children.reduce((sum, child) => sum + child.total + child.count, 0);
	}

	/**
	 * Recursive function used to sort and flatten the tree array.
	 *
	 * Used to allow a flat representation of the tree, ie:
	 *
	 * &mdash; A
	 * &mdash; &mdash; C
	 * &mdash; &mdash; D
	 * &mdash; B
	 *
	 * @param categories
	 * @param level
	 * @returns
	 */
	function flatten(categories: CategoryItem<T>[], level: number = 0): CategoryItem<T>[] {
		let result: CategoryItem<T>[] = [];
		categories.sort((a, b) => a.category.name.localeCompare(b.category.name)).forEach(c => {
			result.push(c);
			c.level = level;
			if (c.children.length) {
				result.push(...flatten(c.children, level + 1));
			}
		});
		return result;
	}

	let result = Object.values(tmp).filter(c => !c.category.parentId);
	result.forEach(c => calculate_totals(c));
	result = flatten(result);
	result.sort((a, b) => (a.order || 0) - (b.order || 0));
	return result;
}

/**
 * For a given category, get a list of his id and all it's parents' ids
 * @param item
 */
export function getCategoryParents<T extends Category>(item: CategoryItem<T>): string[] {
	let result = [item._id];
	if (item.parent) {
		result.push(...getCategoryParents(item.parent));
	}
	return result;
}

export function checkCategory(data: any, type: string): Category {
	CouchDBDocument.check(data, type);
	Events.check(data);
	Named.check(data);
	Deactivable.check(data);
	Sortable.check(data);
	checkValue(data, "parentId", "");
	checkValue(data, "favorite", false);
	return data;
}
