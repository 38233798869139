import { Component, Mixins, Prop } from "vue-property-decorator";
import { BusyMixin } from "@/mixins/BusyMixin";
import {
	arrayToFlag,
	Brand,
	CouchDBDocument,
	DEBUG,
	Named,
	ListState,
	PouchResult,
	PropertyNames,
	servers,
	toaster
} from "@/loader";
import dayjs from "dayjs";

export interface ListItemMatcher<T> {
	title: string
	valueField?: keyof T
	getValue?: (item: T) => any
	match: (item: T, selection: any[]) => boolean
	isFlag?: boolean
}

export interface ListFilter<T extends Brand, U> extends ListItemMatcher<U> {
	items: T[]
	selection: any[]
	valueToString(value: any, separator?: string): string
}

export function isListFilter<T extends Brand, U>(value: any): value is ListFilter<T, U> {
	return value && "selection" in value && "items" in value || false;
}

export function valueMatcher(v: any | any[], selection: any[]) {
	return Array.isArray(v) ? v.some(x => selection.includes(x)) : selection.includes(v);
}

export function flagMatcher(v: any | any[], selection: any[]) {
	return Array.isArray(v) ? v.some(x => (x & arrayToFlag(selection)) > 0) : (v & arrayToFlag(selection)) > 0;
}

export function createListFilter<T extends Brand, U>(data: Partial<ListItemMatcher<U>> | keyof U): ListFilter<T, U> {
	let { getValue, match, valueField, isFlag, title } = typeof data === "object" ? data : { valueField: data, match: undefined, getValue: undefined, isFlag: false, title: "" };
	if (!match) {
		if (getValue) {
			match = (item: U, selection: any[]) => {
				let v = getValue && getValue(item);
				let matcher = isFlag ? flagMatcher : valueMatcher;
				return getValue && matcher(v, selection) || false;
			}
		} else if (valueField) {
			match = (item: U, selection: any[]) => {
				if (valueField && item && typeof item === "object" && <string>valueField in item) {
					// @ts-ignore
					let v = item[valueField];
					let matcher = isFlag ? flagMatcher : valueMatcher;
					return matcher(v, selection);
				}
				return false;
			}
		} else {
			throw "Match method must be defined";
		}
	}
	return {
		title: title || "",
		items: [],
		selection: [],
		// @ts-ignore
		valueField,
		getValue,
		match,
		valueToString(value: any, separator: string = ", "): string {
			if (Array.isArray(value)) {
				return this.items
					.filter(x => value.includes(x._id))
					.map(x => x.name)
					.join(separator)
			} else if (value) {
				return this.items.find(x => x._id === value && (!x.doc || !x.doc._deleted))?.name || "?";
			} else {
				return "";
			}
		}
	};
}

@Component
export class DocumentList<T extends CouchDBDocument> extends Mixins(BusyMixin) {
	@Prop({ type: Boolean, default: false })
	public hideFilters!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideSearch!: boolean;

	@Prop({ type: Boolean, default: false })
	public selectable!: boolean;

	@Prop({ type: Object, default: null })
	public query!: PouchDB.Find.FindRequest<T>;

	@Prop({ type: String, default: () => "" })
	public stateName!: string;

	public filter: string = "";
	public items: T[] = [];
	public limit: number = 20;
	public page: number = 1;
	// @ts-ignore
	public sort: PropertyNames<T> = "_id";
	public sortDesc: boolean = false;
	public total: number = 0;

	public filters: { [filter: string]: ListFilter<Brand, T> } = {};

	public get localStateName(): string {
		return this.stateName;
	}

	public get startkey(): string {
		throw "startkey must be inherited to work";
	}

	public get endkey(): string {
		throw "endkey must be inherited to work";
	}

	public get type(): string {
		throw "type must be inherited to work";
	}

	public get showFilters(): boolean {
		return !this.hideFilters && this.$screen.lg && Object.values(this.filters).reduce((sum, f) => sum + f.items.length, 0) > 0;
	}

	public get checkDocFunction(): Function | null {
		return null;
	}

	public get canCreate(): boolean {
		DEBUG && console.warn("canCreate should be inherited to work properly, currently only admins can create.");
		return servers.isAdmin;
	}

	public loadQuery(): (() => Promise<T[]>) | null {
		return null;
	}

	public get preparedItems() {
		// @ts-ignore
		let sort: PropertyNames<T> = this.sort || "_id";
		let filter = this.filter.removeDiacritics().toLowerCase();
		return this.items
			.filter(item =>
				Object.values(this.filters).every(f => !f.selection.length || f.match(item, f.selection))
				&& (!filter || (item.searchable || "").includes(filter))
			).sort((a, b) => this.defaultSorter(sort, a, b));
	}

	public defaultSorter(sort: PropertyNames<T>, a: T, b: T): number {
		let first: T = this.sortDesc ? b : a;
		let second: T = this.sortDesc ? a : b;
		// @ts-ignore
		return (first[sort] || "").toString().localeCompare(second[sort]?.toString() || "");
	}

	public async loadItems() {
		if (!servers.selected) {
			return;
		}
		let fun = this.loadQuery();

		try {
			let items: T[];
			if (fun) {
				items = await fun();
				DEBUG && console.log("loadQuery?", items);
			} else if (this.query) {
				let result = await this.$pouch.find(this.query, servers.selected.name);
				DEBUG && console.log("find?", this.query, result);
				items = <T[]>result.docs;
				DEBUG && result.warning && console.warn(result.warning);
			} else {
				let result = await this.$pouch.allDocs({ startkey: this.startkey, endkey: this.endkey, include_docs: true }, servers.selected.name);
				DEBUG && console.log("allDocs?" + this.startkey, result);
				items = result.rows.map(x => <T>x.doc);
			}

			this.items = await this.prepareItems(items);
		} catch (error) {
			console.error(error);
			toaster.error({ error });
			this.items = [];
		}
		this.total = this.items.length;
		this.$emit("loaded", this.items);
	}

	public prepareItems(items: T[]): T[] {
		items = items
			.filter(item => item?.type === this.type)
			.map(item => ({
				...<T>(this.checkDocFunction ? this.checkDocFunction(item) : item),
				searchable: this.getSearchableString(<T>item)
			}));
		if (items.length && Named.instanceOf(items[0])) {
			items = items.sort((a, b) => Named.instanceOf(a) && Named.instanceOf(b) && a.name.localeCompare(b.name) || 0);
		}
		return items;
	}

	public mounted() {
		this.loadItems();
		this.loadFromState();
	}

	public getSearchableString(value: T): string {
		throw "getSearchableString must be inherited to work";
	}

	public onFiltered() {
		this.page = 1;
	}

	public setFilterItems(key: string, items: Brand[]) {
		DEBUG && console.log("setFilterItems", key, items);
		if (!(key in this.filters)) {
			console.error(`Filter ${key} not found`);
			return;
		}
		this.filters[key].items = items;
		if (this.filters[key].selection.length) {
			this.filters[key].selection = this.filters[key].selection.filter(s => items.some(i => i._id == s));
		}
		// this.filters[key].selection = [];
	}

	public clearFilters() {
		Object.values(this.filters).forEach(f => f.selection = []);
		this.onFiltered();
	}

	public state(): ListState | null {
		DEBUG && console.log("state", this.localStateName)
		if (!this.localStateName || !servers.selected) {
			return null;
		}
		if (!(this.localStateName in servers.selected.settings.states)) {
			DEBUG && console.warn("state: create");
			return ListState.create();
		}
		return <ListState>servers.selected.settings.states[this.localStateName];
	}

	public beforeDestroy() {
		const state = this.state();
		DEBUG && console.log("beforeDestroy", state);
		if (state) {
			state.filter = this.filter;
			state.limit = this.limit;
			state.page = this.page;
			state.sort = <string>this.sort;
			state.sortDesc = this.sortDesc;
			Object.keys(this.filters).forEach(key => {
				if (isListFilter(this.filters[key])) {
					state.filters[key] = this.filters[key].selection.slice();
				}
			});
			servers.setState({ state: this.localStateName, data: state });
			if (servers.selected) {
				this.$db.saveServerSettings(servers.selected.settings);
			}
		}
	}

	public loadFromState() {
		const state = this.state();
		DEBUG && console.log("loadFromState", state);
		if (state) {
			this.filter = state.filter;
			this.limit = state.limit;
			this.page = state.page;
			if (state.sort) {
				this.sort = <PropertyNames<T>>state.sort;
			}
			this.sortDesc = state.sortDesc;
			Object.keys(this.filters).forEach(key => {
				if (isListFilter(this.filters[key]) && key in state.filters) {
					this.filters[key].selection = state.filters[key].slice();
				}
			});
		}
	}

	public checkSelection() {

	}

	public sortCompare(a: T, b: T, key: keyof T, sortDesc: boolean, formatter?: (value: any, key: keyof T, item: T) => any, compareOptions?: Intl.CollatorOptions, compareLocale?: string | string[]) {
		let valueA = (formatter ? formatter(a[key], key, a) : a[key]) || "";
		let valueB = (formatter ? formatter(b[key], key, b) : b[key]) || "";
		let result: any;
		if (
			(typeof valueA === "number" && typeof valueB === "number")
			|| (valueA instanceof Date && valueB instanceof Date)
			|| (dayjs.isDayjs(valueA) && dayjs.isDayjs(valueB))
		) {
			// @ts-ignore
			result = valueA - valueB;
		} else {
			result = toString(valueA).localeCompare(toString(valueB), compareLocale, compareOptions);
		}

		if (!result && key !== "name" && Named.instanceOf(a) && Named.instanceOf(b)) {
			result = a.name.localeCompare(b.name, compareLocale, compareOptions);
		}

		// if (sortDesc) {
		// 	result *= -1;
		// }
		// console.log({ a, b, valueA, valueB, key, formatter, sortDesc, compareLocale, compareOptions, result });
		return result;
	}

	public booleanBrandFactory(r: PouchResult): Brand {
		return {
			_id: r.key,
			name: r.key ? "Oui" : "Non",
			count: r.value,
			doc: <CouchDBDocument>r.doc
		}
	}
}

function toString(value: any): string {
	if (value === null || value === undefined) {
		return "";
	} else if (Named.instanceOf(value)) {
		return value.name || "";
	} else if (typeof value === "object") {
		return Object.keys(value).sort().map(key => toString(value[key])).join(" ");
	}
	return value.toString();
}
