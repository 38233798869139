





























































import { Vue, Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { EditList, MachineSoftware, Machine, ConfigStatus, nanoid, toaster, Game } from "@/loader";
import MachineSoftwareRow from "./MachineSoftwareRow.vue";
import MachineSoftwareGameRow from "./MachineSoftwareGameRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		MachineSoftwareRow,
		MachineSoftwareGameRow,
	}
})
export default class MachineSoftwaresTable extends Mixins<EditList<MachineSoftware, Machine>>(EditList) {
	public dragOptions = {
		group: "machineSoftwares",
		handle: ".btn-move",
		draggale: ".machine-software-list-item",
	}
	public status: ConfigStatus[] = [];
	public games: Game[] = [];

	@Ref()
	public machineSoftwares!: MachineSoftwareRow | MachineSoftwareRow[];

	public get array(): MachineSoftware[] {
		if (this.status.length) {
			return this.parent?.softwares.filter(mp => this.status.includes(mp.status)) || [];
		}
		return this.parent?.softwares || [];
	}
	public set array(value: MachineSoftware[]) {
		if (this.parent && !this.status.length) {
			this.parent.softwares = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return MachineSoftware.create;
	}

	public get cloneFactory(): (base: MachineSoftware) => MachineSoftware {
		return MachineSoftware.clone;
	}

	public get statusOptions() {
		let array = this.parent?.softwares || [];
		return Object.values(ConfigStatus)
			.filter(value => typeof value === "number")
			.map(value => ({
				value,
				text: `${ConfigStatus.toString(<ConfigStatus>value)} (${array.filter(x => x.status === <ConfigStatus>value).length})`,
				disabled: !array.some(x => x.status === <ConfigStatus>value)
			}));
	}

	public editAll() {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Modifier" });
			return;
		}
		if (Array.isArray(this.machineSoftwares)) {
			this.machineSoftwares.forEach(mp => mp.editMode = true);
		} else {
			this.machineSoftwares.editMode = true;
		}
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (this.machineSoftwares) {
				if (Array.isArray(this.machineSoftwares)) {
					if (this.machineSoftwares.length) {
						this.machineSoftwares[this.machineSoftwares.length - 1].editMode = true;
					}
				} else {
					this.machineSoftwares.editMode = true;
				}
			}
		});
	}

	public async loadGames() {
		if (!this.parent) {
			this.games = [];
			return;
		}
		let keys = this.parent.games.filter(mg => mg.status === ConfigStatus.active).map(mg => mg.gameId);
		keys.push(...this.parent.games.filter(mg => mg.status === ConfigStatus.active && mg.packId).map(mg => mg.packId));
		this.games = await this.$db.allGames({ keys });
	}

	public mounted() {
		this.loadGames();
	}

	@Watch("parent")
	public onParentChanged(newValue: Machine, oldValue?: Machine) {
		if (newValue !== oldValue) {
			this.loadGames();
		}
	}
}
