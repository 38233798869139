import { checkValue } from "@/loader";

export interface Sortable {
	order: number;
}

export namespace Sortable {
	export function instanceOf(value: any): value is Sortable {
		return value && typeof value === "object" && "order" in value;
	}

	export function isSortableArray(value: any[]): value is Sortable[] {
		return value && !!value.length && "order" in value[0];
	}

	export function check(data: any): Sortable {
		checkValue(data, "order", 0);
		return data;
	}
}
