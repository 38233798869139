import {
	checkArrayValue,
	checkValue,
	cloneObject,
	ConfigStatus,
	Events,
	Identifiable,
	nanoid,
	Part,
	PartReplacement,
	UUID
} from "@/loader"


export interface MachinePart extends Identifiable, Events {
	partId: UUID
	categoryId: UUID
	softwareId: UUID
	favorite: boolean
	status: ConfigStatus
	softwareVersion: string | null
	serial: string | null
	children: MachinePart[]
}

export namespace MachinePart {
	export function create(
		partId: string = "",
		softwareId: string = "",
		categoryId: string = "",
		status: ConfigStatus = ConfigStatus.draft,
		softwareVersion: string | null = null,
		serial: string | null = null,
		favorite: boolean = false,
	): MachinePart {
		return {
			id: nanoid(),
			partId,
			softwareId,
			categoryId,
			status,
			favorite,
			softwareVersion,
			serial,
			children: [],
			events: [],
		}
	}

	export function clone(base: MachinePart | PartReplacement): MachinePart {
		let result = cloneObject(base, {
			id: nanoid(),
			serial: "",
			status: ConfigStatus.draft,
			children: base.children.map(clone),
		});
		if (PartReplacement.instanceOf(result)) {
			// @ts-ignore
			delete result.action;
			// @ts-ignore
			delete result.oldId;
		}
		return result;
	}

	export function check(data: any): MachinePart {
		checkValue(data, "id", nanoid);
		checkValue(data, "partId", "");
		checkValue(data, "softwareId", "", undefined, ["osId"]);
		checkValue(data, "categoryId", "");
		checkValue(data, "favorite", false);
		checkValue(data, "status", ConfigStatus.draft);
		checkValue(data, "softwareVersion", null, undefined, ["osVersion"]);
		checkValue(data, "serial", null);
		checkArrayValue(data, "children", check);
		Events.check(data);
		return data;
	}

	export function setStatus(part: MachinePart | null, status: ConfigStatus) {
		if (!part || !ConfigStatus.checkWorkflow(part.status, status)) {
			return;
		}
		part.status = status;
		for (const child of part.children) {
			setStatus(child, status);
		}
	}

	export function toString(value: MachinePart, part: Part | null): string {
		return [
			part?.name,
			value.serial
		].filter(x => !!x).join(" - ");
	}

	export function instanceOf(value: any): value is MachinePart {
		return Identifiable.instanceOf(value) && "partId" in value;
	}

	export function modifyFromReplacement(mp: MachinePart, pr: PartReplacement) {
		mp.categoryId = pr.categoryId;
		mp.partId = pr.partId;
		mp.softwareId = pr.softwareId;
		mp.softwareVersion = pr.softwareVersion;
		mp.softwareVersion = pr.softwareVersion;
		mp.favorite = pr.favorite;
		mp.serial = pr.serial;
	}
}
