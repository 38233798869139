






























































































import { Component, Mixins } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue";
import {
	servers,
	Brand,
	Game,
	Part,
	PartCategory,
	UUID,
	Software,
	createListFilter,
	DocBrand,
	DocumentList,
	CouchDBDocument,
} from "@/loader";

@Component
export default class PartsList extends Mixins<DocumentList<Part>>(DocumentList) {
	public PART_CATEGORY_STARTKEY = PartCategory.STARTKEY;
	public PART_CATEGORY_ENDKEY = PartCategory.ENDKEY;
	public SOFTWARE_STARTKEY = Software.STARTKEY;
	public SOFTWARE_ENDKEY = Software.ENDKEY;

	/** @ts-ignore */
	public sort: string = "name";
	/** @ts-ignore */
	public filters = {
		brands: createListFilter<Brand, Part>("brand"),
		categories: createListFilter<DocBrand<PartCategory>, Part>("categoryId"),
		software: createListFilter<DocBrand<Software>, Part>("softwareId"),
		isDisabled: createListFilter<Brand, Game>({ valueField: "disabled", title: "Désactivé" }),
	};

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "brand",
			label: "Marque",
			sortable: true,
		},
		{
			key: "model",
			label: "Modèle",
			sortable: true,
		},
		{
			key: "categoryId",
			label: "Catégorie",
			formatter: (value) => (this.getCategory(value) || { name: "?" }).name,
			sortable: true,
			sortByFormatted: true,
		},
	];

	public get localStateName(): string {
		return "parts";
	}

	public get startkey(): string {
		return Part.STARTKEY;
	}

	public get endkey(): string {
		return Part.ENDKEY;
	}

	public get type(): string {
		return Part.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get checkDocFunction(): Function | null {
		return Part.check;
	}

	public getCategory(id: UUID) {
		return this.filters.categories.items.find(c => c._id === id);
	}

	public getSearchableString(value: Part): string {
		return [
				value.name.removeDiacritics().toLowerCase(),
				value.brand?.removeDiacritics().toLowerCase() || "",
				value.model?.removeDiacritics().toLowerCase() || "",
			].filter(x => !!x)
			.join(CouchDBDocument.ENDKEY_SUFFIX);
	}
}
