import { Component, Mixins } from "vue-property-decorator";
import { EnumSelect, CounterType, BvListItem } from "@/loader";

@Component
export class CounterTypeSelect extends Mixins<EnumSelect<CounterType>>(EnumSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return CounterType.LIST;
	}
}
