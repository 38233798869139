








































import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, ReactivationTask } from "@/loader";

@Component
export default class ReactivationTaskEditor extends Mixins<TaskDataEditorMixin<ReactivationTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			location: this.focused.location && this.item ? !!this.item.location : null,
			ref: this.focused.ref && this.item ? true : null,
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public invalidFeedbacks = {
		location: "L'emplacement dans le casino est nécessaire pour une installation.",
		ref: "",
		reading: "Une relève est nécessaire pour une réactivation",
	}
}
