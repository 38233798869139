import { Settings, SyncStatus } from "@/loader";

export interface ServerData {
	name: string
	label: string
	url: string
	auth: string
	img: string
}

export interface Server extends ServerData {
	model: string
	active: boolean
	status: SyncStatus
	heartbeat: number
	settings: Settings
	sync: PouchDB.Replication.Sync<{}> | null
}

export function createServer(
	name: string,
	model: string,
	label: string,
	url: string,
	auth: string,
	img: string,
	active: boolean = false,
	status: SyncStatus = SyncStatus.unknown
): Server {
	return {
		name,
		model,
		label,
		url,
		auth,
		img,
		active,
		status,
		heartbeat: 0,
		settings: Settings.create(name),
		sync: null,
	}
}
