

























































































































































































































































































import { Mixins, Component, Prop, Watch, Ref } from "vue-property-decorator";
import dayjs from "dayjs";
import {
	DEBUG,
	EditComponent,
	Reading,
	Counter,
	Machine,
	Software,
	ReadingCounter,
	toaster,
	ReadingStatus,
	servers,
	Events,
	Event,
	EventType,
	TaskAction,
	SoftwareCounterValue,
	AppSettings,
	Mission,
	Task,
	ReadingTask,
	CouchDBDocument,
	ReadingCounterSettings,
	UUID,
	ReadingValue,
	isEditor,
} from '@/loader';
import ReadingCounterInput from "./ReadingCounterInput.vue";
import ReadingStatsTable from "./ReadingStatsTable.vue";

@Component({
	components: {
		ReadingCounterInput,
		ReadingStatsTable,
	}
})
export default class ReadingEditor extends Mixins<EditComponent<Reading, CouchDBDocument>>(EditComponent) {
	@Prop({ type: String, required: true })
	public machineId!: string;

	@Prop({ type: String, default: "div" })
	public tag!: string;

	@Ref()
	public readonly elecsInputs!: ReadingCounterInput[];

	@Ref()
	public readonly mecasInputs!: ReadingCounterInput[];

	@Ref()
	public readonly counterInputs!: ReadingCounterInput[];

	public machine: Machine | null = null;
	public mission: Mission | null = null;
	public task: Task | null = null;
	public software: Software | null = null;
	public counters: Counter[] = [];
	public previous: Reading[] = [];
	public previousCount: number = 1;
	public deltaMargin: number = 50;
	public elecs: SoftwareCounterValue[] = [];
	public mecas: SoftwareCounterValue[] = [];
	public ReadingStatus = ReadingStatus;
	public settings: AppSettings | null = null;
	public display: ReadingCounterSettings | null = null;
	public readingValue: ReadingValue | null = null;

	// public get canEdit(): boolean {
	// 	return this.item && (this.item.status === ReadingStatus.draft || this.item.status === ReadingStatus.control);
	// }

	public get isNew(): boolean {
		return this.item && !this.item._rev || false;
	}

	public get canCreate(): boolean {
		return true;
	}

	public get isDraft(): boolean {
		return this.item?.status === ReadingStatus.draft;
	}

	public get isControl(): boolean {
		return this.item?.status === ReadingStatus.control;
	}

	public get isDone(): boolean {
		return this.item?.status === ReadingStatus.done;
	}

	public get missionId(): string {
		let missionId = this.$route.query.missionId;
		return Array.isArray(missionId) ? (missionId.length ? missionId[0] || "" : "") : missionId;
	}

	public get taskId(): string {
		let taskId = this.$route.query.taskId;
		return Array.isArray(taskId) ? (taskId.length ? taskId[0] || "" : "") : taskId;
	}

	public get itemFactory(): Function | null {
		if (!this.machineId) {
			throw "Impossible de créer une relève sans machine";
		}
		return () => Reading.create(this.machineId, this.machine?.osId || "");
	}

	public get cloneFactory(): (base: Reading) => Reading {
		throw "Impossible de cloner une relève";
	}

	public get checkDocFunction(): Function | null {
		return Reading.check;
	}

	public get routeName(): string {
		return "reading";
	}

	public get motive() {
		return (p: Reading) => {
			return TaskAction.toString(p.motive);
		}
	}

	public get previousDuration(): (p: Reading, i: number) => number {
		return (p, i) => {
			if (i >= this.previous.length) {
				return 0;
			}
			let other = !i ? this.item : this.previous[i - 1];
			return other ? dayjs(p.date).diff(other.date, "d", true) : 0;
		};
	}

	public get previousDurationLabel(): (p: Reading, i: number) => string {
		return (p: Reading, i: number) => {
			let diff = this.previousDuration(p, i);
			return `(+${dayjs.duration(diff, "d").humanize()})`;
		};
	}

	public get isElecUnavailable() {
		return this.elecs.every(e => e.value.unavailable);
	}

	public set isElecUnavailable(value: boolean) {
		this.elecs.forEach(e => {
			e.value.unavailable = value;
		});
	}

	public get colCount() {
		return Math.max(...this.display?.rows.map(r => r.columns.filter(c => c.counterId && !c.merged).length) || []);
	}

	public getReadingCounter(counterId: UUID, isMeca: boolean): ReadingCounter {
		if (!this.item) {
			throw "No item available";
		}
		let rc = this.item.values.find(v => v.counterId === counterId);
		if (!rc) {
			rc = ReadingCounter.create(counterId, isMeca);
			this.item.values.push(rc);
		}
		return rc;
	}

	public getPreviousValues(counterId: string): ReadingCounter[] {
		return this.previous.map(p => p.values.find(x => x.counterId === counterId) || null).filter(x => x !== null) as ReadingCounter[];
	}

	public async loadMachine() {
		if (!this.settings) {
			toaster.error({ message: "Réglages non trouvés. Veuillez prévenir l'administrateur." });
			throw "Settings not found";
		}
		if (!this.item) {
			toaster.error({ message: "Relève non trouvée. Veuillez recharger la page." });
			throw "Reading not found";
		}
		this.machine = await this.$db.getMachine(this.machineId);
		if (!this.machine) {
			toaster.error({ message: "Machine inconnue" });
			throw "Machine not found: " + this.machineId;
		}
		this.software = await this.$db.getSoftware(this.machine.osId);
		if (!this.software) {
			toaster.error({ message: "OS inconnu. Veuillez vérifier l'OS de la machine." });
			throw "Software not found: " + this.machine.osId;
		}
		await this.loadPrevious();

		this.readingValue = ReadingValue.create(this.item, this.machine, this.software, this.counters, this.settings, this.previous);

		this.elecs = this.readingValue.elecs;
		this.mecas = this.readingValue.mecas;
		this.display = this.readingValue.display;

		await this.loadMission();
	}

	public async loadMission() {
		this.mission = await this.$db.getMission(this.item?.missionId);
		this.task = await this.$db.getTask(this.item?.taskId);
		if (this.isNew && this.item && !this.item.motive) {
			this.item.motive = this.task?.action || TaskAction.none;
		}
		if (this.task?.action === TaskAction.reset_counter && this.readingValue) {
			ReadingValue.reuseLastMeca(this.readingValue);
		}
		if (this.task?.action === TaskAction.change_counter && this.readingValue) {
			ReadingValue.reuseLastElec(this.readingValue);
		}
	}

	public async loadPrevious() {
		let options: PouchDB.Core.AllDocsWithinRangeOptions = {
			// limit: this.previousCount + 1,
			startkey: this.item?._id || Reading.getId(this.machineId, dayjs()),
			endkey: Reading.getId(this.machineId, 0),
			descending: true,
		}
		if (!this.isNew) {
			options.skip = 1;
		}
		this.previous = await this.$db.allReadings(this.machineId, undefined, options);
	}

	public async mounted() {
		this.settings = await this.$db.getAppSettings();
		this.counters = await this.$db.allCounters();
		this.loadMachine();
	}

	public machineIdChanged(newValue: string, oldValue?: string) {
		if (newValue && newValue !== oldValue) {
			this.loadMachine();
		}
	}

	public cancel() {
		this.$router.push({ name: "machine", params: { id: this.machineId } });
	}

	public async save(status: ReadingStatus, eventType?: EventType, eventComment: string = "") {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.item || !this.changed) {
			toaster.noChange();
			return false;
		}
		if (!this.canEdit) {
			toaster.forbidden();
			return false;
		}

		this.item.status = status;
		if (Events.instanceOf(this.item)) {
			if (status === ReadingStatus.done) {
				this.item.events.push(Event.create(
					servers.author,
					EventType.done,
					eventComment,
					this.original,
					this.item
				));
			} else {
				this.item.events.push(Event.create(
					servers.author,
					eventType || (this.isNew ? EventType.created : EventType.modified),
					eventComment,
					this.original,
					this.item
				));
			}
		}

		if (await this.$db.save(this.item)) {
			this.original = JSON.parse(JSON.stringify(this.item));
			this.clean();
			toaster.success({ message: "Relève enregistrée" });

			if (this.task && ReadingTask.instanceOf(this.task.data) && this.task.data.reading !== this.item._id) {
				const original = JSON.parse(JSON.stringify(this.task));
				this.task.data.reading = this.item._id;
				this.task.events.push(Event.create(
					servers.author,
					EventType.modified,
					"",
					original,
					this.task,
					{ reading: this.item._id }
				));
				if (await this.$db.save(this.task)) {
					toaster.success({ message: "Tâche mise à jour" });
				} else {
					toaster.error({ message: "La tâche n'a pas été mise à jour." });
				}
			}
			return true;
		}
		return false;
	}

	public async planChangeCounter() {
		this.addTask(TaskAction.change_counter, "RAZ des compteurs");
	}

	public async planResetCounter() {
		this.addTask(TaskAction.reset_counter, "Changement de compteurs");
	}

	public async addTask(action: TaskAction, operation: string) {
		if (!this.item) {
			toaster.noItem({ operation, itemType: "Relève" });
			return;
		}
		if (!servers.selected) {
			toaster.noServer({ operation });
			return;
		}
		if (!this.missionId) {
			toaster.invalidWorkflow({ operation });
			return;
		}

		const mission = await this.$db.getMission(this.missionId);
		if (!mission) {
			toaster.noItem({ operation, itemType: "Mission" });
			return;
		}
		const tasks = await this.$db.queryTasksByMission(this.missionId);
		DEBUG && console.log(tasks);
		if (tasks.find(t => t.action === action && t.machineId === this.item?.machineId)) {
			toaster.warn({ message: `Une tâche de type "${operation}" est déjà prévue pour cette machine.` });
			return;
		}

		const task = Task.create(this.missionId, this.item.machineId, action);
		task.events.push(Event.create(servers.author, EventType.created));
		Task.check(task); // To create the data corresponding to the action
		if (await this.$db.save(task)) {
			toaster.success({ message: `Tâche "${operation}" ajoutée avec succès` });
			return true;
		}
		return false;
	}

	public reuseLastElec(calculateStats = false) {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Réutiliser les compteurs Elec T-1" });
			return;
		}
		if (!this.readingValue) {
			toaster.noItem({ operation: "Réutiliser les compteurs Elec T-1", itemType: "Relève" });
			return;
		}
		ReadingValue.reuseLastElec(this.readingValue);
	}

	public reuseLastMeca(calculateStats = false) {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Réutiliser les compteurs Méca T-1" });
			return;
		}
		if (!this.readingValue) {
			toaster.noItem({ operation: "Réutiliser les compteurs Méca T-1", itemType: "Relève" });
			return;
		}
		ReadingValue.reuseLastMeca(this.readingValue);
	}

	public reuseLast() {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Réutiliser les compteurs T-1" });
			return;
		}
		if (!this.readingValue) {
			toaster.noItem({ operation: "Réutiliser les compteurs T-1", itemType: "Relève" });
			return;
		}
		ReadingValue.reuseLast(this.readingValue);
	}

	public next(item: SoftwareCounterValue) {
		let nextItem = (item.value.isMeca ? this.mecas : this.elecs).find(x => x.order === item.order + 1);
		if (!nextItem) {
			nextItem = (item.value.isMeca ? this.elecs : this.mecas)[0];
		}
		let nextInput = this.counterInputs.find(i => i.value === nextItem);
		if (nextInput) {
			nextInput.focus();
		}
	}

	public calcDeltas() {
		this.elecs.forEach(x => SoftwareCounterValue.calcDelta(x, this.deltaMargin));
		this.mecas.forEach(x => SoftwareCounterValue.calcDelta(x, this.deltaMargin));
	}


	public setChanged(value: boolean) {
		if (this.readingValue) {
			value = this.getRefs().some(x => isEditor(x) && x.changed) || !!value;
			ReadingValue.calcStats(this.readingValue);
			this.changed = value;
		}
	}

	@Watch("previousCount")
	public previousCountChanged(newValue: number, oldValue: number) {
		if (newValue !== oldValue) {
			this.loadPrevious();
		}
	}
}
