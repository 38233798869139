import Vue, { VueConstructor } from "vue";
import ToggleButton from "./Button.vue";

let installed = false

export default {
	install(Vue: VueConstructor<Vue>) {
		if (installed) {
			return
		}

		Vue.component('ToggleButton', ToggleButton)
		installed = true
	}
}

export {
	ToggleButton
}
