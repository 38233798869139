







































































import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	DEBUG,
	busy,
	toaster,
	servers,
	EditObject,
	Credentials,
	AuthResponse,
	Account,
	createAccountServer,
	getModel,
	createServerFromAccountServer,
} from "@/loader";

@Component
export default class ConnectServer extends Mixins<EditObject<Credentials>>(EditObject) {
	@Prop({ default: "div" })
	public tag!: any;

	public get itemFactory() {
		return Credentials.create;
	}

	public get account(): Account | null {
		return servers.account;
	}

	public focused = {
		server: false,
		login: false,
		password: false,
	}

	public invalidFeedbacks = {
		server: "Vous devez choisir un serveur",
		login: "L'identifiant ne peut pas être vide",
		password: "Le mot de passe ne peut pas être vide",
	}

	public get states() {
		return {
			server: this.item && this.focused.server ? !!this.item.server : null,
			login: this.item && this.focused.login ? !!this.item.login : null,
			password: this.item && this.focused.password ? !!this.item.password : null,
		}
	}

	public get servers() {
		return servers.definitions.filter(d => !servers.list.find(s => s.model === d.name));
	}

	public async connect() {
		if (!this.check()) {
			toaster.warn({ message: "Veuillez vérifier les données saisies." });
			return;
		}

		if (!servers.settings) {
			toaster.error({ message: "Les réglages ne sont pas définis. Veuillez recharger la page." });
			return;
		}

		if (!this.account) {
			toaster.error({ message: "Le compte n'est pas défini. Veuillez recharger la page." });
			return;
		}

		if (!this.item) {
			toaster.error({ message: "Le document d'identité n'est pas défini. Veuillez recharger la page." });
			return;
		}

		let model = getModel(this.item.server);
		if (!model) {
			toaster.error({ message: "Le serveur n'est pas défini. Veuillez vérifier les données." });
			return;
		}

		DEBUG && console.group("ConnectServer");
		busy.start("Connexion au serveur ...");
		try {
			// Connect to the server
			let response: Response = await fetch(model.auth, {
				method: "POST",
				body: JSON.stringify({ action: "login", username: this.item.login, password: this.item.password })
			});
			DEBUG && console.log("response", response);

			// Parse the response
			let result: AuthResponse = await response.json();
			DEBUG && console.log("result", result);
			if (result.code !== 200 || !result.user || !result.token) {
				throw result;
			}

			// Create the AccountServer stored in the Account
			let accountServer = createAccountServer(this.account.name + "_" + this.item.server, this.item.server);
			this.account.servers.push(accountServer);
			DEBUG && console.log("accountServer", accountServer);

			// Save the account in the server
			// @ts-ignore
			await this.$root.updateAccount();
			DEBUG && console.log("account updated");

			let id = Credentials.getId(this.account.name, this.item.server);
			let cred;
			try {
				cred = await this.$pouch.get(id, {}, this.account.name);
				this.item._rev = cred._rev;
			} catch (error) {
			}
			this.item._id = id;
			// Save the credentials file in the account DB
			await this.$pouch.put(this.item, {}, this.account.name);

			// Init the server and save it's settings with the user and token given by the login
			let server = createServerFromAccountServer(accountServer, model);
			servers.list.push(server);
			servers.modifyServer({ ...result, server });
			await this.$db.saveServerSettings(server.settings);
			DEBUG && console.log("connection to server", JSON.parse(JSON.stringify(server)));

			// Start the server
			// @ts-ignore
			await this.$root.startServer(server, this.item);
			this.$emit("connected", server);
		} catch (error: any) {
			DEBUG && console.error(error);
			toaster.error({ error, noAutoHide: false });
		}
		DEBUG && console.groupEnd();
		busy.stop();
	}

	public clean() {
		this.unfocusAll();
		this.item = this.itemFactory();
	}

	public mounted() {
		this.clean();
	}
}
