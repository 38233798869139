import Vue from "vue";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faSpinner,
	faCaretLeft,
	faCaretRight,
	faTrash,
	faMinus,
	faPlus,
	faExclamationTriangle,
	faWifi,
	faWifiSlash,
	faLock,
	faLink,
	faCircle,
	faUnlink,
	faMinusCircle,
	faPause,
	faPhone,
	faStar as fasStar,
	faQuestion,
	faSync,
	faUndo,
	faTrashUndo,
	faSave,
	faHistory,
	faArrowLeft,
	faTimes,
	faCheck,
	faInfoCircle,
	faQuestionCircle,
	faChevronRight,
	faChevronDown,
	faOctagon,
} from "@fortawesome/pro-solid-svg-icons";
import {
	faBullseyeArrow,
	faCheckSquare,
	faDotCircle,
	faEdit,
	faEnvelope,
	faFax,
	faHome,
	faPuzzlePiece,
	faRepeat,
	faSquare,
	faUser,
	faUsers
} from "@fortawesome/pro-regular-svg-icons";
import {
	faBolt,
	faCalendarAlt,
	faAbacus,
	faAngleLeft,
	faAngleRight,
	faBoxes,
	faBuilding,
	faClipboardListCheck,
	faComputerClassic,
	faGamepad,
	faGlobe,
	faIdCard,
	faLayerGroup,
	faLockAlt,
	faNetworkWired,
	faSignalSlash,
	faSortAlphaDown,
	faSortAlphaDownAlt,
	faSortNumericDown,
	faSortNumericDownAlt,
	faStar as falStar,
	faTags,
	faPowerOff,
	faBoxAlt,
	faExternalLink,
	faBarcode,
	faCubes,
	faCog,
	faLocation,
	faBookmark,
	faGlass,
	faLevelDown,
	faGlasses,
	faFlagCheckered,
	faArrows,
	faTasks,
	faLevelUp,
	faClone,
	faBox,
	faSearch,
	faCoins,
	faGift,
	faMoneyBill,
	faCoin,
	faPlug,
	faExchange,
	faEraser,
	faInfoCircle as falInfoCircle,
	faChevronSquareDown,
	faChevronSquareUp,
	faLineColumns,
	faPercent,
	faArrowToBottom,
	faArrowToTop,
	faBan,
	faEye,
	faEyeSlash,
	faFilePdf,
	faText,
	faCogs,
	faBalanceScale,
	faMicrochip,
	faFingerprint,
	faWaveformPath,
} from "@fortawesome/pro-light-svg-icons";
import { faUbuntu } from "@fortawesome/free-brands-svg-icons";

Vue.component("fa", FontAwesomeIcon);
Vue.component("fa-layers", FontAwesomeLayers);
Vue.component("fa-layers-text", FontAwesomeLayersText);

export type FaType = "solid" | "s" | "regular" | "r" | "light" | "l" | "duotone" | "d" | "brand" | "b";
export type FaSize = "xs" | "sm" | "lg" | "2x" | "3x" | "4x" | "5x" | "7x" | "10x";
export type FaRotate = "90" | "180" | "270";
export type FaFlip = "horizontal" | "vertical" | "both";

export interface IconConfig {
	icon: Object | string[] | string
	beat?: boolean
	border?: boolean
	fade?: boolean
	fixedWidth?: boolean
	flash?: boolean
	flip?: 'horizontal' | 'vertical' | 'both'
	inverse?: boolean
	listItem?: boolean
	mask?: Object | string[] | string
	pull?: 'right' | 'left'
	pulse?: boolean
	rotation?: 90 | 180 | 270
	size?: '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x'
	spin?: boolean
	spinPulse?: boolean
	spinReverse?: boolean
	swapOpacity?: boolean,
	symbol?: boolean | string
	title?: string
	transform?: string | Object
}

export function createIconConfig(icon: Object | string[] | string, config: Partial<IconConfig> = {}): IconConfig {
	return {
		icon,
		fixedWidth: true,
		...config
	};
}

library.add(
	faCalendarAlt,
	faCaretLeft,
	faCaretRight,
	faCircle,
	faClipboardListCheck,
	faComputerClassic,
	faExclamationTriangle,
	faGamepad,
	faHome,
	faLayerGroup,
	faLink,
	faLock,
	faMinus,
	faMinusCircle,
	faPause,
	faPlus,
	faPowerOff,
	faPuzzlePiece,
	faSpinner,
	faTrash,
	faUbuntu,
	faUnlink,
	faUser,
	faUsers,
	faWifi,
	faWifiSlash,
	faEnvelope,
	faGlobe,
	faPhone,
	faFax,
	fasStar,
	falStar,
	faQuestion,
	faSync,
	faBuilding,
	faEdit,
	faUndo,
	faTrashUndo,
	faSave,
	faHistory,
	faArrowLeft,
	faBoxes,
	faTags,
	faTimes,
	faCheck,
	faAbacus,
	faSortAlphaDown,
	faSortAlphaDownAlt,
	faSortNumericDownAlt,
	faSortNumericDown,
	faNetworkWired,
	faAngleLeft,
	faAngleRight,
	faDotCircle,
	faSignalSlash,
	faInfoCircle,
	faLockAlt,
	faIdCard,
	faBoxAlt,
	faExternalLink,
	faQuestionCircle,
	faBarcode,
	faCubes,
	faCog,
	faCogs,
	faLocation,
	faBookmark,
	faGlass,
	faLevelDown,
	faGlasses,
	faFlagCheckered,
	faArrows,
	faTasks,
	faLevelUp,
	faClone,
	faBox,
	faBullseyeArrow,
	faChevronDown,
	faChevronRight,
	faSearch,
	faOctagon,
	faCoins,
	faGift,
	faMoneyBill,
	faCoin,
	faPlug,
	faExchange,
	faEraser,
	falInfoCircle,
	faChevronSquareDown,
	faChevronSquareUp,
	faLineColumns,
	faPercent,
	faArrowToBottom,
	faArrowToTop,
	faBan,
	faEye,
	faEyeSlash,
	faRepeat,
	faFilePdf,
	faCheckSquare,
	faSquare,
	faText,
	faBolt,
	faBalanceScale,
	faMicrochip,
	faFingerprint,
	faWaveformPath,
);

export default {};
