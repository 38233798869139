import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CreateElement } from "vue";
import { Game } from "@/loader";

@Component
export default class GameDisplay extends Vue {
	@Prop({ type: String, default: null })
	public readonly id!: string | null;

	@Prop({ type: Object, default: null})
	public readonly game!: Game | null;

	@Prop({ type: String, default: "span"})
	public readonly tag!: string;

	public localGame: Game | null = null;

	public async setGame(value?: string | Game | null) {
		if (Game.instanceOf(value)) {
			this.localGame = value;
		} else if (typeof value === "string" && !!value) {
			this.localGame = await this.$db.getGame(value);
		} else if (Game.instanceOf(this.game)) {
			this.localGame = this.game;
		} else if (this.id) {
			this.localGame = await this.$db.getGame(this.id);
		} else {
			this.localGame = null;
		}
	}

	public mounted() {
		this.setGame();
	}

	public render(h: CreateElement) {
		return h(this.tag, [this.localGame?.name || ""]);
	}

	@Watch("id")
	public idChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.setGame(newValue);
		}
	}

	@Watch("game")
	public gameChanged(newValue: Game | null, oldValue?: Game | null) {
		if (newValue !== oldValue) {
			this.setGame(newValue);
		}
	}
}
