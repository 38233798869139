import { Component, Mixins } from "vue-property-decorator";
import { DataSelect, ParentSelect, Software } from "@/loader";

@Component
export class SoftwareVersionSelect extends Mixins<DataSelect, ParentSelect<Software>>(DataSelect, ParentSelect) {
	public localOptions: any[] = [];

	// @ts-ignore
	public get options(): any[] {
		let result = this.parent?.versions
			? this.parent.versions.slice(0)
			: [];
		if (!this.required && !this.multiple) {
			result.unshift(this.emptyItem);
		}
		return result;
	}
}
