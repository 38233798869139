












































































































































































import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import dayjs from "dayjs";
import {
	EditView,
	Reading,
	Counter,
	Machine,
	Software,
	SoftwareCounter,
	ReadingCounter,
	toaster,
	ReadingStatus,
	TaskAction,
	SoftwareCounterValue,
	AppSettings,
	Mission,
	Task,
} from '@/loader';
import MachineHeader from "../../../component/machine/MachineHeader.vue";
import ReadingCounterInput from "../../../component/reading/ReadingCounterInput.vue";
import ReadingStatsTable from "../../../component/reading/ReadingStatsTable.vue";

@Component({
	components: {
		MachineHeader,
		ReadingCounterInput,
		ReadingStatsTable,
	}
})
export default class ReadingView extends Mixins<EditView<Reading>>(EditView) {
	@Prop({ type: String, required: true })
	public machineId!: string;

	@Ref()
	public readonly elecsInputs!: ReadingCounterInput[];

	@Ref()
	public readonly mecasInputs!: ReadingCounterInput[];

	public machine: Machine | null = null;
	public mission: Mission | null = null;
	public task: Task | null = null;
	public software: Software | null = null;
	public counters: Counter[] = [];
	public previous: Reading[] = [];
	public previousCount: number = 1;
	public elecs: SoftwareCounterValue[] = [];
	public mecas: SoftwareCounterValue[] = [];
	public ReadingStatus = ReadingStatus;
	public settings: AppSettings | null = null;

	public get canEdit(): boolean {
		return !!this.item && (this.item.status === ReadingStatus.draft || this.item.status === ReadingStatus.control);
	}

	public get canCreate(): boolean {
		return true;
	}

	public get isDraft(): boolean {
		return this.item?.status === ReadingStatus.draft;
	}

	public get isControl(): boolean {
		return this.item?.status === ReadingStatus.control;
	}

	public get isDone(): boolean {
		return this.item?.status === ReadingStatus.done;
	}

	public get missionId(): string {
		let result = this.$route.query.missionId || "";
		return Array.isArray(result) ? result[0] || "" : result;
	}

	public get taskId(): string {
		let result = this.$route.query.taskId || "";
		return Array.isArray(result) ? result[0] || "" : result;
	}

	public get before(): boolean {
		return !!this.$route.query.before || false;
	}

	public get itemFactory(): Function | null {
		if (!this.machineId) {
			throw "Impossible de créer une relève sans machine";
		}
		return () => Reading.create(this.machineId, this.machine?.osId || "");
	}

	public get cloneFactory(): (base: Reading) => Reading {
		throw "Impossible de cloner une relève";
	}

	public get checkDocFunction(): Function | null {
		return Reading.check;
	}

	public get routeName(): string {
		return "reading";
	}

	public get returnTo() {
		if (this.$route.query.machine) {
			return { name: "machine", params: { id: this.machineId } };
		}
		return "readings";
	}

	public get motive() {
		return (p: Reading) => {
			return TaskAction.toString(p.motive);
		}
	}

	public get previousDuration() {
		return (p: Reading, i: number) => {
			if ((i + 1) >= this.previous.length) {
				return 0;
			}
			let other = this.previous[i + 1];
			return dayjs(other.date).diff(p.date, "d", true);
		};
	}

	public get previousDurationLabel() {
		return (p: Reading, i: number) => {
			let diff = this.previousDuration(p, i);
			if (diff >= 1) {
				return `(${diff.toFixed(0)} jours)`;
			} else {
				return `(${dayjs.duration(diff, "d").humanize()})`;
			}
		};
	}

	public getReadingCounter(counterId: string): ReadingCounter {
		if (!this.item) {
			throw "No item available";
		}
		let rc = this.item.values.find(v => v.counterId === counterId);
		if (!rc) {
			rc = ReadingCounter.create(counterId);
			this.item.values.push(rc);
		}
		return rc;
	}

	public getPreviousValues(counterId: string): ReadingCounter[] {
		return this.previous.map(p => p.values.find(x => x.counterId === counterId) || null).filter(x => x !== null) as ReadingCounter[];
	}

	public prepareValues(counters: SoftwareCounter[] = []): SoftwareCounterValue[] {
		return counters
			.filter(sc => !sc.conditions || (sc.conditions & (this.machine?.config || 0)) > 0)
			.map((sc: SoftwareCounter) => ({
				...sc,
				delta: 0,
				deltaElec: 0,
				manual: false,
				valid: true,
				counter: this.counters.find(x => x._id === sc.counterId),
				value: this.getReadingCounter(sc.counterId),
				elecs: [],
				mecas: [],
				previous: [],
			} as SoftwareCounterValue))
			.filter(x => x.counter);
	}

	public prepareRelations() {
		this.elecs.forEach(e => {
			e.mecas = this.mecas.filter(m => m.relations.includes(e.counterId));
		});
		this.mecas.forEach(m => {
			m.elecs = this.elecs.filter(e => e.mecas.includes(m));
		});
	}

	public async loadMachine() {
		this.machine = await this.$db.getMachine(this.machineId);
		if (!this.machine) {
			toaster.error({ message: "Machine inconnue" });
			throw "Machine not found";
		}
		this.software = await this.$db.getSoftware(this.machine.osId);
		this.elecs = this.prepareValues(this.software?.elecs);
		this.mecas = this.prepareValues(this.software?.mecas);
		this.prepareRelations();
		await this.loadPrevious();
		await this.loadMission();
	}

	public async loadMission() {
		this.mission = await this.$db.getMission(this.missionId);
		this.task = await this.$db.getTask(this.taskId);
		if (this.isNew && this.item) {
			this.item.motive = this.task?.action || TaskAction.none;
		}
		if (this.task?.action === TaskAction.reset_counter) {
			this.mecas.forEach(x => {
				x.value.value = x.previous[0]?.value || 0;
			});
		}
		if (this.task?.action === TaskAction.change_counter) {
			this.elecs.forEach(x => {
				x.value.value = x.previous[0]?.value || 0;
			});
		}
	}

	public async loadPrevious() {
		let options: PouchDB.Core.AllDocsWithinRangeOptions = {
			// limit: this.previousCount + 1,
			startkey: this.item?._id || Reading.getId(this.machineId, dayjs()),
			endkey: Reading.getId(this.machineId, 0),
			descending: true,
		}
		if (!this.isNew) {
			options.skip = 1;
		}
		this.previous = await this.$db.allReadings(this.machineId, undefined, options);
		this.elecs.forEach(x => {
			x.previous = this.getPreviousValues(x.counterId);
		});
		this.mecas.forEach(x => {
			x.previous = this.getPreviousValues(x.counterId);
		});
	}

	public async mounted() {
		this.settings = await this.$db.getAppSettings();
		this.counters = await this.$db.allCounters();
		this.loadMachine();
	}

	public machineIdChanged(newValue: string, oldValue?: string) {
		if (newValue && newValue !== oldValue) {
			this.loadMachine();
		}
	}
}
