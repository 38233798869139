<template>
	<v-draggable class="tree level-0" pills vertical v-model="leafs">
		<category-leaf
			v-for="c in leafs"
			:key="c._id"
			:item="c"
			:include="includeWithParents"
			:opened="opened"
			@select="$emit('select', $event)"
		>
			<template #text-prepend="{ item: slotCat, level }"><slot name="text-prepend" :item="slotCat" :level="level"></slot></template>
			<template #text-append="{ item: slotCat, level }"><slot name="text-append" :item="slotCat" :level="level"></slot></template>
			<template #default="{ item: slotCat, level }"><slot :item="slotCat" :level="level"></slot></template>
		</category-leaf>
	</v-draggable>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Category, CategoryItem, CategoryComponent, servers, DEBUG } from '@/loader';
import CategoryLeaf from "./CategoryLeaf.vue";

@Component({
	components: {
		CategoryLeaf,
	}
})
export default class CategoryTree<T extends Category> extends Mixins<CategoryComponent<Category>>(CategoryComponent) {
	@Prop({ type: [Boolean, Array], default: false })
	public opened!: boolean | string[];

	public get leafs(): CategoryItem<T>[]
	{
		return this.categoriesTree
			.filter(c => !this.includeWithParents.length || this.includeWithParents.includes(c._id))
			.sort((a, b) => (a.order || 0) - (b.order || 0)) as CategoryItem<T>[];
	}
	public set leafs(value: CategoryItem<T>[])
	{
		if (!this.includeWithParents.length) {
			value.forEach((item, index) => {
				if (item.order !== index) {
					item.order = index;
					this.save(item);
				}
			});
		}
	}

	public addNewCategory(item: CategoryItem<T>) {
		this.categoriesItems.push(item);
	}

	public removeCategory(item: CategoryItem<T>) {
		this.categoriesItems.remove(item);
	}

	public mounted() {
		this.loadCategories();
	}

	public async save(item: CategoryItem<any>) {
		DEBUG && console.log("save", item._id, item.order);
		if (!servers.selected) {
			return;
		}
		if (!this.$pouch) {
			return;
		}

		try {
			// @ts-ignore
			let result = await this.$pouch.upsert(item._id, (doc: CategoryItem<T>) => {
				if (doc.order !== item.order) {
					doc.order = item.order;
					return doc;
				}
				return false;
			}, servers.selected.name);
			if (result.updated) {
				DEBUG && console.log("save ok", result);
				item._rev = result.rev;
			}
		} catch (error) {
			DEBUG && console.log("save error", error);
		}
	}
}
</script>
