

















































































































import { Component, Watch, Prop, Mixins, Model } from "vue-property-decorator";
// @ts-ignore
import VuePasswordStrengthMeter from "vue-password-strength-meter";
import { ZXCVBNResult, ZXCVBNAttackTime } from "zxcvbn";
import {
	zxcvbn,
	servers,
	Account,
	EditObject,
PropertyNames,
} from "@/loader";

type AccountWithConfirmPassword = Account & { confirmPassword?: string };

@Component({
	components: {
		VuePasswordStrengthMeter,
	},
})
export default class AccountEditor extends Mixins<EditObject<AccountWithConfirmPassword>>(EditObject) {
	@Model("input")
	public value!: Account | null;

	@Prop({ default: "div" })
	public tag!: any;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ type: Boolean, default: false })
	public lockName!: boolean;

	public confirmPassword: string = "";

	public invalidFeedbacks = {
		name: "L'identifiant doit être unique et entre 1 et 60 caractères",
		email: "L'email n'est pas valide",
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "Les deux mots de passe ne sont pas identiques.",
	};

	public get accounts(): string[] {
		return servers.settings?.accounts || [];
	}

	public get states() {
		return {
			name: this.item && this.focused.name ? this.item.name?.length > 0 && this.item?.name?.length < 60 && !this.accounts.find(a => a === this.item?.name) : null,
			email: this.item && this.focused.email ? this.item.email?.length <= 255 && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.item.email) : null,
			firstName: this.item && this.focused.firstName ? true : null,
			lastName: this.item && this.focused.lastName ? true : null,
			password: this.item && this.focused.password ? !this.item.password || !this.invalidFeedbacks.password : null,
			confirmPassword: this.item && this.item.password && this.focused.password ? this.item.password === this.confirmPassword : null,
		};
	}

	public get passwordInfo(): ZXCVBNResult {
		let passwordInfo = zxcvbn(this.item?.password || "", [this.item?.name || "", this.item?.firstName || "", this.item?.lastName || "", this.item?.email || ""]);
		let { suggestions, warning } = passwordInfo.feedback;
		suggestions.unshift(warning);
		this.invalidFeedbacks.password = suggestions.filter(x => !!x).map(s => s.endsWith(".") ? s : s + ".").join(" ").trim();
		return passwordInfo;
	}

	public get passwordTime(): string {
		if (!this.passwordInfo || !this.item?.password) {
			return "";
		}
		let values: [PropertyNames<ZXCVBNAttackTime>, any][] = Object.entries(this.passwordInfo.crack_times_seconds).sort(([, aSec], [, bSec]) => aSec - bSec) as [PropertyNames<ZXCVBNAttackTime>, any][];
		let [min,] = values[0];
		let [max,] = values.pop() || values[0];
		const minTime = this.passwordInfo.crack_times_display[min];
		const maxTime = this.passwordInfo.crack_times_display[max];
		return `Il faudra ${minTime === maxTime ? minTime : `entre ${minTime} et ${maxTime}`} pour découvrir votre mot de passe.`;
	}

	@Watch("value", { immediate: true })
	public valueChanged(newValue: Account | null, oldValue?: Account | null) {
		if (newValue !== oldValue) {
			this.item = newValue;
		}
	}
}
