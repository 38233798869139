import dayjs, { ConfigType } from "dayjs";
import {
	CouchDBDocument,
	Events,
	UUID,
	checkValue,
	checkArrayValue,
	ReadingStatus,
	DateSearch,
	SoftwareCounter,
	Counter,
	CounterType,
	TaskAction,
	ReadingStatOperations,
	DEBUG,
	Machine,
	ReadingStatSettings,
	currency,
	percent,
	number,
	Software,
	ReadingCounterSettings,
	AppSettings,
	Named,
	cloneObject,
	ReadingStatOperation,
	Mission,
} from "@/loader";

/** Maximum value for a mechanical counter: 9 999 999 */
export const MECA_MAX = 9999999;

export interface ReadingStat extends Named {
	result: number
}

export interface Reading extends CouchDBDocument, Events {
	type: "reading"
	machineId: UUID
	missionId: UUID
	taskId: UUID
	softwareId: UUID
	date: ConfigType
	days: number
	motive: TaskAction
	status: ReadingStatus
	values: ReadingCounter[]
	stats: { [key: string]: number }

	machine?: Machine | null
	mission?: Mission | null
}

export interface ReadingCounter {
	counterId: UUID
	value: number
	unavailable: boolean
	delta: number
	deltaElec: number | null
	manual: boolean
	isMeca: boolean
}

export interface SoftwareCounterValue extends SoftwareCounter {
	valid: boolean
	counter: Counter
	value: ReadingCounter
	previous: ReadingCounter[]
	mecas: SoftwareCounterValue[]
	elecs: SoftwareCounterValue[]
}

export interface ReadingValue {
	reading: Reading
	machine: Machine
	software: Software
	previousCount: number
	previous: Reading[]
	elecs: SoftwareCounterValue[]
	mecas: SoftwareCounterValue[]
	display: ReadingCounterSettings
	stats: ReadingStatSettings[]
}

export namespace SoftwareCounterValue {
	export function instanceOf(value: any): value is SoftwareCounterValue {
		return SoftwareCounter.instanceOf(value) && "counter" in value && "value" in value && "previous" in value;
	}

	export function last(item: SoftwareCounterValue, i: number = -1): ReadingCounter | null {
		return item?.previous.length > i + 1 ? item.previous[i + 1] : null;
	}

	export function delta(item: SoftwareCounterValue, i: number): number {
		let last = SoftwareCounterValue.last(item, i);
		if (item.value.isMeca && (last?.value || 0) > item.value.value) {
			return item.value.value + MECA_MAX + 1 - (last?.value || 0); // +1 because the tick to zero count as an increment
		}
		return item.value.value - (last?.value || 0);
	}

	export function valueChanged(item?: SoftwareCounterValue | null, deltaMargin: number = 0) {
		if (!item) {
			return;
		}

		item.value.manual = true;
		calcDelta(item, deltaMargin);

		if (!item.value.isMeca) {
			item.mecas.forEach(m => {
				if (!m.value.manual) {
					m.value.delta = Math.trunc(m.elecs.reduce((sum, e) => sum + e.value.delta, 0));
					m.value.value = m.previous.length ? m.previous[0].value + m.value.delta : m.value.delta;
					if (m.value.value > MECA_MAX) {
						m.value.value -= MECA_MAX + 1; // -1 because the tick to zero count as an increment
					}
				}
				calcDelta(m, deltaMargin);
			});
		}
	}

	export function calcDelta(item: SoftwareCounterValue, deltaMargin: number) {
		item.value.delta = delta(item, -1);

		if (item.value.isMeca) {
			item.value.deltaElec = item.value.delta - Math.trunc(item.elecs.reduce((d, e) => d + e.value.delta, 0));
			item.valid = Math.abs(item.value.deltaElec) <= deltaMargin;
		}
	}
}

export namespace ReadingValue {
	export function create(reading: Reading, machine: Machine, software: Software, counters: Counter[], settings: AppSettings, previous: Reading[] = []): ReadingValue {
		const elecs = prepareValues(software?.elecs || [], machine, counters, reading);
		const mecas = prepareValues(software?.mecas || [], machine, counters, reading);
		elecs.forEach(e => {
			e.mecas = mecas.filter(m => m.relations.includes(e.counterId));
			e.previous = getPreviousValues(previous, e.counterId);
		});
		mecas.forEach(m => {
			m.elecs = elecs.filter(e => e.mecas.includes(m));
			m.previous = getPreviousValues(previous, m.counterId);
		});

		const display: ReadingCounterSettings = JSON.parse(JSON.stringify(settings?.readingCounters || { rows: [] }));
		display.rows.forEach(r => {
			r.columns.forEach(c => {
				let counter = elecs.find(e => e.counterId === c.counterId)
					|| mecas.find(e => e.counterId === c.counterId);
				if (counter) {
					c.counter = counter;
				} else {
					c.counterId = null;
				}
			});
		});
		if (display.rows.length) {
			for (let i = display.rows.length - 1; i > -1; i--) {
				if (display.rows[i].columns.every(c => !c.counterId)) {
					display.rows.splice(i, 1);
					for (let j = 1; j <= i; j++) {
						display.rows[i - j].columns.filter(c => c.rowspan > j).forEach(c => c.rowspan--);
					}
				}
			}
			for (let i = display.rows[0].columns.length - 1; i >= 0; i--) {
				if (display.rows.every(r => !r.columns[i].counterId)) {
					display.rows.forEach(r => {
						r.columns.splice(i, 1);
						for (let j = 1; j <= i; j++) {
							if (r.columns[i - j].colspan > j) {
								r.columns[i - j].colspan--;
							} else if (r.columns[i - j].merged) {
								continue;
							}
							break;
						}
					});
				}
			}
		}

		const item: ReadingValue = {
			reading,
			machine,
			software,
			elecs,
			mecas,
			display,
			previous,
			previousCount: 1,
			stats: settings.readingStats.map(s => cloneObject(s, { log: [] }))
		};

		reading.days = previousDuration(item, reading) || 1;
		reading.stats = settings.readingStats.reduce((stats, stat) => ({ ...stats, [stat.slug]: 0 }), {});

		return item;
	}

	function prepareValues(softwareCounters: SoftwareCounter[] = [], machine?: Machine, counters: Counter[] = [], reading?: Reading): SoftwareCounterValue[] {
		const result: SoftwareCounterValue[] = <SoftwareCounterValue[]>softwareCounters
			.filter(oc => !oc.conditions || (oc.conditions & (machine?.config || 0)) > 0)
			.map((oc: SoftwareCounter) => {
				let counter = counters.find(x => x._id === oc.counterId);
				if (!counter) {
					return null;
				}
				let isMeca = CounterType.isMeca(counter?.definition || 0);
				let rc = reading?.values.find(v => v.counterId === oc.counterId);
				if (reading && !rc) {
					rc = ReadingCounter.create(oc.counterId, isMeca);
					reading.values.push(rc);
				}

				return <SoftwareCounterValue>{
					...oc,
					counter,
					value: rc,
					valid: true,
					previous: [],
					mecas: [],
					elecs: [],
				};
			})
			.filter(x => !!x && x.counter?.name)
			.sort((a, b) => a && b && a.order - b.order || 0);
		result.forEach((x, i) => { x.order = i + 1; });
		return result;
	}

	function getPreviousValues(previous: Reading[], counterId: string): ReadingCounter[] {
		return <ReadingCounter[]>previous
			.map(p => p.values.find(x => x.counterId === counterId) || null)
			.filter(x => !!x);
	}

	export function previousReadings(item: ReadingValue, reading: Reading) {
		const index = item.previous.indexOf(reading);
		return index < item.previous.length ? item.previous.slice(index + 1) : [];
	}

	export function previousDuration(item: ReadingValue, reading: Reading) {
		const index = item.previous.indexOf(reading);
		const other = item.previous.length > 0 && index < item.previous.length ? item.previous[index + 1] : null;
		return other ? dayjs(reading.date).diff(other.date, "d", true) : 0;
	}

	export function reuseLastElec(item: ReadingValue) {
		item.elecs.forEach(e => {
			if (e.previous.length && e.previous[0]) {
				e.value.value = e.previous[0].value;
			}
		});
		item.elecs.forEach(e => SoftwareCounterValue.valueChanged(e));
		item.mecas.forEach(m => SoftwareCounterValue.valueChanged(m));
		calcStats(item);
	}

	export function reuseLastMeca(item: ReadingValue) {
		item.mecas.forEach(e => {
			if (e.previous.length && e.previous[0]) {
				e.value.value = e.previous[0].value;
			}
		});
		item.elecs.forEach(e => SoftwareCounterValue.valueChanged(e));
		item.mecas.forEach(m => SoftwareCounterValue.valueChanged(m));
		calcStats(item);
	}

	export function reuseLast(item: ReadingValue) {
		item.elecs.forEach(e => {
			if (e.previous.length && e.previous[0]) {
				e.value.value = e.previous[0].value;
			}
		});
		item.mecas.forEach(e => {
			if (e.previous.length && e.previous[0]) {
				e.value.value = e.previous[0].value;
			}
		});
		item.elecs.forEach(e => SoftwareCounterValue.valueChanged(e));
		item.mecas.forEach(m => SoftwareCounterValue.valueChanged(m));
		calcStats(item);
	}

	export function calcStats(item: ReadingValue) {
		item?.stats.forEach(stat => {
			DEBUG && (stat.log = [stat.name]);
			item.reading.stats[stat.slug] = calcStatRecursive(item, stat, stat.operations) || 0;
			DEBUG && console.log(stat.log);
		});
	}

	function calcStatRecursive(item: ReadingValue, stat: ReadingStatSettings, operations: ReadingStatOperations) {
		DEBUG && stat.log && stat.log.push("===============================");
		DEBUG && stat.log && stat.log.push(operations);
		if (typeof operations === "number") {
			DEBUG && stat.log && stat.log.push("number: " + operations);
			return operations;
		} else if (typeof operations === "string") {
			return calcStatOpString(item, stat, operations);
		} else {
			return calcStatOpArray(item, stat, operations);
		}
	}

	function calcStatOpString(item: ReadingValue, stat: ReadingStatSettings, operation: string) {
		switch (operation) {
			case "$days":
				DEBUG && stat.log && stat.log.push("$days: " + item.reading.days);
				return Math.abs(item.reading.days);
			case "$rate":
				DEBUG && stat.log && stat.log.push("$rate: " + Machine.getRate(item.machine));
				return Machine.getRate(item.machine)?.rate || NaN;
			default:
				let [, counterId, previous_part] = operation.match(/^(.+?)(?:\[(-?\d+|RAZ)\])?$/) || ["", "", ""];
				let previous_index = Math.abs(parseInt(previous_part));
				let reading: Reading | null = item.reading;
				if (previous_part === "RAZ") {
					reading = item.previous.find(r => r.motive === TaskAction.reset_counter)
						|| (item.previous.length ? item.previous[0] : null);
				}
				if (!isNaN(previous_index)) {
					// Previous is base 1
					reading = previous_index <= item.previous.length ? item.previous[previous_index - 1] : null;
				}

				let counter = reading?.values.find(c => c.counterId === counterId);
				DEBUG && stat.log && stat.log.push("previous: " + previous_part);
				DEBUG && stat.log && stat.log.push("reading: " + (reading ? dayjs(reading.date).format("YYYY-MM-DD HH:mm:ss") + " - " + TaskAction.toString(reading.motive) : "null"));
				DEBUG && stat.log && stat.log.push("counter: " + (counter ? counterId : "null"));
				DEBUG && stat.log && stat.log.push("value: " + (counter?.value || 0));
				return counter?.value || 0;
		}
	}

	function calcStatOpArray(item: ReadingValue, stat: ReadingStatSettings, operations: (string | number | ReadingStatOperation)[]) {
		let result = 0;
		operations.forEach(operation => {
			if (typeof operation === 'string' || typeof operation === 'number') {
				let val = calcStatRecursive(item, stat, operation);
				result += val;
				DEBUG && stat.log && stat.log.push("result: " + result);
				return;
			}

			Object.entries(operation).forEach(([op, value]: [string, ReadingStatOperations]) => {
				try {
					let val = calcStatRecursive(item, stat, value);
					DEBUG && stat.log && stat.log.push(op);
					switch (op) {
						case '$plus':
							result += val;
							break;
						case '$minus':
							result -= val;
							break;
						case '$multiply':
							result *= val;
							break;
						case '$div':
							result /= val;
							break;
					}
				} catch (error) {
					DEBUG && stat.log && stat.log.push(error);
					result = NaN;
				}
				DEBUG && stat.log && stat.log.push("result: " + result);
			});
		});
		return result;
	}
}

export namespace Reading {
	export const TYPE = "reading";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Reading {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function getId(machineId: UUID, date: ConfigType) {
		let d = dayjs(date);
		return TYPE
			+ CouchDBDocument.PREFIX_SEPARATOR
			+ machineId
			+ CouchDBDocument.PREFIX_SEPARATOR
			+ (d.isValid() ? d.format("YYYY-MM-DD-HH-mm-ss") : "");
	}

	export function getStartKey(machineId: UUID, date?: DateSearch): string {
		return getId(machineId, date?.minDate || date?.date || "");
	}

	export function getEndKey(machineId: UUID, date?: DateSearch): string {
		return getId(machineId, date?.maxDate || date?.date || "") + "\ufff0";
	}

	export function create(
		machineId: UUID,
		softwareId: UUID,
		missionId: UUID = "",
		taskId: UUID = "",
		motive: TaskAction = TaskAction.none,
		status: ReadingStatus = ReadingStatus.draft,
		date: ConfigType = dayjs().toISODateTimeString(),
	): Reading {
		return {
			_id: getId(machineId, date),
			_rev: "",
			type: TYPE,
			machineId,
			softwareId,
			missionId,
			taskId,
			date,
			status,
			motive,
			days: 0,
			values: [],
			stats: {},
			events: [],
		}
	}

	export function check(data: any): Reading {
		CouchDBDocument.check(data, TYPE);
		Events.check(data);
		checkValue(data, "machineId", "");
		checkValue(data, "softwareId", "", undefined, ["osId"]);
		checkValue(data, "missionId", null);
		checkValue(data, "taskId", null);
		checkValue(data, "date", null);
		checkValue(data, "days", 0);
		checkValue(data, "before", false);
		checkValue(data, "status", ReadingStatus.draft);
		checkValue(data, "motive", TaskAction.none);
		checkValue(data, "stats", {});
		checkArrayValue(data, "values", ReadingCounter.check);
		return data;
	}

	export function compare(a: Reading, b: Reading): number {
		return a.date == b.date ? 0 : dayjs(a.date).isBefore(b.date) ? -1 : 1;
	}

	export function compareDesc(a: Reading, b: Reading): number {
		return a.date == b.date ? 0 : dayjs(a.date).isBefore(b.date) ? 1 : -1;
	}

	export function statStatus(item: Reading, stat: ReadingStatSettings) {
		const result = item.stats[stat.slug] || NaN;
		return stat.status?.find(s => {
			return Object.entries(s).reduce((match, [key, value]) => {
				switch (key) {
					case "$gt": return match && result > value;
					case "$gte": return match && result >= value;
					case "$lt": return match && result < value;
					case "$lte": return match && result <= value;
					case "$eq": return match && result === value;
					case "$neq": return match && result !== value;
					case "$in": return match && value.includes(result);
					case "$between": return match && result >= value[0] && result <= value[1];
				}
				return match;
			}, true);
		})?.status || undefined;
	}

	export function statFormat(item: Reading, stat: ReadingStatSettings) {
		let formatter;
		switch (stat.format) {
			case "currency": formatter = currency.format; break;
			case "percent": formatter = percent.format; break;
			default: formatter = number.format;
		}
		return formatter(item.stats[stat.slug] || NaN);
	}
}

export namespace ReadingCounter {
	export function create(
		counterId: UUID,
		isMeca: boolean = false,
	): ReadingCounter {
		return {
			counterId,
			isMeca,
			value: 0,
			unavailable: false,
			delta: 0,
			deltaElec: null,
			manual: false,
		}
	}

	export function check(data: any): ReadingCounter {
		checkValue(data, "counterId", "");
		checkValue(data, "value", 0);
		checkValue(data, "unavailable", false);
		checkValue(data, "isMeca", false);
		checkValue(data, "delta", 0);
		checkValue(data, "deltaElec", null);
		checkValue(data, "manual", false);
		return data;
	}
}
