


























import { Component, Mixins } from "vue-property-decorator";
import { TutorialComponent } from '@/loader';

@Component
export default class TutorialWelcome extends Mixins(TutorialComponent) {
	public title = "Fonctionne sans Internet";
	public icon = ["fal", "signal-slash"];
}
