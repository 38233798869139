

















import { Vue, Component, Prop } from "vue-property-decorator";
import { Coordinate, CoordinateType } from '@/loader';

@Component
export default class CoordinateView extends Vue {
	@Prop({ type: Object, required: true })
	public coordinate!: Coordinate;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	public get coordinateType() {
		return CoordinateType.toString(this.coordinate.type);
	}

	public get actionIcon() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
				return ["fas", "phone"];
			case CoordinateType.fax:
				return ["far", "fax"];
			case CoordinateType.email:
				return ["far", "envelope"];
			case CoordinateType.website:
				return ["fal", "globe"];
			default:
				return ["fas", "question"];
		}
	}

	public get actionLink() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
				return "tel:" + this.coordinate.value.replace(/[^0-9+]+/g, '');
			case CoordinateType.email:
				return "mail:" + this.coordinate.value;
			case CoordinateType.website:
				return this.coordinate.value;
			default:
				return null;
		}
	}
}
