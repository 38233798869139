import { arrayToFlag, BvListItem, flagToArray, flagToString, flagToStrings } from "@/loader";

export enum Frequency {
	less_frequent = 1,
	average = 2,
	frequent = 4,
	more_frequent = 8
}

function frequencyToString(frequency: Frequency): string {
	switch (frequency) {
		case Frequency.less_frequent: return "Moins Fréquent";
		case Frequency.average: return "Moyen";
		case Frequency.frequent: return "Fréquent";
		case Frequency.more_frequent: return "Plus Fréquent";
		default: return "?";
	}
}

function frequencyToShortString(frequency: Frequency): string {
	switch (frequency) {
		case Frequency.less_frequent: return "-";
		case Frequency.average: return "~";
		case Frequency.frequent: return "+";
		case Frequency.more_frequent: return "++";
		default: return "?";
	}
}

export namespace Frequency {
	export const LIST: BvListItem[] = Object.values(Frequency)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<Frequency>value) }));

	export function toArray(value: Frequency): Frequency[] {
		return flagToArray(value);
	}

	export function fromArray(values: Frequency[]): Frequency {
		return arrayToFlag(values);
	}

	export function toStrings(value: Frequency): string[] {
		return flagToStrings(value, frequencyToString);
	}

	export function toString(value: Frequency, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, frequencyToString, separator, last_separator);
	}

	export function toShortStrings(value: Frequency): string[] {
		return flagToStrings(value, frequencyToShortString);
	}

	export function toShortString(value: Frequency, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, frequencyToShortString, separator, last_separator);
	}
}
