
















import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, StockTask } from "@/loader";

@Component
export default class StockTaskEditor extends Mixins<TaskDataEditorMixin<StockTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			companyId: this.focused.companyId && this.item ? !!this.item.companyId : null,
		}
	}

	public invalidFeedbacks = {
		companyId: "Une destination doit être renseignée",
	}
}
