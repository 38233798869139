
























































import { Vue, Component, Mixins, Ref } from "vue-property-decorator";
import { EditList, MachineGame, Machine, ConfigStatus, nanoid } from "@/loader";
import MachineGameRow from "./MachineGameRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		MachineGameRow
	}
})
export default class MachineGameTable extends Mixins<EditList<MachineGame, Machine>>(EditList) {
	public dragOptions = {
		group: "machineGames",
		handle: ".btn-move",
		draggale: ".machine-game-list-item",
	}
	public status: ConfigStatus[] = [];

	@Ref()
	public machineGames!: MachineGameRow | MachineGameRow[];

	public get array(): MachineGame[] {
		if (this.status.length) {
			return this.parent?.games.filter(mg => this.status.includes(mg.status)) || [];
		}
		return this.parent?.games || [];
	}
	public set array(value: MachineGame[]) {
		if (this.parent && !this.status.length) {
			this.parent.games = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return MachineGame.create;
	}

	public get cloneFactory(): (base: MachineGame) => MachineGame {
		return MachineGame.clone;
	}

	public get statusOptions() {
		let array = this.parent?.games || [];
		return Object.values(ConfigStatus)
			.filter(value => typeof value === "number")
			.map(value => ({
				value,
				text: `${ConfigStatus.toString(<ConfigStatus>value)} (${array.filter(x => x.status === <ConfigStatus>value).length})`,
				disabled: !array.some(x => x.status === <ConfigStatus>value)
			}));
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (this.machineGames) {
				if (Array.isArray(this.machineGames)) {
					if (this.machineGames.length) {
						this.machineGames[this.machineGames.length - 1].editMode = true;
					}
				} else {
					this.machineGames.editMode = true;
				}
			}
		});
	}
}
