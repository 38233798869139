












import { Vue, Component } from "vue-property-decorator";
import MissionsTable from "../../../component/mission/MissionsTable.vue";

@Component({
	components: {
		MissionsTable
	}
})
export default class MissionsList extends Vue {
	public get canCreate() {
		return true;
	}
}
