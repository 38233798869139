import { Component, Mixins, Prop } from "vue-property-decorator";
import { Editor, toaster, EditComponent, cloneObject } from "@/loader";

@Component
export class EditList<T extends Object, P extends Object> extends Mixins(Editor) {
	@Prop({ type: Object, default: null })
	public parent!: P | null;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ default: "div" })
	public tag!: any;

	@Prop({ type: [String, Array], default: "" })
	public exclude!: string | string[];

	@Prop({ type: [String, Array], default: "" })
	public include!: string | string[];

	public get focusOnAction() {
		return true;
	}

	public get array(): T[] {
		return [];
	}

	public get cloneFactory(): (base: T) => T {
		return cloneObject;
	}

	public focus() {
		if (!this.focusOnAction) {
			return;
		} else if (Array.isArray(this.$refs.rows) && this.$refs.rows.length) {
			(<EditComponent<T, P>>this.$refs.rows?.[this.$refs.rows.length - 1])?.focus();
		} else if (this.$refs.rows) {
			(<EditComponent<T, P>>this.$refs.rows).focus();
		} else if (Array.isArray(this.$refs.inputs) && this.$refs.inputs.length) {
			(<HTMLInputElement>this.$refs.inputs?.[this.$refs.inputs.length - 1])?.focus();
		} else if (this.$refs.inputs) {
			(<HTMLInputElement>this.$refs.inputs).focus();
		} else {
			const els: NodeListOf<HTMLInputElement> = this.$el.querySelectorAll("input, textarea");
			els[els.length - 1]?.focus();
		}
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			this.focus();
		});
	}

	public clone(item?: T | null) {
		if (!this.parent || !item) {
			toaster.invalidOperation({ operation: "Cloner" });
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Cloner" });
			return;
		}
		let clone = this.cloneFactory(item);
		this.array.push(clone);
		this.$emit("cloned", clone, item);
		this.changed = true;

		this.$nextTick().then(() => {
			this.focus();
		});
	}

	public remove(item?: T | null) {
		if (!this.parent || !item) {
			toaster.invalidOperation({ operation: "Supprimer" });
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Supprimer" });
			return;
		}
		this.array.remove(item);
		this.$emit("deleted", item);
		this.changed = true;

		this.$nextTick().then(() => {
			this.focus();
		});
	}
}
