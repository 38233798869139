import { ReadingTask } from "@/loader";

export interface ChangeCounterTask extends ReadingTask {
}

export namespace ChangeCounterTask {
	export function instanceOf(value: any): value is ChangeCounterTask {
		return ReadingTask.instanceOf(value)
	}

	export function check(data: any): ChangeCounterTask {
		ReadingTask.check(data);
		return data;
	}

	export function create(): ChangeCounterTask {
		return {
			reading: "",
		};
	}
}
