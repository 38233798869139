import { BvListItem } from "../base/BootstrapVue";

export enum EventType {
	created = 1,
	modified = 2,
	deleted = 3,
	undeleted = 4,
	read = 5,
	unread = 6,
	sent = 7,
	validated = 8,
	unvalidated = 9,
	confirmed = 10,
	unconfirmed = 11,
	planned = 12,
	unplanned = 13,
	cancelled = 14,
	uncancelled = 15,
	deactivated = 16,
	reactivated = 17,
	activate = 18,
	cloned = 19,
	installed = 20,
	controlled = 21,
	mixed = 22,
	counter_reset = 23,
	counter_changed = 24,
	stored = 25,
	transferred = 26,
	destroyed = 27,
	sold = 28,
	repaired = 29,
	done = 30,
	imported = 31,
}

export namespace EventType {
	export function toString(type: EventType) {
		switch (type) {
			case EventType.created: return "Création";
			case EventType.modified: return "Modification";
			case EventType.deleted: return "Suppression";
			case EventType.undeleted: return "Annulation de suppression";
			case EventType.read: return "Lecture";
			case EventType.unread: return "Annulation de lecture";
			case EventType.sent: return "Envoi";
			case EventType.validated: return "Validation";
			case EventType.unvalidated: return "Annulation de validation";
			case EventType.confirmed: return "Confirmation";
			case EventType.unconfirmed: return "Annulation de la confirmation";
			case EventType.planned: return "Plannification";
			case EventType.unplanned: return "Annulation de la plannification";
			case EventType.cancelled: return "Annulation";
			case EventType.uncancelled: return "Désannulation";
			case EventType.deactivated: return "Désactivation";
			case EventType.reactivated: return "Réactivation";
			case EventType.activate: return "Activation";
			case EventType.cloned: return "Clonage";
			case EventType.installed: return "Installation";
			case EventType.controlled: return "Contrôle";
			case EventType.mixed: return "Mixe";
			case EventType.counter_reset: return "Remise à zéro des compteurs";
			case EventType.counter_changed: return "Changement des compteurs";
			case EventType.stored: return "Stockage";
			case EventType.transferred: return "Transfert";
			case EventType.destroyed: return "Destruction";
			case EventType.sold: return "Vente";
			case EventType.repaired: return "Réparé";
			case EventType.done: return "Terminé";
			case EventType.imported: return "Importé";
			default: return "";
		}
	}

	export const LIST: BvListItem[] = Object.values(EventType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<EventType>value) }));
}
