











































import { Component, Mixins } from "vue-property-decorator";
import { EditList, SoftwareCompatibility, HasCompatibilities } from "@/loader";
import SoftwareCompatibilityRow from "./SoftwareCompatibilityRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		SoftwareCompatibilityRow
	}
})
export default class SoftwareEditList extends Mixins<EditList<SoftwareCompatibility, HasCompatibilities>>(EditList) {
	public dragOptions = {
		group: "softwareCompatibilities",
		handle: ".btn-move",
		draggale: ".software-compatibility-row",
	}

	public get array(): SoftwareCompatibility[] {
		return this.parent ? this.parent.compatibilities.softwares : [];
	}
	public set array(value: SoftwareCompatibility[]) {
		if (this.parent) {
			this.parent.compatibilities.softwares = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return SoftwareCompatibility.create;
	}

	public get cloneFactory(): (base: SoftwareCompatibility) => SoftwareCompatibility {
		return SoftwareCompatibility.clone;
	}
}
