import { Component, Mixins } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, MissionStatus } from "@/loader";

@Component
export class MissionStatusBadge extends Mixins<EnumBadge<MissionStatus>>(EnumBadge) {
	public get variant(): BVariant {
		switch (this.value) {
			case MissionStatus.done: return BVariant.success;
			case MissionStatus.cancelled: return BVariant.outlineDanger;
			case MissionStatus.control: return BVariant.warning;
			case MissionStatus.ongoing: return BVariant.info;
			case MissionStatus.planned:
			default:
				return BVariant.outlineSecondary;
		}
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case MissionStatus.done:
				return createIconConfig(['fas', 'check']);
			case MissionStatus.planned:
				return createIconConfig(['fal', 'calendar-alt']);
			case MissionStatus.cancelled:
				return createIconConfig(['fas', 'trash']);
			case MissionStatus.control:
				return createIconConfig(['fal', 'search']);
			case MissionStatus.ongoing:
				return createIconConfig(['far', 'edit']);
			default:
				return createIconConfig(['fas', 'question']);
		}
	}

	public get title(): string {
		return MissionStatus.toString(this.value);
	}
}
