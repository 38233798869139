import {
	checkArrayValue,
	Named,
	checkValue,
	ConfigStatus,
	ControllerType,
	GameController,
	nanoid,
	number,
	percent,
	UUID,
	ValueUnit,
	cloneObject,
	ControllerReplacement,
	Identifiable,
	Events
} from "@/loader";


export interface MachineController extends Identifiable, Events, Named {
	global: boolean
	description: string | null
	type: ControllerType
	status: ConfigStatus
	unit: ValueUnit
	checkable: boolean
	startup: number | null
	increment: number | null
	games: UUID[]
	machines: MachineControllerLink[]
}

export interface MachineControllerLink {
	machineId: UUID
	usedForCalculation: boolean
}

export namespace MachineController {
	export function instanceOf(value: any): value is MachineController {
		return Named.instanceOf(value) && Identifiable.instanceOf(value) && "increment" in value && "startup" in value;
	}

	export function create(
		name: string = "",
		type: ControllerType = ControllerType.progressif,
		status: ConfigStatus = ConfigStatus.draft,
		unit: ValueUnit = ValueUnit.credit,
		checkable: boolean = false,
		global: boolean = true,
		startup: number | null = null,
		increment: number | null = null,
		description: string | null = null,
	): MachineController {
		return {
			id: nanoid(),
			name,
			description,
			global,
			type,
			status,
			unit,
			checkable,
			startup,
			increment,
			machines: [],
			games: [],
			events: [],
		}
	}

	export function createFromGame(gameController: GameController): MachineController {
		return create(
			gameController.name,
			gameController.type,
			ConfigStatus.draft,
			ValueUnit.value,
			ControllerType.isCheckable(gameController.type),
			true,
			gameController.startup,
			gameController.increment,
			gameController.description
		);
	}

	export function clone(base: MachineController | ControllerReplacement): MachineController {
		let result = cloneObject(base, {
			status: ConfigStatus.draft,
			machines: base.machines.map(MachineControllerLink.clone),
		});
		if (ControllerReplacement.instanceOf(result)) {
			// @ts-ignore
			delete result.action;
			// @ts-ignore
			delete result.oldId;
			result.name = base.name;
		}
		return result;
	}

	export function check(data: any): MachineController {
		Events.check(data);
		Named.check(data);
		checkValue(data, "id", nanoid);
		checkValue(data, "type", ControllerType.progressif);
		checkValue(data, "status", ConfigStatus.draft);
		checkValue(data, "unit", ValueUnit.credit);
		checkValue(data, "checkable", false);
		checkValue(data, "global", true);
		checkValue(data, "startup", null);
		checkValue(data, "increment", null);
		checkValue(data, "description", null);
		checkArrayValue(data, "machines", MachineControllerLink.check);
		return data;
	}

	export function toString(value: MachineController): string {
		return [
			value.name,
			number.format(value.startup || 0),
			percent.format((value.increment || 0) / 100)
		].filter(x => !!x).join(" - ");
	}

	export function modifyFromReplacement(mc: MachineController, cr: ControllerReplacement) {
		mc.checkable = cr.checkable;
		mc.description = cr.description;
		mc.games = cr.games;
		mc.global = cr.global;
		mc.increment = cr.increment;
		mc.machines = cr.machines;
		mc.name = cr.name;
		mc.startup = cr.startup;
		mc.type = cr.type;
		mc.unit = cr.unit;
	}
}

export namespace MachineControllerLink {
	export function create(
		machineId: string = "",
		usedForCalculation: boolean = false
	): MachineControllerLink {
		return {
			machineId,
			usedForCalculation
		}
	}

	export function clone(base: MachineControllerLink): MachineControllerLink {
		return cloneObject(base);
	}

	export function check(data: any): MachineControllerLink {
		checkValue(data, "machineId", "");
		checkValue(data, "usedForCalculation", false);
		return data;
	}
}
