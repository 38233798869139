



























































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import {
	ControllerReplacement,
	EditView,
	Event,
	EventType,
	GameReplacement,
	Machine,
	MachineControllerTask,
	MachineGameTask,
	MachinePartTask,
	MachineSoftwareTask,
	Mission,
	MissionStatus,
	PartCategory,
	PartReplacement,
	ReplacementAction,
	servers,
	SoftwareReplacement,
	Task,
	TaskAction,
	toaster,
	UUID,
} from "@/loader";
import MissionHeader from "../../../component/mission/MissionHeader.vue";
import MachineHeader from "../../../component/machine/MachineHeader.vue";
import StockTaskEditor from "../../../component/task/data/StockTaskEditor.vue";
import InstallationTaskEditor from "../../../component/task/data/InstallationTaskEditor.vue";
import MixTaskEditor from "../../../component/task/data/MixTaskEditor.vue";
import RepairTaskEditor from "../../../component/task/data/RepairTaskEditor.vue";
import ControlTaskEditor from "../../../component/task/data/ControlTaskEditor.vue";
import ResetCounterTaskEditor from "../../../component/task/data/ResetCounterTaskEditor.vue";
import ChangeCounterTaskEditor from "../../../component/task/data/ChangeCounterTaskEditor.vue";
import ReserveTaskEditor from "../../../component/task/data/ReserveTaskEditor.vue";
import TransferTaskEditor from "../../../component/task/data/TransferTaskEditor.vue";
import DestroyTaskEditor from "../../../component/task/data/DestroyTaskEditor.vue";
import ReactivationTaskEditor from "../../../component/task/data/ReactivationTaskEditor.vue";
import SellTaskEditor from "../../../component/task/data/SellTaskEditor.vue";
import MachineGameEditor from "../../../component/machine/MachineGameEditor.vue";

type DataEditors = StockTaskEditor | InstallationTaskEditor | MixTaskEditor | RepairTaskEditor | ControlTaskEditor | ResetCounterTaskEditor | ChangeCounterTaskEditor | ReserveTaskEditor | TransferTaskEditor | DestroyTaskEditor | ReactivationTaskEditor | SellTaskEditor | MachineGameEditor;

@Component({
	components: {
		ChangeCounterTaskEditor,
		ControlTaskEditor,
		DestroyTaskEditor,
		InstallationTaskEditor,
		MachineGameEditor,
		MachineHeader,
		MissionHeader,
		MixTaskEditor,
		ReactivationTaskEditor,
		RepairTaskEditor,
		ReserveTaskEditor,
		ResetCounterTaskEditor,
		SellTaskEditor,
		StockTaskEditor,
		TransferTaskEditor,
	}
})
export default class TaskView extends Mixins<EditView<Task>>(EditView) {
	public machine: Machine | null = null;
	public mission: Mission | null = null;
	public newMissionId: UUID | null = null;
	public partCategories: PartCategory[] = [];

	public TaskAction = TaskAction;

	@Ref()
	public readonly dataEditor!: DataEditors;

	public get itemFactory() {
		return () => {
			let missionId = this.$route.query.missionId;
			let machineId = this.$route.query.machineId;
			if (Array.isArray(missionId)) {
				missionId = missionId[0] || "";
			}
			if (Array.isArray(machineId)) {
				machineId = machineId[0] || "";
			}
			return Task.create(missionId, machineId);
		}
	}

	public get checkDocFunction() {
		return Task.check;
	}

	public get cloneFactory() {
		return (base: Task) => Task.clone(base, this.newMissionId);
	}

	public get machineId() {
		return this.item?.machineId;
	}

	public get missionId() {
		return this.item?.missionId;
	}

	public get isPlanned(): boolean {
		return this.item?.status === MissionStatus.planned;
	}

	public get isDone(): boolean {
		return this.item?.status === MissionStatus.done;
	}

	public get isCancelled(): boolean {
		return this.item?.status === MissionStatus.cancelled;
	}

	public get missionValid() {
		return this.mission && this.mission.status !== MissionStatus.done || false;
	}

	public get canEdit(): boolean {
		return this.hasValidRole && this.missionValid && !this.isCancelled;
	}

	public get canSave(): boolean {
		return this.canEdit && this.changed;
	}

	public get canFinish(): boolean {
		return this.canEdit && !this.changed;
	}

	public get canDelete(): boolean {
		return this.hasValidRole && this.missionValid && !this.isNew && !this.changed && !this.isDone;
	}

	public get canDeactivate(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed && !this.isCancelled && !this.isDone;
	}

	public get canReactivate(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed && this.isCancelled;
	}

	public get actionLabel() {
		return TaskAction.toString(this.item?.action || 0)
	}

	public get taskDataComponent(): string {
		switch (this.item?.action) {
			case TaskAction.stock: return "StockTaskEditor";
			case TaskAction.installation: return "InstallationTaskEditor";
			case TaskAction.mix: return "MixTaskEditor";
			case TaskAction.repair: return "RepairTaskEditor";
			case TaskAction.control: return "ControlTaskEditor";
			case TaskAction.reset_counter: return "ResetCounterTaskEditor";
			case TaskAction.change_counter: return "ChangeCounterTaskEditor";
			case TaskAction.reserve: return "ReserveTaskEditor";
			case TaskAction.transfer: return "TransferTaskEditor";
			case TaskAction.destroy: return "DestroyTaskEditor";
			case TaskAction.reactivation: return "ReactivationTaskEditor";
			case TaskAction.sell: return "SellTaskEditor";
			default: return "";
		}
	}

	public async mounted() {
		this.partCategories = (await this.$db.allPartCategories()).filter(c => !c.parentId);
		if (this.dataEditor) {
			this.dataEditor.focus();
		}
	}

	public async loadRelations() {
		this.mission = await this.$db.getMission(this.item?.missionId);
		this.machine = await this.$db.getMachine(this.item?.machineId);
	}

	public async setData(trigger: "machine" | "action") {
		if (!this.item) {
			return;
		}

		let data = Task.createData(this.item.action);
		const oldData = this.item.data;
		if (oldData && data) {
			Object.keys(oldData).forEach(key => {
				if (data && key in data) {
					// @ts-ignore
					data[key] = oldData[key];
				}
			});
		}

		if (trigger === "machine") {
			let machine = await this.$db.getMachine(this.item.machineId);
			if (MachineControllerTask.instanceOf(data)) {
				data.controllers = machine?.controllers.map(ControllerReplacement.clone) || [];
			}
			if (MachineGameTask.instanceOf(data)) {
				data.games = machine?.games.map(GameReplacement.clone) || [];
			}
			if (MachinePartTask.instanceOf(data)) {
				data.parts = machine?.parts.map(PartReplacement.clone) || [];
				const doneCategories = data.parts.map(p => p.categoryId);
				data.parts.push(...this.partCategories.filter(c => !doneCategories.includes(c._id)).map(c => PartReplacement.create(null, ReplacementAction.none, c._id)));
			}
			if (MachineSoftwareTask.instanceOf(data)) {
				data.softwares = machine?.softwares.map(SoftwareReplacement.clone) || [];
			}
		}

		this.item.data = data;
		console.log("setData", { data, oldData });
	}

	public async beforeSave() {
		if ("saveReading" in this.dataEditor) {
			this.dataEditor.saveReading();
		}
		return await this.save();
	}

	public async deactivate() {
		return await this.setStatus(
			MissionStatus.cancelled,
			EventType.cancelled,
			"Annuler",
			"Tâche annulée",
			true
		);
	}

	public async reactivate() {
		return await this.setStatus(
			MissionStatus.planned,
			EventType.uncancelled,
			"Rétablir",
			"Tâche rétablie",
			false
		);
	}

	public async control() {
		return await this.setStatus(
			MissionStatus.control,
			EventType.modified,
			"à contrôler",
			"Tâche marquée pour contrôle",
			true
		);
	}

	public async finish() {
		return await this.setStatus(
			MissionStatus.done,
			EventType.done,
			"Terminer",
			"Tâche terminée",
			true
		);
	}

	public async setStatus(status: MissionStatus, eventType: EventType, operation?: string, message?: string, redirect?: boolean | RawLocation): Promise<boolean> {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation });
			return false;
		}
		if (status === MissionStatus.cancelled && !this.canDeactivate) {
			toaster.forbidden({ operation });
			return false;
		}
		if (status === MissionStatus.planned && !this.canReactivate) {
			toaster.forbidden({ operation });
			return false;
		}
		if (status === MissionStatus.control && !this.canEdit) {
			toaster.forbidden({ operation });
			return false;
		}
		if (status === MissionStatus.done && !this.canFinish) {
			toaster.forbidden({ operation });
			return false;
		}
		if (status === MissionStatus.done && !Task.isFinishable(this.item)) {
			toaster.invalidWorkflow({ operation });
			return false;
		}
		if ("saveReading" in this.dataEditor) {
			this.dataEditor.saveReading(status === MissionStatus.done);
		}

		let oldStatus = this.item.status;
		this.item.status = status;
		this.item.events.push(Event.create(
			servers.author,
			eventType,
			"",
			this.original,
			this.item
		));

		if (await this.$db.save(this.item)) {
			if (message) {
				toaster.success({ message });
			}
			this.clean();
			if (redirect) {
				redirect = redirect === true ? { name: "mission", params: { id: this.item.missionId } } : redirect;
				this.$router.push(redirect);
			}
			return true;
		} else {
			this.item.status = oldStatus;
			this.item.events.pop();
			return false;
		}
	}

	public async remove() {
		const operation = "Supprimer";
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation });
			return false;
		}
		if (!this.canDelete) {
			toaster.forbidden({ operation });
			return false;
		}

		if (await this.$db.remove(this.item)) {
			toaster.success({ message: "Tâche supprimée" });
			this.clean();
			this.$router.push({ name: "mission", params: { id: this.item.missionId } });
			return true;
		} else {
			return false;
		}
	}

	public async addRAZ() {
		return await this.createTask(TaskAction.reset_counter, "Programmer RAZ");
	}

	public async addChangeCounter() {
		return await this.createTask(TaskAction.change_counter, "Programmer Changement de Compteur");
	}

	public async createTask(action: TaskAction, operation: string) {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation });
			return false;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation });
			return false;
		}
		if (!this.missionId) {
			toaster.noItem({ operation, itemType: "mission" });
			return false;
		}

		const tasks = await this.$db.queryTasksByMission(this.missionId);
		if (tasks.find(t => t.action === action && t.machineId === this.machineId)) {
			toaster.invalidWorkflow({ operation, reason: `une tâche avec la même action est déjà prévue pour cette machine` });
			return false;
		}

		const task = Task.create(this.item.missionId, this.item.machineId, action, MissionStatus.planned, Task.createData(action));
		if (await this.$db.save(task)) {
			toaster.success({ message: "Tâche créée" });
			return true;
		} else {
			toaster.error({ message: "Une erreur inattendue est survenue" });
			return false;
		}
	}

	@Watch("machineId", { immediate: true })
	public async onMachineIdChanged(newValue?: string | null, oldValue?: string | null) {
		if (newValue != oldValue && newValue) {
			this.removeConflictChecker(oldValue, servers.selected?.name);
			this.addConflictChecker({ _id: newValue, _rev: "", db: servers.selected?.name, callback: this.loadRelations });
			this.loadRelations();
		}
	}

	@Watch("missionId", { immediate: true })
	public async onMissionIdChanged(newValue?: string | null, oldValue?: string | null) {
		if (newValue != oldValue && newValue) {
			this.removeConflictChecker(oldValue, servers.selected?.name);
			this.addConflictChecker({ _id: newValue, _rev: "", db: servers.selected?.name, callback: this.loadRelations });
			this.loadRelations();
		}
	}
}
