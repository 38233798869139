















































import { Component, Prop, Mixins } from "vue-property-decorator";
import { GameReplacement, Task, EditList, Machine, Mission, MachineGameTask, ReplacementAction } from "@/loader";
import GameReplacementRow from "./GameReplacementRow.vue";

@Component({
	components: {
		GameReplacementRow,
	}
})
export default class GameReplacementTable extends Mixins<EditList<GameReplacement, Task>>(EditList) {
	public dragOptions = {
		group: "gameReplacementRows",
		handle: ".btn-move",
		draggale: ".game-replacement-row",
	}

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public get array(): GameReplacement[] {
		return this.parent && MachineGameTask.instanceOf(this.parent.data) ? this.parent.data.games : [];
	}
	public set array(value: GameReplacement[]) {
		if (this.parent && MachineGameTask.instanceOf(this.parent.data)) {
			this.parent.data.games = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return () => GameReplacement.create("", ReplacementAction.add);
	}

	public get cloneFactory(): (base: GameReplacement) => GameReplacement {
		return GameReplacement.clone;
	}

	public get label() {
		return "Jeux";
	}
}
