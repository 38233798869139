

































import { Vue, Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { MachineGame, Denomination, GameRate, Machine } from "@/loader";

@Component
export default class MachineGameDisplay extends Vue {
	@Prop({ type: String, default: "div" })
	public tag!: string;

	@Prop({ type: Object, required: true })
	public item!: MachineGame;

	@Prop({ type: Object, required: true })
	public machine!: Machine;

	@Prop({ type: Boolean, default: false })
	public hideRate!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideDeno!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideLine!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideAntebet!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideMaxbet!: boolean;

	public game: Game | null = null;
	public pack: Game | null = null;

	public get deno() {
		return Denomination.toString(this.item.deno);
	}

	public get rate() {
		return GameRate.toString(MachineGame.getRate(this.item, this.machine.controllers));
	}

	public async loadRelations() {
		this.game = await this.$db.getGame(this.item.gameId);
		this.pack = await this.$db.getGame(this.item.packId);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue: MachineGame, oldValue?: MachineGame) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
