import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import {
	CouchDBDocument,
	EventType,
	Deactivable,
	servers,
	toaster,
	Editor,
	Named,
	DEBUG,
	SyncEvent,
	Events,
	Event,
	ConflictCheckerMixin,
	cloneObject,
} from "@/loader";
import { NavigationGuardNext, Route } from "vue-router";
import { detailedDiff } from "deep-object-diff";

const NEW_IDS = ["nouveau", "nouvelle"];

@Component({
	beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<EditView<any>>) {
		DEBUG && console.log("beforeRouteLeave", this.changed);
		if (this.changed) {
			this.$bvModal.msgBoxConfirm(
				[
					this.$createElement("p", "Des modifications ont été apportées et seront perdues si vous continuer sans enregistrer"),
					this.$createElement("p", "Voulez-vous les enregistrer ?"),
					this.$createElement("p", [this.$createElement("small", "Vous pouvez annuler le changement de page en cliquant sur le fond grisé ou sur la croix en haut à droite de cette fenêtre.")]),
				],
				{
					cancelTitle: "Perdre mes modifications",
					cancelVariant: "outline-warning",
					headerCloseLabel: "Annuler",
					headerCloseVariant: "outline-secondary",
					hideHeaderClose: false,
					okTitle: "Sauvegarder",
					okVariant: "success",
					title: "Modifications non enregistrées"
				}
			).then((result: boolean | null) => {
				// True if OK, false if CANCEL, null if CLOSE or backdrop
				if (result) {
					this.save();
				}
				if (result === null) {
					next(false);
				} else {
					next();
				}
			})
		} else {
			next();
		}
	}
})
export class EditView<T extends CouchDBDocument> extends Mixins<Editor, ConflictCheckerMixin>(Editor, ConflictCheckerMixin) {
	@Prop({ type: String, required: true })
	public id!: string;

	public item: T | null = null;
	public original: T | null = null;
	public focused: { [prop in keyof T]?: boolean; } = {};
	public invalidFeedbacks: { [prop in keyof T]?: string; } = {};

	public get states(): { [prop in keyof T]?: boolean | null; } {
		return {};
	}

	public get checkDocFunction(): Function | null {
		return null;
	}

	public get cloneFactory(): (base: T) => T {
		return cloneObject;
	}

	public get canCancel(): boolean {
		return this.canEdit && (this.isNew || this.changed || this.editMode);
	}

	public get hasValidRole(): boolean {
		return true;
	}

	public get canClone(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed;
	}

	public get canDelete(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed && !this.editMode;
	}

	public get canEdit(): boolean {
		return this.hasValidRole && (!Deactivable.instanceOf(this.item) || !this.item.disabled);
	}

	public get canDeactivate(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed && Deactivable.instanceOf(this.item) && !this.item.disabled;
	}

	public get canReactivate(): boolean {
		return this.hasValidRole && !this.isNew && !this.changed && Deactivable.instanceOf(this.item) && this.item.disabled;
	}

	public get isNew(): boolean {
		return NEW_IDS.includes(this.id);
	}

	public async cancel(): Promise<boolean> {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.item) {
			toaster.noItem();
			return false;
		}
		if (!this.canCancel) {
			toaster.forbidden();
			return false;
		}
		if (!this.changed && !this.editMode) {
			toaster.noChange();
			return false;
		}
		this.loadItem();
		return true;
	}

	public async clone(): Promise<boolean> {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.canClone) {
			toaster.forbidden({ operation: "Cloner" });
			return false;
		}
		if (!this.item || this.changed) {
			toaster.invalidOperation({ operation: "Cloner" });
			return false;
		}
		let origin_id = this.item._id;
		let origin = Named.instanceOf(this.item) ? this.item.name : this.item._id;

		const newItem = this.cloneFactory(this.item);

		if (Events.instanceOf(newItem)) {
			newItem.events.push(Event.create(
				servers.author,
				EventType.cloned,
				"Cloné depuis " + origin,
				null,
				newItem,
				{ cloned_from: origin_id }
			));
		}

		try {
			let response = await this.$pouch.put(newItem, {}, servers.selected.name);
			if (response.ok) {
				this.item = newItem;
				this.item._rev = response.rev;
				if (this.id !== response.id && this.routeName) {
					this.$router.push({ name: this.routeName, params: { id: response.id } });
				} else {
					this.original = JSON.parse(JSON.stringify(this.item));
					this.clean();
				}
				toaster.success({ message: "Elément désactivé" });
				return true;
			}
		} catch (error) {
			console.error(error);
			toaster.error({ error });
		}
		return false;
	}

	public async save(eventType?: EventType | false, eventComment: string = "") {
		if (!servers.selected) {
			toaster.noServer({ operation: "Sauvegarder" });
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation: "Sauvegarder" });
			return false;
		}
		if (!this.changed) {
			toaster.noChange();
			return false;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Sauvegarder" });
			return false;
		}

		if (Events.instanceOf(this.item) && eventType !== false) {
			this.item.events.push(Event.create(
				servers.author,
				eventType || (this.isNew ? EventType.created : EventType.modified),
				eventComment,
				this.original,
				this.item
			));
		}

		if (await this.$db.save(this.item)) {
			this.saved();
			if (this.id !== this.item._id && this.routeName) {
				this.$router.push({ name: this.routeName, params: { id: this.item._id } });
			} else {
				this.original = JSON.parse(JSON.stringify(this.item));
				this.clean();
			}
			toaster.success({ message: "Sauvegarde effectuée" });
			return true;
		}
		return false;
	}

	public async saved() {
		// override
	}

	public async deactivate(): Promise<boolean> {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.canDeactivate) {
			toaster.forbidden({ operation: "Désactiver" });
			return false;
		}
		if (!this.item || this.changed || !Deactivable.instanceOf(this.item)) {
			toaster.invalidOperation({ operation: "Désactiver" });
			return false;
		}

		this.item.disabled = true;

		if (Events.instanceOf(this.item)) {
			this.item.events.push(Event.create(
				servers.author,
				EventType.deactivated,
				"",
				this.original,
				this.item
			));
		}

		if (await this.$db.save(this.item)) {
			if (this.id !== this.item._id && this.routeName) {
				this.$router.push({ name: this.routeName, params: { id: this.item._id } });
			} else {
				this.original = JSON.parse(JSON.stringify(this.item));
				this.clean();
			}
			toaster.success({ message: "Sauvegarde effectuée" });
			return true;
		} else {
			this.item.disabled = false;
			if (Events.instanceOf(this.item)) {
				this.item.events.pop();
			}
			return false;
		}
	}

	public async reactivate(): Promise<boolean> {
		if (!servers.selected) {
			toaster.noServer();
			return false;
		}
		if (!this.canReactivate) {
			toaster.forbidden({ operation: "Réactiver" });
			return false;
		}
		if (!this.item || this.changed || !Deactivable.instanceOf(this.item)) {
			toaster.invalidOperation({ operation: "Réactiver" });
			return false;
		}

		this.item.disabled = false;

		if (Events.instanceOf(this.item)) {
			this.item.events.push(Event.create(
				servers.author,
				EventType.reactivated,
				"",
				this.original,
				this.item
			));
		}

		if (await this.$db.save(this.item)) {
			if (this.id !== this.item._id && this.routeName) {
				this.$router.push({ name: this.routeName, params: { id: this.item._id } });
			} else {
				this.original = JSON.parse(JSON.stringify(this.item));
				this.clean();
			}
			toaster.success({ message: "Sauvegarde effectuée" });
			return true;
		} else {
			this.item.disabled = true;
			if (Events.instanceOf(this.item)) {
				this.item.events.pop();
			}
			return false;
		}
	}

	public loadItem() {
		if (this.isNew) {
			try {
				this.item = this.itemFactory ? this.itemFactory() : null;
				this.original = null;
				this.clean();
			} catch (error) {
				toaster.error({ error });
			}
		} else if (servers.selected) {
			this.$pouch.get(this.id, {}, servers.selected.name)
				.then(result => {
					this.item = this.checkDocFunction ? this.checkDocFunction(result) : <T>result;
					this.original = <T>JSON.parse(JSON.stringify(result));
					this.clean();
				}).catch(error => {
					console.error(error);
					toaster.error({ error });
				});
		}
	}

	public async loadRelations() {
	}

	public mounted() {
		this.loadItem();
		this.loadRelations();
		this.addConflictChecker({ _id: this.id, _rev: "", db: servers.selected?.name, callback: this.checkConflict });
	}

	public checkConflict(e: SyncEvent, doc: CouchDBDocument) {
		if (this.changed) {
			console.log({ doc, item: this.item, diff: detailedDiff(doc, this.item) });
		} else {
			if (e.info?.direction === "pull") {
				toaster.info({ title: "Modification détectée", message: "Un autre utilisateur a modifié cet élément. Ces nouvelles informations sont automatiquement utilisées puisque vous n'aviez effectué aucune modification." });
				this.loadItem();
			}
		}
	}

	public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
		DEBUG && console.log("beforeRouteLeave", this.changed);
		if (this.changed) {
			this.$bvModal.msgBoxConfirm(
				[
					this.$createElement("p", "Des modifications ont été apportées et seront perdues si vous continuer sans enregistrer"),
					this.$createElement("p", "Voulez-vous les enregistrer ?"),
					this.$createElement("p", [this.$createElement("small", "Vous pouvez annuler le changement de page en cliquant sur le fond grisé ou sur la croix en haut à droite de cette fenêtre.")]),
				],
				{
					cancelTitle: "Perdre mes modifications",
					cancelVariant: "outline-warning",
					headerCloseLabel: "Annuler",
					headerCloseVariant: "outline-secondary",
					okTitle: "Sauvegarder",
					okVariant: "success",
					title: "Modifications non enregistrées"
				}
			).then((result: boolean | null) => {
				// True if OK, false if CANCEL, null if CLOSE or backdrop
				if (result) {
					this.save();
				}
				if (result === null) {
					next(false);
				} else {
					next();
				}
			})
		} else {
			next();
		}
	}

	@Watch("id")
	public idChanged(newValue?: string | null, oldValue?: string | null) {
		if (newValue && newValue != oldValue) {
			this.removeConflictChecker(oldValue || "", servers.selected?.name);
			this.loadItem();
			this.loadRelations();
			this.addConflictChecker({ _id: newValue, _rev: "", db: servers.selected?.name, callback: this.checkConflict });
		}
	}
}
