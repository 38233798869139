import { ReadingTask } from "@/loader";

export interface DestroyTask extends ReadingTask {
}

export namespace DestroyTask {
	export function instanceOf(value: any): value is DestroyTask {
		return ReadingTask.instanceOf(value);
	}

	export function check(data: any): DestroyTask {
		ReadingTask.check(data);
		return data;
	}

	export function create(): DestroyTask {
		return {
			reading: "",
		};
	}
}
