import dropbox_zxcvbn from "zxcvbn";

export function zxcvbn(password: string, userInputs?: string[]) {
	if (!Array.isArray(userInputs)) {
		userInputs = [];
	}
	userInputs.push("sfmpro", "bally", "sfc2a", "casino");
	let result = dropbox_zxcvbn(password, userInputs);
	switch (result.feedback.warning) {
		case "Straight rows of keys are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les lignes de touches du clavier sont faciles à deviner";
			break;
		case "Short keyboard patterns are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les suites de touches du clavier sont faciles à deviner";
			break;
		case 'Repeats like "aaa" are easy to guess':
			// @ts-ignore
			result.feedback.warning = 'Les répétitions comme "aaa" sont faciles à deviner';
			break;
		case 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
			// @ts-ignore
			result.feedback.warning = 'Les répétitions comme "abcabcabc" sont juste un peu plus difficiles à deviner que "abc"';
			break;
		case "Sequences like abc or 6543 are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les suites comme abc ou 6543 sont faciles à deviner";
			break;
		case "Recent years are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les années récentes sont faciles à deviner";
			break;
		case "Dates are often easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les dates sont souvent faciles à deviner";
			break;
		case "This is similar to a commonly used password":
			// @ts-ignore
			result.feedback.warning = "Le mot de passe est très similaire à un mot de passe très commun";
			break;
		case "This is a top-10 common password":
			// @ts-ignore
			result.feedback.warning = "C'est un mot de passe du top 10";
			break;
		case "This is a top-100 common password":
			// @ts-ignore
			result.feedback.warning = "C'est un mot de passe du top 100";
			break;
		case "This is a very common password":
			// @ts-ignore
			result.feedback.warning = "C'est un mot de passe très commun";
			break;
		case "A word by itself is easy to guess":
			// @ts-ignore
			result.feedback.warning = "Un mot seul est facile à deviner";
			break;
		case "Names and surnames by themselves are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les noms et prénoms seuls sont faciles à deviner";
			break;
		case "Common names and surnames are easy to guess":
			// @ts-ignore
			result.feedback.warning = "Les noms et prénoms communs sont faciles à deviner";
			break;
	}
	for (let i = 0; i < result.feedback.suggestions.length; i++) {
		let suggestion = result.feedback.suggestions[i];
		switch (suggestion) {
			case "Use a few words, avoid common phrases":
				suggestion = "Utilisez quelques mots, évitez les phrases communes";
				break;
			case "No need for symbols, digits, or uppercase letters":
				suggestion = "Pas besoin de sylboles, chiffres ou lettres majuscules";
				break;
			case "Add another word or two. Uncommon words are better.":
				suggestion = "Ajoutez un mot ou deux. Les mots peu communs sont meilleurs";
				break;
			case "Use a longer keyboard pattern with more turns":
				suggestion = "Utilisez une séquence clavier plus longues avec plus de tours";
				break;
			case "Avoid repeated words and characters":
				suggestion = "Evitez de répéter des mots ou des caractères";
				break;
			case "Avoid sequences":
				suggestion = "Evitez les suites";
				break;
			case "Avoid recent years":
				suggestion = "Evitez les années récentes";
				break;
			case "Avoid years that are associated with you":
				suggestion = "Evitez les années liées à vous";
				break;
			case "Avoid dates and years that are associated with you":
				suggestion = "Evitez les dates liées à vous";
				break;
			case "Capitalization doesn't help very much":
				suggestion = "La capitalisation n'aide pas beaucoup";
				break;
			case "All-uppercase is almost as easy to guess as all-lowercase":
				suggestion = "Tout majuscule est aussi facile que tout minuscule";
				break;
			case "Reversed words aren't much harder to guess":
				suggestion = "Les mots à l'envers ne sont pas beaucoup plus difficiles à deviner";
				break;
			case "Predictable substitutions like '@' instead of 'a' don't help very much":
				suggestion = 'Les substitutions pévisibles comme "@" à le place de "a" n\'aide pas beaucoup';
				break;
		}
		result.feedback.suggestions[i] = suggestion;
	}

	Object.keys(result.crack_times_display).forEach(key => {
		// @ts-ignore
		result.crack_times_display[key] = result.crack_times_display[key]
			.replace("second", "seconde")
			.replace("less than a seconde", "moins d'une seconde")
			.replace("hour", "heure")
			.replace("day", "jour")
			.replace("months", "mois")
			.replace("month", "mois")
			.replace("year", "année")
			.replace("centuries", "plusieurs siècles");
	});

	return result;
}
