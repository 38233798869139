


























































import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import { DEBUG, EditList, MachinePart, Part, Machine, ConfigStatus, servers } from "@/loader";
import MachinePartEditor from "./MachinePartEditor.vue";
import MachinePartLeaf from "./MachinePartLeaf.vue";

@Component({
	inheritAttrs: true,
	components: {
		MachinePartEditor,
		MachinePartLeaf
	}
})
export default class MachinePartTree extends Mixins<EditList<MachinePart, Machine>>(EditList) {
	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;

	public item: MachinePart | null = null;
	public activeParent: MachinePart | null = null;
	public ConfigStatus = ConfigStatus;
	public software: Software | null = null;
	public dragOptions = {
		group: "machineParts",
		handle: ".btn-move",
		draggale: ".machine-part-leaf",
	}

	public get focusOnAction() {
		return false;
	}

	public get array() {
		return this.activeParent?.children || this.parent?.parts || [];
	}
	public set array(value: MachinePart[]) {
		if (this.activeParent) {
			this.activeParent.children = value;
			this.changed = true;
		} else if (this.parent) {
			this.parent.parts = value;
			this.changed = true;
		}
	}

	public get mainArray() {
		return this.parent?.parts || [];
	}
	public set mainArray(value: MachinePart[]) {
		if (this.parent) {
			this.parent.parts = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return MachinePart.create;
	}

	public get cloneFactory(): (base: MachinePart) => MachinePart {
		return MachinePart.clone;
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.mainArray.push(item);
		this.$emit("created", { item, parent: null });
		this.changed = true;
		this.setItem({ item, parent: null });
	}

	public setStatus(status: ConfigStatus) {
		if (this.item) {
			this.item.status = status;
			this.changed = true;
		}
	}

	public setItem({ item, parent }: { item: MachinePart | null, parent: MachinePart | null }) {
		this.item = item;
		this.activeParent = parent;
		this.setSoftware();
	}

	public async setSoftware() {
		this.software = await this.$db.getSoftware(this.item.softwareId);
	}
}
