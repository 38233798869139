




































import { Vue, Component, Prop } from "vue-property-decorator";
import {
	busy,
	servers,
	toaster,
	Server,
	Credentials,
} from '@/loader';

@Component({
	inheritAttrs: true
})
export default class LoginForm extends Vue {
	@Prop({ type: Object, required: true })
	public server!: Server;

	@Prop({ type: Boolean, default: false })
	public inline!: boolean;

	public username: string = "";
	public password: string = "";

	public focused = {
		username: false,
		password: false,
	};

	public states: { username: boolean | null, password: boolean | null } = {
		username: null,
		password: null,
	};

	public invalidFeedbacks = {
		username: "Identifiant requis",
		password: "Mot de passe requis",
	};

	public get formIsValid(): boolean {
		return !!this.states.username && !!this.states.password;
	}

	public checkForm() {
		this.states.username = this.focused.username ? !!this.username : null;
		this.states.password = this.focused.password ? !!this.password : null;
	}

	public async connect(event?: Event) {
		if (event) {
			event.preventDefault();
		}

		if (!this.server || !servers.account) {
			return;
		}

		this.checkForm();
		if (!this.formIsValid) {
			toaster.invalidData();
			return;
		}

		busy.start("Connexion en cours ...");
		let credentials = Credentials.create(
			Credentials.getId(servers.account, this.server),
			this.server.model,
			this.username,
			this.password
		);
		try {
			// @ts-ignore
			await this.$root.startServer(this.server, credentials);
		} catch (error: any) {
		}
		busy.stop();
	}
}
