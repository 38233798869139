










































































































































































































import { Component, Mixins } from "vue-property-decorator";
import {
	Part,
	servers,
	EditView,
} from "@/loader";
import GameCompatibilityTable from "../../../component/game/GameCompatibilityTable.vue";
import PartChildTable from "../../../component/part/PartChildTable.vue";
import PartCompatibilityTable from "../../../component/part/PartCompatibilityTable.vue";
import SoftwareCompatibilityTable from "../../../component/software/SoftwareCompatibilityTable.vue";

@Component({
	components: {
		GameCompatibilityTable,
		PartChildTable,
		PartCompatibilityTable,
		SoftwareCompatibilityTable,
	}
})
export default class PartView extends Mixins<EditView<Part>>(EditView) {
	public get hasValidRole(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get itemFactory(): Function | null {
		return Part.create;
	}

	public get cloneFactory(): (base: Part) => Part {
		return Part.clone;
	}

	public get checkDocFunction(): Function | null {
		return Part.check;
	}

	public get routeName(): string {
		return "part";
	}

	public get title(): string {
		if (this.item) {
			return "Matériel &mdash; " + this.item.name;
		}
		return "Matériel";
	}

	public get states() {
		return {
			name: this.focused.name ? !!this.item?.name : null,
			brand: this.focused.brand ? true : null,
			model: this.focused.model ? true : null,
			ref: this.focused.ref ? true : null,
			categoryId: this.focused.categoryId ? true : null,
			softwareId: this.focused.softwareId ? true : null,
			versionMin: this.focused.versionMin ? true : null,
			versionMax: this.focused.versionMax ? true : null,
		}
	}

	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		brand: "",
		model: "",
		ref: "",
		categoryId: "",
		softwareId: "",
		versionMin: "",
		versionMax: "",
	};
}
