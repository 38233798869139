import { BvListItem } from "@/loader";

export enum Civility {
	monsieur = 0,
	madame = 1,
	mademoiselle = 2
}

export namespace Civility {
	export function toString(civility: Civility): string {
		switch (civility) {
			case Civility.madame: return "Madame";
			case Civility.mademoiselle: return "Manager";
			case Civility.monsieur:
			default:
				return "Monsieur";
		}
	}

	export const LIST: BvListItem[] = Object.values(Civility)
	.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<Civility>value) }));
}
