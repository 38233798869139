import { Component, Prop, Mixins } from "vue-property-decorator";
import {
	Machine,
	Mission,
	EditComponent,
	Replacement,
	Task,
	ReplacementAction,
	PropertyNames,
} from "@/loader";

@Component
export class ReplacementRow<T extends Replacement> extends Mixins<EditComponent<Replacement, Task>>(EditComponent) {
	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public item: T | null = null;
	public original: T | null = null;
	public focused: { [prop in PropertyNames<T>]?: boolean; } = {};
	public invalidFeedbacks: { [prop in PropertyNames<T>]?: string; } = {}

	public get states(): { [prop in PropertyNames<T>]?: boolean | null; } {
		return {};
	}

	public get canDelete() {
		return this.canEdit && this.item && !this.item.oldId;
	}

	public get action() {
		return this.item?.action || ReplacementAction.none;
	}

	public get actionSlug() {
		return ReplacementAction.toSlug(this.action);
	}

	public get rowVariant() {
		switch (this.action) {
			case ReplacementAction.add: return 'success';
			case ReplacementAction.remove: return 'danger';
			default: return '';
		}
	}

	public get isOldRequired(): boolean {
		return ReplacementAction.NEED_OLD.includes(this.action);
	}

	public get isNewRequired(): boolean {
		return ReplacementAction.NEED_NEW.includes(this.action);
	}

	public get hideOld(): boolean {
		return ReplacementAction.HIDE_OLD.includes(this.action);
	}

	public get hideNew(): boolean {
		return ReplacementAction.HIDE_NEW.includes(this.action);
	}

	public get allowedActions() {
		return this.item?.oldId ? ReplacementAction.ACTIONS_WITH_OLD : ReplacementAction.ACTIONS_WITHOUT_OLD;
	}
}
