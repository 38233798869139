

























































import { Vue, Component, Prop } from "vue-property-decorator";
import { Coordinate } from "@/loader";
import CoordinateEdit from "./CoordinateEdit.vue"
import CoordinateView from "./CoordinateView.vue"

@Component({
	components: {
		CoordinateEdit,
		CoordinateView,
	}
})
export default class CoordinatesCard extends Vue {
	@Prop({ type: Array, required: true })
	public coordinates!: Coordinate[];

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	public add() {
		this.coordinates.push(Coordinate.create());
	}

	public coordinate: Coordinate | null = null;

	public add() {
		this.edit(Coordinate.create());
	}

	public edit(coordinate: Coordinate) {
		this.coordinate = coordinate;
		this.$bvModal.show("coordinate_modal");
	}

	public cancel() {
		this.coordinate = null;
	}

	public save() {
		if (!this.coordinates.includes(this.coordinate)) {
			this.coordinates.push(this.coordinate);
			this.$emit('added', this.coordinate);
		} else {
			this.$emit('updated', this.coordinate);
		}
		this.$emit('change', this.coordinate);
	}

	public remove(coordinate: Coordinate) {
		this.coordinate = coordinate;
		this.$bvModal.show("delete_coordinate_modal");
	}

	public confirmRemove() {
		this.coordinates.remove(this.coordinate);
		this.$emit('deleted', this.coordinate);
		this.$emit('change', this.coordinate);
	}
}
