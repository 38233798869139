


















































































import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	ConfigStatus,
	EditComponent,
	Machine,
	MachineSoftware,
	servers,
	Software,
	SoftwareCategory,
	SoftwareCategorySelect,
	SoftwareType,
} from "@/loader";

const DELETABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const ACTIVABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const PLANNING_STATUS = [ConfigStatus.draft, ConfigStatus.disabled];

@Component
export default class MachineSoftwareRow extends Mixins<EditComponent<MachineSoftware, Machine>>(EditComponent) {
	public software: Software | null = null;
	public useEditMode: boolean = true;
	public ConfigStatus = ConfigStatus;
	public SoftwareType = SoftwareType;

	public get canDelete(): boolean {
		return this.canEdit && !this.editMode && DELETABLE_STATUS.includes(this.item?.status);
	}

	public get canDisable(): boolean {
		return this.canEdit && !this.editMode && this.item?.status === ConfigStatus.active;
	}

	public get canActivate(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get canPlan(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get softwareRoute() {
		return this.item?.softwareId ? {
			name: "software",
			params: {
				id: this.item.softwareId,
				serverName: servers.selected?.name,
			},
			query: {
				redirect: this.$route.fullPath,
				redirectTitle: `Machine ${this.parent?.serial || ""}`
			}
		} : undefined;
	}

	public async loadRelations() {
		this.software = await this.$db.getSoftware(this.item?.softwareId);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("editMode")
	public editModeChanged(newValue: boolean | null, oldValue?: boolean | null) {
		if (!newValue && newValue !== oldValue) {
			this.loadRelations();
		}
	}

	@Watch("item", { deep: true })
	public itemChanged(newValue: MachineSoftware | null, oldValue?: MachineSoftware | null) {
		if (newValue?.softwareId !== this.software?._id) {
			this.loadRelations();
		}
	}
}
