






















































import { Component, Mixins, Ref } from "vue-property-decorator";
import {
	busy,
	servers,
	toaster,
	Account,
	EditObject,
	AccountResponse,
	EventType,
} from "@/loader";
import AccountEditor from "../../component/user/AccountEditor.vue";
import AccountServerList from "../../component/user/AccountServerList.vue";

@Component({
	components: {
		AccountEditor,
		AccountServerList,
	},
})
export default class AccountView extends Mixins<EditObject<Account>>(EditObject) {
	@Ref()
	public readonly editor!: AccountEditor;

	public tab: number = 0;

	public get canEdit() {
		return servers.online;
	}

	public get fullName() {
		return this.item ? `${this.item.firstName} ${this.item.lastName}` : 'Utilisateur';
	}

	public async cancel() {
		if (!this.item || !this.changed) {
			toaster.noChange();
			return;
		}

		if (!this.canEdit) {
			toaster.forbidden();
			return;
		}

		try {
			let response = await fetch("/account.php", {
				method: "POST",
				body: JSON.stringify({
					action: "get",
					id: this.item._id
				}),
				headers: {
					Authorization: `Bearer ${servers.token}`
				}
			});
			let result: AccountResponse = await response.json();
			if (result.code !== 200) {
				throw result;
			}
			servers.setAccount(result.account || null);
			if (this.editor) {
				this.editor.clean();
			}

			await this.$db.saveGlobalSettings(servers.settings);
		} catch (error) {
			console.error(error);
			toaster.error({ error });
		}
	}

	public async save(eventType?: EventType, eventComment: string = "") {
		if (!this.item || !this.changed) {
			toaster.noChange();
			return;
		}

		if (!this.canEdit) {
			toaster.forbidden();
			return;
		}

		if (!this.editor?.check()) {
			toaster.invalidData();
			return;
		}

		busy.start("Sauvegarde en cours ...");
		// @ts-ignore
		if (await this.$root.updateAccount(this.item)) {
			this.item = servers.account;
			if (this.editor) {
				this.editor.clean();
			}
		}
		busy.stop();
	}

	public mounted() {
		this.item = servers.account;
	}
}
