










































import { Component, Mixins, Prop } from "vue-property-decorator";
import { EditList, SoftwareCounter, Software } from "@/loader";
import SoftwareCounterRow from "./SoftwareCounterRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		SoftwareCounterRow
	}
})
export default class SoftwareCounterList extends Mixins<EditList<SoftwareCounter, Software>>(EditList) {
	@Prop({ type: String, default: "Compteurs" })
	public title!: string;

	@Prop({ type: Boolean, default: false })
	public elec!: boolean;

	@Prop({ type: Boolean, default: false })
	public meca!: boolean;

	public get arrayKey(): "elecs" | "mecas" {
		return this.meca ? "mecas" : "elecs";
	}

	public get array(): SoftwareCounter[] {
		return this.parent ? this.parent[this.arrayKey] : [];
	}
	public set array(value: SoftwareCounter[]) {
		if (this.parent) {
			this.parent[this.arrayKey] = value.reorder(1);
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return () => SoftwareCounter.create("", "", this.array.length + 1);
	}

	public get cloneFactory(): (base: SoftwareCounter) => SoftwareCounter {
		return SoftwareCounter.clone;
	}
}
