
















import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, ResetCounterTask } from "@/loader";

@Component
export default class ResetCounterTaskEditor extends Mixins<TaskDataEditorMixin<ResetCounterTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire après avoir remis à zéro les compteurs",
	}
}
