import { checkArrayValue, SoftwareReplacement } from "@/loader";

export interface MachineSoftwareTask {
	softwares: SoftwareReplacement[]
}

export namespace MachineSoftwareTask {
	export function instanceOf(value: any): value is MachineSoftwareTask {
		return value && typeof value === "object" && "softwares" in value;
	}

	export function check(data: any): MachineSoftwareTask {
		checkArrayValue(data, "softwares", SoftwareReplacement.check);
		return data;
	}
}
