import { checkValue } from "@/loader";


export interface LocationTask {
	location: string
}

export namespace LocationTask {
	export function instanceOf(value: any): value is LocationTask {
		return value && typeof value === "object" && "location" in value;
	}

	export function check(data: any): LocationTask {
		checkValue(data, "location", "");
		return data;
	}
}
