


































import { Component, Mixins, Prop } from "vue-property-decorator";
import dayjs from "dayjs";
import {
	EditObject,
	Mission,
	MissionStatus,
	toaster,
	servers,
	Event,
	EventType,
	Task
} from "@/loader";
import MissionsTable from "./MissionsTable.vue";
import MissionEditor from "./MissionEditor.vue";

@Component({
	components: {
		MissionEditor,
		MissionsTable,
	}
})
export default class MissionModal extends Mixins<EditObject<Mission>>(EditObject) {

	@Prop({ type: String, default: null })
	public readonly companyId!: string | null;

	@Prop({ type: String, default: null })
	public readonly machineId!: string | null;

	public id: string | null = null;
	public mission: Mission | null = null;
	public visible: boolean = false;
	public tasks: Task[] = [];

	public get missionQuery(): PouchDB.Find.FindRequest<Mission> {
		let result: PouchDB.Find.FindRequest<Mission> = {
			selector: {
				status: { $in: MissionStatus.FINISHABLE },
				start: { "$gte": dayjs().toISODateString() }
			},
			use_index: "mission_indexes",
		};
		if (this.companyId) {
			result.selector.companyId = this.companyId;
		}
		return result;
	}

	public show() {
		this.visible = true;
	}

	public hide() {
		this.visible = false;
	}

	public assignTasks() {
		this.tasks.forEach(t => { t.missionId = this.mission?._id || ""; });
	}

	public createNew() {
		this.mission = Mission.create(this.companyId);
		this.assignTasks();
	}

	public async selectMission(mission: Mission | null) {
		this.mission = mission;
		this.assignTasks();
	}

	public cancel() {
		this.clean();
	}

	public async save() {
		if (!this.mission) {
			toaster.noItem({ operation: "Sauvegarder", itemType: "mission" });
			return false;
		}

		this.mission.events.push(Event.create(
			servers.author,
			!this.mission._rev ? EventType.created : EventType.modified,
			"",
			this.original,
			this.item
		));

		if (await this.$db.save(this.mission)) {
			this.tasks.forEach(t => this.$db.save(t));
			this.clean();
			toaster.success({ message: "Sauvegarde effectuée" });
		} else {
			toaster.error({ message: "Erreur lors de la sauvegarde" });
		}
	}

	public clean() {
		this.visible = false;
		this.mission = null;
		this.tasks = [];
	}
}
