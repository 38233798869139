import { Component, Mixins, Prop } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, Server, servers, SyncStatus } from "@/loader";

@Component
export class ServerStatusBadge extends Mixins<EnumBadge<SyncStatus>>(EnumBadge) {
	@Prop({ required: true })
	public server!: Server;

	public get selectedServerName(): string {
		return servers.selected && servers.selected.name || "";
	}

	public get isSelected(): boolean {
		return this.server.name === this.selectedServerName;
	}

	public get variant(): BVariant {
		switch (this.value) {
			case SyncStatus.unknown:
				return BVariant.secondary;
			case SyncStatus.disconnected:
				return BVariant.warning;
			case SyncStatus.error:
			case SyncStatus.denied:
				return BVariant.danger;
			case SyncStatus.complete:
				return BVariant.success;
			case SyncStatus.change:
			case SyncStatus.active:
			case SyncStatus.paused:
			default:
				return BVariant.info;
		}
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case SyncStatus.unknown:
				return createIconConfig(['fas', 'lock']);
			case SyncStatus.disconnected:
				return createIconConfig(['fas', 'unlink']);
			case SyncStatus.error:
				return createIconConfig(['fas', 'exclamation-triangle']);
			case SyncStatus.denied:
				return createIconConfig(['fas', 'minus-circle']);
			case SyncStatus.change:
			case SyncStatus.active:
				return createIconConfig(['fas', 'sync'], { pulse: true });
			case SyncStatus.complete:
				return createIconConfig(['far', 'check-circle']);
			case SyncStatus.paused:
			default:
				return createIconConfig(['fas', 'pause']);
		}
	}

	public get title(): string {
		return SyncStatus.toString(this.value);
	}
}
