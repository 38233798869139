import {
	checkValue,
	cloneObject,
	CouchDBDocument,
	Deactivable,
	Events,
	generateUUID,
	HasCompatibilities,
	Named,
} from "@/loader";

export interface Platform extends CouchDBDocument, Events, Named, Deactivable, HasCompatibilities {
	type: "platform"
	brand: string | null
	ref: string | null
}

export namespace Platform {
	export const TYPE = "platform";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Platform {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		brand: string | null = null,
		ref: string | null = null,
	): Platform {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			brand,
			ref,
			name,
			disabled: false,
			...Events.create(),
			...HasCompatibilities.create(),
		}
	}

	export function clone(base: Platform) {
		return cloneObject(base, {
			...HasCompatibilities.clone(base),
		});
	}

	export function check(data: any): Platform {
		CouchDBDocument.check(data, TYPE);
		Deactivable.check(data);
		Named.check(data);
		Events.check(data);
		HasCompatibilities.check(data);
		checkValue(data, "brand", null);
		checkValue(data, "ref", null);
		return data;
	}
}
