import { arrayToFlag, BvListItem, flagToArray, flagToString, flagToStrings } from "@/loader";


export enum ValueUnit {
	none = 0,
	credit = 1,
	value = 2
}

function valueUnitToString(value: ValueUnit): string {
	switch (value) {
		case ValueUnit.credit: return "Crédit";
		case ValueUnit.value: return "Valeur";
		default: return "?";
	}
}

export namespace ValueUnit {
	export const LIST: BvListItem[] = Object.values(ValueUnit)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: valueUnitToString(<ValueUnit>value) }));

	export function toArray(value: ValueUnit): ValueUnit[] {
		return flagToArray(value);
	}

	export function fromArray(values: ValueUnit[]): ValueUnit {
		return arrayToFlag(values);
	}

	export function toStrings(value: ValueUnit): string[] {
		return flagToStrings(value, valueUnitToString);
	}

	export function toString(value: ValueUnit, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, valueUnitToString, separator, last_separator);
	}
}
