import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DataSelect, ParentSelect, Game, GameController, MachineGame, BvListGroup, Named, ConfigStatus } from '@/loader';

@Component
export default class GameControllerSelect extends Mixins<DataSelect, ParentSelect<Game>>(DataSelect, ParentSelect) {
	public games: Game[] = [];

	@Prop({ type: [String, Object, Array], default: () => [] })
	public game!: string | Game | MachineGame | (string | Game | MachineGame)[];

	public get options(): (GameController | BvListGroup)[] {
		let result: any[] = this.games.map(g => ({ "label": g?.name, options: g?.controllers }));

		if (!this.required && !this.multiple) {
			result.unshift(<string>this.emptyItem);
		}
		return result;
	}

	@Watch("game")
	public onGameChanged(newValue?: string | Game | (string | Game)[], oldValue?: string | Game | (string | Game)[]) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}

	public mounted() {
		this.loadRelations();
	}

	public async loadRelations() {
		let game = this.game;
		if (!Array.isArray(game)) {
			game = [game];
		}
		this.games = <Game[]>game.filter((x, i, a) => Game.instanceOf(x) && a.indexOf(x) === i);
		let keys = <string[]>game
			.filter(x => typeof x === "string" || MachineGame.instanceOf(x))
			.map(x => MachineGame.instanceOf(x) && x.status !== ConfigStatus.disabled ? x.gameId : x)
			.filter((x, i, a) => a.indexOf(x) === i);
		if (keys.length) {
			this.games.push(...await this.$db.allGames({ keys }));
		}
		this.games.sort((a, b) => a.name.localeCompare(b.name));
	}

	public getOptionKey(option: GameController | BvListGroup | null): string {
		return GameController.instanceOf(option) ? option.id : option?.label || "";
	}

	public getOptionLabel(option: GameController | Named | BvListGroup | null): string {
		return GameController.instanceOf(option) ? GameController.toString(option)
			: Named.instanceOf(option) ? option.name
			: option?.label
			|| this.emptyText;
	}
}
