import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	DocBrand,
	CouchDBDocument,
	DEBUG,
	servers,
	toaster,
	FilterCard,
	PouchResult,
	Named,
} from "@/loader";

@Component
export class DocumentFilterCard<T extends CouchDBDocument> extends Mixins(FilterCard) {
	@Prop({ type: Function, default: (r: PouchResult, defs: T[]) => {
		let key = Array.isArray(r.key) ? r.key[r.key.length - 1] : r.key;
		let doc = defs.find(d => d._id === key)
		return {
			_id: key,
			name: Named.instanceOf(doc) ? doc.name : key,
			count: r.value,
			doc
		};
	}})
	public readonly itemFactory!: (r: PouchResult, defs?: T[]) => DocBrand<T>;

	@Prop({ type: String, required: true })
	public readonly startkey!: string;

	@Prop({ type: String, required: true })
	public readonly endkey!: string;

	public items: DocBrand<T>[] = [];
	public definitions: T[] = [];
	public selection: DocBrand<T>[] = [];

	public async loadPrerequisites() {
		if (!servers.selected) {
			return;
		}

		try {
			let result = await this.$pouch.allDocs({
				startkey: this.startkey,
				endkey: this.endkey,
				include_docs: true
			}, servers.selected.name);

			DEBUG && console.log("allDocs?" + this.startkey, result);
			this.definitions = result.rows.map(r => <T>r.doc);
			this.$emit("definitions-loaded", this.definitions);
		} catch (error) {
			console.error("allDocs?" + this.startkey, error);
			toaster.error({ error });
			this.definitions = [];
			this.$emit("definitions-loaded", []);
		}
	}

	public callItemFactory(r: PouchResult) {
		return this.itemFactory(r, this.definitions);
	}
}
