
















































import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	DocumentList,
	Reading,
	BvTableFieldArray,
	Machine,
	Software,
	Company,
	Counter,
	PropertyNames,
	toaster,
	CouchDBDocument,
	number,
	numberWithSign,
	CounterType,
	AppSettings,
	Mission,
	MachineGame,
} from "@/loader";

type Display = "values" | "deltas" | "deltas_elec" | "stats";
const DISPLAY_VALUES = "values"
const DISPLAY_DELTAS = "deltas"
const DISPLAY_DELTAS_ELEC = "deltas_elec"
const DISPLAY_STATS = "stats"
// const DISPLAYS = [DISPLAY_VALUES, DISPLAY_DELTAS, DISPLAY_DELTAS_ELEC, DISPLAY_STATS];

@Component
export default class ReadingsTable extends Mixins<DocumentList<Reading>>(DocumentList) {
	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public company!: Company | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public settings: AppSettings | null = null;
	public softwares: Software[] = [];
	public counters: Counter[] = [];
	public fields: BvTableFieldArray = [];
	public display: Display = DISPLAY_VALUES;
	public displays: { value: Display; text: string }[] = [
		{ value: DISPLAY_VALUES, text: "Valeurs" },
		{ value: DISPLAY_DELTAS, text: "Deltas T-1" },
		{ value: DISPLAY_DELTAS_ELEC, text: "Deltas Élec-Méca" },
		{ value: DISPLAY_STATS, text: "Stats" },
	];

	public sort: PropertyNames<Reading> = "date";
	public sortDesc: boolean = true;

	public get startkey(): string {
		if (!this.machine) {
			return "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
		}
		return Reading.getStartKey(this.machine._id);
	}

	public get endkey(): string {
		if (!this.machine) {
			return "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" + CouchDBDocument.ENDKEY_SUFFIX;
		}
		return Reading.getEndKey(this.machine._id);
	}

	public get type(): string {
		return Reading.TYPE;
	}

	public getSearchableString(value: Reading): string {
		return "";
	}

	public async mounted() {
		this.settings = await this.$db.getAppSettings();
		this.counters = await this.$db.allCounters();
		this.init();
	}

	public async init() {
		this.fields = [
			{
				key: "date",
				label: "Date",
				sortable: true,
				sortDirection: "desc",
				stickyColumn: true,
				isRowHeader: true,
			}
		];
		if (!this.machine) {
			this.fields.push({
				key: "machine.name",
				label: "Machine",
				sortable: true,
				stickyColumn: true,
				isRowHeader: true,
			}, {
				key: "machine.location",
				label: "Socle",
				sortable: true,
				stickyColumn: true,
				isRowHeader: true,
				tdClass: "text-center",
			}, {
				key: "machine.ref",
				label: "N°MAS",
				sortable: true,
				stickyColumn: true,
				isRowHeader: true,
				tdClass: "text-center",
			}, {
				key: "machine.serial",
				label: "N°Série",
				sortable: true,
				stickyColumn: true,
				isRowHeader: true,
			});
		}
		try {
			if (this.display === DISPLAY_STATS) {
				this.settings?.readingStats.forEach(s => {
					this.fields.push({
						key: s.slug,
						label: s.name,
						tdClass: this.statClass,
						formatter: this.statFormatter
					});
				});
				return;
			}

			let machines: Machine[] = [];
			if (this.machine) {
				machines.push(this.machine);
			} else if (this.mission) {
				let tasks = await this.$db.queryTasksByMission(this.mission._id);
				machines = await this.$db.allMachines({ keys: tasks.map(x => x.machineId) });
			} else if (this.company) {
				machines = await this.$db.queryMachinesByOwner(this.company._id);
			}

			this.softwares = await this.$db.allSoftwares({ keys: machines.map(m => m.osId).filter((v, i, a) => a.indexOf(v) === i) });

			if (this.softwares.length && this.counters.length) {
				this.softwares.flatMap(s => [...s.elecs, ...s.mecas]).forEach(oc => {
					let c = this.counters.find(x => x._id === oc.counterId);
					if (!c
						|| (oc.conditions && (oc.conditions & (this.machine?.config || 0)) == 0)
						|| (this.display === DISPLAY_DELTAS_ELEC && !CounterType.isMeca(c.definition))
						|| (this.fields.find(x => c && x && typeof x === "object" && x.key === c._id))) {
						return;
					}
					this.fields.push({
						key: c._id,
						label: c.name,
						headerTitle: oc.name,
						formatter: this.formatter
					});
				});
			}
		} catch (error) {
			console.error(error);
			toaster.warn({ message: "Une erreur est survenue durant le chargement des relèves" });
			this.softwares = [];
		}
	}

	public loadQuery(): (() => Promise<Reading[]>) | null {
		if (this.mission) {
			return () => this.$db.queryReadingsByMission(this.mission?._id);
		}
		return null;
	}

	public prepareItems(items: Reading[]): Reading[] {
		items = items
			.filter(item => item?.type === this.type)
			.map(item => ({
				...<Reading>(this.checkDocFunction ? this.checkDocFunction(item) : item),
				searchable: this.getSearchableString(<Reading>item),
			}));

		if (this.mission || this.company) {
			items.forEach(item => {
				this.$db.getMachine(item.machineId).then(m => {
					this.$set(item, "machine", m);
					return m ? this.$db.allGames({ keys: MachineGame.getRepresentingGames(m.games) }) : [];
				}).then(games => {
					if (item.machine && games.length) {
						item.machine.name = Machine.getName(item.machine, games);
					}
				});
			});
		}

		return items;
	}

	public statFormatter(value: any, key: string, item: Reading) {
		const stat = this.settings?.readingStats.find(x => x.slug === key)
		return stat ? Reading.statFormat(item, stat) : "";
	}

	public statClass(value: any, key: string, item: Reading) {
		const stat = this.settings?.readingStats.find(x => x.slug === key)
		const variant = stat ? Reading.statStatus(item, stat) : "";
		return variant ? `table-${variant}` : "";
	}

	public formatter(value: any, key: string, item: Reading) {
		const counter = item.values.find(x => x.counterId === key);
		switch (this.display) {
			case DISPLAY_DELTAS:
				return numberWithSign.format(counter?.delta || 0);
			case DISPLAY_DELTAS_ELEC:
				return numberWithSign.format(counter?.deltaElec || 0);
		}
		return number.format(counter?.value || 0);
	}

	@Watch("machine")
	public machineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue !== oldValue) {
			this.init();
		}
	}

	@Watch("mission")
	public missionChanged(newValue: Mission | null, oldValue?: Mission | null) {
		if (newValue !== oldValue) {
			this.init();
		}
	}

	@Watch("display")
	public displayChanged(newValue: Display, oldValue?: Display) {
		if (newValue !== oldValue) {
			this.init();
		}
	}
}
