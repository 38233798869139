import { checkArrayValue, PartReplacement } from "@/loader";

export interface MachinePartTask {
	parts: PartReplacement[]
}

export namespace MachinePartTask {
	export function instanceOf(value: any): value is MachinePartTask {
		return value && typeof value === "object" && "parts" in value;
	}

	export function check(data: any): MachinePartTask {
		checkArrayValue(data, "parts", PartReplacement.check);
		return data;
	}
}
