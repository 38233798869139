import { Component, Mixins, Prop } from "vue-property-decorator";
import { DataSelect, ParentSelect, Game, GAME_BETS, GameVariant } from '@/loader';

@Component
export default class GameBetSelect extends Mixins<DataSelect, ParentSelect<Game>>(DataSelect, ParentSelect) {
	public localOptions: any[] = GAME_BETS;

	@Prop({ type: Boolean, default: false })
	public isAntebets!: boolean;

	@Prop({ type: Boolean, default: false })
	public isMaxbets!: boolean;

	@Prop({ type: Array, default: () => [] })
	public compatibilities!: GameVariant[];

	// @ts-ignore
	public get options(): any[] {
		let result = [];
		if (this.compatibilities.length) {
			let key: "antebets" | "maxbets" = this.isAntebets ? "antebets" : "maxbets";
			result = this.compatibilities.reduce((list: string[], c) => {
				list.push(...c[key]);
				return list;
			}, []).filter((x, i, list) => list.indexOf(x) === i);
			result.sort((a: string, b: string) => a.localeCompare(b));
		}

		if (!result?.length) {
			result = this.parent
				? this.isAntebets
					? this.parent.antebets.slice(0)
					: this.parent.maxbets.slice(0)
				: this.localOptions
					? this.localOptions.slice(0)
					: [];
			if (!this.required && !this.multiple) {
				result.unshift(this.emptyItem);
			}
		}
		return result;
	}
}
