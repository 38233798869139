





































import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import { servers, Server, toaster, Credentials } from "@/loader";
import ConnectServer from "./ConnectServer.vue";

@Component({
	inheritAttrs: true,
	components: {
		ConnectServer,
	}
})
export default class AccountServerList extends Vue {
	@Prop({ default: "div" })
	public tag!: any;

	@Ref()
	public readonly connectServer!: ConnectServer;

	public get servers() {
		return servers.list;
	}

	public get online() {
		return servers.online;
	}

	public get canAdd(): boolean {
		return servers.definitions.some(d => !servers.list.find(s => s.model === d.name));
	}

	public connected() {
		if (this.connectServer) {
			this.connectServer.clean();
		}
	}

	public async removeServer(server: Server) {
		if (!this.online) {
			toaster.noConnection({ operation: "Supprimer un serveur" });
			return;
		}
		if (!server || !servers.account) {
			toaster.invalidData();
			return;
		}

		let result = await this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer le serveur ${server.label} ?`, {
			title: "Suppression d'un serveur",
			bodyBorderVariant: "danger",
			footerBorderVariant: "danger",
			headerBorderVariant: "danger",
			okVariant: "danger",
			cancelVariant: "outline-secondary",
			cancelTitle: "Annuler",
			okTitle: "Supprimer",
			size: "sm",
			centered: true,
		});
		if (!result) {
			return;
		}

		servers.removeServer(server);
		// @ts-ignore
		await this.$root.updateAccount();

		try {
			let credentials = await this.$pouch.get(Credentials.getId(servers.account.name, server.name), {}, servers.account.name);
			if (credentials) {
				await this.$pouch.remove(credentials, {}, servers.account.name);
			}
		} catch (error) {
		}
		try {
			await this.$pouch.remove(server.settings, {}, servers.account.name);
		} catch (error) {
		}
		try {
			this.$pouch.disconnect(server.url);
			this.$pouch.close(server.url);
			this.$pouch.destroy(server.name);
		} catch (error) {
		}
	}
}
