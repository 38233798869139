



































































































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import {
	EditComponent,
	MachineController,
	Machine,
	ConfigStatus,
	ControllerType,
} from "@/loader";
import MachineGameDisplay from "./MachineGameDisplay.vue";

const DELETABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const ACTIVABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const PLANNING_STATUS = [ConfigStatus.draft, ConfigStatus.disabled];

@Component({
	components: {
		MachineGameDisplay,
	},
})
export default class MachineControllerRow extends Mixins<EditComponent<MachineController, Machine>>(EditComponent) {
	public useEditMode: boolean = true;
	public ConfigStatus = ConfigStatus;

	public get canDelete(): boolean {
		return this.canEdit && !this.editMode && DELETABLE_STATUS.includes(this.item?.status);
	}

	public get canDisable(): boolean {
		return this.canEdit && !this.editMode && this.item?.status === ConfigStatus.active;
	}

	public get canActivate(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get canPlan(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get types(): string {
		return ControllerType.toString(this.item?.type || 0);
	}

	public get specificGames(): MachineGame[] {
		return !this.parent || !this.item || this.item.global ? []
			: this.parent.games.filter(g => this.item.games.includes(g.id));
	}
}
