








































import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, RepairTask } from "@/loader";

@Component
export default class RepairTaskEditor extends Mixins<TaskDataEditorMixin<RepairTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public get tabCount(): number[] {
		return [this.partsCount, 1];
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire après une réparation",
	}
}
