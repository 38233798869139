import { ActionContext } from "vuex";
import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import {
	store,
	IMessage,
} from "@/loader";

@Module({
	name: "messages",
	store,
	dynamic: true,
	namespaced: true,
})
export class MessagesModule extends VuexModule {
	public messages: IMessage[] = [];

	@Mutation
	public add(messages: IMessage | IMessage[]): void {
		if (!Array.isArray(messages)) {
			messages = [messages];
		}
		this.messages.push(...messages);
	}

	@Mutation
	public remove(message: IMessage) {
		this.messages.remove(message);
	}
}

export const messages = getModule(MessagesModule, store);

export function commitMessagesAdd(context: ActionContext<any, any>, messages: IMessage | IMessage[]) {
	context.commit("messages/add", messages, { root: true });
}

export function commitMessagesRemove(context: ActionContext<any, any>, message: IMessage) {
	context.commit("messages/remove", message, { root: true });
}
