







































































import { Component, Mixins } from "vue-property-decorator";
import { Game, GameFeature, EditList, BvTableFieldArray, } from '@/loader';

@Component
export default class GameFeatureTable extends Mixins<EditList<GameFeature, Game>>(EditList) {
	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
		},
		{
			key: "frequencies",
			label: "Fréquence",
		},
		{
			key: "description",
			label: "Description",
		},
		{
			key: "action",
			label: "",
			tdClass: "action-td",
		}
	]

	public get itemFactory(): Function | null {
		return GameFeature.create;
	}

	public get array() {
		return this.parent?.features || [];
	}
	public set array(value: GameFeature[]) {
		if (this.parent) {
			this.parent.features = value;
			this.changed = true;
		}
	}

	public get cloneFactory(): (base: GameFeature) => GameFeature {
		return GameFeature.clone;
	}
}
