











































































import { Component, Mixins } from "vue-property-decorator";
import { EditList, Software, toaster } from "@/loader";

@Component
export default class SoftwareVersionList extends Mixins<EditList<String, Software>>(EditList) {
	public get array(): string[] {
		return this.parent ? this.parent.versions : [];
	}
	public set array(value: string[]) {
		if (this.parent) {
			this.parent.versions = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return () => "";
	}

	public get cloneFactory(): (base: String) => String {
		return String; //(x) => String(x);
	}

	public setValue(value: string, index: number) {
		this.array[index] = value;
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item = this.itemFactory();
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (Array.isArray(this.$refs.inputs) && this.$refs.inputs.length > 0) {
				const input = this.$refs.inputs?.[this.$refs.inputs.length - 1] as HTMLInputElement;
				if (input) {
					input.focus();
				}
			}
		});
	}

	public remove(item?: String, index?: number) {
		if (!this.parent || index === undefined || index < 0 || index >= this.array.length) {
			toaster.invalidOperation({ operation: "Supprimer" });
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Supprimer" });
			return;
		}
		this.array.splice(index, 1);
		this.$emit("deleted", item, index);
	}
}
