import { Component, Prop, Mixins, Watch, Model } from "vue-property-decorator";
import { toaster, Editor, PropertyNames } from "@/loader";

@Component
export class EditComponent<T, P> extends Mixins(Editor) {
	@Model("input", { default: null })
	public value!: T | null;

	@Prop({ default: null })
	public parent!: P | null;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ default: "div" })
	public tag!: any;

	@Prop({ type: [String, Array], default: "" })
	public exclude!: string | string[];

	@Prop({ type: [String, Array], default: "" })
	public include!: string | string[];

	public item: T | null = null;
	public original: T | null = null;
	public focused: { [prop in PropertyNames<T>]?: boolean; } = {};
	public invalidFeedbacks: { [prop in PropertyNames<T>]?: string; } = {}

	public get states(): { [prop in PropertyNames<T>]?: boolean | null; } {
		return {};
	}

	public focus() {
		(<HTMLInputElement>this.$el.querySelector('input:not(.vs__search), textarea'))?.focus();
	}

	public select(item: T | null) {
		this.item = item;
	}

	public createNew() {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		this.item = this.itemFactory();
		this.$emit("created", this.item);
	}

	public mounted() {
		this.item = this.value;
	}

	@Watch("item", { immediate: true })
	public onItemChanged(newValue: any, oldValue?: any) {
		if (newValue !== oldValue) {
			this.clean();
			this.original = JSON.parse(JSON.stringify(newValue));
		}
	}

	@Watch("value", { immediate: true })
	public onValueChanged(newValue: T | null, oldValue?: T | null) {
		if (newValue !== oldValue) {
			this.item = newValue;
		}
	}
}
