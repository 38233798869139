import {
	CouchDBDocument,
	UserData,
	Named,
	ServerData,
	Server,
	createServer,
	checkValue,
	checkArrayValue,
} from "@/loader";

export const ACCOUNT_TYPE = "account";

export interface AccountServer extends Named {
	model: string
}

export interface Account extends CouchDBDocument, UserData {
	key: string | null
	servers: AccountServer[],
}

export function isAccount(value: any): value is Account {
	return value?.type === ACCOUNT_TYPE;
}

export function createAccount(
	name: string = "",
	email: string = "",
	password: string = "",
	firstName: string = "",
	lastName: string = "",
	key: string | null = null,
): Account {
	return {
		_id: "",
		_rev: "",
		type: ACCOUNT_TYPE,
		...UserData.create(name, email, password, firstName, lastName),
		key,
		servers: []
	};
}

export function createAccountServer(
	name: string = "",
	model: string = "",
): AccountServer {
	return {
		name,
		model,
	};
}

export function createServerFromAccountServer(
	accountServer: AccountServer,
	data?: ServerData | null
): Server {
	return createServer(
		accountServer.name,
		accountServer.model,
		data?.label || "",
		data?.url || "",
		data?.auth || "",
		data?.img || ""
	);
}

export function checkAccount(data: any): Account {
	CouchDBDocument.check(data, ACCOUNT_TYPE);
	UserData.check(data);
	checkValue(data, "key", null);
	checkArrayValue(data, "servers", checkAccountServer);
	return data;
}

export function checkAccountServer(data: any): AccountServer {
	Named.check(data);
	checkValue(data, "model", "");
	return data;
}
