import { CreateElement } from "vue";
import { Component, Mixins } from "vue-property-decorator";
import { arrayToFlag, flagToArray, EnumSelect, BvListItem } from "@/loader";

@Component
//@ts-ignore
export class FlagSelect<T extends number> extends Mixins<EnumSelect<T>>(EnumSelect) {
	public get values(): T | T[] {
		return this.multiple ? flagToArray(this.value) : this.value;
	}

	public get includedValues(): T[] {
		return Array.isArray(this.include) ? this.include : flagToArray(this.include);
	}

	public render(h: CreateElement) {
		return h(
			"v-select",
			{
				props: {
					appendToBody: true,
					options: this.includedOptions,
					multiple: this.multiple,
					disabled: this.disabled,
					value: this.values,
					label: "text",
					reduce: (option: BvListItem) => option.value,
					calculatePosition: this.calculatePosition,
				},
				on: {
					input: (values: T | T[]) => this.$emit("input", Array.isArray(values) ? arrayToFlag(values) : values),
				},
			}
		);
	}
}
