<template>
	<div>
		<b-card>
			<b-card-text>
				L'application est conçue pour fonctionner hors-ligne :
				si vous n'êtes pas connectés à Internet,
				vous pouvez continuer à travailler normalement.
			</b-card-text>
			<b-card-text>
				<fa :icon="['fas', 'sync']" fixed-width></fa>
				Dès que votre connexion sera rétablie,
				les données seront synchronisées avec le serveur automatiquement.
			</b-card-text>
			<b-alert variant="warning" show>
				<fa :icon="['fas', 'exclamation-triangle']" fixed-width></fa>
				Les données qui n'ont pas encore été synchronisées
				ne seront visibles que sur cet appareil.
			</b-alert>
		</b-card>
		<b-card class="mt-3">
			<b-card-title>Et que se passe-t-il si 2 personnes hors-ligne modifient la même machine ?</b-card-title>
			<b-card-text></b-card-text>
		</b-card>
	</div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { TutorialComponent } from '@/loader';

@Component
export default class TutorialWelcome extends Mixins(TutorialComponent) {
	public title = "Fonctionne sans Internet";
	public icon = ["fal", "signal-slash"];
}
</script>
