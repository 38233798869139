export const isString = (value: any): value is String => {
	return typeof value === "string";
}

export const isBoolean = (value: any): value is Boolean => {
	return typeof value === "boolean";
}

export const isObject = (value: any): value is Object => {
	return typeof value === "object";
}

export const has = (object: any, key: string) => {
	return isObject(object) && object.hasOwnProperty(key);
}

export const get = (object: any, key: string, defaultValue: any): any => {
	return has(object, key) ? object[key] : defaultValue;
}

export const px = (value: number) => {
	return `${value}px`;
}

export const translate3d = (x: string, y: string, z: string = '0px'): string => {
	return `translate3d(${x}, ${y}, ${z})`
}
