



































import { Vue, Component, Prop } from "vue-property-decorator";
import { Address } from "@/loader";
import CountrySelect from "../country/CountrySelect.vue";
import { Autocomplete } from "../../plugin/vue2-google-maps";

@Component({
	components: {
		CountrySelect,
		'gmap-autocomplete': Autocomplete,
	}
})
export default class AddressEdit extends Vue {
	@Prop({ type: Object, required: true })
	public address!: Address;

	@Prop({ type: String, default: "div" })
	public tag!: string;

	public focused = {
		label: false,
		place: false,
	};

	public states = {
		label: null,
		place: null,
	};

	public invalidFeedbacks = {
		label: "Non valide",
		place: "Non valide",
	};

	public setPlace(place: any) {
		// We need to remove all functions, getters and setters for future save
		place = JSON.parse(JSON.stringify(place || { formatted_address: "" }));
		delete place.photos;
		delete place.reviews;
		this.address.place = place;
	}
}
