import { CouchDBDocument } from "@/loader";

export const COUNTRY_TYPE = "country";
export const COUNTRY_KEY = COUNTRY_TYPE + ":";

export enum CountryLanguages {
	ara = "ara",
	ces = "ces",
	cym = "cym",
	deu = "deu",
	est = "est",
	fin = "fin",
	fra = "fra",
	hrv = "hrv",
	hun = "hun",
	ita = "ita",
	jpn = "jpn",
	kor = "kor",
	nld = "nld",
	per = "per",
	pol = "pol",
	por = "por",
	rus = "rus",
	slk = "slk",
	spa = "spa",
	swe = "swe",
	urd = "urd",
	zho = "zho",
}

export type LatLng = [number, number]

export interface CountryName {
	common: string
	official: string
}

export type CountryNames = {
	[key in CountryLanguages]?: CountryName
}

export interface CountryNativeName extends CountryName {
	native: CountryNames
}

export interface CountryCurrency {
	name: string
	symbol: string
}

export interface Country extends CouchDBDocument {
	type: "country"
	name: CountryNativeName
	tld: string[]
	cca2: string
	ccn3: string
	cca3: string
	cioc: string
	independent: boolean
	status: string
	unMember: boolean
	currencies: {
		[currency_code: string]: CountryCurrency
	}
	idd: {
		root: string
		suffixes: string[]
	}
	capital: string[]
	altSpellings: string[]
	region: string
	subregion: string
	languages: {
		[language in CountryLanguages]?: string
	}
	translations: CountryNames
	latlng: LatLng
	landlocked: true
	borders: string[]
	area: number
	demonyms: {
		[language in CountryLanguages]?: {
			f: string
			m: string
		}
	}
	flag: string
	maps: {
		googleMaps: string
		openStreetMaps: string
	}
	population: number
	gini: { [year in number]?: number },
	fifa: string
	car: {
		signs: string[],
		side: "right" | "left"
	}
	timezones: string[]
	continents: string[]
	flags: {
		png: string
		svg: string
	}
	coatOfArms: {
		png: string
		svg: string
	}
	startOfWeek: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
	capitalInfo: { latlng: LatLng }
	postalCode: {
		format: string,
		regex: string
	}
}

export function isCountry(obj: any): obj is Country {
	return obj && obj.type && obj.type === COUNTRY_TYPE;
}

export function createCountry(data: Country, old: Country | any = {}): Country {
	return Object.assign({
		_id: COUNTRY_KEY + data.cca2,
		rev: "",
		type: COUNTRY_TYPE,
	}, old, data);
}
