import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Software, SoftwareType, servers, DocumentSelect, busy, Platform, AllDocsOptions, Compatibility } from '@/loader';

@Component
export class SoftwareSelect extends Mixins<DocumentSelect<Software>>(DocumentSelect) {
	@Prop({ type: String, default: null })
	public platformId!: string | null;

	@Prop({ type: Number, default: null })
	public definition!: SoftwareType | null;

	public platform: Platform | null = null;

	public async loadOptions() {
		let options: AllDocsOptions | undefined = undefined;
		if (this.platformId) {
			this.platform = await this.$db.getPlatform(this.platformId);
			options = {
				keys: this.platform?.compatibilities.softwares.length
					? this.platform.compatibilities.softwares
						.filter(p => p.compatible !== Compatibility.incompatible)
						.map(software => software.softwareId)
					: undefined
			};
		}
		this.options = await this.$db.allSoftwares(options);
	}

	public filterOptions(options: Software[]): Software[] {
		options = this._union(options, this.include, true);
		options = this._union(options, this.exclude, false);
		if (this.definition) {
			options = options.filter(d => d.definition === this.definition || (this.showEmpty && d === this.emptyItem));
		}
		return options;
	}

	public get editRoute(): string {
		return "software";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public optionSlot(h: CreateElement, option: Software): string | VNode | (string | VNode | null)[] {
		return !option._id ? option.name : h("span", [
			option.name,
			h("software-type-badge", { props: { value: option.definition }, class: "ml-2" })
		]);
	}

	public mounted() {
		this.loadOptions();
	}

	public createOption(option: any): any {
		busy.start(`Création du logiciel "${option.name}" ...`);
		let item = Software.create(option.name);
		this.pushItem(item, option);
	}

	@Watch("platformId")
	public onPlatformIdChanged(newValue?: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}
}
