import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import EmptyView from "../view/EmptyView.vue";
import Home from "../view/Home.vue";
import ServerHome from "../view/ServerHome.vue";
import CatalogDashboard from "../view/catalog/CatalogDashboard.vue";
import PartsList from "../view/catalog/part/PartsList.vue";
import PartView from "../view/catalog/part/PartView.vue";
import GameCategoriesList from "../view/catalog/game-category/GameCategoriesList.vue";
import PartCategoriesList from "../view/catalog/part-category/PartCategoriesList.vue";
import GamesList from "../view/catalog/game/GamesList.vue";
import GameView from "../view/catalog/game/GameView.vue";
import SoftwaresList from "../view/catalog/software/SoftwaresList.vue";
import SoftwareView from "../view/catalog/software/SoftwareView.vue";
import CountersList from "../view/catalog/counter/CountersList.vue";
import CounterView from "../view/catalog/counter/CounterView.vue";
import PlatformsList from "../view/catalog/platform/PlatformsList.vue";
import PlatformView from "../view/catalog/platform/PlatformView.vue";
import UsersList from "../view/user/UsersList.vue";
import UserView from "../view/user/UserView.vue";
import ProfilView from "../view/user/ProfilView.vue";
import ParcDashboard from "../view/parc/ParcDashboard.vue";
import CompaniesList from "../view/parc/company/CompaniesList.vue";
import CompanyView from "../view/parc/company/CompanyView.vue";
import MachinesList from "../view/parc/machine/MachinesList.vue";
import MachineView from "../view/parc/machine/MachineView.vue";
import ReadingsList from "../view/parc/reading/ReadingsList.vue";
import ReadingView from "../view/parc/reading/ReadingView.vue";
import MissionsList from "../view/planning/mission/MissionsList.vue";
import MissionView from "../view/planning/mission/MissionView.vue";
import TaskView from "../view/planning/task/TaskView.vue";
import SettingsView from "../view/catalog/settings/SettingsView.vue";
// import PlanningDashboard from "../view/planning/PlanningDashboard.vue";
import { Role, servers, toaster, getServer } from "@/loader";

Vue.use(VueRouter);

const TITLE = "SFMPro";

const routes: RouteConfig[] = [
	{
		path: "/",
		name: "home",
		component: Home
	},
	{
		path: "/profil",
		name: "profile",
		component: ProfilView
	},
	{
		path: "/:serverName",
		beforeEnter(to, from, next) {
			if (!getServer(to.params.serverName)) {
				toaster.toast({
					message: `Le serveur "${to.params.serverName}" n'existe pas.`,
					variant: "danger"
				});
				next(false);
			}
			next();
		},
		component: EmptyView,
		children: [
			{
				path: "",
				name: "server",
				props: true,
				component: ServerHome,
			},
			{
				path: "catalogues",
				props: true,
				component: EmptyView,
				meta: {
					menu: "catalogs",
					roles: [Role.director, Role.manager],
				},
				children: [
					{
						path: "",
						name: "catalogs",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: CatalogDashboard
					},
					{
						path: "materiels",
						name: "parts",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: PartsList
					},
					{
						path: "materiel/:id",
						name: "part",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: PartView
					},
					{
						path: "materiels/categories",
						name: "part_categories",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: PartCategoriesList
					},
					{
						path: "jeux",
						name: "games",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: GamesList
					},
					{
						path: "jeu/:id",
						name: "game",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: GameView
					},
					{
						path: "jeux/categories",
						name: "game_categories",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: GameCategoriesList
					},
					{
						path: "logiciels",
						name: "softwares",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: SoftwaresList
					},
					{
						path: "logiciel/:id",
						name: "software",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: SoftwareView
					},
					{
						path: "plateformes",
						name: "platforms",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: PlatformsList
					},
					{
						path: "plateforme/:id",
						name: "platform",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: PlatformView
					},
					{
						path: "compteurs",
						name: "counters",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: CountersList
					},
					{
						path: "compteur/:id",
						name: "counter",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: CounterView
					},
					{
						path: "reglages",
						name: "settings",
						props: true,
						meta: {
							menu: "catalogs",
						},
						component: SettingsView
					},
				]
			},
			{
				path: "parc",
				component: EmptyView,
				meta: {
					menu: "parc",
				},
				children: [
					{
						path: "",
						name: "parc",
						props: true,
						meta: {
							menu: "parc",
						},
						component: ParcDashboard,
					},
					{
						path: "casinos",
						name: "companies",
						props: true,
						meta: {
							menu: "parc",
							roles: [Role.director, Role.manager, Role.accounting],
						},
						component: CompaniesList
					},
					{
						path: "casino/:id",
						name: "company",
						props: true,
						meta: {
							menu: "parc",
							roles: [Role.director, Role.manager, Role.accounting],
						},
						component: CompanyView
					},
					{
						path: "machines",
						name: "machines",
						props: true,
						meta: {
							menu: "parc",
						},
						component: MachinesList
					},
					{
						path: "machine/:id",
						name: "machine",
						props: true,
						meta: {
							menu: "parc",
						},
						component: MachineView
					},
					{
						path: "machine/:machineId/releves",
						name: "readings",
						props: true,
						meta: {
							menu: "parc",
						},
						component: ReadingsList
					},
					{
						path: "machine/:machineId/releve/:id",
						name: "reading",
						props: true,
						meta: {
							menu: "parc",
						},
						component: ReadingView
					},
				]
			},
			{
				path: "planning",
				meta: {
					menu: "planning",
				},
				component: EmptyView,
				children: [
					{
						path: "",
						name: "planning",
						props: true,
						meta: {
							menu: "planning",
						},
						component: ServerHome,
					},
					{
						path: "interventions",
						name: "missions",
						props: true,
						meta: {
							menu: "planning",
						},
						component: MissionsList
					},
					{
						path: "intervention/:id",
						name: "mission",
						props: true,
						meta: {
							menu: "planning",
						},
						component: MissionView
					},
					{
						path: "tache/:id",
						name: "task",
						props: true,
						meta: {
							menu: "planning",
						},
						component: TaskView
					},
				]
			},
			{
				path: "rh",
				component: EmptyView,
				meta: {
					menu: "rh",
					roles: [Role.director, Role.accounting]
				},
				children: [
					{
						path: "",
						name: "users",
						props: true,
						meta: {
							menu: "rh",
						},
						component: UsersList
					},
					{
						path: ":id",
						name: "user",
						props: true,
						meta: {
							menu: "rh",
						},
						component: UserView
					},
				]
			}
		]
	}
];

export const router = new VueRouter({
	routes,
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { x: 0, y: 0 };
	},
});

/** Add a start function to router to tell the router to start analyzing routes */
let promise = new Promise(resolve => {
	// @ts-ignore
	router.start = resolve;
})
router.beforeEach(async (to, from, next) => {
	console.log(to);
	// Router will be blocked until router.start is called
	await promise;
	// if (!servers.list) {
	// 	next(false);
	// 	return;
	// }
	// if (!servers.account) {
	// 	next(false);
	// 	return;
	// }

	if (to.params.serverName) {
		let server = getServer(to.params.serverName);
		if (!server) {
			toaster.toast({
				message: `Le serveur "${to.params.serverName || "inconnu"}" n'existe pas.`,
				variant: "danger"
			})
			next(false);
			return;
		}

		if (to.meta?.roles) {
			if (!server.settings.user || !server.settings.user.roles.filter(r => to.meta?.roles.includes(r))) {
				toaster.toast({
					message: `Vous n'avez pas l'autorisation d'accéder à cette page pour le serveur "${server.label}".`,
					variant: "danger"
				})
				next(false);
				return;
			}
		}

		if (servers.selected?.name !== server.name) {
			servers.setSelected(server);
		}
	} else if (servers.selected) {
		servers.setSelected(null);
	}

	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
	document.title = nearestWithTitle?.meta.title || TITLE;
	next();
})



export interface NavigationStates {
	home: any
}
