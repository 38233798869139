











































































import { Component, Mixins } from "vue-property-decorator";
import { toaster, busy, Editor } from "@/loader";

@Component
export default class LoginView extends Mixins<Editor>(Editor) {
	public username: string = "";
	public password: string = "";

	public focused = {
		username: false,
		password: false,
	}

	public invalidFeedbacks = {
		username: "L'identifiant est requis",
		password: "Le mot de passe est requis",
	}

	public get states() {
		return {
			username: this.focused.username ? !!this.username : null,
			password: this.focused.password ? !!this.password : null,
		}
	}

	public async resetPassword() {
		toaster.info({ message: "Cette fonction n'est pas encore disponible." });
	}

	public async connect(e?: Event) {
		e && e.preventDefault();
		if (!this.check()) {
			toaster.warn({ message: "" })
			return;
		}
		busy.start("Connexion en cours ...");
		try {
			// @ts-ignore
			await this.$root.login(this.username, this.password);
			this.$emit("logged-in");
		} catch (error) {
			toaster.error({ error });
		}
		busy.stop();
	}
}
