










































































import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	servers,
	MachineType,
	Company,
	toaster,
	EventType,
	Event,
	Machine,
	ValueUnit,
	MachineConfig,
	IncomeCounterStandards,
	INCOME_COUNTER_STANDARDS,
	MachineSoftware,
	ConfigStatus
} from '@/loader';
import { BvModalEvent } from 'bootstrap-vue';

@Component
export default class MachineCreateModal extends Vue {
	public newSerial: string = "";
	public newCompanyId: string = "";
	public newPlatformId: string = "";
	public newOsId: string = "";
	public newUnit: ValueUnit = ValueUnit.value;
	public newStandard: IncomeCounterStandards = "V1";
	public newConfig: MachineConfig[] = [];
	public newDefinition: MachineType = MachineType.mas;

	public valueUnits = ValueUnit.LIST;
	public machineConfigs = MachineConfig.LIST;
	public INCOME_COUNTER_STANDARDS = INCOME_COUNTER_STANDARDS;

	public item: Machine | null = null;

	@Prop({ default: null })
	public readonly base: Machine | null = null;

	public get canCreate(): boolean {
		return servers.selected && servers.user && !servers.isOnlyTech;
	}

	public async createNewMachine(e: BvModalEvent) {
		if (!this.$pouch || !servers.selected) {
			toaster.warning({ message: "Initialisation en cours, veuillez attendre quelques secondes et réessayer" });
			e.preventDefault();
			return;
		}
		if (!this.newSerial) {
			toaster.invalidData();
			e.preventDefault();
			return;
		}
		if (!this.canCreate) {
			toaster.forbidden({ operation: "Nouvelle machine" });
			e.preventDefault();
			return;
		}
		if (await this.$db.getMachine(Machine.getId(this.newSerial))) {
			toaster.invalidData({ message: `Le N° de série ${this.newSerial} existe déjà` });
			e.preventDefault();
			return;
		}

		let machine;
		if (this.base) {
			machine = await this.$db.cloneMachine(this.newSerial, this.base);
			machine.events.push(Event.create(servers.author, EventType.cloned, "", null, null, { from : this.base._id }));
		} else {
			machine = await this.$db.createMachine(this.newSerial);
			machine.events.push(Event.create(servers.author, this.base ? EventType.cloned : EventType.created));
		}
		machine.ownerId = this.newCompanyId;
		machine.platformId = this.newPlatformId;
		machine.osId = this.newOsId;
		machine.softwares.push(MachineSoftware.create(this.newOsId, ConfigStatus.active, null, true));
		machine.definition = this.newDefinition;
		machine.config = MachineConfig.fromArray(this.newConfig);
		machine.unit = this.newUnit;
		machine.incomeCounterStandard = this.newStandard;

		this.$pouch.put(machine, {}, servers.selected.name).then(result => {
			if (result.ok) {
				this.$router.push({ name: "machine", params: { id: result.id } });
			} else {
				toaster.error({ error: result });
			}
		}).catch(error => {
			toaster.error({ error });
		});
	}

	@Watch("base")
	public onBaseChanged(newValue?: Machine | null, oldValue?: Machine | null) {
		if (newValue && newValue !== oldValue) {
			this.newSerial = "";
			this.newCompanyId = newValue.ownerId;
			this.newPlatformId = newValue.platformId;
			this.newOsId = newValue.osId;
			this.newUnit = newValue.unit;
			this.newStandard = newValue.incomeCounterStandard;
			this.newConfig = MachineConfig.toArray(newValue.config);
			this.newDefinition = newValue.definition;
		}
	}
}
