<template>
	<li :class="['nav-item', 'tree-item', { 'opened': expand }]">
		<div :class="['leaf', { disabled } ]">
			<b-button
				variant="link"
				:class="['leaf-toggle', { invisible: !item.children.length }]"
				@click="expand = !expand"
			>
				<fa :icon="['fas', expand ? 'minus' : 'plus']" fixed-width></fa>
			</b-button>
			<b-button class="btn-move" variant="outline-secondary" title="Déplacer" :disabled="include.length > 0">
				<fa :icon="['fal', 'arrows']" fixed-width></fa>
			</b-button>
			<b-button
				variant="link"
				class="ip-leaf leaf-text"
				@click="select(item)"
			>
				<slot name="text-prepend" :item="item" :level="level"></slot>
				<span class="name" v-html="item.category.name"></span>
				<fa
					v-if="item.category.favorite"
					class="favorite"
					fixed-width
					:icon="['fas', 'star']"
				></fa>
				<slot name="text-append" :item="item" :level="level"></slot>
			</b-button>
			<slot :item="item" :level="level"></slot>
		</div>
		<v-draggable
			v-if="item.children.length && expand"
			v-model="leafs"
			tag="b-nav"
			pills
			vertical
			:class="'tree level-' + (level + 1).toString()"
		>
			<category-leaf
				v-for="child in leafs"
				:key="child._id"
				:item="child"
				:level="level + 1"
				:include="include"
				:opened="opened"
				@select="select"
			>
				<template
					v-for="slot in Object.keys($scopedSlots)"
					slot-scope="scope"
					:slot="slot"
					><slot :name="slot" v-bind="scope"
				/></template>
			</category-leaf>
		</v-draggable>
	</li>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { CategoryItem, Category, DEBUG, servers } from '@/loader';

@Component
export default class CategoryLeaf<T extends Category>  extends Vue {
	@Prop({ type: Object, required: true })
	public readonly item!: CategoryItem<T>;

	@Prop({ type: Number, default: 0 })
	public readonly level!: number;

	@Prop({ type: Array, default: () => [] })
	public readonly include!: CategoryItem<T>[];

	@Prop({ type: [Boolean, Array], default: false })
	public readonly opened!: boolean | string[];

	public expand: boolean = false;
	public disabled: boolean = false;

	public get leafs(): CategoryItem<T>[] {
		return this.item.children
			.filter(c => !this.include.length || this.include.includes(c._id))
			.sort((a, b) => (a.order || 0) - (b.order || 0));
	}
	public set leafs(value: CategoryItem<T>[]) {
		if (this.item && !this.include.length) {
			this.item.children = value;
			this.item.children.forEach((item, index) => {
				if (item.order !== index) {
					item.order = index;
					this.save(item);
				}
			})
		}
	}

	public select(item: CategoryItem<T> | null) {
		this.$emit("select", item);
	}

	public mounted() {
		if (Array.isArray(this.opened) && this.opened.find(x => x === this.category._id) || this.opened === true) {
			this.expand = true;
		}
	}

	public async save(item: CategoryItem<any>) {
		DEBUG && console.log("save", item._id, item.order);
		if (!servers.selected) {
			return;
		}
		if (!this.$pouch) {
			return;
		}

		try {
			let result = await this.$pouch.upsert(item._id, (i: CategoryItem<any>) => {
				if (i.order !== item.order) {
					i.order = item.order;
					return i;
				}
				return false;
			}, servers.selected.name);
			if (result.updated) {
				DEBUG && console.log("save ok", result);
				item._rev = result.rev;
			}
		} catch (error) {
			DEBUG && console.log("save error", error);
		}
	}
}
</script>
