



















import { Component, Vue } from "vue-property-decorator";
import { servers } from "@/loader";
import MachineHeader from "../../../component/machine/MachineHeader.vue";
import MachinesTable from "../../../component/machine/MachinesTable.vue";
import MachineCreateModal from "../../../component/machine/MachineCreateModal.vue";

@Component({
	components: {
		MachineHeader,
		MachinesTable,
		MachineCreateModal,
	}
})
export default class MachinesList extends Vue {
	public get canCreate(): boolean {
		return !!servers.selected && !!servers.user && !servers.isOnlyTech;
	}
}
