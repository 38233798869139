














































import { Component, Prop, Mixins } from "vue-property-decorator";
import { ControllerReplacement, Task, EditList, Machine, Mission, MachineControllerTask, ReplacementAction } from '@/loader';
import ControllerReplacementRow from "./ControllerReplacementRow.vue";

@Component({
	components: {
		ControllerReplacementRow,
	}
})
export default class ControllerReplacementTable extends Mixins<EditList<ControllerReplacement, Task>>(EditList) {
	public dragOptions = {
		group: "controllerReplacementRows",
		handle: ".btn-move",
		draggale: ".controller-replacement-row",
	}

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public get array(): ControllerReplacement[] {
		return this.parent && MachineControllerTask.instanceOf(this.parent.data) ? this.parent.data.controllers : [];
	}
	public set array(value: ControllerReplacement[]) {
		if (this.parent && MachineControllerTask.instanceOf(this.parent.data)) {
			this.parent.data.controllers = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return () => ControllerReplacement.create(null, ReplacementAction.add);
	}

	public get cloneFactory(): (base: ControllerReplacement) => ControllerReplacement {
		return ControllerReplacement.clone;
	}

	public get label() {
		return "Progressifs";
	}
}
