import {
	checkArrayValue,
	checkValue,
	cloneObject,
	Compatibility,
	Compatible,
	SoftwareType,
	UUID,
} from "@/loader";

export interface GameCompatibility extends Compatible {
	categoryId: UUID
	packId: UUID
	gameId: UUID
}

export interface PartCompatibility extends Compatible {
	categoryId: UUID
	partId: UUID
}

export interface SoftwareCompatibility extends Compatible {
	definition: SoftwareType
	softwareId: UUID
	minVersion: string | null
	maxVersion: string | null
}

export interface Compatibilities {
	games: GameCompatibility[]
	parts: PartCompatibility[]
	softwares: SoftwareCompatibility[]
}

export interface HasCompatibilities {
	compatibilities: Compatibilities
}

export namespace HasCompatibilities {
	export function instanceOf(obj: any): obj is HasCompatibilities {
		return obj?.compatibilities !== undefined;
	}

	export function create(): HasCompatibilities {
		return {
			compatibilities: Compatibilities.create(),
		}
	}

	export function clone(base: HasCompatibilities): HasCompatibilities {
		return {
			compatibilities: Compatibilities.clone(base.compatibilities),
		}
	}

	export function check(data: any): HasCompatibilities {
		checkValue(data, "compatibilities", Compatibilities.create, Compatibilities.check);
		return data;
	}
}

export namespace Compatibilities {
	export function create(): Compatibilities {
		return {
			games: [],
			parts: [],
			softwares: [],
		}
	}

	export function clone(base: Compatibilities): Compatibilities {
		return {
			games: base.games.map(GameCompatibility.clone),
			parts: base.parts.map(PartCompatibility.clone),
			softwares: base.softwares.map(SoftwareCompatibility.clone),
		}
	}

	export function check(data: any): Compatibilities {
		checkArrayValue(data, "games", GameCompatibility.check);
		checkArrayValue(data, "parts", PartCompatibility.check);
		checkArrayValue(data, "softwares", SoftwareCompatibility.check);
		return data;
	}
}

export namespace GameCompatibility {
	export function create(
		gameId: UUID,
		packId: UUID = "",
		categoryId: UUID = "",
		compatible: Compatibility = Compatibility.compatible
	): GameCompatibility {
		return {
			gameId,
			packId,
			compatible,
			categoryId,
		}
	}

	export function clone(base: GameCompatibility): GameCompatibility {
		return cloneObject(base);
	}

	export function check(data: any): GameCompatibility {
		if (typeof data === "string") {
			return create(data);
		}

		Compatible.check(data);
		checkValue(data, "gameId", "");
		checkValue(data, "packId", "");
		checkValue(data, "categoryId", "");
		return data;
	}
}

export namespace PartCompatibility {
	export function create(
		partId: UUID,
		categoryId: UUID = "",
		compatible: Compatibility = Compatibility.compatible
	): PartCompatibility {
		return {
			partId,
			categoryId,
			compatible,
		}
	}

	export function clone(base: PartCompatibility): PartCompatibility {
		return cloneObject(base);
	}

	export function check(data: any): PartCompatibility {
		if (typeof data === "string") {
			return create(data);
		}

		Compatible.check(data);
		checkValue(data, "partId", "");
		checkValue(data, "categoryId", "");
		return data;
	}
}

export namespace SoftwareCompatibility {
	export function create(
		softwareId: UUID,
		definition: SoftwareType = SoftwareType.none,
		minVersion: string | null = null,
		maxVersion: string | null = null,
		compatible: Compatibility = Compatibility.compatible
	): SoftwareCompatibility {
		return {
			softwareId,
			definition,
			minVersion,
			maxVersion,
			compatible,
		}
	}

	export function clone(base: SoftwareCompatibility): SoftwareCompatibility {
		return cloneObject(base);
	}

	export function check(data: any): SoftwareCompatibility {
		if (typeof data === "string") {
			return create(data);
		}

		Compatible.check(data);
		checkValue(data, "softwareId", "");
		checkValue(data, "definition", SoftwareType.none);
		checkValue(data, "minVersion", null);
		checkValue(data, "maxVersion", null);
		return data;
	}
}
