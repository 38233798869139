import { Component, Mixins, Prop } from "vue-property-decorator";
import { EnumSelect, ReplacementAction, BvListItem } from "@/loader";

@Component
export class ReplacementActionSelect extends Mixins<EnumSelect<ReplacementAction>>(EnumSelect) {
	@Prop({ type: Boolean, default: false })
	public isPart!: boolean;

	@Prop({ type: Boolean, default: false })
	public clearable!: boolean;

	// @ts-ignore
	public get options(): BvListItem[] {
		let options = ReplacementAction.LIST.slice();
		if (this.required) {
			let none = options.find(v => !v.value);
			if (none) {
				options.remove(none);
			}
		}
		if (!this.isPart) {
			let move = options.find(v => v.value === ReplacementAction.move);
			if (move) {
				options.remove(move);
			}
		}
		return options;
	}
}
