import { checkValue } from "@/loader";


export interface LimitMachineTask {
	limitCredit: number;
	limitInsert: number;
	limitJackpot: number;
	limitPayment: number;
}

export namespace LimitMachineTask {
	export function instanceOf(value: any): value is LimitMachineTask {
		return value && typeof value === "object" && "limitCredit" in value;
	}

	export function check(data: any): LimitMachineTask {
		checkValue(data, "limitCredit", 0);
		checkValue(data, "limitInsert", 0);
		checkValue(data, "limitJackpot", 0);
		checkValue(data, "limitPayment", 0);
		return data;
	}
}
