import { BvListItem } from "@/loader";

export enum CoordinateType {
	other = 0,
	phone = 1,
	fax = 2,
	mobile = 3,
	email = 4,
	website = 5
}

export namespace CoordinateType {
	export function toString(role: CoordinateType): string {
		switch (role) {
			case CoordinateType.phone: return "Téléphone";
			case CoordinateType.fax: return "Fax";
			case CoordinateType.mobile: return "Portable";
			case CoordinateType.email: return "Email";
			case CoordinateType.website: return "Site";
			default: return "Autre";
		}
	}

	export const LIST: BvListItem[] = Object.values(CoordinateType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<CoordinateType>value) }));
}
