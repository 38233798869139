






















import { Vue, Component, Prop } from "vue-property-decorator";
import { Address } from '@/loader';

@Component
export default class AddressView extends Vue {
	// public ATTRIBUTION = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';

	@Prop({ type: Object, required: true })
	public address!: Address;

	@Prop({ default: "div" })
	public tag!: any;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	// @Prop({ type: Boolean, default: false })
	// public hideMap!: boolean;

	// public get showCountry() {
	// 	return this.address
	// 		&& this.address.country
	// 		&& this.address.country !== 'FR';
	// }

	// public get showMap() {
	// 	return !this.hideMap
	// 		&& this.address
	// 		&& this.address.place
	// 		&& this.address.place.geometry
	// 		&& this.address.place.geometry.location
	// 		&& this.address.place.geometry.location.lat !== undefined
	// 		&& this.address.place.geometry.location.lng !== undefined;
	// }

	// public get latlng() {
	// 	const lat = this.showMap
	// 		? typeof this.address.place.geometry.location.lat === "function"
	// 			? this.address.place.geometry.location.lat()
	// 			: this.address.place.geometry.location.lat
	// 		: 48.864716;
	// 	const lng = this.showmap
	// 		? typeof this.address.place.geometry.location.lng === "function"
	// 			? this.address.place.geometry.location.lng()
	// 			: this.address.place.geometry.location.lng
	// 		: 2.349014;
	// 	return [lat, lng];
	// }
}
