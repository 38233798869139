













































































import { Component, Mixins } from "vue-property-decorator";
import {
	servers,
	Company,
	createListFilter,
	DocBrand,
	DocumentList,
	Brand,
	CouchDBDocument,
	BvTableFieldArray,
	PropertyNames,
} from "@/loader";

@Component
export default class CompaniesList extends Mixins<DocumentList<Company>>(DocumentList) {
	public sort: PropertyNames<Company> = "name";
	public readonly COMPANY_STARTKEY = Company.STARTKEY;
	public readonly COMPANY_ENDKEY = Company.ENDKEY;

	public filters = {
		owners: createListFilter<DocBrand<Company>, Company>("ownerId"),
		isDisabled: createListFilter<Brand, Company>({ valueField: "disabled", title: "Désactivé" }),
	};

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "owner",
			label: "Groupe",
			sortable: true,
			formatter: (value: any, key: string, item: Company) => {
				return this.filters.owners.items.find(x => x._id === item.ownerId)?.name || "";
			},
			sortByFormatted: true,
		},
	];

	public get localStateName(): string {
		return "companies";
	}

	public get startkey(): string {
		return Company.STARTKEY;
	}

	public get endkey(): string {
		return Company.ENDKEY;
	}

	public get type(): string {
		return Company.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager || servers.isAccounting;
	}

	public getSearchableString(value: Company): string {
		return [
				value?.name?.removeDiacritics().toLowerCase() || "",
			].filter(x => !!x)
			.join(CouchDBDocument.ENDKEY_SUFFIX);
	}
}
