










































































































































































































import { Component, Mixins } from "vue-property-decorator";
import { SortableOptions } from "sortablejs";
import {
	Software,
	ValueUnit,
	servers,
	EditView,
	SoftwareType,
} from "@/loader";
import SoftwareCounterList from "../../../component/software/SoftwareCounterList.vue";
import SoftwareVersionList from "../../../component/software/SoftwareVersionList.vue";
import GameCompatibilityTable from "../../../component/game/GameCompatibilityTable.vue";
import PartCompatibilityTable from "../../../component/part/PartCompatibilityTable.vue";
import SoftwareCompatibilityTable from "../../../component/software/SoftwareCompatibilityTable.vue";

@Component({
	components: {
		SoftwareCounterList,
		SoftwareVersionList,
		GameCompatibilityTable,
		PartCompatibilityTable,
		SoftwareCompatibilityTable,
	}
})
export default class SoftwareView extends Mixins<EditView<Software>>(EditView) {
	public VALUE_UNIT_LIST = ValueUnit.LIST;

	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		brand: "",
		ref: "",
		unit: "L'unité ne peut pas être vide",
		definition: "Le type ne peut pas être vide",
	}

	public get states() {
		return {
			name: this.item && this.focused.name ? !!this.item.name : null,
			brand: this.item && this.focused.brand ? true : null,
			ref: this.item && this.focused.brand ? true : null,
			unit: this.item && this.focused.unit ? !!this.item.unit : null,
			definition: this.item && this.focused.definition ? !!this.item.definition : null,
		}
	}

	public sortableOptions: SortableOptions = {
		sort: false,
		handle: ".btn-move",
	}

	public get itemFactory(): Function | null {
		return Software.create;
	}

	public get cloneFactory(): (base: Software) => Software {
		return Software.clone;
	}

	public get checkDocFunction(): Function | null {
		return Software.check;
	}

	public get routeName(): string {
		return "software";
	}

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get hasCounters(): boolean {
		return this.item?.definition === SoftwareType.os;
	}

	public get title(): string {
		if (this.item) {
			return "Logiciel &mdash; " + this.item.name;
		}
		return "Logiciel";
	}
}
