import { BvListItem } from "@/loader";

export enum ReadingStatus {
	draft,
	control,
	valid,
	done
}

export namespace ReadingStatus {
	export function toString(readingStatus: ReadingStatus): string {
		switch (readingStatus) {
			case ReadingStatus.control: return "A Contrôler";
			case ReadingStatus.valid: return "Validée";
			case ReadingStatus.done: return "Terminée";
			case ReadingStatus.draft: return "Brouillon";
			default:
				return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(ReadingStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<ReadingStatus>value) }));
}
