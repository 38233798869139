





























import { Vue, Component, Prop, Watch, Mixins } from "vue-property-decorator";
import { Company, Mission, TaskAction, User, Task } from "@/loader";

@Component({
	inheritAttrs: true
})
export default class MissionListItem extends Vue {
	@Prop({ type: Object, required: true })
	public item!: Mission;

	@Prop({ type: [String, Object], default: "b-list-group-item" })
	public tag!: any;

	@Prop({ type: Boolean, default: false })
	public displayTech!: boolean;

	public company: Company | null = null;
	public tech: User | null = null;
	public tasks: Task[] = [];

	public get techName(): string {
		return this.tech ? User.toString(this.tech) : ""
	}

	public get vq(): boolean {
		return this.tasks.some(t => t.action === TaskAction.control) || false;
	}

	public async loadRelations() {
		this.company = await this.$db.getCompany(this.item.companyId);
		this.tech = this.displayTech ? await this.$db.getUser(this.item.techId) : null;
		this.tasks = await this.$db.queryTasksByMission(this.item._id);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue: Mission, oldValue?: Mission) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}

	@Watch("displayTech")
	public onItemChanged(newValue: boolean, oldValue?: boolean) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
