import { BvListItem } from "@/loader";

export enum MissionStatus {
	planned = 0,
	ongoing = 1,
	done = 3,
	cancelled = 4,
	control = 5,
}

export namespace MissionStatus {
	export function toString(missionstatus: MissionStatus): string {
		switch (missionstatus) {
			case MissionStatus.planned: return "Plannifiée";
			case MissionStatus.ongoing: return "En Cours";
			case MissionStatus.done: return "Terminée";
			case MissionStatus.cancelled: return "Annulée";
			case MissionStatus.control: return "A contrôler";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(MissionStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<MissionStatus>value) }));

	export const TRASHABLE = [MissionStatus.planned];
	export const DELETABLE = [MissionStatus.planned];
	export const FINISHABLE = [MissionStatus.planned, MissionStatus.ongoing];
	export const CANCELLABLE = [MissionStatus.planned, MissionStatus.ongoing];
}
