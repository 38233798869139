import { CreateElement } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BVariant, IconConfig } from "@/loader";

@Component({
	inheritAttrs: true
})
export class EnumBadge<T extends number> extends Vue {
	@Prop({ required: true })
	public value!: T;

	@Prop({ type: Boolean, default: false })
	public hideIcon!: boolean;

	@Prop({ type: Boolean, default: false })
	public iconOnly!: boolean;

	@Prop({ type: Boolean, default: false })
	public shortText!: boolean;

	public get variant(): BVariant {
		throw this.$options.name + ": variant must be inherited";
	}

	public get icon(): IconConfig | null {
		throw this.$options.name + ": icon must be inherited";
	}

	public get title(): string {
		throw this.$options.name + ": title must be inherited";
	}

	public render(h: CreateElement) {
		return h("b-badge", {
			props: {
				variant: this.variant,
			},
			attrs: {
				title: this.iconOnly ? this.title : undefined
			},
		}, [
			!this.hideIcon && this.icon ? h("fa", { props: this.icon }) : null,
			!this.iconOnly ? " " + this.title : null
		]);
	}
}
