import { decodeJwt, decodeProtectedHeader, JWTVerifyResult, JWTVerifyOptions, JWTHeaderParameters, errors } from "jose";

const minute = 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const year = day * 365.25;
const REGEX = /^(\d+|\d+\.\d+) ?(seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)$/i;
const secs = (str: string) => {
    const matched = REGEX.exec(str);
    if (!matched) {
        throw new TypeError("Invalid time period format");
    }
    const value = parseFloat(matched[1]);
    const unit = matched[2].toLowerCase();
    switch (unit) {
        case "sec":
        case "secs":
        case "second":
        case "seconds":
        case "s":
            return Math.round(value);
        case "minute":
        case "minutes":
        case "min":
        case "mins":
        case "m":
            return Math.round(value * minute);
        case "hour":
        case "hours":
        case "hr":
        case "hrs":
        case "h":
            return Math.round(value * hour);
        case "day":
        case "days":
        case "d":
            return Math.round(value * day);
        case "week":
        case "weeks":
        case "w":
            return Math.round(value * week);
        default:
            return Math.round(value * year);
    }
};

const epoch = (date: Date) => Math.floor(date.getTime() / 1000);

const normalizeTyp = (value: string) => value.toLowerCase().replace(/^application\//, "");

const checkAudiencePresence = (audPayload: string | string[], audOption: string[]) => {
	if (typeof audPayload === "string") {
		return audOption.includes(audPayload);
	}
	if (Array.isArray(audPayload)) {
		return audOption.some(Set.prototype.has.bind(new Set(audPayload)));
	}
	return false;
};

/** Parse a JWT token */
export function getJwt(token: string): JWTVerifyResult {
	return {
		protectedHeader: <JWTHeaderParameters>decodeProtectedHeader(token),
		payload: decodeJwt(token)
	};
}

/**
 * Parse a JWT token and check it's claims
 */
export function checkJwt(token: string, options: JWTVerifyOptions = {}): JWTVerifyResult {
	const jwt = getJwt(token);

	const { typ } = options;
	if (typ &&
		(
			typeof jwt.protectedHeader.typ !== "string"
			|| normalizeTyp(jwt.protectedHeader.typ) !== normalizeTyp(typ)
		)
	) {
		throw new errors.JWTClaimValidationFailed(`unexpected "typ" JWT header value`, "typ", "check_failed");
	}
	if (typeof jwt.payload !== "object" || !jwt.payload) {
		throw new errors.JWTInvalid("JWT Claims Set must be a top-level JSON object");
	}
	const { issuer } = options;
	if (issuer && !(Array.isArray(issuer) ? issuer : [issuer]).includes(jwt.payload.iss || "")) {
		throw new errors.JWTClaimValidationFailed(`unexpected "iss" claim value`, "iss", "check_failed");
	}
	const { subject } = options;
	if (subject && jwt.payload.sub !== subject) {
		throw new errors.JWTClaimValidationFailed(`unexpected "sub" claim value`, "sub", "check_failed");
	}
	const { audience } = options;
	if (audience &&
		!checkAudiencePresence(jwt.payload.aud || "", typeof audience === "string" ? [audience] : audience)) {
		throw new errors.JWTClaimValidationFailed(`unexpected "aud" claim value`, "aud", "check_failed");
	}
	let tolerance;
	switch (typeof options.clockTolerance) {
		case "string":
			tolerance = secs(options.clockTolerance);
			break;
		case "number":
			tolerance = options.clockTolerance;
			break;
		case "undefined":
			tolerance = 0;
			break;
		default:
			throw new TypeError("Invalid clockTolerance option type");
	}
	const { currentDate } = options;
	const now = epoch(currentDate || new Date());
	if (jwt.payload.iat !== undefined || options.maxTokenAge) {
		if (typeof jwt.payload.iat !== "number") {
			throw new errors.JWTClaimValidationFailed(`"iat" claim must be a number`, "iat", "invalid");
		}
		if (jwt.payload.exp === undefined && jwt.payload.iat > now + tolerance) {
			throw new errors.JWTClaimValidationFailed(`"iat" claim timestamp check failed (it should be in the past)`, "iat", "check_failed");
		}
	}
	if (jwt.payload.nbf !== undefined) {
		if (typeof jwt.payload.nbf !== "number") {
			throw new errors.JWTClaimValidationFailed(`"nbf" claim must be a number`, "nbf", "invalid");
		}
		if (jwt.payload.nbf > now + tolerance) {
			throw new errors.JWTClaimValidationFailed(`"nbf" claim timestamp check failed`, "nbf", "check_failed");
		}
	}
	if (jwt.payload.exp !== undefined) {
		if (typeof jwt.payload.exp !== "number") {
			throw new errors.JWTClaimValidationFailed(`"exp" claim must be a number`, "exp", "invalid");
		}
		if (jwt.payload.exp <= now - tolerance) {
			throw new errors.JWTExpired(`"exp" claim timestamp check failed`, "exp", "check_failed");
		}
	}
	if (options.maxTokenAge) {
		const age = now - (jwt.payload.iat || 0);
		const max = typeof options.maxTokenAge === "number" ? options.maxTokenAge : secs(options.maxTokenAge);
		if (age - tolerance > max) {
			throw new errors.JWTExpired(`"iat" claim timestamp check failed (too far in the past)`, "iat", "check_failed");
		}
		if (age < 0 - tolerance) {
			throw new errors.JWTClaimValidationFailed(`"iat" claim timestamp check failed (it should be in the past)`, "iat", "check_failed");
		}
	}

	return jwt;
}
