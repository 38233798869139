





































import { Vue, Component } from "vue-property-decorator";
import { User, servers, Role, DEBUG } from "@/loader";
import { BvTableFieldArray } from "bootstrap-vue";

@Component
export default class UsersList extends Vue {
	public users: User[] = [];
	public fields: BvTableFieldArray = [
		{
			key: 'name',
			label: 'Identifiant',
			sortable: true,
		},
		{
			key: 'lastName',
			label: 'Nom',
			sortable: true,
		},
		{
			key: 'firstName',
			label: 'Prénom',
			sortable: true,
		},
		{
			key: 'email',
			label: 'Email',
			sortable: true,
		},
		{
			key: 'roles',
			label: 'Rôles',
			sortable: true,
			formatter(value: Role[]) {
				return value.map(r => Role.toString(r)).join(", ");
			}
		},
	];
	public sortBy: string = "name";
	public sortDesc: boolean = false;
	public filter: string | null = null;

	public get localStateName(): string {
		return "users";
	}

	public get isRH() {
		return servers.isRH;
	}

	public async mounted() {
		if (servers.selected && servers.selected.settings.token) {
			try {
				let response = await fetch(servers.selected.auth, {
					method: "POST",
					body: JSON.stringify({
						action: "list"
					}),
					headers: {
						Authorization: `Bearer ${servers.selected.settings.token}`
					}
				});
				let result = await response.json();
				this.users = result.users;
			} catch (error) {
				DEBUG && console.error(error);
				this.users = [];
			}
		}
	}
}
