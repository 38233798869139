import { CompanyTask, ReadingTask, UUID } from "@/loader";

export interface SellTask extends ReadingTask, CompanyTask {
}

export namespace SellTask {
	export function instanceOf(value: any): value is SellTask {
		return ReadingTask.instanceOf(value)
			&& CompanyTask.instanceOf(value);
	}

	export function check(data: any): SellTask {
		CompanyTask.check(data);
		ReadingTask.check(data);
		return data;
	}

	export function create(companyId: UUID = ""): SellTask {
		return {
			companyId,
			reading: ""
		};
	}
}
