


















































































































import { Vue, Component, Mixins, Watch } from "vue-property-decorator";
import {
	EditComponent,
	MachineGame,
	Machine,
	Game,
	Denomination,
	percent,
	flagMatch,
	GameVariant,
	ConfigStatus,
	toaster,
	Compatibility
} from "@/loader";
import GameBetSelect from "../game/GameBetSelect";
import GameDenominationSelect from "../game/GameDenominationSelect";
import GameLineSelect from "../game/GameLineSelect";
import GameRateSelect from "../game/GameRateSelect";

@Component({
	components: {
		GameBetSelect,
		GameDenominationSelect,
		GameLineSelect,
		GameRateSelect,
	}
})
export default class MachineGameEditor extends Mixins<EditComponent<MachineGame, Machine>>(EditComponent) {
	public game: Game | null = null;
	public pack: Game | null = null;

	public get compatibilities(): GameVariant[] {
		return this.game?.variants.filter(v =>
			(v.denos && this.item?.deno && flagMatch(v.denos, this.item.deno))
			|| (v.rates.length && this.item?.rate?.rate && v.rates.find(r => r.rate === this.item.rate.rate))
			|| (v.lines.length && this.item?.line && v.lines.includes(this.item.line))
			|| (v.antebets.length && this.item?.antebet && v.antebets.includes(this.item.antebet))
			|| (v.maxbets.length && this.item?.maxbet && v.maxbets.includes(this.item.maxbet))
			|| (v.osId && this.parent?.osId && v.osId === this.parent.osId)
		) || [];
	}

	public get isValid() {
		return this.compatibilities.map(v => {
			let deno = v.denos ? flagMatch(v.denos, this.item?.deno) : null;
			let rate = v.rates.length ? v.rates.find(r => r.rate === this.item?.rate?.rate) : null;
			let line = v.lines.length ? v.lines.includes(this.item?.line) : null;
			let antebet = v.antebets.length ? v.antebets.includes(this.item?.antebet) : null;
			let maxbet = v.maxbets.length ? v.maxbets.includes(this.item?.maxbet) : null;
			let osId = v.osId ? v.osId === this.parent?.osId : null;

			let result = {
				deno: v.compatible === Compatibility.incompatible ? !deno : deno,
				rate: v.compatible === Compatibility.incompatible ? !rate : rate,
				line: v.compatible === Compatibility.incompatible ? !line : line,
				antebet: v.compatible === Compatibility.incompatible ? !antebet : antebet,
				maxbet: v.compatible === Compatibility.incompatible ? !maxbet : maxbet,
				osId: v.compatible === Compatibility.incompatible ? !osId : osId,
			}
			result.isValid = Object.values(result).every(v => v !== false);
			return result;
		});
	}

	public async loadRelations() {
		this.game = await this.$db.getGame(this.item?.gameId);
		this.pack = await this.$db.getGame(this.item?.packId);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item", { deep: true })
	public itemChanged(newValue: MachineGame | null, oldValue?: MachineGame | null) {
		if (newValue !== oldValue || this.game?._id !== newValue?.gameId || this.pack?._id !== newValue?.packId) {
			this.loadRelations();
		}
	}
}
