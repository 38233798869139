


















































































import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import { TutorialComponent } from "@/loader";
import TutorialWelcome from "../component/tutorial/TutorialWelcome.vue";
import TutorialOffline from "../component/tutorial/TutorialOffline.vue";
import TutorialSecurity from "../component/tutorial/TutorialSecurity.vue";
import TutorialCreateAccount from "../component/tutorial/TutorialCreateAccount.vue";
import TutorialCreateServer from "../component/tutorial/TutorialConnectServer.vue";

@Component({
	components: {
		TutorialOffline,
		TutorialWelcome,
		TutorialSecurity,
		TutorialCreateAccount,
		TutorialCreateServer,
	}
})
export default class TutorialView extends Vue {
	public page: number = 1;
	public title: string = "";
	public icon: string | string[] = "";
	public componentCanNext: boolean = true;
	public componentCanPrev: boolean = true;

	@Prop({ type: Boolean, default: false })
	public canLogin!: boolean;

	@Ref()
	public readonly tuto!: TutorialComponent;

	public get minPage(): number {
		return 1;
	}

	public get maxPage(): number {
		return 5;
	}

	public get component() {
		switch (this.page) {
			case 1:
				return TutorialWelcome;
			case 2:
				return TutorialOffline;
			case 3:
				return TutorialSecurity;
			case 4:
				return TutorialCreateAccount;
			case 5:
				return TutorialCreateServer;
			default:
				return "div";
		}
	}

	public get canPrev(): boolean {
		return this.page > this.minPage;
	}

	public get canNext(): boolean {
		return this.page < (this.maxPage - 1);
	}

	public prev() {
		if (!this.canPrev) {
			return;
		}
		this.page--;
	}

	public next() {
		if (!this.canNext) {
			return;
		}
		this.page++;
	}

	public initPage({ title, icon }: { title: string, icon: string | string[] }) {
		this.title = title || "";
		this.icon = icon || "";
	}

	public mounted() {
		this.$watch(() => {
			return this.tuto?.canPrev;
		}, (newValue, oldValue) => {
			this.componentCanPrev = newValue || false;
		});

		this.$watch(() => {
			return this.tuto?.canNext;
		}, (newValue, oldValue) => {
			this.componentCanNext = newValue || false;
		});
	}
}
