import dayjs from "dayjs";
import {
	Content,
	DynamicContent,
	ContextPageSize,
	StyleDictionary,
	TableCell,
	TDocumentDefinitions
} from "pdfmake/interfaces";
import {
	AppSettings,
	number,
	percent,
	EventType,
	Machine,
	Reading,
	ReadingTask,
	ReferenceTask,
	servers,
	Task,
	UUID,
	Game,
	Denomination,
	TaskAction
} from "@/loader";

const STYLES: StyleDictionary  = {
	mainheader: {
		fontSize: 10,
		bold: true,
		fillColor: "green",
		color: "white",
		alignment: "center",
	},
	subheader: {
		fontSize: 10,
		fillColor: "green",
		fillOpacity: 0.5,
		color: "white",
		alignment: "center",
	},
	header: {
		fontSize: 8,
		alignment: "center",
	},
	info_cell: {
		fontSize: 6,
		alignment: "center",
	},
	value_cell: {
		fontSize: 6,
	},
	number_cell: {
		fontSize: 6,
		alignment: "right",
	},
	ref_cell: {
		fontSize: 6,
		alignment: "center",
	},
};

const TABLE_HEIGHTS_1 = [
	10,
	40,
	10,
	10,
	10,
	30,
	10
];

const TABLE_WIDTHS_1 = [
	35,
	35,
	"auto", //35
	"*", // 80
	"*", // 80
	"*", // 80
	40,
	40,
	"*", // 80
	45,
	47,
	45,
	47,
	8,
];

const TABLE_HEADER_1: TableCell[][] = [
	[
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
	],
	[
		{ text: "\n\n\n\nDATE\nHEURE", colSpan: 1, rowSpan: 5, style: "mainheader", border: [true, true, true, false] },
		{ text: "IDENTIFICATION MACHINE OU POSTE DE JEU", colSpan: 2, rowSpan: 1, style: "mainheader" },
		"",
		{ text: "COMPTEUR DES ENTREES", colSpan: 1, rowSpan: 1, style: "mainheader" },
		{ text: "COMPTEUR DES RECETTES", colSpan: 1, rowSpan: 1, style: "mainheader" },
		{ text: "COMPTEUR DES SORTIES", colSpan: 1, rowSpan: 1, style: "mainheader" },
		{ text: "COMPTEUR DES GAINS", colSpan: 2, rowSpan: 1, style: "mainheader" },
		"",
		{ text: "COMPTEUR DES BILLETS ENTRANTS", colSpan: 1, rowSpan: 1, style: "mainheader" },
		{ text: "COMPTEUR DES TICKETS TITO", colSpan: 2, rowSpan: 1, style: "mainheader" },
		"",
		{ text: "COMPTEUR DES CARTES OU AUTRES", colSpan: 2, rowSpan: 1, style: "mainheader" },
		"",
		{ text: "", border: [false, false, false, false], rowSpan: 6 },
	],
	[
		{ text: "", colSpan: 1, rowSpan: 1, borderColor: "green", style: "mainheader" },
		{ text: "N° MAS ou\nN° Table", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Numéro de série ou de l'Appareil", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Électroniques", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Électroniques", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Électroniques", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Électroniques", colSpan: 2, rowSpan: 1, style: "subheader" },
		"",
		{ text: "Électroniques", colSpan: 1, rowSpan: 3, style: "header" },
		{ text: "Électroniques", colSpan: 2, rowSpan: 1, style: "subheader" },
		"",
		{ text: "Électroniques", colSpan: 2, rowSpan: 1, style: "subheader" },
		"",
		"",
	],
	[
		"",
		"",
		"",
		"",
		"",
		"",
		{ text: "Jackpots", colSpan: 1, rowSpan: 2, style: "header" },
		{ text: "Lots cumulés", colSpan: 1, rowSpan: 2, style: "header" },
		"",
		{ text: "IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "OUT", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "OUT", colSpan: 1, rowSpan: 1, style: "header" },
		"",
	],
	[
		"",
		"",
		"",
		"",
		"",
		"",
		"",
		"",
		"",
		{ text: "PROMO IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "PROMO OUT", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "PROMO IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "PROMO OUT", colSpan: 1, rowSpan: 1, style: "header" },
		"",
	],
	[
		"",
		{ text: "V1 ou V2", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "Emplace-ment ou N°Casino", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "Électromécaniques", colSpan: 1, rowSpan: 2, style: "header" },
		{ text: "Électromécaniques", colSpan: 1, rowSpan: 2, style: "header" },
		{ text: "Électromécaniques", colSpan: 1, rowSpan: 2, style: "header" },
		{ text: "Électromécaniques\nJackpots et lots cumulés", colSpan: 2, rowSpan: 2, style: "header" },
		"",
		{ text: "Électromécaniques", colSpan: 1, rowSpan: 2, style: "header" },
		{ text: "Mécaniques TITO PROMO", colSpan: 2, rowSpan: 1, style: "subheader" },
		"",
		{ text: "Électromécaniques (Tables)", colSpan: 2, rowSpan: 1, style: "subheader" },
		"",
		"",
	],
	[
		{ text: "Nom du modèle", colSpan: 3, rowSpan: 1, style: "header" },
		"",
		"",
		"",
		"",
		"",
		"",
		"",
		"",
		{ text: "IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "OUT", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "IN", colSpan: 1, rowSpan: 1, style: "header" },
		{ text: "OUT", colSpan: 1, rowSpan: 1, style: "header" },
		"",
	],
];

const TABLE_HEIGHTS_2 = [
	10,
	40,
	30,
	25,
	25
];

const TABLE_WIDTHS_2 = [
	10,
	55,
	55,
	50,
	80,
	"*",
	80,
	80
];

const TABLE_HEADER_2: TableCell[][] = [
	[
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V"
	],
	[
		{ text: "", border: [false, false, false, false], rowSpan: 4 },
		{ text: "OPTIONS ET LIMITES", style: "mainheader", colSpan: 2 },
		"",
		{ text: "MISES", style: "mainheader", rowSpan: 2 },
		{ text: "REDISTRIBUTION", style: "mainheader", rowSpan: 2 },
		{ text: "MOTIF D'INTERVENTION", style: "mainheader", rowSpan: 4 },
		{ text: "SIGNATURES", style: "mainheader", colSpan: 2 },
		"",
	],
	[
		"",
		{ text: "ENTRANTS", style: "subheader" },
		{ text: "SORTANTS", style: "subheader", },
		"",
		"",
		"",
		{ text: "Mécanicien du casino ou technicien de la SFM ou de la sté chargée de la gestion des systèmes de tickets, des cartes de paiement précrédités", style: "header", rowSpan: 3 },
		{ text: "MEMBRE DU COMITÉ DE DIRECTION RESPONSABLE", style: "header", rowSpan: 3 },
	],
	[
		"",
		{ text: "Crédits", style: "header" },
		{ text: "Jackpots", style: "header" },
		{ text: "Unitaire ou Multi-Dénos", style: "header", rowSpan: 2 },
		{ text: "Taux ou *Moyenne des taux", style: "header" },
		"",
		"",
		"",
	],
	[
		"",
		{ text: "Insertions", style: "header" },
		{ text: "Paiement Max.", style: "header" },
		"",
		{ text: "% PROG ou *moyenne", style: "header" },
		"",
		"",
		"",
	],
];

function footer(currentPage: number, pageCount: number, pageSize: ContextPageSize): Content {
	const realCount = pageCount / 2;
	const realCurrent = currentPage > realCount ? (currentPage - realCount) + "bis" : currentPage;
	return {
		fontSize: 8,
		margin: [8, 4],
		color: "gray",
		columns: [
			{
				width: "*",
				text: `SFMPro v3, généré par ${servers.author} le ${dayjs().format("DD/MM/YYYY à HH:mm")}`,
			},
			{
				width: "auto",
				noWrap: true,
				text: realCurrent + " / " + realCount,
				alignment: "right",
			},
		],
	};
}

function borderStrToArray(borders: string) {
	return [
		borders.indexOf("l") > -1,
		borders.indexOf("t") > -1,
		borders.indexOf("r") > -1,
		borders.indexOf("b") > -1
	];
}

function formatNumber(value: number) {
	return number.format(value).replace(/\u202f|\xa0/g, " ");
}

function formatPercent(value: number) {
	return percent.format(value / 100).replace(/\u202f|\xa0/g, " ");
}

function getCounterCell(reading: Reading | undefined, counterId: UUID, cell: Partial<TableCell>): TableCell {
	let result = {
		text: "-",
		...cell,
	};

	let counter = reading?.values.find(v => v.counterId === counterId);
	if (counter) {
		result.text = counter.unavailable ? "ILLISIBLE" : formatNumber(counter.value);
	}
	return result;
}


export function generateTaskReport(tasks: Task[], machines: Machine[], readings: Reading[], games: Game[], settings: AppSettings | null) {
	if (!settings) {
		return null;
	}
	const rows: TableCell[][] = [];
	const rows_2: TableCell[][] = [];
	tasks = tasks.sort((a, b) => {
		if (!a && !b) {
			return 0;
		}
		if (!a && b) {
			return -1;
		}
		if (a && !b) {
			return 1;
		}

		let result = TaskAction.sort(a.action, b.action);
		if (result !== 0) {
			return result;
		}

		let aRef = ReferenceTask.instanceOf(a.data) ? a.data.ref : (machines.find(m => m._id === a.machineId)?.ref || "");
		let bRef = ReferenceTask.instanceOf(b.data) ? b.data.ref : (machines.find(m => m._id === b.machineId)?.ref || "");

		if (Number(aRef) && Number(bRef)) {
			result = Number(aRef) - Number(bRef);
		}
		return aRef.localeCompare(bRef);
	});

	tasks.forEach((t, i) => {
		let even = i % 2 === 0;
		let event = t.events.find(e => e.type === EventType.done) || t.events[t.events.length -1];
		let machine = machines.find(m => m._id === t.machineId);
		let rate = Machine.getRate(machine || null);
		let readingId = ReadingTask.instanceOf(t.data) ? t.data.reading : readings.filter(r => r.machineId === t.machineId).sort(Reading.compareDesc).shift()?._id;
		let reading = readings.find(r => r._id === readingId);
		let defaultCell: Partial<TableCell> = { fillColor: even ? "#eee" : undefined };
		let infoCell: Partial<TableCell> = { ...defaultCell, style: "info_cell", noWrap: true };
		let valueCell: Partial<TableCell> = { ...defaultCell, style: "value_cell" };
		let numberCell: Partial<TableCell> = { ...defaultCell, style: "number_cell" };
		let refCell: Partial<TableCell> = { ...defaultCell, style: "ref_cell" };

		rows.push([
			{ text: dayjs(event.date).format("DD/MM/YY"), ...infoCell, border: borderStrToArray("lt") },
			{ text: machine?.ref || "?", ...infoCell, border: borderStrToArray("t") },
			{ text: machine?.serial || "?", ...infoCell, border: borderStrToArray("tr") },
			getCounterCell(reading, settings.taskReport.in_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.revenue_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.out_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.profit_jackpot_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.profit_lots_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.bill_in_elec, { ...numberCell, rowSpan: 2 }),
			getCounterCell(reading, settings.taskReport.tito_in_elec, numberCell),
			getCounterCell(reading, settings.taskReport.tito_out_elec, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_in_elec, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_out_elec, numberCell),
			{ text: machine?.ref?.split("").join("\n") || "?", ...refCell, rowSpan: 3 },
		]);
		rows.push([
			{ text: dayjs(event.date).format("HH:mm"), ...infoCell, border: borderStrToArray("l") },
			{ text: machine?.incomeCounterStandard, ...infoCell, border: borderStrToArray("") },
			{ text: machine?.location || "?", ...infoCell, border: borderStrToArray("r") },
			"",
			"",
			"",
			"",
			"",
			"",
			getCounterCell(reading, settings.taskReport.tito_promo_in_elec, numberCell),
			getCounterCell(reading, settings.taskReport.tito_promo_out_elec, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_promo_in_elec, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_promo_out_elec, numberCell),
			"",
		]);
		rows.push([
			{ text: Machine.getName(machine, games), ...infoCell, colSpan: 3, border: borderStrToArray("lrb") },
			"",
			"",
			getCounterCell(reading, settings.taskReport.in_meca, numberCell),
			getCounterCell(reading, settings.taskReport.revenue_meca, numberCell),
			getCounterCell(reading, settings.taskReport.out_meca, numberCell),
			getCounterCell(reading, settings.taskReport.profit_meca, { ...numberCell, colSpan: 2 }),
			"",
			getCounterCell(reading, settings.taskReport.bill_in_meca, numberCell),
			getCounterCell(reading, settings.taskReport.tito_in_meca, numberCell),
			getCounterCell(reading, settings.taskReport.tito_out_meca, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_in_meca, numberCell),
			getCounterCell(reading, settings.taskReport.cashless_out_meca, numberCell),
			"",
		]);

		rows_2.push([
			{ text: machine?.ref?.split("").join("\n") || "?", ...refCell, rowSpan: 2 },
			{ text: machine ? formatNumber(machine.limitCredit || 0) : "?", ...numberCell },
			{ text: machine ? formatNumber(machine.limitJackpot || 0) : "?", ...numberCell },
			{ text: Denomination.toString(Machine.getDenos(machine)), ...valueCell, rowSpan: 2 },
			{ text: rate ? formatPercent(rate.rate) + (rate.isAverage ? "*" : "") : "?", ...numberCell },
			{ text: TaskAction.toString(t.action) + (t.description ? " : " + t.description : ""), ...valueCell, rowSpan: 2 },
			{ text: event.author, ...valueCell, rowSpan: 2 },
			{ text: "", ...valueCell, rowSpan: 2 },
		]);
		rows_2.push([
			"",
			{ text: machine ? formatNumber(machine.limitInsert || 0) : "?", ...numberCell },
			{ text: machine ? formatNumber(machine.limitPayment || 0) : "?", ...numberCell },
			"",
			{ text: rate && rate.external ? formatPercent(rate.external) + (rate.isAverage ? "*" : "") : "-", ...numberCell },
			"",
			"",
			"",
		]);
	});

	const def: TDocumentDefinitions = {
		pageSize: "A4",
		pageOrientation: "landscape",
		pageMargins: [10, 10, 10, 20],
		styles: STYLES,
		footer,
		content: [
			{
				table: {
					// dontBreakRows: true,
					headerRows: TABLE_HEADER_1.length,
					widths: TABLE_WIDTHS_1,
					heights: [...TABLE_HEIGHTS_1, ...rows.map(() => 8)],
					body: [...TABLE_HEADER_1, ...rows]
				}
			},
			{
				table: {
					dontBreakRows: true,
					headerRows: TABLE_HEADER_2.length,
					widths: TABLE_WIDTHS_2,
					heights: [...TABLE_HEIGHTS_2, ...rows_2.map(() => 14)],
					body: [...TABLE_HEADER_2, ...rows_2]
				},
				pageBreak: 'before'
			}
		]
	};

	return def;
}

