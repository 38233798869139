import Vue from "vue";
import {
	checkArrayValue,
	checkValue,
	cloneObject,
	CompanyTask,
	ConfigStatus,
	ControllerReplacement,
	CouchDBDocument,
	DEBUG,
	Denomination,
	ErpObject,
	Event,
	EventLinks,
	Events,
	EventType,
	Game,
	GameRate,
	GameReplacement,
	LimitMachineTask,
	LocationTask,
	MachineConfig,
	MachineController,
	MachineControllerTask,
	MachineGame,
	MachineGameTask,
	MachinePart,
	MachinePartTask,
	MachineSoftware,
	MachineSoftwareTask,
	MachineStatus,
	MachineType,
	Named,
	PartReplacement,
	ReferenceTask,
	ReplacementAction,
	SoftwareReplacement,
	SoftwareType,
	Task,
	TaskAction,
	UUID,
	ValueUnit,
} from "@/loader";

export type IncomeCounterStandards = "V1" | "V2" | "FR";

export const INCOME_COUNTER_STANDARDS = ["V1", "V2", "FR"];

export interface Machine extends CouchDBDocument, Events, Named, ErpObject {
	type: "machine"
	ownerId: UUID
	platformId: UUID
	osId: UUID
	definition: MachineType
	status: MachineStatus
	unit: ValueUnit
	serial: string
	ref: string | null
	config: MachineConfig
	location: string | null
	limitCredit: number | null
	limitInsert: number | null
	limitJackpot: number | null
	limitPayment: number | null
	incomeCounterStandard: IncomeCounterStandards
	controllers: MachineController[]
	games: MachineGame[]
	parts: MachinePart[]
	softwares: MachineSoftware[]
}

export namespace Machine {
	export const TYPE = "machine";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Machine {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function getId(serial: string): UUID {
		return STARTKEY + serial;
	}

	export function create(
		serial: string,
		name: string = "",
		definition: MachineType = MachineType.mas,
		status: MachineStatus = MachineStatus.stocked,
		config: MachineConfig = 0,
		unit: ValueUnit = ValueUnit.credit,
		ref: string | null = null,
		location: string | null = null,
		ownerId: string = "",
		platformId: string = "",
		osId: string = "",
		limitCredit: number | null = null,
		limitInsert: number | null = null,
		limitJackpot: number | null = null,
		limitPayment: number | null = null,
	): Machine {
		return {
			_id: getId(serial),
			_rev: "",
			type: TYPE,
			ownerId,
			platformId,
			osId,
			erpId: null,
			erpUpdateDate: null,
			definition,
			status,
			config,
			unit,
			serial,
			ref,
			location,
			name,
			limitCredit,
			limitInsert,
			limitJackpot,
			limitPayment,
			incomeCounterStandard: "V1",
			controllers: [],
			games: [],
			parts: [],
			softwares: [],
			events: [],
		}
	}

	export function clone(serial: string, base: Machine): Machine {
		let result = cloneObject(base, {
			_id: getId(serial),
			serial,
			status: MachineStatus.stocked,
			controllers: base.controllers.map(MachineController.clone),
			games: base.games.map(MachineGame.clone),
			parts: base.parts.map(MachinePart.clone),
			softwares: base.softwares.map(MachineSoftware.clone),
		});
		if (!base.name) {
			base.name = "";
		}
		return result;
	}

	export function check(data: any): Machine {
		CouchDBDocument.check(data, TYPE);
		ErpObject.check(data);
		checkValue(data, "ownerId", "");
		checkValue(data, "platformId", "");
		checkValue(data, "osId", "");
		checkValue(data, "definition", MachineType.mas);
		checkValue(data, "status", MachineStatus.stocked);
		checkValue(data, "config", 0);
		checkValue(data, "unit", ValueUnit.credit);
		checkValue(data, "serial", null);
		checkValue(data, "ref", null);
		checkValue(data, "location", null);
		checkValue(data, "limitCredit", null);
		checkValue(data, "limitInsert", null);
		checkValue(data, "limitJackpot", null);
		checkValue(data, "limitPayment", null);
		checkValue(data, "incomeCounterStandard", "V1");
		checkArrayValue(data, "controllers", MachineController.check);
		checkArrayValue(data, "games", MachineGame.check);
		checkArrayValue(data, "parts", MachinePart.check);
		checkArrayValue(data, "softwares", MachineSoftware.check);
		return data;
	}

	export function setSearchable(data: Machine): Machine {
		Vue.set(data, "searchable", [
			data.serial.removeDiacritics().toLowerCase(),
			data.name && data.name.removeDiacritics().toLowerCase() || "",
			data.ref && data.ref.removeDiacritics().toLowerCase() || "",
			data.location && data.location.removeDiacritics().toLowerCase() || "",
		].filter(x => !!x).join(CouchDBDocument.ENDKEY_SUFFIX));
		return data;
	}

	export function idToSerial(id?: string | null) {
		return (id || "").replace(STARTKEY, "");
	}

	export function getDenos(machine?: Machine | null): Denomination | null {
		if (!machine) {
			return null;
		}
		return Denomination.fromArray(machine.games.filter(x => x.status === ConfigStatus.active).map(mg => mg.deno));
	}

	export function getRate(machine?: Machine | null): GameRate | null {
		if (!machine) {
			return null;
		}
		let machineGames = machine.games.filter(x => x.status === ConfigStatus.active);
		const rates = machineGames.map(mg => MachineGame.getRate(mg, machine.controllers));
		return GameRate.create(
			"Global",
			rates.reduce((result, game) => result + (Number(game.rate) || NaN), 0) / rates.length,
			rates.reduce((result, game) => result + (game.external || NaN), 0) / rates.length,
			machineGames.length > 1
		);
	}

	export function findPart(id: UUID | null, parts: MachinePart[], parent: MachinePart | null = null): { part: MachinePart | null, parent: MachinePart | null } {
		if (!id) {
			return { part: null, parent: null };
		}
		for (const part of parts) {
			if (part.id === id) {
				return { part, parent };
			}
			if (part.children.length) {
				let result = findPart(id, part.children, part);
				if (result) {
					return result;
				}
			}
		}
		return { part: null, parent: null };
	}

	export function findPartByCategories(categories: UUID | UUID[], parts: MachinePart[], status: ConfigStatus | ConfigStatus[] = []): MachinePart[] {
		if (!categories || (Array.isArray(categories) && !categories.length)) {
			return [];
		}
		if (!Array.isArray(categories)) {
			categories = [categories];
		}
		if (!Array.isArray(status)) {
			status = [status];
		}
		let result = [];
		for (const part of parts) {
			if (part.categoryId && categories.includes(part.categoryId) && (!status.length || status.includes(part.status))) {
				result.push(part);
			}
			if (part.children.length) {
				result.push(...findPartByCategories(categories, part.children, status));
			}
		}
		return result;
	}

	export function getName(machine?: Machine, games?: Game[], short: boolean = false): string {
		if (!machine) {
			return "";
		}
		if (machine.name) {
			return machine.name;
		}

		let _games = MachineGame.getRepresentingGames(machine.games, games);
		return _games.map(g => Game.instanceOf(g) && g.name || "").join(" - ");
	}

	function applyReplacementTaskToMachine<T extends MachineController | MachineGame | MachinePart | MachineSoftware, U extends ControllerReplacement | GameReplacement | PartReplacement | SoftwareReplacement>(
		replacements: U[],
		array: T[],
		clone: (replacement: U) => T,
		modify: (item: T, replacement: U) => void,
		author: string,
		links?: EventLinks
	) {
		replacements.forEach(replacement => {
			if (replacement.action === ReplacementAction.none) {
				return;
			}
			if (replacement.action === ReplacementAction.add) {
				let newItem = clone(replacement);
				newItem.status = ConfigStatus.active;
				newItem.events.push(Event.create(author, EventType.installed, "", null, null, links));
				array.push(newItem);
				return;
			}
			let oldItem = array.find(x => x.id === replacement.oldId);
			if (!oldItem) {
				return;
			}
			if (replacement.action === ReplacementAction.remove) {
				DEBUG && console.log("MachinePartTask remove", replacement);
				oldItem.status = ConfigStatus.disabled;
				oldItem.events.push(Event.create(author, EventType.deactivated, "", null, null, links));
				return;
			} else if (replacement.action === ReplacementAction.replace) {
				DEBUG && console.log("MachinePartTask replace", replacement);
				let newItem = clone(replacement);
				newItem.status = ConfigStatus.active;
				newItem.events.push(Event.create(author, EventType.installed, "", null, null, links));
				array.push(newItem);
				oldItem.status = ConfigStatus.disabled;
				oldItem.events.push(Event.create(author, EventType.deactivated, "", null, null, links));
				return;
			} else if (replacement.action === ReplacementAction.modify) {
				DEBUG && console.log("MachinePartTask modify", replacement);
				let original = Object.assign({}, oldItem);
				oldItem.status = ConfigStatus.active;
				modify(oldItem, replacement);
				oldItem.events.push(Event.create(author, EventType.modified, "", original, oldItem, links));
				return;
			}
		});
	}

	export function applyTaskToMachine(task: Task, machine: Machine, author: string) {
		if (!task) {
			return;
		}
		if (!machine) {
			return;
		}
		const original = Object.assign({}, machine);
		const links = {
			task: task._id,
			mission: task.missionId,
		};

		if (CompanyTask.instanceOf(task.data)) {
			DEBUG && console.log("CompanyTask", task.data);
			machine.ownerId = task.data.companyId;
		}

		if (LimitMachineTask.instanceOf(task.data)) {
			DEBUG && console.log("LimitMachineTask", task.data);
			machine.limitCredit = task.data.limitCredit;
			machine.limitInsert = task.data.limitInsert;
			machine.limitJackpot = task.data.limitJackpot;
			machine.limitPayment = task.data.limitPayment;
		}

		if (LocationTask.instanceOf(task.data)) {
			DEBUG && console.log("LocationTask", task.data);
			machine.location = task.data.location;
		}

		if (ReferenceTask.instanceOf(task.data)) {
			DEBUG && console.log("ReferenceTask", task.data);
			machine.ref = task.data.ref;
		}

		if (MachineGameTask.instanceOf(task.data)) {
			DEBUG && console.log("MachineGameTask", task.data);
			applyReplacementTaskToMachine(
				task.data.games,
				machine.games,
				MachineGame.clone,
				MachineGame.modifyFromReplacement,
				author,
				links
			);
		}

		if (MachinePartTask.instanceOf(task.data)) {
			DEBUG && console.log("MachinePartTask", task.data);
			applyReplacementTaskToMachine(
				task.data.parts,
				machine.parts,
				MachinePart.clone,
				MachinePart.modifyFromReplacement,
				author,
				links
			);
		}

		if (MachineSoftwareTask.instanceOf(task.data)) {
			DEBUG && console.log("MachineSoftwareTask", task.data);
			applyReplacementTaskToMachine(
				task.data.softwares,
				machine.softwares,
				MachineSoftware.clone,
				MachineSoftware.modifyFromReplacement,
				author,
				links
			);
			machine.osId = machine.softwares.find(x => x.definition === SoftwareType.os)?.softwareId || machine.osId;
		}

		if (MachineControllerTask.instanceOf(task.data)) {
			DEBUG && console.log("MachineControllerTask", task.data);
			applyReplacementTaskToMachine(
				task.data.controllers,
				machine.controllers,
				MachineController.clone,
				MachineController.modifyFromReplacement,
				author,
				links
			);
		}

		switch (task.action) {
			case TaskAction.stock:
				DEBUG && console.log("stock");
				machine.status = MachineStatus.stocked;
				machine.events.push(Event.create(author, EventType.stored, "", original, machine, links));
				break;
			case TaskAction.installation:
				DEBUG && console.log("installation");
				machine.status = MachineStatus.installed;
				machine.events.push(Event.create(author, EventType.installed, "", original, machine, links));
				break;
			case TaskAction.mix:
				DEBUG && console.log("mix");
				machine.events.push(Event.create(author, EventType.mixed, "", original, machine, links));
				break;
			case TaskAction.repair:
				DEBUG && console.log("repair");
				machine.events.push(Event.create(author, EventType.repaired, "", original, machine, links));
				break;
			case TaskAction.control:
				DEBUG && console.log("control");
				machine.events.push(Event.create(author, EventType.controlled, "", original, machine, links));
				break;
			case TaskAction.reset_counter:
				DEBUG && console.log("reset_counter");
				machine.events.push(Event.create(author, EventType.counter_reset, "", original, machine, links));
				break;
			case TaskAction.change_counter:
				DEBUG && console.log("change_counter");
				machine.events.push(Event.create(author, EventType.counter_changed, "", original, machine, links));
				break;
			case TaskAction.reserve:
				DEBUG && console.log("reserve");
				machine.status = MachineStatus.stocked;
				machine.events.push(Event.create(author, EventType.stored, "", original, machine, links));
				break;
			case TaskAction.transfer:
				DEBUG && console.log("transfer");
				machine.status = MachineStatus.exported;
				machine.events.push(Event.create(author, EventType.transferred, "", original, machine, links));
				break;
			case TaskAction.destroy:
				DEBUG && console.log("destroy");
				machine.status = MachineStatus.destroyed;
				machine.events.push(Event.create(author, EventType.destroyed, "", original, machine, links));
				break;
			case TaskAction.reactivation:
				DEBUG && console.log("reactivation");
				machine.status = MachineStatus.installed;
				machine.events.push(Event.create(author, EventType.reactivated, "", original, machine, links));
				break;
			case TaskAction.sell:
				DEBUG && console.log("sell");
				machine.status = MachineStatus.exported;
				machine.events.push(Event.create(author, EventType.sold, "", original, machine, links));
				break;
		}
	}
}
