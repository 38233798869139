











































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import {
	TaskDataEditorMixin,
	CheckMachine,
	LimitMachineTask,
	LocationTask,
	ReferenceTask,
	PropertyNames,
	Machine,
} from "@/loader";

type CheckMachineTask = LimitMachineTask | LocationTask | ReferenceTask | CheckMachine;
type Properties = PropertyNames<(LimitMachineTask & LocationTask & ReferenceTask & CheckMachine) | Machine>;
// type Properties = "limitCredit" | "limitInsert" | "limitJackpot" | "limitPayment" | "location" | "ref";

@Component
export default class CheckMachineTaskEditor extends Mixins<TaskDataEditorMixin<CheckMachine | LocationTask | ReferenceTask | LimitMachineTask>>(TaskDataEditorMixin) {
	@Ref()
	public readonly dataInputs!: any[];

	public get states() {
		return {
			controlDone: this.focused.controlDone && CheckMachine.instanceOf(this.item) ? !!this.item.controlDone : null,
			limitCredit: this.focused.limitCredit && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitCredit : null,
			limitInsert: this.focused.limitInsert && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitInsert : null,
			limitJackpot: this.focused.limitJackpot && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitJackpot : null,
			limitPayment: this.focused.limitPayment && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitPayment : null,
			location: this.focused.location && LocationTask.instanceOf(this.item) ? !!this.item.location : null,
			ref: this.focused.ref && ReferenceTask.instanceOf(this.item) ? !!this.item.ref : null,
		}
	}

	public invalidFeedbacks = {
		controlDone: "Vous devez vérifier les informations de la machine",
		limitCredit: "La limite de crédit doit être renseignée",
		limitInsert: "La limite d'insertion doit être renseignée",
		limitJackpot: "La limite de jackpot doit être renseignée",
		limitPayment: "La limite de paiement doit être renseignée",
		location: "Le socle de la machine doit être renseigné",
		ref: "Le N°MAS du casino doit être renseigné",
	}

	public isCheckMachine = CheckMachine.instanceOf;
	public isLimitMachineTask = LimitMachineTask.instanceOf;
	public isLocationTask = LocationTask.instanceOf;
	public isReferenceTask = ReferenceTask.instanceOf;

	public setDefaultData() {
		this.setData("limitCredit", LimitMachineTask.instanceOf);
		this.setData("limitInsert", LimitMachineTask.instanceOf);
		this.setData("limitJackpot", LimitMachineTask.instanceOf);
		this.setData("limitPayment", LimitMachineTask.instanceOf);
		this.setData("location", LocationTask.instanceOf);
		this.setData("ref", ReferenceTask.instanceOf);
	}

	public setData<T extends CheckMachineTask>(property: Properties, checkTypeFunction: (value: any) => value is T) {
		if (this.item && this.machine && checkTypeFunction(this.item) && property in this.item) {
			// @ts-ignore
			if (!this.item[property]) {
				// @ts-ignore
				this.item[property] = this.machine[property];
			// @ts-ignore
			} else if (this.item[property] !== this.machine[property]) {
				this.$set(this.focused, property, true);
			}
		}
	}

	public next(event: Event) {
		if (Array.isArray(this.dataInputs)) {
			const index = this.dataInputs.indexOf(event.target as HTMLInputElement);
			if (index !== -1 && index < this.dataInputs.length - 1) {
				this.dataInputs[index + 1].focus();
			} else {
				this.$emit("next");
			}
		}
	}

	public mounted() {
		this.setDefaultData();
	}

	@Watch("machine")
	public onMachineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue !== oldValue)  {
			this.setDefaultData();
		}
	}
}
