












































































































import { Vue, Component } from "vue-property-decorator";
import {
	servers,
	toaster,
	CategoryItem,
	GameCategory,
	Event,
	EventType,
	createCategoryItem,
	busy,
} from "@/loader";
import { CategoryTreeState } from '@/component/category/CategoryTree.vue';

@Component
export default class GameCategoriesList extends Vue {
	public changed: boolean = false;
	public filter: string = "";
	public item: CategoryItem<GameCategory> | null = null;

	public get tree(): GameCategoryTree {
		return this.$refs.tree as GameCategoryTree;
	}

	public get isNew(): boolean {
		return this.item && this.item.isNew;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public async select(category: CategoryItem<GameCategory> | null) {
		if (category) {
			this.item = category;
			this.changed = false;
		} else {
			this.item = null;
			this.changed = false;
		}
	}

	public createNew(parent: CategoryItem<GameCategory> | null = null) {
		this.item = createCategoryItem(GameCategory.create);
		if (parent) {
			this.item.parent = parent;
			this.item.category.parentId = parent._id;
			parent.children.push(this.item);
		}
		this.tree.addNewCategory(this.item);
		this.changed = true;
	}

	public cancel() {
		if (!this.item || !this.canEdit) {
			return;
		}

		if (this.item?.isNew) {
			if (this.item.parent) {
				this.item.parent.children.remove(this.item, true);
			}
			this.tree.removeCategory(this.item);
			this.item = null;
			this.changed = false;
		} else {
			busy.start();
			this.$pouch.get(this.item._id, {}, servers.selected.name).then(doc => {
				this.item.category = doc;
				this.changed = false;
			}).catch(error => {
				console.error(error);
				toaster.error({ error });
			}).finally(() => {
				busy.stop();
			});
		}
	}

	public async save(eventType: EventType | null, eventComment: string = "") {
		if (!this.item || !this.canEdit || !this.changed) {
			return;
		}

		this.item.category.events.push(Event.create(
			servers.author,
			eventType || (this.item.isNew ? EventType.created : EventType.modified),
			eventComment
		));

		try {
			let response = await this.$pouch.put(this.item.category, {}, servers.selected.name);
			this.changed = false;
			if (response.ok) {
				this.item.category._rev = response.rev;
				this.item.isNew = false;
				if ((this.item.parent ? this.item.parent._id : "") !== this.item.category.parentId) {
					if (this.item.parent) {
						this.item.parent.children.remove(this.item);
					}
					if (this.item.category.parentId) {
						this.item.parent = this.tree.categoriesItems.find(c => c._id === this.item.category.parentId);
						this.item.parent.children.push(this.item);
					}
				}
			}
		} catch (error) {
			console.error(error);
			toaster.error({ error });
		}
	}

	public deactivate() {
		if (!this.item || !this.canEdit || this.isNew || this.item.disabled) {
			return;
		}

		this.item.disabled = true;
		this.changed = true;
		this.save(EventType.deactivate);
	}

	public reactivate() {
		if (!this.item || !this.canEdit || this.isNew || !this.item.disabled) {
			return;
		}

		this.item.disabled = false;
		this.changed = true;
		this.save(EventType.reactivate);
	}
}

export interface GameCategoriesListState extends CategoryTreeState {
}
