









































import { Component, Mixins } from "vue-property-decorator";
import { EditList, GameCompatibility, HasCompatibilities } from "@/loader";
import GameCompatibilityRow from "./GameCompatibilityRow.vue";

@Component({
	components: {
		GameCompatibilityRow,
	}
})
export default class GameCompatibilityTable extends Mixins<EditList<GameCompatibility, HasCompatibilities>>(EditList) {
	public get array(): GameCompatibility[] {
		return this.parent ? this.parent.compatibilities.games : [];
	}
	public set array(value: GameCompatibility[]) {
		if (this.parent) {
			this.parent.compatibilities.games = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return GameCompatibility.create;
	}

	public get cloneFactory(): (base: GameCompatibility) => GameCompatibility {
		return GameCompatibility.clone;
	}
}
