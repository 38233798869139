import { ActionContext } from "vuex";
import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { store } from "@/loader";

export interface BusyOverlayOptions {
	/** Background theme color variant to use for the overlay backdrop. Default 'light'. */
	variant?: string;
	/** Text theme color variant to use for the overlay content. Default 'light'. */
	colorVariant?: string;
	/** Alignement to use for the overlay content. Default 'light'. */
	textAlign?: string;
	/** CSS color to use as the opaque overlay backdrop color. If set, overrides the `variant` prop. Default undefined. */
	bgColor?: string;
	/** Opacity of the overlay backdrop. Valid range is `0` to `1`. Default 0.85. */
	opacity?: number | string;
	/** Value for the CSS blur backdrop-filter. Be sure to include the CSS units. Not supported in IE 11. Set to null or an empty string to disable blurring. Default '2px'. */
	blur?: string;
	/** Apply rounding to the overlay to match your content routing. Valid values are `true`, `'sm'`, `lg`, `circle`, `pill`, `top`, `right`, `bottom`, or `left`. Default false. */
	rounded?: boolean | string;
	/** When set, disables the vertical and horizontal centering of the overlay content. Default false. */
	noCenter?: boolean;
	/** Disables the fade transition of the overlay. Default false. */
	noFade?: boolean;
	/** Disabled generating the wrapper element, and ignored the default slot. Requires that `<b-overlay>` be placed in an element with position relative set. Default false. */
	noWrap?: boolean;
	/** When prop `no-wrap` is set, will use fixed positioning instead of absolute positioning. Handy if you want to obscure the entire application page. Default false. */
	fixed?: boolean;
	/** Z-index value to apply to the overlay. You may need to increase this value to suit your content or placement. Default 10. */
	zIndex?: number | string;
}

export interface FontAwesomeIconProps {
	border?: boolean;
	fixedWidth?: boolean;
	flip?: "horizontal" | "vertical" | "both" | null;
	icon?: object | string[] | string;
	mask?: object | string[] | string;
	listItem?: boolean;
	pull?: "right" | "left" | null;
	pulse?: boolean;
	rotation?: 90 | 180 | 270 | null;
	swapOpacity?: boolean;
	size?: "lg" | "xs" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x" | null;
	spin?: boolean;
	transform?: string | object;
	symbol?: boolean | string;
	title?: string;
	inverse?: boolean;
	class?: object | string | any[];
}

export interface BusyState {
	status: boolean;
	text?: string;
	overlayOptions?: BusyOverlayOptions;
	icon?: FontAwesomeIconProps;
}

const defaultOverlayOptions: BusyOverlayOptions = {
	variant: "dark",
	textAlign: "center",
	colorVariant: "light",
	zIndex: 9999,
};

const defaultIcon: FontAwesomeIconProps = {
	icon: "spinner",
	pulse: true,
	class: ["d-block", "mx-auto", "my-3"]
};

@Module({
	name: "busy",
	store,
	dynamic: true,
	namespaced: true,
})
export class BusyModule extends VuexModule implements BusyState {
	public status: boolean = false;
	public text: string = "";
	public overlayOptions: BusyOverlayOptions = defaultOverlayOptions;
	public icon: FontAwesomeIconProps = defaultIcon;

	@Mutation
	public set(payload: BusyState) {
		this.status = payload.status;
		this.text = payload.text || "";
		this.overlayOptions = {
			...defaultOverlayOptions,
			...payload.overlayOptions
		};
		this.icon = {
			...defaultIcon,
			...payload.icon
		};
	}

	@Mutation
	public start(payload: BusyState | string) {
		if (typeof payload === "string") {
			this.status = true;
			this.text = payload;
			return;
		}
		this.status = payload && "status" in payload ? payload.status : true;
		this.text = payload?.text || "";
		this.overlayOptions = {
			...defaultOverlayOptions,
			...(payload?.overlayOptions || {})
		};
		this.icon = {
			...defaultIcon,
			...(payload?.icon || {})
		};
	}

	@Mutation
	public stop(payload?: BusyState) {
		this.status = payload && "status" in payload ? payload.status : false;
		this.text = payload?.text || "";
		this.overlayOptions = {
			...defaultOverlayOptions,
			...payload?.overlayOptions || {}
		};
		this.icon = {
			...defaultIcon,
			...payload?.icon || {}
		};
	}

	@Mutation
	public setText(value: string) {
		this.text = value;
	}
}

export const busy = getModule(BusyModule, store);

export function commitBusySet(context: ActionContext<any, any>, payload: BusyState) {
	context.commit("busy/set", payload, { root: true });
}

export function commitBusyStart(context: ActionContext<any, any>, payload: string | BusyState) {
	context.commit("busy/start", payload, { root: true });
}

export function commitBusyStop(context: ActionContext<any, any>, payload?: BusyState) {
	context.commit("busy/stop", payload, { root: true });
}
