import { checkValue } from "@/loader";

export interface Named {
	name: string;
}

export namespace Named {
	export function instanceOf(value: any): value is Named {
		return value && typeof value === "object" && "name" in value && typeof value.name === "string";
	}

	export function check(data: any): Named {
		checkValue(data, "name", "");
		return data;
	}
}
