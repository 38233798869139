import { BvListItem } from "@/loader";


export enum SyncStatus {
	unknown = 0,
	active = 100, // Continue
	complete = 200, // OK
	change = 202, // Accepted
	paused = 204, // No Content
	denied = 403, // Forbidden
	disconnected = 410, // Gone
	error = 500, // Internal Server Error
}

export namespace SyncStatus {
	export const OFF: SyncStatus[] = [SyncStatus.disconnected, SyncStatus.unknown];

	export function toString(syncstatus: SyncStatus): string {
		switch (syncstatus) {
			case SyncStatus.disconnected: return "Déconnecté";
			case SyncStatus.error: return "Erreur";
			case SyncStatus.denied: return "Refusé";
			case SyncStatus.change: return "Changement";
			case SyncStatus.active: return "Actif";
			case SyncStatus.complete: return "Terminé";
			case SyncStatus.paused: return "En attente";
			case SyncStatus.unknown:
			default:
				return "Inconnu";
		}
	}

	export const LIST: BvListItem[] = Object.values(SyncStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<SyncStatus>value) }));
}
