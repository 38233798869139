import { Component, Mixins } from "vue-property-decorator";
import { EnumSelect, Civility, BvListItem } from "@/loader";

@Component
export class CivilitySelect extends Mixins<EnumSelect<Civility>>(EnumSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return Civility.LIST;
	}
}
