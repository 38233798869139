





































































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
	MachineSoftware,
	ReplacementAction,
	ReplacementRow,
	Software,
	SoftwareReplacement,
} from "@/loader";

@Component
export default class SoftwareReplacementRow extends Mixins<ReplacementRow<SoftwareReplacement>>(ReplacementRow) {
	public oldSoftware: Software | null = null;
	public newSoftware: Software | null = null;
	public invalidFeedbacks = {
		action: "L'action est requise",
		oldId: "Un élément doit être sélectionné",
		softwareId: "Un élément doit être sélectionné",
		version: "",
	}

	public get states() {
		return {
			action: this.item && this.focused.action ? true : null,
			oldId: this.item && this.focused.oldId && ReplacementAction.NEED_OLD.includes(this.item.action) ? !!this.item.oldId : null,
			softwareId: this.item && this.focused.softwareId && ReplacementAction.NEED_NEW.includes(this.item.action) ? !!this.item.softwareId : null,
			version: this.item && this.focused.version ? true : null,
		}
	}

	public get array(): MachineSoftware[] {
		return this.machine?.softwares || [];
	}

	public get oldMachineSoftware(): MachineSoftware | null {
		return this.array.find(x => x.id === this.item?.oldId) || null;
	}

	public get isSoftwareDifferent(): boolean {
		return this.oldMachineSoftware && this.item && this.oldMachineSoftware.softwareId !== this.item.softwareId || false;
	}

	public get isVersionDifferent(): boolean {
		return this.oldMachineSoftware && this.item && (this.oldMachineSoftware.version || "") != (this.item.version || "") || false;
	}

	public mounted() {
		this.loadOldRelations();
	}

	public async loadOldRelations(value?: MachineSoftware | null) {
		value = value || this.oldMachineSoftware;
		this.oldSoftware = await this.$db.getSoftware(value?.softwareId);
	}

	@Watch("oldMachineSoftware")
	public onOldMachineSoftwareChanged(newValue: MachineSoftware | null, oldValue?: MachineSoftware | null) {
		if (this.item && newValue !== oldValue) {
			this.loadOldRelations(newValue);
		}
	}
}
