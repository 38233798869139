import { arrayToFlag, BvListItem, flagToArray, flagToString, flagToStrings } from "@/loader";

export enum ControllerType {
	none = 0,
	bonus = 1,
	progressif = 2,
	mystery = 4,
	hidden = 8,
	multisite = 16,
	standalone = 32,
	linked = 64,
	mandatory = 128,
}

function controllerTypeToString(controllerType: ControllerType): string {
	switch (controllerType) {
		case ControllerType.bonus: return "Bonus";
		case ControllerType.progressif: return "Progressif";
		case ControllerType.mystery: return "Mystère";
		case ControllerType.hidden: return "Masqué";
		case ControllerType.multisite: return "Multisite";
		case ControllerType.standalone: return "Standalone";
		case ControllerType.linked: return "Linked";
		case ControllerType.mandatory: return "Obligatoire";
		default: return "?";
	}
}

export namespace ControllerType {
	export const LIST: BvListItem[] = Object.values(ControllerType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<ControllerType>value) }));

	export function toArray(value: ControllerType): ControllerType[] {
		return flagToArray(value);
	}

	export function fromArray(values: ControllerType[]): ControllerType {
		return arrayToFlag(values);
	}

	export function toStrings(value: ControllerType): string[] {
		return flagToStrings(value, controllerTypeToString);
	}

	export function toString(value: ControllerType, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, controllerTypeToString, separator, last_separator);
	}

	export function isCheckable(value: ControllerType): boolean {
		return (value & ControllerType.progressif) > 0 && (value & ControllerType.bonus) === 0;
	}
}
