




















































































































import { Component, Mixins } from "vue-property-decorator";
import { Platform, EditView, servers } from "@/loader";
import GameCompatibilityTable from "../../../component/game/GameCompatibilityTable.vue";
import PartCompatibilityTable from "../../../component/part/PartCompatibilityTable.vue";
import SoftwareCompatibilityTable from "../../../component/software/SoftwareCompatibilityTable.vue";

@Component({
	components: {
		GameCompatibilityTable,
		PartCompatibilityTable,
		SoftwareCompatibilityTable,
	}
})
export default class PlatformView extends Mixins<EditView<Platform>>(EditView) {
	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		brand: "",
		ref: "",
		games: "",
		parts: "",
		software: "",
	}

	public get states() {
		return {
			name: this.item && this.focused.name ? !!this.item.name : null,
			brand: null,
			ref: null,
			games: null,
			parts: null,
			software: null,
		}
	}

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get itemFactory(): Function | null {
		return Platform.create;
	}

	public get cloneFactory(): (base: Platform) => Platform {
		return Platform.clone;
	}

	public get checkDocFunction(): Function | null {
		return Platform.check;
	}

	public get routeName(): string {
		return "platform";
	}

	public get title(): string {
		if (this.item) {
			return "Plateforme &mdash; " + this.item.name;
		}
		return "Plateforme";
	}
}
