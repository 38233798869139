import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Reading, DocumentSelect, TaskAction } from '@/loader';
import dayjs, { ConfigType } from "dayjs";

@Component
export class ReadingSelect extends Mixins<DocumentSelect<Reading>>(DocumentSelect) {
	// @ts-ignore
	public readonly emptyItem: Reading = { _id: "", _rev: "", type: Reading.TYPE, date: null };

	@Prop({ type: String, required: true })
	public machineId!: string;

	@Prop({ type: String, default: null })
	public minDate!: string | null;

	@Prop({ type: String, default: null })
	public maxDate!: string | null;

	@Prop({ type: String, default: null })
	public date!: string | null;

	public get viewRoute(): string {
		return "reading";
	}

	public get canViewOption(): boolean {
		return this.canRedirect;
	}

	public optionViewRoute(option: Reading) {
		return {
			name: this.viewRoute,
			params: { id: option._id, machineId: option.machineId },
			query: { redirect: this.$route.fullPath, redirectTitle: this.redirectTitle }
		}
	}

	public async loadOptions() {
		this.options = await this.$db.allReadings(this.machineId, { date: this.date, minDate: this.minDate, maxDate: this.maxDate });
	}

	public mounted() {
		this.loadOptions();
	}

	public getOptionLabel(option: Reading): string {
		return option._id ? [dayjs(option.date).format("L LT"), TaskAction.toString(option.motive)].filter(x => !!x).join(" - ") : this.emptyText;
	}

	public optionSlot(h: CreateElement, option: Reading): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: Reading): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	@Watch("machineId")
	public onMachineIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}

	@Watch("date")
	public onDateChanged(newValue: ConfigType | null, oldValue?: ConfigType | null) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}

	@Watch("minDate")
	public onMinDateChanged(newValue: ConfigType | null, oldValue?: ConfigType | null) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}

	@Watch("maxDate")
	public onMaxDateChanged(newValue: ConfigType | null, oldValue?: ConfigType | null) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}

	public get editRoute(): string {
		return "reading";
	}

	public get canEditOption(): boolean {
		return false;
	}
}
