import { checkArrayValue, ControllerReplacement } from "@/loader";

export interface MachineControllerTask {
	controllers: ControllerReplacement[]
}

export namespace MachineControllerTask {
	export function instanceOf(value: any): value is MachineControllerTask {
		return value && typeof value === "object" && "controllers" in value;
	}

	export function check(data: any): MachineControllerTask {
		checkArrayValue(data, "controllers", ControllerReplacement.check);
		return data;
	}
}
