





































import { Vue, Component, Mixins } from "vue-property-decorator";
import { EditComponent, PartChild, Part } from "@/loader";

@Component
export default class PartChildRow extends Mixins<EditComponent<PartChild, Part>>(EditComponent) {
}
