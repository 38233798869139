import {
	Civility,
	Role,
	CouchDBDocument,
	Events,
	Named,
	Addresses,
	Coordinates,
	checkValue,
	Deactivable,
	checkArrayValue,
} from "@/loader";

export const USER_TYPE = "user";
export const USER_KEY = "org.couchdb.user:";
export const USER_STARTKEY = USER_KEY;
export const USER_ENDKEY = USER_KEY + CouchDBDocument.ENDKEY_SUFFIX;

export interface UserData extends Named {
	email: string
	password?: string
	firstName: string
	lastName: string
}

export interface User extends CouchDBDocument, Events, UserData, Deactivable, Addresses, Coordinates {
	type: "user"
	managerId: string | null
	roles: Role[]
	civility: Civility
	password_scheme?: string
	iterations?: number
	derived_key?: string
	salt?: string
}

export namespace UserData {
	export function create(
		name: string,
		email: string,
		password: string = "",
		firstName: string = "",
		lastName: string = "",
	): UserData {
		return {
			email,
			password,
			name,
			firstName,
			lastName,
		}
	}

	export function check(data: any): UserData {
		checkValue(data, "email", "");
		checkValue(data, "name", "");
		checkValue(data, "password", "");
		checkValue(data, "firstName", "");
		checkValue(data, "lastName", "");
		return data;
	}
}

export namespace User {
	export function instanceOf(value: any): value is User {
		return CouchDBDocument.instanceOf(value) && value.type == USER_TYPE;
	}

	export function toString(value: User): string {
		return `${value.firstName.capitalize()} ${value.lastName.toUpperCase()}`.trim();
	}

	export function create(
		name: string,
		email: string,
		managerId: string | null = null,
		password: string = "",
		firstName: string = "",
		lastName: string = "",
		disabled: boolean = false,
		roles: Role[] = [],
		civility: Civility = Civility.monsieur
	): User {
		return {
			_id: USER_KEY + name,
			_rev: "",
			type: USER_TYPE,
			...UserData.create(name, email, password, firstName, lastName),
			managerId,
			disabled,
			roles,
			civility,
			addresses: [],
			coordinates: [],
			events: []
		}
	}

	export function check(data: any): User {
		CouchDBDocument.check(data, USER_TYPE);
		UserData.check(data);
		Deactivable.check(data);
		Addresses.check(data);
		Coordinates.check(data);
		Events.check(data);

		checkValue(data, "managerId", null);
		checkValue(data, "civility", Civility.monsieur);

		checkArrayValue(data, "roles");
		return data;
	}
}
