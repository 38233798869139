import { checkValue, UUID } from "@/loader";

export interface CompanyTask {
	companyId: UUID
}

export namespace CompanyTask {
	export function instanceOf(value: any): value is CompanyTask {
		return value && typeof value === "object" && "companyId" in value;
	}

	export function check(data: any): CompanyTask {
		checkValue(data, "companyId", "");
		return data;
	}
}
