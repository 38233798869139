import { Component, Mixins } from "vue-property-decorator";
import { GameCategory } from '@/loader';
import CategoryTree from "../category/CategoryTree.vue";

//@ts-ignore
@Component
//@ts-ignore
export class GameCategoryTree extends Mixins<CategoryTree<GameCategory>>(CategoryTree) {
	public get startkey(): string {
		return GameCategory.STARTKEY;
	}

	public get endkey(): string {
		return GameCategory.ENDKEY;
	}

	public get query(): string {
		return "game/categories";
	}
}
