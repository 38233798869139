import { ConfigType } from "dayjs";
import { CouchDBDocument, User, Account, checkAccount, checkValue, checkArrayValue, Named, Sortable, UUID, SoftwareCounterValue } from "@/loader";
import { BvTableVariant } from "bootstrap-vue";


export interface ListState {
	filter: string
	limit: number
	page: number
	sort: string
	sortDesc: boolean
	filters: { [filter: string]: any[] }
}

export namespace ListState {
	export function instanceOf(value: any): value is ListState {
		return value && "page" in value || false;
	}

	export function create(): ListState {
		return {
			filter: "",
			filters: {},
			limit: 20,
			page: 1,
			sort: "",
			sortDesc: false,
		};
	}

	export function check(data: any): ListState {
		checkValue(data, "limit", 20);
		checkValue(data, "page", 1);
		checkValue(data, "filter", null);
		checkValue(data, "sort", "");
		checkValue(data, "sortDesc", false);
		checkValue(data, "filters", {});
		return data;
	}
}

export interface TreeState {
	opened: string[]
	selected: string | null
	filter: string
}

export namespace TreeState {
	export function instanceOf(value: any): value is TreeState {
		return value && "opened" in value || false;
	}

	export function create(): TreeState {
		return {
			filter: "",
			opened: [],
			selected: null
		}
	}

	export function check(data: any): TreeState {
		checkValue(data, "opened", []);
		checkValue(data, "selected", null);
		checkValue(data, "filter", null);
		return data;
	}
}

export interface ViewState {
	name: string | null
	activeTab: number | null
	selected: string | ViewState | null
}

export namespace ViewState {
	export function instanceOf(value: any): value is ViewState {
		return value && "activeTab" in value || false;
	}

	export function create(): ViewState {
		return {
			name: null,
			activeTab: null,
			selected: null
		}
	}

	export function check(data: any): ViewState | string | null {
		if (typeof data === "string" || data === null) {
			return data;
		}
		checkValue(data, "name", null);
		checkValue(data, "activeTab", null);
		checkValue(data, "selected", null, ViewState.check);
		return data;
	}
}

export interface ReadingStatStatus {
	status: BvTableVariant
	$gt?: number
	$gte?: number
	$lt?: number
	$lte?: number
	$eq?: number
	$neq?: number
	$in?: number[]
	$between?: [number, number]
}

export interface ReadingStatOperation {
	$plus?: ReadingStatOperations
	$minus?: ReadingStatOperations
	$div?: ReadingStatOperations
	$multiply?: ReadingStatOperations
}

export type ReadingStatOperations = number | string | (number | string | ReadingStatOperation)[];

export interface ReadingStatSettings extends Named, Sortable {
	slug: string
	operations: ReadingStatOperations
	format?: string
	description?: string
	status?: ReadingStatStatus[]
	log?: any[]
}

export interface ReadingCounterSettings {
	rows: ReadingCounterRowSettings[]
}

export namespace ReadingCounterSettings {
	export function instanceOf(obj: any): obj is ReadingCounterSettings {
		return obj && "rows" in obj;
	}

	export function create() {
		return {
			rows: []
		};
	}

	export function check(data: any): ReadingCounterSettings {
		checkArrayValue(data, "rows", ReadingCounterRowSettings.check);
		return data;
	}
}

export interface ReadingCounterRowSettings {
	columns: ReadingCounterColumnSettings[]
}

export namespace ReadingCounterRowSettings {
	export function instanceOf(obj: any): obj is ReadingCounterRowSettings {
		return obj && "columns" in obj;
	}

	export function create(): ReadingCounterRowSettings {
		return {
			columns: []
		};
	}

	export function check(data: any): ReadingCounterRowSettings {
		checkArrayValue(data, "columns", ReadingCounterColumnSettings.check);
		return data;
	}
}

export interface ReadingCounterColumnSettings {
	colspan: number
	rowspan: number
	merged: boolean
	counterId: UUID | null
	counter?: SoftwareCounterValue | null
}

export namespace ReadingCounterColumnSettings {
	export function instanceOf(obj: any): obj is ReadingCounterColumnSettings {
		return obj && "counterId" in obj;
	}

	export function create(): ReadingCounterColumnSettings {
		return {
			colspan: 1,
			rowspan: 1,
			counterId: null,
			merged: false,
		};
	}

	export function check(data: any): ReadingCounterColumnSettings {
		checkValue(data, "colspan", 1);
		checkValue(data, "rowspan", 1);
		checkValue(data, "counterId", null);
		checkValue(data, "merged", false);
		return data;
	}
}

export interface TaskReportSettings {
	in_elec: UUID
	in_meca: UUID
	out_elec: UUID
	out_meca: UUID
	revenue_elec: UUID
	revenue_meca: UUID
	profit_jackpot_elec: UUID
	profit_lots_elec: UUID
	profit_meca: UUID
	bill_in_elec: UUID
	bill_in_meca: UUID
	tito_in_elec: UUID
	tito_out_elec: UUID
	tito_promo_in_elec: UUID
	tito_promo_out_elec: UUID
	tito_in_meca: UUID
	tito_out_meca: UUID
	cashless_in_elec: UUID
	cashless_out_elec: UUID
	cashless_promo_in_elec: UUID
	cashless_promo_out_elec: UUID
	cashless_in_meca: UUID
	cashless_out_meca: UUID
}

export namespace TaskReportSettings {
	export function instanceOf(obj: any): obj is TaskReportSettings {
		return obj && "in_elec" in obj && "out_elec" in obj;
	}

	export function create() {
		return {
			in_elec: "",
			in_meca: "",
			out_elec: "",
			out_meca: "",
			revenue_elec: "",
			revenue_meca: "",
			profit_jackpot_elec: "",
			profit_lots_elec: "",
			profit_meca: "",
			bill_in_elec: "",
			bill_in_meca: "",
			tito_in_elec: "",
			tito_out_elec: "",
			tito_promo_in_elec: "",
			tito_promo_out_elec: "",
			tito_in_meca: "",
			tito_out_meca: "",
			cashless_in_elec: "",
			cashless_out_elec: "",
			cashless_promo_in_elec: "",
			cashless_promo_out_elec: "",
			cashless_in_meca: "",
			cashless_out_meca: "",
		};
	}

	export function check(data: any): TaskReportSettings {
		checkValue(data, "in_elec", "");
		checkValue(data, "in_meca", "");
		checkValue(data, "out_elec", "");
		checkValue(data, "out_meca", "");
		checkValue(data, "revenue_elec", "");
		checkValue(data, "revenue_meca", "");
		checkValue(data, "profit_jackpot_elec", "");
		checkValue(data, "profit_lots_elec", "");
		checkValue(data, "profit_meca", "");
		checkValue(data, "bill_in_elec", "");
		checkValue(data, "bill_in_meca", "");
		checkValue(data, "tito_in_elec", "");
		checkValue(data, "tito_out_elec", "");
		checkValue(data, "tito_promo_in_elec", "");
		checkValue(data, "tito_promo_out_elec", "");
		checkValue(data, "tito_in_meca", "");
		checkValue(data, "tito_out_meca", "");
		checkValue(data, "cashless_in_elec", "");
		checkValue(data, "cashless_out_elec", "");
		checkValue(data, "cashless_promo_in_elec", "");
		checkValue(data, "cashless_promo_out_elec", "");
		checkValue(data, "cashless_in_meca", "");
		checkValue(data, "cashless_out_meca", "");
		return data;
	}
}

export interface GlobalSettings extends CouchDBDocument {
	type: "global-settings"
	main: string | null
	hideServers: string[]
	countryLastSync: ConfigType
	accounts: string[]
	account: Account | null
	token: string | null
}

export namespace GlobalSettings {
	export const TYPE = "global-settings";
	export const KEY = "_local/settings";

	export function instanceOf(obj: any): obj is GlobalSettings {
		return obj && "type" in obj && obj.type == TYPE
	}

	export function create(
		main: string | null = null,
		hideServers: string[] = [],
		countryLastSync: ConfigType = null
	): GlobalSettings {
		return {
			_id: KEY,
			_rev: "",
			type: TYPE,
			main,
			hideServers,
			countryLastSync,
			accounts: [],
			account: null,
			token: null,
		}
	}

	export function check(data: any): GlobalSettings {
		CouchDBDocument.check(data, TYPE);

		checkValue(data, "main", null);
		checkValue(data, "countryLastSync", null);
		checkValue(data, "account", null, checkAccount);
		checkValue(data, "token", null);

		checkArrayValue(data, "hideServers");
		checkArrayValue(data, "accounts");

		if (data.current !== undefined) {
			delete data.current;
		}
		return data;
	}
}

export interface Settings extends CouchDBDocument {
	type: "settings",
	user: User | null
	token: string | null
	states: { [route: string]: ListState | TreeState | ViewState }
}

export namespace Settings {
	export const TYPE = "settings";

	export function instanceOf(obj: any): obj is Settings {
		return obj && obj.type && obj.type == TYPE
	}

	export function create(
		name: string,
		user: User | null = null,
		token: string | null = null
	): Settings {
		return {
			_id: TYPE + ":" + name,
			_rev: "",
			type: TYPE,
			user,
			token,
			states: {}
		}
	}

	export function check(data: any): Settings {
		CouchDBDocument.check(data, TYPE);
		checkValue(data, "user", null);
		checkValue(data, "token", null);
		checkValue(data, "states", {});
		Object.keys(data.states).forEach(key => {
			if (ListState.instanceOf(data.states[key])) {
				checkValue(data.states, key, ListState.create, ListState.check);
			} else if (TreeState.instanceOf(data.states[key])) {
				checkValue(data.states, key, TreeState.create, TreeState.check);
			} else if (ViewState.instanceOf(data.states[key])) {
				checkValue(data.states, key, ViewState.create, ViewState.check);
			}
		});

		return data;
	}
}

export interface AppSettings extends CouchDBDocument {
	type: "app-settings"
	readingStats: ReadingStatSettings[]
	favoritePartCategories: UUID[]
	taskReport: TaskReportSettings
	readingCounters: ReadingCounterSettings
}

export namespace AppSettings {
	export const TYPE = "app-settings";
	export const KEY = Settings.TYPE + CouchDBDocument.PREFIX_SEPARATOR + "app";

	export function instanceOf(obj: any): obj is AppSettings {
		return obj && obj.type && obj.type == TYPE
	}

	export function check(data: any): AppSettings {
		checkValue(data, "type", TYPE);
		checkArrayValue(data, "readingStats");
		checkValue(data, "favoritePartCategories", []);
		checkValue(data, "taskReport", TaskReportSettings.create, TaskReportSettings.check);
		checkValue(data, "readingCounters", ReadingCounterSettings.create, ReadingCounterSettings.check);
		return data;
	}
}
