


































































import { Watch, Component, Mixins } from "vue-property-decorator";
import { EditComponent, GameCompatibility, Game } from "@/loader";

@Component
export default class GameCompatibilityRow extends Mixins<EditComponent<GameCompatibility, Game>>(EditComponent) {
	public selectedPack: Game | null = null;
	public selectedGame: Game | null = null;

	public invalidFeedbacks = {
		gameId: "Le jeu, le pack et la catégorie ne peuvent pas être vide",
		packId: "Le jeu, le pack et la catégorie ne peuvent pas être vide",
		categoryId: "Le jeu, le pack et la catégorie ne peuvent pas être vide",
		compatible: "La compatibilité ne peut pas être vide",
	}

	public get gameId() {
		return this.item?.gameId;
	}

	public get states() {
		return {
			gameId: this.item && this.focused.gameId ? !!this.item.gameId && !!this.item.packId && !!this.item.categoryId : null,
			packId: this.item && this.focused.packId ? !!this.item.gameId && !!this.item.packId && !!this.item.categoryId : null,
			categoryId: this.item && this.focused.categoryId ? !!this.item.gameId && !!this.item.packId && !!this.item.categoryId : null,
			compatible: this.item && this.focused.compatible ? !!this.item.compatible : null,
		}
	}

	@Watch("gameId")
	public async gameIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.selectedGame = await this.$db.getGame(newValue);
		}
	}

	@Watch("packId")
	public async packIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue !== oldValue) {
			this.selectedPack = await this.$db.getGame(newValue);
		}
	}

	public async mounted() {
		this.selectedGame = await this.$db.getGame(this.item?.gameId);
		this.selectedPack = await this.$db.getGame(this.item?.packId);
	}
}
