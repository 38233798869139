import { Vue, Component, Prop } from "vue-property-decorator";
import { CreateElement } from 'vue';

@Component
export class TitleCard extends Vue {
	@Prop({ type: String, default: "" })
	public title!: string;

	@Prop({ type: String, default: "h1" })
	public titleTag!: string;

	@Prop({ type: String, default: "m-0" })
	public titleClass!: string;

	@Prop({ type: [String, Array], default: "" })
	public icon!: string | string[];

	@Prop({ type: Boolean, default: false })
	public onlyTitle!: boolean;

	@Prop({ type: [String, Number], default: 12 })
	public titleCols!: string | number;

	@Prop({ type: [String, Number], default: null })
	public titleColsSm!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public titleColsMd!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public titleColsLg!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public titleColsXl!: string | number | null;

	@Prop({ type: [String, Number], default: 12 })
	public buttonCols!: string | number;

	@Prop({ type: [String, Number], default: null })
	public buttonColsSm!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public buttonColsMd!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public buttonColsLg!: string | number | null;

	@Prop({ type: [String, Number], default: null })
	public buttonColsXl!: string | number | null;


	@Prop({ type: Boolean, default: false })
	public canReturn!: boolean;

	@Prop({ type: [String, Array], default: () => ["fas", "arrow-left"] })
	public returnIcon!: string | string[];

	@Prop({ type: String, default: "Retourner à la liste" })
	public returnText!: string;

	@Prop({ type: [String, Object], default: "" })
	public returnTo!: string | object;

	@Prop({ type: String, default: "outline-secondary" })
	public returnVariant!: string;


	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;

	@Prop({ type: [String, Array], default: () => ["fas", "plus"] })
	public createIcon!: string | string[];

	@Prop({ type: String, default: "Ajouter" })
	public createText!: string;

	@Prop({ type: [String, Object], default: "" })
	public createTo!: string | object;

	@Prop({ type: String, default: "primary" })
	public createVariant!: string;


	@Prop({ type: Boolean, default: false })
	public canRedirect!: boolean;

	@Prop({ type: [String, Array], default: () => ["fal", "level-up"] })
	public redirectIcon!: string | string[];

	@Prop({ type: String, default: "Retour" })
	public redirectText!: string;

	@Prop({ type: [String, Object], default: "" })
	public redirectTo!: string | object;

	@Prop({ type: String, default: "outline-info" })
	public redirectVariant!: string;

	public get redirectUrl(): string | object | null {
		let redirect = this.canRedirect ? this.$route.query.redirect || this.redirectTo : null
		if (Array.isArray(redirect)) {
			redirect = redirect[0];
		}
		return redirect;
	}

	public get redirectTitle(): string {
		let text = this.$route.query.redirectTitle || this.redirectText;
		if (Array.isArray(text)) {
			text = text.join(" &circle; ");
		}
		return text.replace(/<\/?[^>]+>/ig, " "); // Remove all tags
	}

	public render(h: CreateElement) {
		let showButtons = this.canReturn || this.canCreate || this.$slots.buttons;

		let row = h(
			"b-row", {
				class: this.onlyTitle ? this.$attrs.class || "" : "card-body",
				props: {
					noGutters: true, alignH: "between", alignV: "center"
				}
			},
			[
				h(
					"b-col",
					{
						props: {
							cols: this.titleCols,
							sm: this.titleColsSm ? this.titleColsSm : undefined,
							md: this.titleColsMd ? this.titleColsMd : showButtons ? "auto" : 12,
							lg: this.titleColsLg ? this.titleColsLg : undefined,
							xl: this.titleColsXl ? this.titleColsXl : undefined,
						}
					},
					[h(
						this.titleTag,
						{
							class: this.titleClass,
						},
						this.$slots.title || [
							this.icon ? h("fa", { props: { icon: this.icon, fixedWidth: true } }) : null,
							h("span", { domProps: { innerHTML: " " + this.title } }),
						]
					)]
				),
				showButtons ? h(
					"b-col",
					{
						props: {
							cols: this.buttonCols,
							sm: this.buttonColsSm ? this.buttonColsSm : undefined,
							md: this.buttonColsMd ? this.buttonColsMd : "auto",
							lg: this.buttonColsLg ? this.buttonColsLg : undefined,
							xl: this.buttonColsXl ? this.buttonColsXl : undefined,
						}
					},
					[
						h(
							"b-button-group",
							[
								this.canReturn ? h(
									"b-button",
									{
										props: {
											variant: this.returnVariant,
											to: this.returnTo,
										},
										on: {
											click: () => this.$emit("return"),
										},
									},
									[
										this.returnIcon ? h("fa", { props: { icon: this.returnIcon, fixedWidth: true } }) : null,
										" " + this.returnText
									]
								) : null,
								this.redirectUrl ? h(
									"b-button",
									{
										props: {
											variant: this.redirectVariant,
											to: this.redirectUrl,
										},
										on: {
											click: () => this.$emit("redirect"),
										},
									},
									[
										this.redirectIcon ? h("fa", { props: { icon: this.redirectIcon, fixedWidth: true } }) : null,
										h("span", { domProps: { innerHTML: this.redirectTitle } })
									]
								) : null,
								this.canCreate ? h(
									"b-button",
									{
										props: {
											variant: this.createVariant,
											to: this.createTo,
										},
										on: {
											click: () => this.$emit("create"),
										},
									},
									[
										this.createIcon ? h("fa", { props: { icon: this.createIcon, fixedWidth: true } }) : null,
										" " + this.createText
									]
								) : null,
								this.$slots.buttons,
							]
						)
					]
				) : null
			]
		);
		return this.onlyTitle
			? h("div", { class: "title-card" }, [row, this.$slots.default])
			: h("b-card", { class: "title-card", props: { noBody: true } }, [row, this.$slots.default]);
	}
}
