import { checkValue } from "@/loader";

export interface Deactivable {
	disabled: boolean;
}

export namespace Deactivable {
	export function instanceOf(value: any): value is Deactivable {
		return value && typeof value === "object" && "disabled" in value;
	}

	export function check(data: any): Deactivable {
		checkValue(data, "disabled", false);
		return data;
	}
}
