




import { Vue, Component } from "vue-property-decorator";

@Component({
	inheritAttrs: true,
})
export default class EmptyView extends Vue {
}
