












































































































import { Component, Mixins } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue";
import {
	servers,
	Brand,
	Platform,
	DocumentList,
	createListFilter,
	DocBrand,
	Software,
	Part,
	Game,
	Compatibility,
	CouchDBDocument,
	PartCompatibility,
	GameCompatibility,
	SoftwareCompatibility,
	PropertyNames,
} from "@/loader";

@Component
export default class PlatformsList extends Mixins<DocumentList<Platform>>(DocumentList) {
	public SOFTWARE_STARTKEY = Software.STARTKEY;
	public SOFTWARE_ENDKEY = Software.ENDKEY;
	public GAME_STARTKEY = Game.STARTKEY;
	public GAME_ENDKEY = Game.ENDKEY;
	public PART_STARTKEY = Part.STARTKEY;
	public PART_ENDKEY = Part.ENDKEY;

	public sort: PropertyNames<Platform> = "name";
	/** @ts-ignore */
	public filters = {
		brands: createListFilter<Brand, Platform>("brand"),
		softwares: createListFilter<DocBrand<Software>, Platform>({
			match(item: Platform, selection: any[]) {
				return item.compatibilities.softwares.map(o => o.softwareId).filter(softwareId => selection.includes(softwareId)).length > 0;
			}
		}),
		parts: createListFilter<DocBrand<Part>, Platform>({
			match(item: Platform, selection: any[]) {
				return item.compatibilities.parts.map(p => p.partId).filter(partId => selection.includes(partId)).length > 0;
			}
		}),
		games: createListFilter<DocBrand<Game>, Platform>({
			match(item: Platform, selection: any[]) {
				return item.compatibilities.games.map(g => g.gameId).filter(gameId => selection.includes(gameId)).length > 0;
			}
		}),
		isDisabled: createListFilter<Brand, Game>({ valueField: "disabled", title: "Désactivé" }),
	};

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "brand",
			label: "Marque",
			sortable: true,
		},
		{
			key: "ref",
			label: "Référence",
			sortable: true,
		},
		{
			key: "compatibilities.games",
			label: "Jeux",
		},
		{
			key: "compatibilities.softwares",
			label: "Logiciels",
		},
		{
			key: "compatibilities.parts",
			label: "Pièces",
		},
	];

	public get localStateName(): string {
		return "platforms";
	}

	public get startkey(): string {
		return Platform.STARTKEY;
	}

	public get endkey(): string {
		return Platform.ENDKEY;
	}

	public get type(): string {
		return Platform.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get checkDocFunction(): Function | null {
		return Platform.check;
	}

	public getSearchableString(value: Platform): string {
		return [
			value.name.removeDiacritics().toLowerCase(),
			value.brand?.removeDiacritics().toLowerCase() || "",
		].filter(x => !!x)
			.join(CouchDBDocument.ENDKEY_SUFFIX);
	}

	public gamesLabel(value: GameCompatibility[]) {
		return value.reduce((list: string[], v: GameCompatibility) => {
			let item = this.filters.games.items.find(x => x._id === v.gameId);
			if (item && item.name) {
				list.push(v.compatible === Compatibility.compatible ? item.name : `<s>${item.name}</s>`);
			}
			return list;
		}, []).join(", ");
	}

	public softwaresLabel(value: SoftwareCompatibility[]) {
		console.log("softwaresLabel", value);
		return value.reduce((list: string[], v: SoftwareCompatibility) => {
			let item = this.filters.softwares.items.find(x => x._id === v.softwareId);
			if (item && item.name) {
				list.push(v.compatible === Compatibility.compatible ? item.name : `<s>${item.name}</s>`);
			}
			return list;
		}, []).join(", ");
	}

	public partsLabel(value: PartCompatibility[]) {
		return value.reduce((list: string[], v: PartCompatibility) => {
			let item = this.filters.parts.items.find(x => x._id === v.partId);
			if (item && item.name) {
				list.push(v.compatible === Compatibility.compatible ? item.name : `<s>${item.name}</s>`);
			}
			return list;
		}, []).join(", ");
	}
}
