
















import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, ChangeCounterTask } from "@/loader";

@Component
export default class ChangeCounterTaskEditor extends Mixins<TaskDataEditorMixin<ChangeCounterTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire après avoir changé les compteurs",
	}
}
