import { BvListItem } from "@/loader";

export enum MessageStatus {
	draft = 0,
	sent = 1,
	deleted = 2,
	unread = 3,
	read = 4
}

export namespace MessageStatus {
	export function toString(messagestatus: MessageStatus): string {
		switch (messagestatus) {
			case MessageStatus.draft: return "Brouillon";
			case MessageStatus.sent: return "Envoyé";
			case MessageStatus.deleted: return "Supprimé";
			case MessageStatus.unread: return "Non Lu";
			case MessageStatus.read: return "Lu";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(MessageStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<MessageStatus>value) }));
}
