import { ConfigType } from "dayjs";
import {
	CouchDBDocument,
	UUID,
	generateUUID,
	Events,
	MissionStatus,
	checkValue,
	Named,
	cloneObject,
	checkArrayValue,
} from "@/loader";
import Vue from "vue";

export interface Mission extends CouchDBDocument, Events, Named {
	type: "mission"
	status: MissionStatus
	start: ConfigType
	end: ConfigType
	companyId: UUID | null
	techs: UUID[]
}

export namespace Mission {
	export const TYPE = "mission";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Mission {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		companyId: UUID | null = null,
		techs: UUID[] = [],
		status: MissionStatus = MissionStatus.planned,
		start: ConfigType = null,
		end: ConfigType = null,
		name: string = "",
	): Mission {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			companyId,
			techs,
			status,
			start,
			end,
			name,
			events: [],
		}
	}

	export function check(data: any): Mission {
		CouchDBDocument.check(data, TYPE);
		Named.check(data);
		Events.check(data);

		checkValue(data, "companyId", null);
		checkArrayValue(data, "techs");
		checkValue(data, "start", "");
		checkValue(data, "end", "");
		checkValue(data, "status", MissionStatus.planned);
		checkValue(data, "name", null);

		if ("date" in data) {
			if (!data.start) {
				Vue.set(data, "start", data.date);
			}
			delete data.date;
		}
		return data;
	}

	export function clone(base: Mission): Mission {
		return cloneObject(base, { status: MissionStatus.planned });
	}
}
























