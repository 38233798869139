import { checkValue } from "@/loader";

export interface Required {
	required: boolean;
}

export namespace Required {
	export function instanceOf(value: any): value is Required {
		return value  && typeof value === "object" && "required" in value;
	}

	export function check(data: any): Required {
		checkValue(data, "required", false);
		return data;
	}
}
