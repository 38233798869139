

























































import { Vue, Component, Prop } from "vue-property-decorator";
import { Address } from '@/loader';
import AddressEdit from "./AddressEdit.vue"
import AddressView from "./AddressView.vue"

@Component({
	components: {
		AddressEdit,
		AddressView,
	}
})
export default class AddressesCard extends Vue {
	@Prop({ type: Array, required: true })
	public addresses!: Address[];

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	public address: Address | null = null;

	public add() {
		this.edit(Address.create());
	}

	public edit(address: Address) {
		this.address = address;
		this.$bvModal.show("address_modal");
	}

	public cancel() {
		this.address = null;
	}

	public save() {
		if (!this.address) {
			return;
		}
		if (!this.addresses.includes(this.address)) {
			this.addresses.push(this.address);
			this.$emit('added', this.address);
		} else {
			this.$emit('updated', this.address);
		}
		this.$emit('change', this.address);
	}

	public remove(address: Address) {
		this.address = address;
		this.$bvModal.show("delete_address_modal");
	}

	public confirmRemove() {
		if (!this.address) {
			return;
		}
		this.addresses.remove(this.address);
		this.$emit('deleted', this.address);
		this.$emit('change', this.address);
	}
}
