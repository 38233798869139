














import { Component, Mixins } from "vue-property-decorator";
import { ReadingCounterRowSettings, EditComponent, ReadingCounterSettings } from "@/loader";
import ReadingCounterColumn from "./ReadingCounterColumn.vue";

@Component({
	components: {
		ReadingCounterColumn
	}
})
export default class ReadingCounterRow extends Mixins<EditComponent<ReadingCounterRowSettings, ReadingCounterSettings>>(EditComponent) {

}
