import { Component, Mixins, Prop } from "vue-property-decorator";
import { BusyMixin, DEBUG } from "@/loader";

export interface IEditor {
	changed: boolean
	clean(): void
	check(): boolean
	focusAll(): void
	unfocusAll(): void
}

export function isEditor(obj: any): obj is IEditor {
	return obj && typeof obj.clean === "function";
}

@Component
export class Editor extends Mixins(BusyMixin) implements IEditor {
	@Prop({ type: Boolean, default: false })
	public canRedirect!: boolean;

	@Prop({ type: String, default: "" })
	public redirectTitle!: string;

	public focused: { [prop in string]?: boolean; } = {};
	public invalidFeedbacks: { [prop in string]?: string; } = {}
	public localChanged: boolean = false;
	public useEditMode: boolean = false;
	public editMode: boolean = false;

	public get changed(): boolean {
		return this.localChanged;
	}
	public set changed(value: boolean) {
		if (this.localChanged !== value) {
			this.localChanged = value;
			this.$emit("changed", this.localChanged);
		}
	}

	public get states(): { [prop in string]?: boolean | null; } {
		return {};
	}

	public get routeName(): string {
		return "";
	}

	public get isNew(): boolean {
		return false;
	}

	public get itemFactory(): Function | null {
		return null;
	}

	public focusOn(prop: string): void {
		DEBUG && console.log("focusOn", prop);
		this.$set(this.focused, prop, true);
		this.changed = true;
	}

	public unfocusAll(): void {
		for (let p in this.focused) {
			this.focused[p] = false;
		}
		this.getRefs().forEach(x => isEditor(x) && x.unfocusAll())
	}

	public focusAll(): void {
		for (let p in this.focused) {
			this.focused[p] = true;
		}
		this.getRefs().forEach(x => isEditor(x) && x.focusAll())
	}

	public clean() {
		this.unfocusAll();
		this.changed = false;
		this.editMode = this.useEditMode && this.isNew;
		this.getRefs().forEach(x => isEditor(x) && x.clean())
	}

	public check(): boolean {
		this.focusAll();
		return !Object.entries(this.states).some(([, v]) => v === false) && this.getRefs().every(x => !isEditor(x) || x.check());
	}

	public getRefs(): (Vue | Element)[] {
		return <(Vue | Element)[]>Object.values(this.$refs).flat().filter(x => x && typeof x === "object");
	}

	public toTitleCase(value?: string | null) {
		let values = (value || "").split(/[^a-zA-Z0-9À-ž]+/g);
		return values.map(w => w.capitalize()).join("-");
	}

	public toUpperCase(value?: string | null) {
		return (value || "").toUpperCase();
	}

	public setChanged(value: boolean) {
		value = this.getRefs().some(x => isEditor(x) && x.changed) || !!value;
		this.changed = value;
	}

	public mounted() {
		this.editMode = this.useEditMode && this.isNew;
	}
}
