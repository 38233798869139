




























































import { Vue, Component, Prop, Watch, Mixins } from "vue-property-decorator";
// import MainMenu from "./view/MainMenu.vue"
import TutorialView from "./view/TutorialView.vue"
import LoginView from "./view/LoginView.vue";
// import ThemeTest from "./component/ThemeTest.vue"
import SidebarMenu from "./component/SidebarMenu.vue";
import { busy, servers, checkJwt, ConflictCheckerMixin, GlobalSettings } from "./loader";

@Component({
	components: {
		// MainMenu,
		TutorialView,
		LoginView,
		SidebarMenu,
		// ThemeTest,
	}
})
export default class Main extends Mixins(ConflictCheckerMixin) {
	public tuto: boolean = false;
	public login: boolean = false;

	public get isBusy(): boolean {
		return busy.status;
	}

	public get overlayText(): string {
		return busy.text;
	}

	public get overlayOptions(): BusyOverlayOptions {
		return busy.overlayOptions;
	}

	public get overlayIcon() {
		return busy.icon;
	}

	public get canLogin(): boolean {
		return (servers.settings?.accounts.length || 0) > 0;
	}

	public get isLoggedIn(): boolean {
		return !!servers.account;
	}

	public initUI(force: number = 0) {
		console.log("initUI", force, { ...servers.settings });
		this.tuto = (!this.canLogin && force !== 2) || force === 1;
		this.login = (this.canLogin && !this.isLoggedIn && force !== 1) || force === 2;
	}

	public async mounted() {
		busy.start("Chargement de l'application ...");
		this.addConflictChecker({ _id: GlobalSettings.KEY, _rev: "", db: "", callback: (e: SyncEvent, doc: CouchDBDocument) => {
			servers.setSettings(doc as GlobalSettings);
		} });
		servers.setSettings(await this.$db.getGlobalSettings());
		if (servers.settings?.account || servers.settings?.token) {
			try {
				console.log(checkJwt(servers.settings.token || ""));
				this.$root.startAccountHeartbeat(true);
				servers.initAccountServers();
				await this.$root.startAllServers();
			} catch (error) {
				this.$root.logout();
			}
		}
		this.$router.start();
		this.initUI();
		busy.stop();
	}

	@Watch("isLoggedIn")
	public loggedInChanged(newValue: boolean, oldValue: boolean) {
		if (newValue !== oldValue && !newValue) {
			this.initUI();
		}
	}
}
