import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DEBUG, Machine, Company, servers, toaster, DocumentSelect, MachineStatus } from '@/loader';
import { CreateElement, VNode } from "vue";

@Component
export class MachineSelect extends Mixins<DocumentSelect<Machine>>(DocumentSelect) {
	@Prop({ type: String, default: null })
	public companyId!: string | null;

	@Prop({ type: Boolean, default: false })
	public onlyUninstalled!: boolean;

	public companies: Company[] = []

	public async loadOptions() {
		if (this.onlyUninstalled) {
			DEBUG && console.log("MachineSelect->loadOptions(onlyUninstalled")
			this.options = await this.$db.queryMachinesByStatus(MachineStatus.stocked, {});
			return;
		}
		if (this.companyId) {
			DEBUG && console.log("MachineSelect->loadOptions(companyId)")
			this.options = await this.$db.queryMachinesByOwner(this.companyId, {});
			return;
		}

		this.$pouch.allDocs({
			startkey: Machine.STARTKEY,
			endkey: Machine.ENDKEY,
			include_docs: true
		}, servers.selected?.name).then(result => {
			DEBUG && console.log("MachineSelect->allDocs?machine:", result);
			this.options = result.rows.map(r => Machine.setSearchable(Machine.check(r.doc)));
		}).catch(error => {
			console.error("MachineSelect->allDocs?machine:", error);
			toaster.error({ error });
		});
	}

	public async mounted() {
		this.companies = await this.$db.allCompanies();
		this.loadOptions();
	}

	public getOptionLabel(option: Machine): string {
		let companyName = null;
		if (this.onlyUninstalled && option.ownerId) {
			let company = this.companies.find(c => c._id === option.ownerId);
			if (company) {
				companyName = "prévue pour " + company.name;
			}
		}
		return [option.serial, option.name, companyName].filter(x => !!x).join(" - ");
	}

	public optionSlot(h: CreateElement, option: Machine): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: Machine): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public applyFilter(options: Machine[], search: string) {
		search = search.toLowerCase().removeDiacritics();
		return options.filter(option => option.searchable?.toLowerCase().removeDiacritics().includes(search));
	}

	@Watch("companyId")
	public companyIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	@Watch("onlyUninstalled")
	public onlyUninstalledChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	public get editRoute(): string {
		return "machine";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}
}
