import { Component, Mixins, Prop } from "vue-property-decorator";
import { FlagSelect, ParentSelect, Game, BvListItem, Denomination, GameVariant } from '@/loader';

@Component
export default class GameDenominationSelect extends Mixins<FlagSelect<Denomination>, ParentSelect<Game>>(FlagSelect, ParentSelect) {
	public localOptions: BvListItem[] = Denomination.LIST;

	@Prop({ type: Array, default: () => [] })
	public compatibilities!: GameVariant[];

	// @ts-ignore
	public get options(): BvListItem[] {
		let result: BvListItem[] = [];
		if (this.compatibilities.length) {
			result = Denomination.toArray(
					this.compatibilities.reduce((d: number, c) => d | c.denos, 0)
				).map(value => ({ value, text: Denomination.toString(value) }));
		}

		if (!result.length) {
			result = this.parent
				? Denomination.toArray(this.parent.denos).map(value => ({ value, text: Denomination.toString(value) }))
				: this.localOptions
					? this.localOptions.slice(0)
					: [];
		}
		return result;
	}
}
