









































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import {
	servers,
	toaster,
	EditView,
	Game,
	Denomination,
	Volatility,
	GAME_LINES,
	GAME_BETS,
	GameFeature,
	GameVariant,
	GameRate,
} from "@/loader";
import GameBetSelect from "../../../component/game/GameBetSelect";
import GameControllerList from "../../../component/game/GameControllerList.vue";
import GameFeatureTable from "../../../component/game/GameFeatureTable.vue";
import GameLineSelect from "../../../component/game/GameLineSelect";
import GameRateTable from "../../../component/game/GameRateTable.vue";
import GameVariantTable from "../../../component/game/GameVariantTable.vue";
import PackGameTable from "../../../component/game/PackGameTable.vue";
import GameCompatibilityTable from "../../../component/game/GameCompatibilityTable.vue";
import PartCompatibilityTable from "../../../component/part/PartCompatibilityTable.vue";
import SoftwareCompatibilityTable from "../../../component/software/SoftwareCompatibilityTable.vue";

@Component({
	components: {
		GameBetSelect,
		GameControllerList,
		GameFeatureTable,
		GameLineSelect,
		GameRateTable,
		GameVariantTable,
		PackGameTable,
		GameCompatibilityTable,
		PartCompatibilityTable,
		SoftwareCompatibilityTable,
	}
})
export default class GameView extends Mixins<EditView<Game>>(EditView) {
	public denos = Denomination.LIST;
	public volatilities = Volatility.LIST;
	public lines = GAME_LINES;
	public bets = GAME_BETS;
	public games: Game[] = [];

	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		brand: "",
		ref: "",
		categoryId: "",
		denos: "",
		lines: "",
		antebets: "",
		maxbets: "",
		volatilities: "",
		frequencies: "",
		games: "Au moins un jeu est requis dans un pack"
	};

	public get states() {
		return {
			name: this.focused.name ? !!this.item?.name : null,
			brand: this.focused.brand ? true : null,
			ref: this.focused.ref ? true : null,
			categoryId: this.focused.categoryId ? true : null,
			denos: this.focused.denos ? true : null,
			lines: this.focused.lines ? true : null,
			antebets: this.focused.antebets ? true : null,
			maxbets: this.focused.maxbets ? true : null,
			volatilities: this.focused.volatilities ? true : null,
			frequencies: this.focused.frequencies ? true : null,
			games: this.focused.games && this.item ? !this.item.isPack || this.item.games.length > 0 : null
		}
	}

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get itemFactory(): Function | null {
		return Game.create;
	}

	public get cloneFactory(): (base: Game) => Game {
		return Game.clone;
	}

	public get checkDocFunction(): Function | null {
		return Game.check;
	}

	public get routeName(): string {
		return "game";
	}

	public get title(): string {
		if (this.item) {
			return (this.item.isPack ? "Pack" : "Jeu") + " &mdash; " + this.item.name;
		}
		return "Jeu";
	}

	public addRate(item: GameRate) {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Nouveau Taux" });
			return;
		}
		if (!this.item || !item) {
			toaster.invalidOperation({ operation: "Nouveau Taux" });
			return;
		}
		this.item.rates.push(item);
		this.focusOn("rates");
	}

	public addFeature(item: GameFeature) {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Nouveau Feature" });
			return;
		}
		if (!this.item) {
			toaster.invalidOperation({ operation: "Nouveau Feature" });
			return;
		}
		this.item.features.push(item);
		this.changed = true;
	}

	public addVariant(item: GameVariant) {
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Nouvelle Variation" });
			return;
		}
		if (!this.item) {
			toaster.invalidOperation({ operation: "Nouvelle Variation" });
			return;
		}
		this.item.variants.push(item);
		this.changed = true;
	}
}
