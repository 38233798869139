<template>
	<b-card>
		<b-card-text>
			Nous allons vous guider durant la création de votre compte
			et vous expliquer comment fonctionne l'application.
		</b-card-text>
		<b-card-text>
			La première étape va consister à créer un compte.
			Vous pourrez définir ce compte comme vous le souhaitez (identifiant, email et mot de passe à votre guise).
		</b-card-text>
		<b-alert variant="info" show>
			Il ne s'agit pas ici des informations de connexion (identifiant &amp; mot de passe) fournis par votre/vos SFM.
		</b-alert>
		<b-alert variant="warning" show>
			Cependant, ce compte ne sera valable que sur le site de la SFM {{ host }}.
		</b-alert>
		<b-card-text>
			Une fois ce compte créé vous pourrez renseigner les informations de connexion fournis par chaque SFM.
			Vous pourrez ainsi consulter toutes les données de chacune des SFM directement sur ce site.
		</b-card-text>
	</b-card>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { TutorialComponent } from '@/loader';

@Component
export default class TutorialWelcome extends Mixins(TutorialComponent) {
	public title = "Bienvenue sur SFMPro";
	public icon = "";

	public get host() {
		return window.location.host;
	}
}
</script>
