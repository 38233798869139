import {
	checkArrayValue,
	checkValue,
	cloneObject,
	CouchDBDocument,
	Deactivable,
	Events,
	generateUUID,
	HasCompatibilities,
	Named,
	SemVer,
	UUID,
} from "@/loader";

export interface Part extends CouchDBDocument, Events, Named, Deactivable, HasCompatibilities {
	type: "part"
	softwareId: string | null
	categoryId: string | null
	versionMin: SemVer | null
	versionMax: SemVer | null
	brand: string | null
	model: string | null
	ref: string | null
	children: PartChild[]
}

export interface PartChild {
	partId: UUID
	quantity: number
}

export namespace Part {
	export const TYPE = "part";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Part {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		brand: string | null = null,
		model: string | null = null,
		ref: string | null = null,
		categoryId: string | null = null,
		softwareId: string | null = null,
		versionMin: string | null = null,
		versionMax: string | null = null,
	): Part {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			softwareId,
			categoryId,
			versionMin,
			versionMax,
			brand,
			model,
			ref,
			name,
			children: [],
			disabled: false,
			...Events.create(),
			...HasCompatibilities.create(),
		}
	}

	export function clone(base: Part): Part {
		return cloneObject(base, {
			children: base.children.map(PartChild.clone),
			...HasCompatibilities.clone(base),
		});
	}

	export function check(data: any): Part {
		CouchDBDocument.check(data, TYPE);
		Named.check(data);
		Deactivable.check(data);
		Events.check(data);
		HasCompatibilities.check(data);
		checkValue(data, "softwareId", null, undefined, ["osId"]);
		checkValue(data, "categoryId", null);
		checkValue(data, "versionMin", null, undefined, ["osMin"]);
		checkValue(data, "versionMax", null, undefined, ["osMax"]);
		checkValue(data, "brand", null);
		checkValue(data, "model", null);
		checkValue(data, "ref", null);
		checkValue(data, "name", null);
		checkArrayValue(data, "children", PartChild.check);
		return data;
	}
}

export namespace PartChild {
	export function create(
		partId: UUID,
		quantity: number = 1
	): PartChild {
		return {
			partId,
			quantity,
		}
	}

	export function clone(base: PartChild): PartChild {
		return cloneObject(base);
	}

	export function check(data: any): PartChild {
		if (typeof data === "string") {
			return create(data);
		}

		checkValue(data, "partId", "");
		checkValue(data, "quantity", 1);
		return data;
	}
}
