import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	User,
	DocBrand,
	DEBUG,
	servers,
	toaster,
	DocumentFilterCard,
	PouchResult,
	USER_STARTKEY,
	USER_ENDKEY,
	AuthResponse,
} from "@/loader";

@Component
export class UserFilterCard extends Mixins<DocumentFilterCard<User>>(DocumentFilterCard) {
	@Prop({ type: Function, default: (r: PouchResult, defs: User[]) => {
		let key = Array.isArray(r.key) ? r.key[r.key.length - 1] : r.key;
		let doc = defs.find(d => d._id === key)
		return {
			_id: key,
			name: doc ? doc.firstName + " " + doc.lastName : key,
			count: r.value,
			doc
		};
	}})
	public readonly itemFactory!: (r: PouchResult, defs?: User[]) => DocBrand<User>;

	@Prop({ type: String, default: USER_STARTKEY })
	public readonly startkey!: string;

	@Prop({ type: String, default: USER_ENDKEY })
	public readonly endkey!: string;

	public async loadPrerequisites() {
		if (!servers.selected) {
			return;
		}

		try {
			let response: Response = await fetch(servers.selected.auth, {
				method: "POST",
				body: JSON.stringify({ action: "list" }),
				headers: {
					Authorization: `Bearer ${servers.selected.settings.token}`
				}
			});
			let result: AuthResponse = await response.json();
			DEBUG && console.log("UserFilterCard->allDocs?user:", result);
			if (result.code !== 200 || !result.users) {
				throw result;
			}
			this.definitions = result.users.map(User.check);
			this.$emit("definitions-loaded", this.definitions);
		} catch (error) {
			console.error("UserFilterCard->allDocs?user:", error);
			toaster.error({ error });
			this.definitions = [];
			this.$emit("definitions-loaded", []);
		}
	}
}
