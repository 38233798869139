import { Component, Mixins } from "vue-property-decorator";
import { EnumSelect, MachineType, BvListItem } from "@/loader";

@Component
export class MachineTypeSelect extends Mixins<EnumSelect<MachineType>>(EnumSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return MachineType.LIST;
	}
}
