import {
	UUID,
	Category,
	createCategory,
	checkCategory,
	checkValue,
	Compatible,
	Compatibility,
	cloneCategory,
	CouchDBDocument,
} from "@/loader";

export interface PartCategory extends Category {
	type: "part-category";
	compatibilities: PartCategoryCompatibility[];
}

export interface PartCategoryCompatibility extends Compatible {
	categoryId: UUID
}

export namespace PartCategory {
	export const TYPE = "part-category";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is PartCategory {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		parentId: UUID = ""
	): PartCategory {
		return <PartCategory>Object.assign(createCategory(TYPE, name, parentId), {
			compatibilities: [],
		});
	}

	export function clone(base: PartCategory): PartCategory {
		return cloneCategory(base, { compatibilities: base.compatibilities.map(PartCategoryCompatibility.clone) });
	}

	export function check(data: any): PartCategory {
		return <PartCategory>checkCategory(data, TYPE);
	}
}

export namespace PartCategoryCompatibility {
	export function create(
		categoryId: UUID,
		compatible: Compatibility = Compatibility.unknown,
	): PartCategoryCompatibility {
		return {
			categoryId,
			compatible,
		}
	}

	export function clone(base: PartCategoryCompatibility): PartCategoryCompatibility {
		return Object.assign({}, base);
	}

	export function check(data: any): PartCategoryCompatibility {
		if (typeof data === "string") {
			return create(data);
		}

		Compatible.check(data);
		checkValue(data, "categoryId", "");
		return data;
	}
}
