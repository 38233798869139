import {
	Contact,
	CouchDBDocument,
	Named,
	Deactivable,
	generateUUID,
	Events,
	Addresses,
	Coordinates,
	checkArrayValue,
	checkValue,
	ErpObject,
	Address,
	Coordinate,
	cloneObject,
} from "@/loader";

export interface Company extends CouchDBDocument, Events, Named, Deactivable, Addresses, Coordinates, ErpObject {
	type: "company"
	ownerId: string | null
	contacts: Contact[]
}

export namespace Company {
	export const TYPE = "company";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Company {
		return CouchDBDocument.instanceOf(value) && value.type == TYPE;
	}

	export function create(name: string, owner?: Company | string | null): Company {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			ownerId: instanceOf(owner) ? owner._id : owner || null,
			erpId: null,
			erpUpdateDate: null,
			name,
			disabled: false,
			contacts: [],
			addresses: [],
			coordinates: [],
			events: [],
		}
	}

	export function clone(base: Company): Company {
		return cloneObject(base, {
			addresses: base.addresses.map(Address.clone),
			coordinates: base.coordinates.map(Coordinate.clone),
			contacts: base.contacts.map(Contact.clone),
		});
	}

	export function check(data: any): Company {
		CouchDBDocument.check(data, TYPE);
		ErpObject.check(data);
		Named.check(data);
		Deactivable.check(data);
		checkValue(data, "ownerId", null);
		checkArrayValue(data, "contacts", Contact.check);
		Addresses.check(data);
		Coordinates.check(data);
		Events.check(data);
		return data;
	}
}
