import { CreateElement, VNode } from "vue";
import { Component, Model, Prop, Watch, Mixins } from "vue-property-decorator";
import { busy, Deactivable, PopperDropdown } from "@/loader";

export type VueSelectSelectOptionContainer<T> = { option: T, deselect: Function, multiple: boolean, disabled: boolean }

@Component
export class DataSelect extends Mixins(PopperDropdown) {
	public localOptions: any[] = [];
	public emptyItem: string | object = "Aucun";

	@Model("input", { default: null })
	public value!: any;

	@Prop({ type: Boolean, default: false })
	public multiple!: boolean;

	@Prop({ type: Boolean, default: null })
	public closeOnSelect!: boolean | null;

	@Prop({ type: Boolean, default: false })
	public required!: boolean;

	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ type: Function, default: (option: any) => option })
	public reduce!: Function;

	@Prop({ type: Boolean, default: false })
	public disableEmpty!: boolean;

	@Prop({ type: Boolean, default: false })
	public taggable!: boolean;

	@Prop({ type: String, default: "Aucun" })
	public emptyText!: string;

	@Prop({ type: String, default: "" })
	public name!: string;

	public get isBusy(): boolean {
		return busy.status;
	}

	public get showEmpty(): boolean {
		return !this.required && !this.multiple && !this.disableEmpty;
	}

	public get options(): any[] {
		let result = this.localOptions ? this.localOptions.slice() : [];
		if (this.showEmpty) {
			result.unshift(this.emptyItem);
		}
		return this.filterOptions(result);
	}

	public set options(value: any[]) {
		// DEBUG && console.log("DataSelect.options.set", value, this.localOptions);
		this.localOptions = value;
	}

	public get localCloseOnSelect(): boolean {
		return this.closeOnSelect !== null ? this.closeOnSelect : !this.multiple;
	}

	public get textField(): string {
		return "name";
	}

	public focus() {
		(<HTMLInputElement>this.$el.querySelector(".vs__search"))?.focus();
	}

	public filterOptions(options: any[]): any[] {
		return options;
	}

	public applyFilter(options: any[], search: string) {
		search = search.toLowerCase().removeDiacritics();
		return options.filter(option => this.getOptionLabel(option).toLowerCase().removeDiacritics().includes(search));
	}

	public render(h: CreateElement): string | VNode | (string | VNode | null)[] {
		let select = h("v-select", {
			// class: "form-control",
			attrs: {
				name: this.name,
			},
			ref: "select",
			props: {
				appendToBody: true,
				selectOnTab: true,
				closeOnSelect: this.localCloseOnSelect,
				value: this.value,
				multiple: this.multiple,
				disabled: this.isBusy || !this.canEdit,
				options: this.options,
				reduce: this.reduce,
				taggable: this.taggable,
				label: this.textField,
				filter: this.applyFilter,
				getOptionKey: this.getOptionKey,
				getOptionLabel: this.getOptionLabel,
				// calculatePosition: this.calculatePosition,
			},
			on: {
				input: (value: any) => this.$emit("input", value),
				"option:created": (option: any) => this.createOption(option)
			},
			scopedSlots: {
				option: (option: any) => this.optionSlot(h, option),
				// "selected-option": (option: any) => this.selectedOptionSlot(h, option),
				"selected-option-container": (args: VueSelectSelectOptionContainer<any>) => this.selectedOptionContainerSlot(h, args),
			},
		});

		return select;
	}

	public createOption(option: any) {
		this.localOptions.push(option);
	}

	public getOptionKey(option: any): string | null {
		return option.value ? option.value : option.name ? option.name : option;
	}

	public getOptionLabel(option: any): string {
		return option && option.name ? option.name : option.toString();
	}

	public optionSlot(h: CreateElement, option: any): string | VNode | (string | VNode | null)[] {
		if (option.label && option.options && Array.isArray(option.options)) {
			return [
				option.label,
				h("ul", option.options.map((o: any) => h("li", [this.optionSlot(h, o)])))
			];
		}
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: any): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionContainerSlot(h: CreateElement, args: VueSelectSelectOptionContainer<any>): string | VNode | (string | VNode | null)[] {
		return h(
			"span",
			{
				class: "vs__selected",
			},
			[
				this.getOptionLabel(args.option),
				args.multiple ? h(
					"button",
					{
						ref: "deselectButtons",
						class: "vs__deselect",
						attrs: {
							type: "button",
							disabled: args.disabled,
							title: `Désélectionner ${this.getOptionLabel(args.option)}`,
							ariaLabel: `Désélectionner ${this.getOptionLabel(args.option)}`,
						},
						on: {
							click: (event: Event) => {
								args.deselect(args.option);
							}
						}
					},
					[
						h("fa", { props: { icon: "times" }, class: "text-muted" })
					]
				) : null
			]
		);
	}

	public selectable(option: any): boolean {
		return Deactivable.instanceOf(option) ? !option.disabled : true;
	}

	@Watch("emptyText", { immediate: true })
	public onEmptyTextChanged(newValue: string, oldValue: string) {
		if (newValue !== oldValue) {
			this.emptyItem = newValue;
		}
	}
}
