import { DirectiveOptions } from "vue";
import Sortable from "sortablejs";
import dayjs from "dayjs";

export type AllDocsOptions = PouchDB.Core.AllDocsWithKeyOptions | PouchDB.Core.AllDocsOptions | PouchDB.Core.AllDocsWithKeysOptions | PouchDB.Core.AllDocsWithinRangeOptions | PouchDB.Query.Options<{}, {}>;

export const number = new Intl.NumberFormat("fr", {
	style: "decimal",
	useGrouping: true,
	minimumIntegerDigits: 1,
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
});

export const numberWithSign = new Intl.NumberFormat("fr", {
	style: "decimal",
	useGrouping: true,
	minimumIntegerDigits: 1,
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
	signDisplay: "exceptZero",
});

export const currency = new Intl.NumberFormat("fr", {
	style: "currency",
	currency: "EUR",
	currencyDisplay: "symbol",
	useGrouping: true,
	minimumIntegerDigits: 1,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const currencyNoDecimal = new Intl.NumberFormat("fr-FR", {
	style: "currency",
	currency: "EUR",
	currencyDisplay: "symbol",
	useGrouping: true,
	minimumIntegerDigits: 1,
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const percent = new Intl.NumberFormat("fr-FR", {
	style: "percent",
	useGrouping: true,
	minimumIntegerDigits: 1,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const sortable: DirectiveOptions = {
	bind(el, binding, vnode) {
		const target = el.querySelector("tbody") || el.querySelector("table");
		if (target) {
			// @ts-ignore
			el.$sortable = new Sortable(target, { ...binding.value });
		}
	}
}

// export function diff<T>(a: T, b: T) {
// 	return Object.keys(a).reduce((result, key) => {
// 		return result;
// 	}, {});
// }

export async function isOnline() {
	if (!navigator.onLine) {
		return false;
	}
	const url = new URL(window.location.origin);
	url.searchParams.set("dt", dayjs().format("YYYY-MM-DD-HH-mm-ss"));
	try {
		return (await fetch(url, { method: "HEAD" })).ok;
	} catch (error) {
		return false;
	}
}
