import { CreateElement } from 'vue';
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import {
	Task,
	ReadingTask,
	EditComponent,
	toaster,
	Reading,
	Event,
	servers,
	EventType,
	Machine,
	Mission,
	ReadingStatus,
	DEBUG
} from "@/loader";
import ReadingEditor from "../../reading/ReadingEditor.vue";

@Component({
	components: {
		ReadingEditor
	}
})
export default class ReadingTaskEditor extends Mixins<EditComponent<ReadingTask, Task>>(EditComponent) {
	public reading: Reading | null = null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Boolean, default: false })
	public asCard!: boolean;

	@Ref()
	public readonly readingEditor!: ReadingEditor;

	public focus() {
		if (this.item?.reading) {
			DEBUG && console.log(this.$el.querySelector('.reading-counter'));
			(<HTMLInputElement>this.$el.querySelector('.reading-counter'))?.focus();
		} else {
			(<HTMLInputElement>this.$el.querySelector('button'))?.focus();
		}
	}

	public async createNewReading() {
		const operation = "Commencer la relève";
		if (!this.canEdit) {
			toaster.forbidden({ operation })
			return;
		}
		if (!this.item) {
			toaster.noItem({ operation, itemType: "données de tâche" })
			return;
		}
		if (this.item.reading) {
			toaster.warn({ message: "Une relève est déjà en cours" })
			return;
		}
		if (!this.parent) {
			toaster.noItem({ operation, itemType: "tâche" })
			return;
		}
		if (!this.machine) {
			toaster.noItem({ operation, itemType: "machine" })
			return;
		}
		let reading = Reading.create(
			this.machine._id,
			this.machine.osId,
			this.parent.missionId,
			this.parent._id,
			this.parent.action
		);
		reading.events.push(Event.create(servers.author, EventType.created));

		this.item.reading = reading._id;
		this.reading = reading;
		this.focusOn("reading");
		setTimeout(() => this.focus(), 200);
	}

	public async mounted() {
		this.reading = await this.$db.getReading(this.item?.reading || "");
		if (this.item?.reading && !this.reading) {
			this.item.reading = "";
			this.focusOn("reading");
		}
	}

	public async save(finish: boolean = false) {
		if (!this.reading) {
			return false;
		}
		DEBUG && console.log("ReadingTaskEditor.save", { reading: this.reading, finish });
		let oldStatus = this.reading.status;
		if (finish) {
			this.reading.status = ReadingStatus.done;
			this.reading.events.push(Event.create(servers.author, EventType.done));
		} else {
			this.reading.events.push(Event.create(servers.author, EventType.modified));
		}
		let result = await this.$db.save(this.reading);
		if (!result) {
			this.reading.status = oldStatus;
			this.reading.events.pop();
		} else {
			this.clean();
		}
		return result;
	}

	public render(h: CreateElement) {
		if (!this.item?.reading) {
			return h(this.asCard ? "b-card" : "div",
					{
						class: "reading-task-editor text-center" + (this.asCard ? " border-top-0" : ""),
						style: this.asCard ? "border-top-left-radius: 0; border-top-right-radius: 0;" : "",
					},
					[
						h("b-button",
							{
								ref: "start_button",
								props: { variant: "primary", },
								on: { click: this.createNewReading }
							},
							[
								h("fa", { props: { icon: ["fal", "glasses"], fixedWidth: true } }),
								" Commencer la relève"
							]
						)
					]
				);
		} else {
			return h("reading-editor", {
				ref: "readingEditor",
				props: {
					canEdit: this.canEdit,
					machineId: this.parent?.machineId,
					value: this.reading,
					tag: this.asCard ? "b-card" : "div",
				},
				class: this.asCard ? "border-top-0" : "",
				style: this.asCard ? "border-top-left-radius: 0; border-top-right-radius: 0;" : "",
				on: {
					changed: this.setChanged
				}
			})
		}
	}
}
