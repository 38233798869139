import { ActionContext } from "vuex";
import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { store } from "@/loader";
import { BvToast, BvToastOptions } from "bootstrap-vue";

@Module({
	name: "toaster",
	store,
	dynamic: true,
	namespaced: true,
})
export class ToasterModule extends VuexModule {
	public bvToast!: BvToast;
	public defaultOptions: BvToastOptions = {
		solid: true,
		toaster: "b-toaster-top-center",
	};
	public noAutoHideVariant: string[] = ["danger"];

	@Mutation
	public setToaster(toaster: BvToast) {
		this.bvToast = toaster;
	}

	@Mutation
	public setDefaultOptions(payload: BvToastOptions) {
		this.defaultOptions = payload;
	}

	@Mutation
	public toast(payload: BvToastOptions & { message: string }) {
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send an error message
	 *
	 * @param payload
	 */
	@Mutation
	public error(payload: BvToastOptions & { error?: any, message?: string }) {
		payload.title = payload.title || "Une erreur est survenue";
		payload.variant = payload.variant || "danger";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message || "";
		if (!message && payload.error) {
			if (typeof payload.error === "object") {
				if (payload.error.message) {
					message = payload.error.message;
				}
			}

			if (!message) {
				message = `${payload.error}`;
			}
		}
		console.error(payload);
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Required
	 */
	@Mutation
	public warn(payload: BvToastOptions & { message: string }) {
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `[L'opération "${payload.operation}"|Cette opération] nécessite qu'un serveur soit actif.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 */
	@Mutation
	public noServer(payload?: BvToastOptions & { message?: string, operation?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Aucun serveur actif";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message
			|| `${payload.operation ? `L'opération "${payload.operation}"` : 'Cette opération'} nécessite qu'un serveur soit actif.`;
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `Vous n'avez pas l'autorisation d'effectuer [l'opération "${payload.operation}"|cette opération].`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 */
	@Mutation
	public forbidden(payload?: BvToastOptions & { message?: string, operation?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Opération non autorisée";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message
			|| `Vous n'avez pas l'autorisation d'effectuer ${payload.operation ? `l'opération "${payload.operation}"` : 'cette opération'}.`;
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `Vous n'avez effectué aucune modification.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 */
	@Mutation
	public noChange(payload?: BvToastOptions & { message?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message
			|| "Vous n'avez effectué aucune modification.";
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload,
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `Veuillez sauvegarder les modifications.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 */
	@Mutation
	public saveFirst(payload?: BvToastOptions & { message?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message
			|| "Veuillez sauvegarder les modifications.";
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload,
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `Les données saisies ne sont pas correctes.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 */
	@Mutation
	public invalidData(payload?: BvToastOptions & { message?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		let message = payload.message
			|| "Les données saisies ne sont pas correctes.";
		this.bvToast.toast(message, {
			...this.defaultOptions,
			...payload,
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `L'opération ["${payload.operation}"] ne peut pas être effectuée.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 */
	@Mutation
	public invalidOperation(payload?: BvToastOptions & { message?: string, operation?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message || `L'opération${payload.operation ? ` "${payload.operation}"` : ""} ne peut pas être effectuée.`, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `L'opération ["${payload.operation}"] ne peut pas être effectuée : [${payload.reason}|le changement demandé n'est pas valide].`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 * @param {string} payload.reason Optional. Default "le changement demandé n'est pas valide"
	 */
	@Mutation
	public invalidWorkflow(payload?: BvToastOptions & { message?: string, operation?: string, reason?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		payload.reason = payload.reason || "le changement demandé n'est pas valide";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message || `L'opération${payload.operation ? ` "${payload.operation}"` : ""} ne peut pas être effectuée : ${payload.reason}.`, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message
	 *
	 * @model `L'opération ["${payload.operation}"] nécessite d'être connecté à Internet.`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 */
	@Mutation
	public noConnection(payload?: BvToastOptions & { message?: string, operation?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message || `L'opération${payload.operation ? ` "${payload.operation}"` : ""} nécessite d'être connecté à Internet.`, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a warning message.
	 *
	 * @model `L'opération ["${payload.operation}"] nécessite d'avoir un élément valide [de type ${payload.itemType}].`
	 * @param payload
	 * @param {string} payload.title Optional. Default "Oups".
	 * @param {string} payload.variant Optional. Default "warning".
	 * @param {string} payload.message Optional. Use model if undefined.
	 * @param {string} payload.operation Optional. Ignored if undefined.
	 */
	@Mutation
	public noItem(payload?: BvToastOptions & { message?: string, operation?: string, itemType?: string }) {
		payload = payload || {};
		payload.title = payload.title || "Oups";
		payload.variant = payload.variant || "warning";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message || `L'opération${payload.operation ? ` "${payload.operation}"` : ""} nécessite d'avoir un élément valide${payload.itemType ? ` de type ${payload.itemType}` : ""}.`, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send an info message
	 *
	 * @param payload
	 * @param {string} payload.title Optional. Default "Information".
	 * @param {string} payload.variant Optional. Default "info".
	 * @param {string} payload.message Required
	 */
	@Mutation
	public info(payload: BvToastOptions & { message: string }) {
		payload.title = payload.title || "Information";
		payload.variant = payload.variant || "info";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message, {
			...this.defaultOptions,
			...payload
		});
	}

	/**
	 * Send a success message
	 *
	 * @param payload
	 * @param {string} payload.title Optional. Default "Opération réussie".
	 * @param {string} payload.variant Optional. Default "success".
	 * @param {string} payload.message Required
	 */
	@Mutation
	public success(payload: BvToastOptions & { message: string }) {
		payload.title = payload.title || "Opération réussie";
		payload.variant = payload.variant || "success";
		if (payload.noAutoHide === undefined && payload.variant) {
			payload.noAutoHide = this.noAutoHideVariant.includes(payload.variant);
		}
		this.bvToast.toast(payload.message, {
			...this.defaultOptions,
			...payload
		});
	}
}

export const toaster = getModule(ToasterModule, store);

export function commitToasterToast(context: ActionContext<any, any>, payload: BvToastOptions & { message: string }) {
	context.commit("toaster/toast", payload, { root: true });
}
