










import { Vue, Component, Prop } from "vue-property-decorator";
import { Events, Event } from '@/loader';
import EventCard from "./EventCard.vue";

@Component({
	components: {
		EventCard,
	}
})
export default class EventsSidebar extends Vue {
	@Prop({ type: Object, default: null })
	public item!: Events | null;

	public get events(): Event[] {
		return this.item && this.item.events || [];
	}
}
