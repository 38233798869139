export interface BvListItem {
	text?: string
	value: any
	html?: string
	disabled?: boolean
}

export interface BvListGroup {
	label: string
	options: BvListItems
}

export type BvListItems = (string | BvListItem | BvListGroup)[];

export enum BVariant {
	primary = "primary",
	secondary = "secondary",
	success = "success",
	warning = "warning",
	danger = "danger",
	info = "info",
	outlinePrimary = "outline-primary",
	outlineSecondary = "outline-secondary",
	outlineSuccess = "outline-success",
	outlineWarning = "outline-warning",
	outlineDanger = "outline-danger",
	outlineInfo = "outline-info",
}
