




























































import { Component, Prop, Mixins, Watch, Ref } from "vue-property-decorator";
import {
	EditComponent,
	SoftwareCounterValue,
	Reading,
	CounterType,
	Machine,
	ValueUnit,
	MECA_MAX,
}
from "@/loader";

@Component
export default class ReadingCounterInput extends Mixins<EditComponent<SoftwareCounterValue, Reading>>(EditComponent) {
	@Prop({ type: Number, default: 0 })
	public displayPrevious!: number;

	@Prop({ type: Number, default: 0 })
	public deltaMargin!: number;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Ref()
	public readonly input!: HTMLInputElement;

	public get isMeca(): boolean {
		return this.item && CounterType.isMeca(this.item.counter.definition) || false;
	}

	public get isValue(): boolean {
		return ((this.machine?.unit || ValueUnit.none) & ValueUnit.value) === ValueUnit.value;
	}

	public get min() {
		return 0;
	}

	public get step() {
		return this.isMeca ? 1 : 0.01;
	}

	public get max() {
		return this.isMeca ? MECA_MAX : undefined;
	}

	// public last(i: number = -1): ReadingCounter | null {
	// 	return this.item?.previous.length > i + 1 ? this.item.previous[i + 1] : null;
	// }

	// public delta(t: ReadingCounter, i: number): number {
	// 	let last = this.last(i);
	// 	if (this.isMeca && last?.value > t.value) {
	// 		return t.value + MECA_MAX + 1 - last.value; // +1 because the tick to zero count as an increment
	// 	}
	// 	return t.value - (last?.value || 0);
	// }

	public counterValueChanged() {
		SoftwareCounterValue.valueChanged(this.item, this.deltaMargin);
		this.focusOn("value");
		// if (!this.item) {
		// 	return;
		// }

		// this.item.manual = true;
		// // if (this.item.value.unavailable) {
		// // 	this.item.value.value = 0;
		// // }
		// this.item.delta = this.delta(this.item.value, -1);
		// this.changed = true;

		// if (this.isMeca) {
		// 	this.item.deltaElec = this.item.delta - Math.trunc(this.item.elecs.reduce((d, e) => d + e.delta, 0));
		// 	this.item.valid = !this.item.deltaElec;
		// } else {
		// 	this.item.mecas.forEach(m => {
		// 		if (!m.manual) {
		// 			m.delta = m.elecs.reduce((sum, e) => sum + e.delta, 0);
		// 			m.value.value = m.previous.length ? m.previous[0].value + m.delta : m.delta;
		// 			if (m.value.value > MECA_MAX) {
		// 				m.value.value -= MECA_MAX + 1; // -1 because the tick to zero count as an increment
		// 			}
		// 		}
		// 		m.deltaElec = m.delta - Math.trunc(m.elecs.reduce((d, e) => d + e.delta, 0));
		// 		m.valid = !m.deltaElec;
		// 	});
		// }
	}

	public focus() {
		this.input.focus();
	}

	@Watch("item", { immediate: true })
	public onItemChanged(newValue: any, oldValue?: any) {
		if (newValue !== oldValue) {
			this.clean();
			this.original = JSON.parse(JSON.stringify(newValue?.value || null));
		}
	}
}
