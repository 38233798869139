


































































































































































































































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { AppSettings, EditView, ReadingCounterRowSettings, ReadingCounterColumnSettings } from "@/loader";
import ReadingCounterRow from "../../../component/settings/ReadingCounterRow.vue";

@Component({
	components: {
		ReadingCounterRow
	}
})
export default class SettingsView extends Mixins<EditView<AppSettings>>(EditView) {
	@Prop({ type: String, default: AppSettings.KEY })
	public id!: string;

	public async loadItem() {
		this.item = await this.$db.getAppSettings();
	}

	public get rowsCount() {
		return this.item?.readingCounters.rows.length || 0;
	}

	public get columnsCount() {
		return this.item?.readingCounters.rows[0]?.columns.length || 0;
	}

	public get canIncreaseRows() {
		return !!this.item;
	}

	public get canDecreaseRows() {
		return this.rowsCount > 1;
	}

	public get canIncreaseColumns() {
		return !!this.item;
	}

	public get canDecreaseColumns() {
		return this.columnsCount > 1;
	}

	public increaseRows() {
		if (this.canIncreaseRows && this.item) {
			let row = ReadingCounterRowSettings.create();
			row.columns.push(...Array(this.columnsCount).fill(0).map(_ => ReadingCounterColumnSettings.create()))
			this.item.readingCounters.rows.push(row);
			this.focusOn("readingCounters");
		}
	}

	public decreaseRows() {
		if (this.canDecreaseRows && this.item) {
			this.item.readingCounters.rows.pop();
			this.focusOn("readingCounters");
		}
	}

	public increaseColumns() {
		if (this.canIncreaseColumns && this.item) {
			this.item.readingCounters.rows.forEach(row => {
				row.columns.push(ReadingCounterColumnSettings.create());
			});
			this.focusOn("readingCounters");
		}
	}

	public decreaseColumns() {
		if (this.canDecreaseColumns && this.item) {
			this.item.readingCounters.rows.forEach(row => {
				row.columns.pop();
			});
			this.focusOn("readingCounters");
		}
	}
}
