
























































































import { Component, Mixins } from "vue-property-decorator";
import { Counter, servers, EditView } from "@/loader";

@Component
export default class CounterView extends Mixins<EditView<Counter>>(EditView) {
	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		definition: "Le type ne peut pas être vide",
		condition: ""
	};

	public get states() {
		return {
			name: this.item && this.focused.name ? !!this.item.name : null,
			definition: this.item && this.focused.definition ? !!this.item.definition : null,
			condition: this.item && this.focused.condition ? true : null,
		};
	}

	public get itemFactory(): Function | null {
		return Counter.create;
	}

	public get cloneFactory(): (base: Counter) => Counter {
		return Counter.clone;
	}

	public get checkDocFunction(): Function | null {
		return Counter.check;
	}

	public get routeName(): string {
		return "counter";
	}

	public get canEdit(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get title(): string {
		if (this.item) {
			return "Compteur &mdash; " + this.item.name;
		}
		return "Compteur";
	}
}
