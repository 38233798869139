//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isString, isObject, isBoolean, has, get, translate3d, px } from './utils'

const DEFAULT_COLOR_CHECKED = '#75c791'
const DEFAULT_COLOR_UNCHECKED = '#bfcbd9'
const DEFAULT_LABEL_CHECKED = 'Oui'
const DEFAULT_LABEL_UNCHECKED = 'Non'
const DEFAULT_SWITCH_COLOR = '#fff'

export default {
	name: 'ToggleButton',
	props: {
		value: {
			type: Boolean,
			default: false
		},
		name: {
			type: String
		},
		disabled: {
			type: Boolean,
			default: false
		},
		tag: {
			type: String,
		},
		sync: {
			type: Boolean,
			default: false
		},
		speed: {
			type: Number,
			default: 300
		},
		color: {
			type: [String, Object],
			validator (value) {
				return isString(value)
					|| has(value, 'checked')
					|| has(value, 'unchecked')
					|| has(value, 'disabled')
			}
		},
		switchColor: {
			type: [String, Object],
			validator (value) {
				return isString(value)
					|| has(value, 'checked')
					|| has(value, 'unchecked')
			}
		},
		cssColors: {
			type: Boolean,
			default: false
		},
		labels: {
			type: [Boolean, Object],
			default: false,
			validator (value) {
				return typeof value === 'object'
					? (value.checked || value.unchecked)
					: typeof value === 'boolean'
			}
		},
		height: {
			type: Number,
			default: 32
		},
		width: {
			type: Number,
			default: 50
		},
		margin: {
			type: Number,
			default: 3
		},
		labelMargin: {
			type: Number,
			default: 5
		},
		fontSize: {
			type: Number
		}
	},
	computed: {
		className () {
			let { toggled, disabled } = this

			return ['vue-js-switch', {
				toggled,
				disabled
			}]
		},

		coreStyle () {
			return {
				width: `calc(${this.maxChar}ch + ${px(this.buttonRadius)} + (${px(this.margin)} * 2) + 1ch)`,// px(this.width),
				height: px(this.height),
				backgroundColor: this.cssColors
					? null
					: (this.disabled ? this.colorDisabled : this.colorCurrent),
				borderRadius: px(Math.round(this.height / 2))
			}
		},

		maxChar () {
			return Math.max(this.labelChecked.length, this.labelUnchecked.length);
		},

		buttonRadius () {
			return this.height - this.margin * 2;
		},

		distance () {
			return `calc(${this.maxChar}ch + ${px(this.margin)} + 1ch)`; // px(this.width - this.height + this.margin)
		},

		buttonStyle () {
			const transition = `transform ${this.speed}ms`
			const margin = px(this.margin)

			const transform = this.toggled
				? translate3d(this.distance, margin)
				: translate3d(margin, margin)

			const background = this.switchColor
				? this.switchColorCurrent
				: null

			return {
				width: px(this.buttonRadius),
				height: px(this.buttonRadius),
				transition,
				transform,
				background
			}
		},

		labelStyle () {
			return {
				lineHeight: px(this.height),
				fontSize: this.fontSize ? px(this.fontSize) : null
			}
		},

		colorChecked () {
			let { color } = this

			if (!isObject(color)) {
				return color || DEFAULT_COLOR_CHECKED
			}

			return get(color, 'checked', DEFAULT_COLOR_CHECKED)
		},

		colorUnchecked () {
			return get(this.color, 'unchecked', DEFAULT_COLOR_UNCHECKED)
		},

		colorDisabled () {
			return get(this.color, 'disabled', this.colorCurrent)
		},

		colorCurrent () {
			return this.toggled
				? this.colorChecked
				: this.colorUnchecked
		},

		labelChecked () {
			return get(this.labels, 'checked', DEFAULT_LABEL_CHECKED)
		},

		labelUnchecked () {
			return get(this.labels, 'unchecked', DEFAULT_LABEL_UNCHECKED)
		},

		switchColorChecked () {
			return get(this.switchColor, 'checked', DEFAULT_SWITCH_COLOR)
		},

		switchColorUnchecked () {
			return get(this.switchColor, 'unchecked', DEFAULT_SWITCH_COLOR)
		},

		switchColorCurrent () {
			let { switchColor } = this

			if (!isObject(this.switchColor)) {
				return this.switchColor || DEFAULT_SWITCH_COLOR
			}

			return this.toggled
				? this.switchColorChecked
				: this.switchColorUnchecked
		}

	},
	watch: {
		value (value) {
			if (this.sync) {
				this.toggled = !!value
			}
		}
	},
	data () {
		return {
			toggled: !!this.value
		}
	},
	methods: {
		toggle (event) {
			const toggled = !this.toggled;

			if (!this.sync) {
				this.toggled = toggled;
			}

			this.$emit('input', toggled);
			this.$emit('change', {
				value: toggled,
				tag: this.tag,
				srcEvent: event,
			});
		},
	}
}
