























































































import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	EditComponent,
	MachineGame,
	Machine,
	Game,
	Denomination,
	percent,
	ConfigStatus,
	toaster,
	servers,
} from "@/loader";
import MachineGameEditor from "./MachineGameEditor.vue";

const DELETABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const ACTIVABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const PLANNING_STATUS = [ConfigStatus.draft, ConfigStatus.disabled];

@Component({
	components: {
		MachineGameEditor,
	}
})
export default class MachineGameRow extends Mixins<EditComponent<MachineGame, Machine>>(EditComponent) {
	@Prop({ type: Boolean, default: false })
	public canMove!: boolean;

	public game: Game | null = null;
	public pack: Game | null = null;
	public useEditMode: boolean = true;
	public ConfigStatus = ConfigStatus;

	public get canDelete(): boolean {
		return this.canEdit && !this.editMode && DELETABLE_STATUS.includes(this.item?.status);
	}

	public get canDisable(): boolean {
		return this.canEdit && !this.editMode && this.item?.status === ConfigStatus.active;
	}

	public get canActivate(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get canPlan(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get rate(): string {
		return this.item?.rate ? `${this.item.rate.name} (${percent.format(this.item.rate.rate / 100)})` : ""
	}

	public get deno() {
		return Denomination.toStrings(this.item?.deno || 0).join(", ");
	}

	public get gameRoute() {
		return this.game ? {
			name: "game",
			params: {
				id: this.game._id,
				serverName: servers.selected?.name,
			},
			query: {
				redirect: this.$route.fullPath,
				redirectTitle: `Machine ${this.parent?.serial || ""}`
			}
		} : undefined;
	}

	public get packRoute() {
		return this.pack ? {
			name: "game",
			params: {
				id: this.pack._id,
				serverName: servers.selected?.name,
			},
			query: {
				redirect: this.$route.fullPath,
				redirectTitle: `Machine ${this.parent?.serial || ""}`
			}
		} : undefined;
	}

	public async loadRelations() {
		this.game = await this.$db.getGame(this.item?.gameId);
		this.pack = await this.$db.getGame(this.item?.packId);
	}

	public setStatus(status: ConfigStatus) {
		if (!this.item) {
			toaster.noItem();
			return;
		}
		this.item.status = status;
		this.changed = true;
	}

	public plan() {

	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public itemChanged(newValue: MachineGame | null, oldValue?: MachineGame | null) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
