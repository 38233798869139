import dayjs, { ConfigType } from "dayjs";
import { DetailedDiff, detailedDiff } from "deep-object-diff";
import { checkArrayValue, checkValue, EventType, UUID } from "@/loader";

export interface EventLinks {
	[link: string]: UUID
}

export interface Event {
	author: string
	type: EventType
	date: ConfigType
	comment: string
	links: EventLinks
	diff: DetailedDiff<any>
}

export interface Events {
	events: Event[]
}

export namespace Events {
	export function instanceOf(value: any): value is Events {
		return value && typeof value === "object" && "events" in value && Array.isArray(value.events);
	}

	export function create(): Events {
		return {
			events: [],
		};
	}

	export function check(data: any): Events {
		checkArrayValue(data, "events", Event.check);
		return data;
	}
}

export namespace Event {
	export function create(
		author: string,
		type: EventType,
		comment: string = "",
		original?: any,
		actual?: any,
		links: EventLinks = {},
		date: ConfigType = dayjs(),
	): Event {
		// Invert original and actual to keep original values
		let diff: DetailedDiff<any> = { added: {}, deleted: {}, updated: {} };
		if (original && actual) {
			let o = JSON.parse(JSON.stringify(original));
			let a = JSON.parse(JSON.stringify(actual));
			delete o.events;
			delete a.events;
			diff = detailedDiff(o, a);
		}

		return {
			author,
			type,
			date: dayjs(date).toISODateTimeString(),
			comment,
			diff,
			links,
		}
	}

	export function check(data: any): Event {
		checkValue(data, "author", "");
		checkValue(data, "type", 0);
		checkValue(data, "date", null);
		checkValue(data, "comment", "");
		checkValue(data, "diff", { added: {}, deleted: {}, updated: {} });
		checkValue(data, "links", {});
		return data;
	}

	export function checkDiff<T extends Events>(oldValue: T, newValue: T) {
		if (!Events.instanceOf(oldValue) || !Events.instanceOf(newValue)) {
			return null;
		}
		if (oldValue.events.length === newValue.events.length) {
			return null;
		}
		return detailedDiff(oldValue, newValue);
	}
}
