import { checkValue } from "@/loader";


export interface ReferenceTask {
	ref: string
}

export namespace ReferenceTask {
	export function instanceOf(value: any): value is ReferenceTask {
		return value && typeof value === "object" && "ref" in value;
	}

	export function check(data: any): ReferenceTask {
		checkValue(data, "ref", "");
		return data;
	}
}
