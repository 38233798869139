



















































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { servers, DEBUG, busy } from '@/loader';
import ServerButton from "./server/ServerButton.vue";

@Component({
	components: {
		ServerButton
	}
})
export default class SidebarMenu extends Vue {
	public imgWidth: number = 50;
	public DEBUG = DEBUG;
	public displayCatalogs: boolean = false;
	public displayParc: boolean = false;
	public displayPlanning: boolean = false;
	public logo = "/images/logo.svg";

	public get servers() {
		return servers.list;
	}

	public get canParc(): boolean {
		return servers.isDirector || servers.isManager || servers.isAccounting || servers.isTech || servers.isAdmin;
	}

	public get canPlanning(): boolean {
		return servers.isDirector || servers.isManager || servers.isAccounting || servers.isTech || servers.isAdmin;
	}

	public get canHR(): boolean {
		return servers.isDirector || servers.isAccounting || servers.isAdmin;
	}

	public get canCatalog(): boolean {
		return servers.isDirector || servers.isManager || servers.isAdmin;
	}

	public get activeServerName(): string {
		return servers.selected?.model || "";
	}

	public get online(): boolean {
		return servers.online;
	}

	public get onlineVariant(): string {
		return this.online ? 'success' : 'danger';
	}

	public get onlineIcon(): string[] {
		return ['fas', this.online ? 'wifi' : 'wifi-slash']
	}

	public get onlineTitle(): string {
		return this.online ? 'Connecté' : 'Déconnecté';
	}

	public get isCatalogs() {
		return this.$route.meta?.menu === "catalogs";
	}

	public get isParc() {
		return this.$route.meta?.menu === "parc";
	}

	public get isPlanning() {
		return this.$route.meta?.menu === "planning";
	}

	public get showCatalogs() {
		return this.displayCatalogs || this.$route.meta?.menu === "catalogs" || !this.$screen.lg;
	}

	public get showParc() {
		return this.displayParc || this.$route.meta?.menu === "parc" || !this.$screen.lg;
	}

	public get showPlanning() {
		return this.displayPlanning || this.$route.meta?.menu === "planning" || !this.$screen.lg;
	}

	public get currentPath() {
		return this.$router.currentRoute.path;
	}

	public async logout() {
		busy.start("Déconnexion ...");
		try {
			// @ts-ignore
			await this.$root.logout();
		} catch (error) {
			console.error(error);
		}
		busy.stop();
	}
}
