























































































import { Component, Mixins } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue";
import {
	servers,
	Counter,
	CounterType,
	Brand,
	Game,
	DocumentList,
	createListFilter,
	PouchResult,
	MachineConfig,
} from "@/loader";

@Component
export default class CountersList extends Mixins<DocumentList<Counter>>(DocumentList) {
	/** @ts-ignore */
	public sort: string = "name";
	/** @ts-ignore */
	public filters = {
		definitions: createListFilter<Brand, Counter>("definition"),
		conditions: createListFilter<Brand, Counter>("condition"),
		isDisabled: createListFilter<Brand, Game>({ valueField: "disabled", title: "Désactivé" }),
	};

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "definition",
			label: "Type",
			formatter: CounterType.toString,
			sortable: true,
			sortByFormatted: true,
		},
		{
			key: "condition",
			label: "Condition",
			formatter: MachineConfig.toString,
			sortable: true,
			sortByFormatted: true,
		},
	];

	public get localStateName(): string {
		return "counters";
	}

	public get startkey(): string {
		return Counter.STARTKEY;
	}

	public get endkey(): string {
		return Counter.ENDKEY;
	}

	public get type(): string {
		return Counter.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get checkDocFunction(): Function | null {
		return Counter.check;
	}

	public getSearchableString(value: Counter): string {
		return value.name.removeDiacritics().toLowerCase();
	}

	public definitionsBrandFactory(r: PouchResult): Brand {
		return {
			_id: <number>r.key,
			name: CounterType.toString(r.key),
			count: r.value,
		};
	}

	public conditionsBrandFactory(r: PouchResult): Brand {
		return {
			_id: <number>r.key,
			name: MachineConfig.toString(r.key) || "Aucun",
			count: r.value,
		};
	}
}
