import { CompanyTask, ReadingTask, UUID } from "@/loader";


export interface ReserveTask extends ReadingTask, CompanyTask {
}

export namespace ReserveTask {
	export function instanceOf(value: any): value is ReserveTask {
		return ReadingTask.instanceOf(value)
			&& CompanyTask.instanceOf(value);
	}

	export function check(data: any): ReserveTask {
		CompanyTask.check(data);
		ReadingTask.check(data);
		return data;
	}

	export function create(companyId: UUID = ""): ReserveTask {
		return {
			companyId,
			reading: "",
		};
	}
}
