import dayjs, { ConfigType } from "dayjs";
import {
	CouchDBDocument,
	UUID,
	generateUUID,
	Events,
	MessageStatus,
	checkValue,
	checkArrayValue,
} from "@/loader";

export interface Message extends CouchDBDocument, Events {
	type: "message"
	authorId: UUID
	messageId: UUID | null
	status: MessageStatus
	isHtml: boolean
	date: ConfigType
	subject: string
	body: string
	tos: MessageRecipient[]
	ccs: MessageRecipient[]
	bccs: MessageRecipient[]
	attachments: MessageAttachment[]
}

export interface MessageRecipient {
	recipientId: UUID
	status: MessageStatus
}

export interface MessageAttachment {
	name: string
	mime: string
	path: string
}

export namespace Message {
	export const TYPE = "message";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Message {
		return CouchDBDocument.instanceOf(value) && value.type == TYPE;
	}

	export function create(
		authorId: UUID,
		subject: string = "",
		body: string = "",
		messageId: UUID | null = null,
		status: MessageStatus = MessageStatus.draft,
		isHtml: boolean = false,
		date: Exclude<ConfigType, null | undefined> = dayjs(),
	): Message {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			authorId,
			messageId,
			status,
			isHtml,
			date,
			subject,
			body,
			tos: [],
			ccs: [],
			bccs: [],
			attachments: [],
			events: [],
		}
	}

	export function check(data: any): Message {
		CouchDBDocument.check(data, TYPE);
		Events.check(data);
		checkValue(data, "authorId", "");
		checkValue(data, "messageId", null);
		checkValue(data, "status", MessageStatus.draft);
		checkValue(data, "isHtml", false);
		checkValue(data, "date", null);
		checkValue(data, "subject", "");
		checkValue(data, "body", "");
		checkArrayValue(data, "tos", MessageRecipient.check);
		checkArrayValue(data, "ccs", MessageRecipient.check);
		checkArrayValue(data, "bccs", MessageRecipient.check);
		checkArrayValue(data, "attachments", MessageAttachment.check);
		return data;
	}
}

export namespace MessageRecipient {
	export function create(
		recipientId: UUID,
		status: MessageStatus = MessageStatus.draft
	): MessageRecipient {
		return {
			recipientId,
			status
		}
	}

	export function check(data: any): MessageRecipient {
		checkValue(data, "recipientId", "");
		checkValue(data, "status", MessageStatus.draft);
		return data;
	}
}

export namespace MessageAttachment {
	export function create(
		name: string,
		mime: string,
		path: string,
	): MessageAttachment {
		return {
			name,
			mime,
			path,
		}
	}

	export function check(data: any): MessageAttachment {
		checkValue(data, "name", "");
		checkValue(data, "mime", "");
		checkValue(data, "path", "");
		return data;
	}
}
