import { checkArrayValue, GameReplacement } from "@/loader";

export interface MachineGameTask {
	games: GameReplacement[]
}

export namespace MachineGameTask {
	export function instanceOf(value: any): value is MachineGameTask {
		return value && typeof value === "object" && "games" in value;
	}

	export function check(data: any): MachineGameTask {
		checkArrayValue(data, "games", GameReplacement.check);
		return data;
	}
}
