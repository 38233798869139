import { Component, Mixins } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, ValueUnit } from "@/loader";

@Component
export class ValueUnitBadge extends Mixins<EnumBadge<ValueUnit>>(EnumBadge) {
	public get variant(): BVariant {
		return BVariant.info;
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case ValueUnit.credit:
				return createIconConfig(['fal', 'coin']);
			case ValueUnit.value:
				return createIconConfig(['fal', 'money-bill']);
			default:
				return createIconConfig(['fas', 'question']);
		}
	}

	public get title(): string {
		return ValueUnit.toString(this.value);
	}
}
