import { Component, Mixins } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, ReadingStatus } from "@/loader";

@Component
export class ReadingStatusBadge extends Mixins<EnumBadge<ReadingStatus>>(EnumBadge) {
	public get variant(): BVariant {
		switch (this.value) {
			case ReadingStatus.control:
				return BVariant.warning;
			case ReadingStatus.done:
				return BVariant.success;
			case ReadingStatus.draft:
			default:
				return BVariant.outlineSecondary;
		}
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case ReadingStatus.done:
				return createIconConfig(['fas', 'check']);
			case ReadingStatus.control:
				return createIconConfig(['fal', 'search']);
			case ReadingStatus.draft:
				return createIconConfig(['far', 'edit']);
			default:
				return createIconConfig(['fas', 'question-circle']);
		}
	}

	public get title(): string {
		return ReadingStatus.toString(this.value);
	}
}
