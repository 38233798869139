import { CreateElement } from "vue";
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { Brand, DEBUG, servers, toaster } from "@/loader";

export type PouchResult = {
    id: any;
    key: any;
    value: any;
    doc?: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta> | undefined;
}

const keyOfBrands = Object.keys({_id: "", name: "", count: 0, doc: null });
function checkKeyOfBrand(value: string): boolean {
	return keyOfBrands.includes(value);
}

function sortByName(a: Brand, b: Brand, sortDesc: boolean = false) {
	let first = sortDesc ? b.name : a.name;
	let second = sortDesc ? a.name : b.name;
	if (Array.isArray(first)) {
		first = JSON.stringify(first);
	}
	if (Array.isArray(second)) {
		second = JSON.stringify(second);
	}
	return first.localeCompare(second);
}

function sortByCount(a: Brand, b: Brand, sortDesc: boolean = false) {
	let first = sortDesc ? b : a;
	let second = sortDesc ? a : b;
	let result = first.count - second.count;
	return result !== 0 ? result : sortByName(a, b);
}

@Component
export class FilterCard extends Vue {
	@Prop({ type: String, required: true })
	public readonly query!: string;

	@Prop({ type: String, default: "Filtre" })
	public readonly title!: string;

	@Prop({ type: String, default: "Aucun filtre trouvé" })
	public readonly emptyText!: string;

	@Prop({ type: Boolean, default: false })
	public readonly showEmpty!: boolean;

	@Prop({ type: Boolean, default: true })
	public readonly multiple!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly compact!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly hideMode!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly hideSearch!: boolean;

	@Prop({ type: Boolean, default: false })
	public readonly hideSort!: boolean;

	@Prop({ type: Number, default: 10 })
	public readonly modeLimit!: number;

	@Prop({ type: String, default: "name", validator: checkKeyOfBrand })
	public readonly textField!: keyof Brand;

	@Prop({ type: String, default: "_id", validator: checkKeyOfBrand })
	public readonly valueField!: keyof Brand;

	@Prop({ type: Function, default: (r: PouchResult) => {
		let key = Array.isArray(r.key) ? r.key[r.key.length - 1] : r.key;
		return {
			_id: key,
			name: key,
			count: r.value,
			doc: r.doc
		}
	}})
	public readonly itemFactory!: (r: PouchResult) => Brand;

	@Prop({ type: Object, default: () => ({ group: true }) })
	public readonly options!: PouchDB.Query.Options<{}, {}>;

	@Model("input")
	public value!: any;

	public filter: string = "";
	public displayModeSelector: boolean = false;
	public full: boolean = false;
	public sort: string = "count";
	public sortDesc: boolean = true;
	public items: Brand[] = [];
	public selection: any[] = [];

	public get displayedItems(): Brand[] {
		// 1) Filter items according to the searched term, if activated
		let filter = !this.hideSearch && this.filter ? this.filter.toLowerCase().removeDiacritics() : "";
		let items = filter ? this.items.filter(i => i.name.toLowerCase().removeDiacritics().includes(filter)) : this.items;
		// 2) Sort items
		let sorter = this.sort === "count" ? sortByCount : sortByName;
		items.sort((a, b) => sorter(a, b, this.sortDesc));
		// 3) check whether it's full mode or not
		this.displayModeSelector = !this.hideMode && items.length > this.modeLimit;
		let full = this.hideMode || items.length < this.modeLimit || this.full;
		return full ? items : items.slice(0, this.modeLimit);
	}

	public render(h: CreateElement) {
		if (!this.showEmpty && !this.items.length) {
			return h("div");
		}

		const options = h(
			this.multiple ? "b-form-checkbox-group" : "b-form-radio-group",
			{
				props: {
					stacked: !this.compact,
					checked: this.value,
				},
				on: {
					input: (value: Brand[]) => { this.$emit("input", value) }
				}
			},
			[
				this.displayedItems.map(item => h(
					this.multiple ? "b-form-checkbox" : "b-form-radio",
					{
						class: !this.compact ? "mb-2" : null,
						props: {
							value: this.valueField in item ? item[this.valueField] : null,
						}
					},
					[
						item[this.textField]?.toString() || "",
						h("b-badge", { class: "ml-1", props: { variant: "secondary" } }, item.count.toString()),
					]
				)),
			]
		);

		if (this.compact) {
			return h(
				"b-card",
				[
					h(
						"b-card-title",
						{
							class: "d-flex justify-content-between align-items-center m-0",
							props: {
								titleTag: "div",
							}
						},
						[
							h("h4", { class: "m-0" }, this.title),
							options,
						]
					)
				]
			)
		}

		const sortDropdown = h(
			"b-dropdown",
			{
				props: {
					size: "sm",
				},
				scopedSlots: {
					"button-content": () => h(
						"fa",
						{
							props: {
								icon: this.getIcon(this.sort, this.sortDesc),
								fixedWidth: true
							}
						}
					)
				}
			},
			[
				{ field: "name", desc: false, title: "Trier par nom ascendant" },
				{ field: "name", desc: true, title: "Trier par nom descendant" },
				{ field: "count", desc: false, title: "Trier par compteur ascendant" },
				{ field: "count", desc: true, title: "Trier par compteur descendant" },
			].map(x => h(
				"b-dropdown-item-button",
				{
					props: {
						active: this.sort === x.field && this.sortDesc === x.desc,
					},
					on: {
						click: () => { this.sort = x.field; this.sortDesc = x.desc; }
					}
				},
				[
					h("fa", { props: { icon: this.getIcon(x.field, x.desc), fixedWidth: true }, class: "mr-2" }),
					x.title
				]
			))
		);

		return h(
			"b-card",
			[
				h(
					"b-card-title",
					{
						class: "d-flex justify-content-between"
					},
					[
						h("span", this.title),
						!this.hideSort && this.hideSearch && this.items.length > 0 ? sortDropdown : null,
					]
				),
				!this.hideSearch && this.items.length > 0 ? h(
					"b-input-group",
					{
						class: "my-2",
						props: {
							size: "sm",
						}
					},
					[
						h(
							"b-form-input",
							{
								props: {
									value: this.filter,
									placeholder: "Recherche"
								},
								on: {
									input: (value: string) => this.filter = value,
								}
							}
						),
						!this.hideSort ? h(
							"b-input-group-append",
							[
								sortDropdown
							]
						) : null
					],
				) : null,
				options,
				this.displayModeSelector ? h(
					"b-button",
					{
						props: {
							variant: "info",
						},
						on: {
							click: () => this.full = !this.full
						}
					},
					[
						h("fa", { props: { icon: ["fal", "eye" + (this.full ? "-slash" : "")], fixedWidth: true }, class: "mr-2" }),
						this.full ? "Aficher moins" : "Afficher plus"
					]
				) : null,
			]
		);
	}

	public async loadItems() {
		if (!servers.selected) {
			return;
		}

		await this.loadPrerequisites();

		try {
			let result = await this.$pouch.query(this.query, this.options, servers.selected.name);
			DEBUG && console.log("query?" + this.query, this.options, result);
			this.items = result.rows.map(this.callItemFactory);
			this.$emit("loaded", this.items);
		} catch (error) {
			console.error("query?" + this.query, error);
			toaster.error({ error });
			this.items = [];
			this.$emit("loaded", []);
		}
	}

	public mounted() {
		this.loadItems();
	}

	public getIcon(field: string, desc: boolean) {
		return ["fal", "sort-" + (field === "count" ? "numeric" : "alpha") + "-down" + (desc ? "-alt" : "")];
	}

	public callItemFactory(r: PouchResult) {
		return this.itemFactory(r);
	}

	public async loadPrerequisites() {}

	@Watch("query")
	public queryChanged(newValue: string, oldValue?: string) {
		if (newValue && newValue !== oldValue) {
			this.loadItems();
		}
	}
}
