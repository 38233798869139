



















































































import { Component, Mixins } from "vue-property-decorator";
import {
	Brand,
	CouchDBDocument,
	createListFilter,
	DocumentList,
	FilterCard,
	servers,
	Software,
	SoftwareType,
	BvTableFieldArray,
	PropertyNames,
	PouchResult,
} from "@/loader";

@Component
export default class SoftwaresList extends Mixins<DocumentList<Software>>(DocumentList) {
	public sort: PropertyNames<Software> = "name";
	public filters = {
		brands: createListFilter<Brand, Software>("brand"),
		definitions: createListFilter<Brand, Software>("definition"),
		isDisabled: createListFilter<Brand, Software>({ valueField: "disabled", title: "Désactivé" }),
	}

	public fields: BvTableFieldArray = [
		{
			key: "name",
			label: "Nom",
			sortable: true,
		},
		{
			key: "brand",
			label: "Marque",
			sortable: true,
		},
		{
			key: "definition",
			label: "Type",
			formatter: SoftwareType.toString,
			sortable: true,
			sortByFormatted: true,
		},
		{
			key: "versions",
			label: "Version actuelle",
			formatter: (value: string[]) => value[value.length - 1],
			sortable: true,
			sortByFormatted: true,
		},
		{
			key: "counters",
			label: "Compteurs",
			headerTitle: "Élecs / Mécas",
			formatter: (value: any, key: string, item: Software) => item.elecs.length + ' / ' + item.mecas.length,
		},
	];

	public get localStateName(): string {
		return "softwares";
	}

	public get startkey(): string {
		return Software.STARTKEY;
	}

	public get endkey(): string {
		return Software.ENDKEY;
	}

	public get type(): string {
		return Software.TYPE;
	}

	public get canCreate(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public get checkDocFunction(): Function | null {
		return Software.check;
	}

	public getSearchableString(value: Software): string {
		return [
				value.name.removeDiacritics().toLowerCase(),
				value.brand?.removeDiacritics().toLowerCase() || "",
				value.ref?.removeDiacritics().toLowerCase() || "",
			].filter(x => !!x)
			.join(CouchDBDocument.ENDKEY_SUFFIX);
	}

	public definitionsBrandFactory(r: PouchResult): Brand {
		return {
			_id: <number>r.key,
			name: SoftwareType.toString(r.key),
			count: r.value,
		};
	}
}
