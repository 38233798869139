





































import { Vue, Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { GameRate, EditComponent, Game } from "@/loader";

@Component
export default class PackGameRow extends Mixins<EditComponent<string, Game>>(EditComponent) {
	@Prop({ type: Number, default: 0 })
	public index!: number;

	public game: Game | null = null;

	public async loadRelations() {
		this.game = await this.$db.getGame(this.item);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
