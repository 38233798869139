















































































import { Component, Mixins } from "vue-property-decorator";
import { GameController, EditComponent, Game, flagMatch, ControllerType } from "@/loader";

@Component
export default class GameControllerEdit extends Mixins<EditComponent<GameController, Game>>(EditComponent) {
	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		type: "Au moins un type doit être sélectionné",
		increment: "L'incrément ne peut pas être vide",
		startup: "",
		description: "",
	}

	public get states() {
		return {
			name: this.item && this.focused.name ? !!this.item.name : null,
			type: this.item && this.focused.type ? !!this.item.type : null,
			increment: this.item && this.focused.increment ? !!this.item.increment : null,
			startup: this.item && this.focused.startup ? !flagMatch(this.item.type, ControllerType.bonus) || !!this.item.startup : null,
			description: this.item && this.focused.description ? true : null,
		}
	}
}
