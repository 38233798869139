import {
	UUID,
	Category,
	createCategory,
	checkCategory,
	cloneCategory,
	CouchDBDocument,
} from "@/loader";


export interface GameCategory extends Category {
	type: "game-category";
}

export namespace GameCategory {
	export const TYPE = "game-category";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is GameCategory {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		parentId: UUID = ""
	): GameCategory {
		return <GameCategory>createCategory(TYPE, name, parentId);
	}

	export function clone(base: GameCategory): GameCategory {
		return cloneCategory(base);
	}

	export function check(data: any): GameCategory {
		return <GameCategory>checkCategory(data, TYPE);
	}
}
