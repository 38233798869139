










































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import {
	Machine,
	servers,
	EditView,
	MachineStatus,
	MachineType,
	toaster,
	TaskAction,
	isEditor,
	EventType,
	ConfigStatus,
	MachineGame,
	missionModal,
	Task,
	Reading,
	MachinePart,
	MachineSoftware,
	MachineController,
} from "@/loader";
import MachineEditor from "../../../component/machine/MachineEditor.vue";
import MachineGameList from "../../../component/machine/MachineGameTable.vue";
import MachineHeader from "../../../component/machine/MachineHeader.vue";
import MachinePartTree from "../../../component/machine/MachinePartTree.vue";
import MachinePartsTable from "../../../component/machine/MachinePartsTable.vue";
import MachineSoftwaresTable from "../../../component/machine/MachineSoftwaresTable.vue";
import MachineControllersTable from "../../../component/machine/MachineControllersTable.vue";
import MissionModal from "../../../component/mission/MissionModal.vue";
import TasksTable from "../../../component/task/TasksTable.vue";
import ReadingsTable from "../../../component/reading/ReadingsTable.vue";
import MachineCreateModal from "../../../component/machine/MachineCreateModal.vue";

@Component({
	components: {
		MachineControllersTable,
		MachineCreateModal,
		MachineEditor,
		MachineGameList,
		MachineHeader,
		MachinePartsTable,
		MachineSoftwaresTable,
		MachinePartTree,
		MissionModal,
		ReadingsTable,
		TasksTable,
	}
})
export default class MachineView extends Mixins<EditView<Machine>>(EditView) {
	public MachineStatus = MachineStatus;
	public MachineType = MachineType;
	public activeTab: number = 1;
	public useEditMode: boolean = true;
	public newSerial: string = "";

	@Ref()
	public missionModal!: MissionModal | null;

	public get canEdit(): boolean {
		return true;
	}

	public get canCreate(): boolean {
		return servers.isDirector || servers.isAccounting || servers.isManager;
	}

	public get canEditSerial(): boolean {
		return this.canCreate;
	}

	public get canInstall(): boolean {
		return this.item?.status === MachineStatus.stocked;
	}

	public get hasBeenInstalled(): boolean {
		return !!this.item?.events.find(e => e.type === EventType.installed);
	}

	public get itemFactory(): Function | null {
		throw "Machine.itemFactory should not be used directly. Use MachineCreateModal instead.";
	}

	public get cloneFactory(): (base: Machine) => Machine {
		throw "Machine.cloneFactory should not be used directly. Use MachineCreateModal instead.";
	}

	public get checkDocFunction(): Function | null {
		return Machine.check;
	}

	public get routeName(): string {
		return "machine";
	}

	public get title(): string {
		if (this.item) {
			return MachineType.toString(this.item.definition) + " &mdash; " + this.item.serial;
		}
		return "Machine";
	}

	public clean() {
		this.unfocusAll();
		this.changed = false;
		this.editMode = this.isNew;
		this.getRefs().forEach(x => isEditor(x) && x.clean())
	}

	public async disableItem(item?: MachineGame | MachinePart | MachineController | null) {
		this.setStatus(item, ConfigStatus.disabled, EventType.deactivated, "Désactiver");
	}

	public async activateItem(item?: MachineGame | MachinePart | MachineController | null) {
		let event = item?.status === ConfigStatus.disabled ? EventType.reactivated : EventType.activate;
		this.setStatus(item, ConfigStatus.active, event, "Activer");
	}

	public async install() {
		const action = this.hasBeenInstalled ? TaskAction.reactivation : TaskAction.installation;
		this.showMissionModal(Task.create("", this.id, action));
	}

	public showMissionModal(task?: Task) {
		task = task || Task.create("", this.id);
		if (this.missionModal) {
			this.missionModal.tasks.push(task);
			this.missionModal.show();
		}
	}

	public async setStatus(item: MachineGame | MachinePart | MachineController | null | undefined, status: ConfigStatus, event: EventType, operation: string) {
		if (!this.canEdit) {
			toaster.forbidden({ operation });
			return;
		}
		if (!this.item || !item) {
			toaster.noItem({ operation });
			return;
		}
		if (!ConfigStatus.checkWorkflow(item.status, status)) {
			toaster.invalidWorkflow({ operation });
			return;
		}
		item.status = status;
		this.changed = true;
		let comment =
			MachineGame.instanceOf(item) ? MachineGame.toString(item, await this.$db.getGame(item.gameId), await this.$db.getGame(item.packId))
			: MachinePart.instanceOf(item) ? MachinePart.toString(item, await this.$db.getPart(item.partId))
			: MachineController.toString(item);
		this.save(event, comment);
	}

	public async clonedGame(newItem: MachineGame, originalItem: MachineGame) {
		this.changed = true;
		this.save(EventType.cloned, "Original : " + MachineGame.toString(originalItem, await this.$db.getGame(originalItem.gameId), await this.$db.getGame(originalItem.packId)));
	}

	public async clonedPart(newItem: MachinePart, originalItem: MachinePart) {
		this.changed = true;
		this.save(EventType.cloned, "Original : " + MachinePart.toString(originalItem, await this.$db.getPart(originalItem.partId)));
	}

	public async clonedSoftware(newItem: MachineSoftware, originalItem: MachineSoftware) {
		this.changed = true;
		this.save(EventType.cloned, "Original : " + MachineSoftware.toString(originalItem, await this.$db.getSoftware(originalItem.softwareId)));
	}

	public async changeSerial() {
		if (!this.canEditSerial) {
			toaster.forbidden({ operation: "Changer le numéro de série" });
			return;
		}
		if (!this.item) {
			toaster.noItem();
			return;
		}
		if (!servers.selected) {
			toaster.noServer({ operation: "Changer le numéro de série" });
			return;
		}
		if (await this.$db.getMachine(Machine.getId(this.newSerial))) {
			toaster.invalidData({ message: `Le N° de série ${this.newSerial} existe déjà` });
			return;
		}

		const oldId = this.item?._id;
		let newId = this.item._id = Machine.getId(this.newSerial);
		this.item.serial = this.newSerial;
		// @ts-ignore
		delete this.item._rev;
		if (await this.$db.save(this.item) && this.original) {
			await this.$db.remove(this.original);
		}

		let readings = await this.$db.allReadings(oldId);
		await readings.forEach(async (r) => {
			const old = JSON.parse(JSON.stringify(r));
			r._id = Reading.getId(newId, r.date);
			r.machineId = newId;
			// @ts-ignore
			delete r._rev;

			if (await this.$db.save(r)) {
				await this.$db.remove(old);
			}
		});

		let tasks = await this.$db.queryTasksByMachine(oldId);
		await tasks.forEach(async t => {
			t.machineId = newId;
			await this.$db.save(t);
		});

		this.$router.push({ name: "machine", params: { id: newId } });
		toaster.success({ message: "N° de série modifié avec succès" });
	}

	public async saved() {
		// override
	}

	public mounted() {
		this.editMode = this.isNew;
	}
}
