import { CreateElement } from "vue";
import { Component, Model, Prop, Mixins } from "vue-property-decorator";
import { BvListItem, PopperDropdown } from "@/loader";

@Component
export class EnumSelect<T extends number> extends Mixins(PopperDropdown) {
	@Model("input", { type: Number })
	public value!: T;

	@Prop({ type: Boolean, default: true })
	public clearable!: boolean;

	@Prop({ type: Boolean, default: false })
	public multiple!: boolean;

	@Prop({ type: Boolean, default: false })
	public disabled!: boolean;

	@Prop({ type: Boolean, default: null })
	public closeOnSelect!: boolean | null;

	@Prop({ type: Boolean, default: false })
	public required!: boolean;

	@Prop({ type: [Number, Array], default: null })
	public include!: T | T[] | null;

	@Prop({ type: [Number, Array], default: null })
	public exclude!: T | T[] | null;

	public get localCloseOnSelect(): boolean {
		return this.closeOnSelect !== null ? this.closeOnSelect : !this.multiple;
	}

	public get options(): BvListItem[] {
		return [];
	}

	public get includedValues(): T[] {
		return Array.isArray(this.include) ? this.include : this.include !== null ? [this.include] : [];
	}

	public get excludedValues(): T[] {
		return Array.isArray(this.exclude) ? this.exclude : this.exclude !== null ? [this.exclude] : [];
	}

	public get includedOptions(): BvListItem[] {
		let options = this.options;
		if (this.excludedValues.length) {
			options = options.filter(o => !this.excludedValues.includes(o.value));
		}
		if (this.includedValues.length) {
			options = options.filter(o => this.includedValues.includes(o.value));
		}
		return options;
	}

	public render(h: CreateElement) {
		return h(
			"v-select",
			{
				props: {
					appendToBody: true,
					options: this.includedOptions,
					clearable: this.clearable,
					multiple: this.multiple,
					disabled: this.disabled,
					required: this.required,
					closeOnSelect: this.localCloseOnSelect,
					value: this.value,
					label: "text",
					reduce: (option: BvListItem) => option.value,
					calculatePosition: this.calculatePosition,
				},
				on: {
					input: (value: T) => this.$emit("input", value),
				},
			}
		);
	}
}
