














































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { toaster, EditList, Game, UUID } from "@/loader";
import PackGameRow from "./PackGameRow.vue";

@Component({
	inheritAttrs: true,
	components: {
		PackGameRow
	}
})
export default class PackGameTable extends Mixins<EditList<String, Game>>(EditList) {
	public category: string = "";
	public selected: string = "";

	public get array(): UUID[] {
		return this.parent ? this.parent.games : [];
	}
	public set array(value: UUID[]) {
		if (this.parent) {
			this.parent.games = value;
			this.changed = true;
		}
	}

	public get exclude(): UUID[] {
		return [...this.array, this.parent?._id || ""];
	}

	public get itemFactory(): Function | null {
		return "";
	}

	public get cloneFactory(): (base: UUID) => UUID {
		return (x) => x;
	}

	public addSelected() {
		if (!this.parent) {
			toaster.noItem({ operation: "Ajouter" });
			return;
		}
		if (!this.selected) {
			toaster.invalidData();
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Ajouter" });
			return;
		}

		this.array.push(this.selected);
		this.changed = true;
		this.selected = "";
	}
}
