import {
	Addresses,
	Coordinates,
	Civility,
	Deactivable,
	Deactivable,
	checkValue,
	Identifiable,
	nanoid,
	Address,
	Coordinate,
} from "@/loader";

export interface Contact extends Identifiable, Deactivable, Coordinates, Addresses {
	firstName: string
	lastName: string
	civility: Civility
	favorite: boolean
}

export namespace Contact {
	export function create(firstName: string = "", lastName: string = "", civility: Civility = Civility.monsieur): Contact {
		return {
			id: nanoid(),
			firstName,
			lastName,
			civility,
			disabled: false,
			favorite: false,
			addresses: [],
			coordinates: [],
		}
	}

	export function clone(original: Contact): Contact {
		return Object.assign({}, original, {
			addresses: original.addresses.map(Address.clone),
			coordinates: original.coordinates.map(Coordinate.clone)
		});
	}

	export function check(data: any): Contact {
		Deactivable.check(data);
		checkValue(data, "id", nanoid);
		checkValue(data, "firstName", "");
		checkValue(data, "lastName", "");
		checkValue(data, "civility", Civility.monsieur);
		checkValue(data, "favorite", false);
		Address.checkArray(data);
		Coordinate.checkArray(data);
		return data;
	}
}
