<template>
	<component :is="tag" v-if="item && parent">
		<b-row>
			<b-col v-if="isLocationTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="Socle"
					label-cols-xl="auto"
					:state="states.location"
					:invalid-feedback="invalidFeedbacks.location"
				>
					<b-form-input
						v-model="item.location"
						ref="dataInputs"
						size="sm"
						:state="states.location"
						:disabled="!canEdit"
						@input="focusOn('location')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col v-if="isReferenceTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="N°MAS"
					label-cols-xl="auto"
					:state="states.ref"
					:invalid-feedback="invalidFeedbacks.ref"
				>
					<b-form-input
						v-model="item.ref"
						ref="dataInputs"
						size="sm"
						:state="states.ref"
						:disabled="!canEdit"
						@input="focusOn('ref')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col v-if="isLimitMachineTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="Lim.Crédit"
					label-cols-xl="auto"
					:state="states.limitCredit"
					:invalid-feedback="invalidFeedbacks.limitCredit"
				>
					<b-form-input
						v-model.number="item.limitCredit"
						ref="dataInputs"
						type="number"
						size="sm"
						class="form-number-without-spinner"
						:state="states.limitCredit"
						:disabled="!canEdit"
						@input="focusOn('limitCredit')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col v-if="isLimitMachineTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="Lim.Insert"
					label-cols-xl="auto"
					:state="states.limitInsert"
					:invalid-feedback="invalidFeedbacks.limitInsert"
				>
					<b-form-input
						v-model.number="item.limitInsert"
						ref="dataInputs"
						type="number"
						size="sm"
						class="form-number-without-spinner"
						:state="states.limitInsert"
						:disabled="!canEdit"
						@input="focusOn('limitInsert')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col v-if="isLimitMachineTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="Lim.Jackpot"
					label-cols-xl="auto"
					:state="states.limitJackpot"
					:invalid-feedback="invalidFeedbacks.limitJackpot"
				>
					<b-form-input
						v-model.number="item.limitJackpot"
						ref="dataInputs"
						type="number"
						size="sm"
						class="form-number-without-spinner"
						:state="states.limitJackpot"
						:disabled="!canEdit"
						@input="focusOn('limitJackpot')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col v-if="isLimitMachineTask(item)" cols="12" md="6" xl="2">
				<b-form-group
					label="Lim.Paiement"
					label-cols-xl="auto"
					:state="states.limitPayment"
					:invalid-feedback="invalidFeedbacks.limitPayment"
				>
					<b-form-input
						v-model.number="item.limitPayment"
						ref="dataInputs"
						type="number"
						size="sm"
						class="form-number-without-spinner"
						:state="states.limitPayment"
						:disabled="!canEdit"
						@input="focusOn('limitPayment')"
						@keydown.enter="next($event)"
					></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<b-form-group
			v-if="isCheckMachine(item)"
			:state="states.controlDone"
			:invalid-feedback="invalidFeedbacks.controlDone"
		>
			<b-form-checkbox
				v-model="item.controlDone"
				ref="dataInputs"
				:state="states.controlDone"
				:disabled="!canEdit"
				@input="focusOn('controlDone')"
				@keydown.enter="next($event)"
			>
				Je confirme avoir vérifié les informations de la machine.
			</b-form-checkbox>
		</b-form-group>
	</component>
</template>

<script lang="ts">
import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import {
	TaskDataEditorMixin,
	CheckMachine,
	LimitMachineTask,
	LocationTask,
	ReferenceTask,
	PropertyNames,
	Machine,
} from "@/loader";

type CheckMachineTask = LimitMachineTask | LocationTask | ReferenceTask | CheckMachine;
type Properties = PropertyNames<(LimitMachineTask & LocationTask & ReferenceTask & CheckMachine) | Machine>;
// type Properties = "limitCredit" | "limitInsert" | "limitJackpot" | "limitPayment" | "location" | "ref";

@Component
export default class CheckMachineTaskEditor extends Mixins<TaskDataEditorMixin<CheckMachine | LocationTask | ReferenceTask | LimitMachineTask>>(TaskDataEditorMixin) {
	@Ref()
	public readonly dataInputs!: any[];

	public get states() {
		return {
			controlDone: this.focused.controlDone && CheckMachine.instanceOf(this.item) ? !!this.item.controlDone : null,
			limitCredit: this.focused.limitCredit && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitCredit : null,
			limitInsert: this.focused.limitInsert && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitInsert : null,
			limitJackpot: this.focused.limitJackpot && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitJackpot : null,
			limitPayment: this.focused.limitPayment && LimitMachineTask.instanceOf(this.item) ? !!this.item.limitPayment : null,
			location: this.focused.location && LocationTask.instanceOf(this.item) ? !!this.item.location : null,
			ref: this.focused.ref && ReferenceTask.instanceOf(this.item) ? !!this.item.ref : null,
		}
	}

	public invalidFeedbacks = {
		controlDone: "Vous devez vérifier les informations de la machine",
		limitCredit: "La limite de crédit doit être renseignée",
		limitInsert: "La limite d'insertion doit être renseignée",
		limitJackpot: "La limite de jackpot doit être renseignée",
		limitPayment: "La limite de paiement doit être renseignée",
		location: "Le socle de la machine doit être renseigné",
		ref: "Le N°MAS du casino doit être renseigné",
	}

	public isCheckMachine = CheckMachine.instanceOf;
	public isLimitMachineTask = LimitMachineTask.instanceOf;
	public isLocationTask = LocationTask.instanceOf;
	public isReferenceTask = ReferenceTask.instanceOf;

	public setDefaultData() {
		this.setData("limitCredit", LimitMachineTask.instanceOf);
		this.setData("limitInsert", LimitMachineTask.instanceOf);
		this.setData("limitJackpot", LimitMachineTask.instanceOf);
		this.setData("limitPayment", LimitMachineTask.instanceOf);
		this.setData("location", LocationTask.instanceOf);
		this.setData("ref", ReferenceTask.instanceOf);
	}

	public setData<T extends CheckMachineTask>(property: Properties, checkTypeFunction: (value: any) => value is T) {
		if (this.item && this.machine && checkTypeFunction(this.item) && property in this.item) {
			// @ts-ignore
			if (!this.item[property]) {
				// @ts-ignore
				this.item[property] = this.machine[property];
			// @ts-ignore
			} else if (this.item[property] !== this.machine[property]) {
				this.$set(this.focused, property, true);
			}
		}
	}

	public next(event: Event) {
		if (Array.isArray(this.dataInputs)) {
			const index = this.dataInputs.indexOf(event.target as HTMLInputElement);
			if (index !== -1 && index < this.dataInputs.length - 1) {
				this.dataInputs[index + 1].focus();
			} else {
				this.$emit("next");
			}
		}
	}

	public mounted() {
		this.setDefaultData();
	}

	@Watch("machine")
	public onMachineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue !== oldValue)  {
			this.setDefaultData();
		}
	}
}
</script>
