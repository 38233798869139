import { ReadingTask } from "@/loader";

export interface ResetCounterTask extends ReadingTask {
}

export namespace ResetCounterTask {
	export function instanceOf(value: any): value is ResetCounterTask {
		return ReadingTask.instanceOf(value);
	}

	export function check(data: any): ResetCounterTask {
		ReadingTask.check(data);
		return data;
	}

	export function create(): ResetCounterTask {
		return {
			reading: "",
		};
	}
}
