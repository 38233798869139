
























import { Vue, Component } from "vue-property-decorator";
import { Server, servers } from '@/loader';
import ServerButton from "../component/server/ServerButton.vue";

@Component({
	components: {
		ServerButton
	}
})
export default class Home extends Vue {
	public get servers(): Server[] {
		return servers.list
	}

	public get selected() {
		return servers.selected;
	}
}
