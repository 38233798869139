






















































































































































import { Vue, Component, Prop, Mixins, Watch, Ref } from "vue-property-decorator";
import {
	GameReplacement,
	Game,
	MachineGame,
	ReplacementAction,
	GameSelect,
	GameRate,
	Denomination,
	flagMatch,
	ReplacementRow
} from "@/loader";
import GameBetSelect from "../../game/GameBetSelect";
import GameDenominationSelect from "../../game/GameDenominationSelect";
import GameLineSelect from "../../game/GameLineSelect";
import GameRateSelect from "../../game/GameRateSelect";

@Component({
	components: {
		GameBetSelect,
		GameDenominationSelect,
		GameLineSelect,
		GameRateSelect
	}
})
export default class GameReplacementRow extends Mixins<ReplacementRow<GameReplacement>>(ReplacementRow) {
	@Ref()
	public readonly gameSelect!: GameSelect;

	public newGame: Game | null = null;
	public oldGame: Game | null = null;
	public oldPack: Game | null = null;
	public invalidFeedbacks = {
		action: "L'action est requise",
		oldId: "Un élément doit être sélectionné",
		newId: "Un élément doit être sélectionné",
	}

	public get states() {
		return {
			action: this.item && this.focused.action ? true : null,
			oldId: this.item && this.focused.oldId && ReplacementAction.NEED_OLD.includes(this.item.action) ? !!this.item.oldId : null,
			packId: this.item && this.focused.packId ? true : null,
			gameId: this.item && this.focused.gameId ? true : null,
			deno: this.item && this.focused.deno ? true : null,
			rate: this.item && this.focused.rate ? true : null,
			line: this.item && this.focused.line ? true : null,
			antebet: this.item && this.focused.antebet ? true : null,
			maxbet: this.item && this.focused.maxbet ? true : null,
		}
	}

	public get oldMachineGame(): MachineGame | null {
		return this.machine?.games?.find(x => x.id === this.item?.oldId) || null;
	}

	public get oldDeno() {
		return Denomination.toString(this.oldMachineGame?.deno);
	}

	public get oldRate() {
		return GameRate.toString(this.oldMachineGame?.rate);
	}

	public get gameId() {
		return this.item?.gameId || null;
	}

	public get isGameDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.gameId !== this.item.gameId || false;
	}

	public get isPackDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.packId !== this.item.packId || false;
	}

	public get isDenoDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.deno !== this.item.deno || false;
	}

	public get isRateDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.rate?.rate !== this.item.rate?.rate || false;
	}

	public get isLineDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.line !== this.item.line || false;
	}

	public get isAntebetDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.antebet !== this.item.antebet || false;
	}

	public get isMaxbetDifferent() {
		return this.oldMachineGame && this.item && this.oldMachineGame.maxbet !== this.item.maxbet || false;
	}

	public get compatibilities(): GameVariant[] {
		return this.newGame?.variants.filter(v =>
			(v.denos && this.item?.deno && flagMatch(v.denos, this.item.deno))
			|| (v.rates.length && this.item?.rate?.rate && v.rates.find(r => r.rate === this.item.rate.rate))
			|| (v.lines.length && this.item?.line && v.lines.includes(this.item.line))
			|| (v.antebets.length && this.item?.antebet && v.antebets.includes(this.item.antebet))
			|| (v.maxbets.length && this.item?.maxbet && v.maxbets.includes(this.item.maxbet))
			|| (v.osId && this.machine?.osId && v.osId === this.machine.osId)
		) || [];
	}

	public async mounted() {
		this.newGame = await this.$db.getGame(this.gameId);
		this.oldGame = await this.$db.getGame(this.oldMachineGame?.gameId);
		this.oldPack = await this.$db.getGame(this.oldMachineGame?.packId);
	}

	@Watch("gameId")
	public async onGameIdChanged(newValue: number | null, oldValue?: number | null) {
		if (this.item && newValue !== oldValue) {
			this.newGame = await this.$db.getGame(newValue);
		}
	}

	@Watch("oldMachineGame")
	public async onOldMachineGameChanged(newValue: MachineGame | null, oldValue?: MachineGame | null) {
		if (this.item && newValue !== oldValue) {
			this.oldGame = await this.$db.getGame(newValue?.gameId);
			this.oldPack = await this.$db.getGame(newValue?.packId);
		}
	}
}
