import { Component, Mixins } from "vue-property-decorator";
import { BVariant, createIconConfig, EnumBadge, IconConfig, SoftwareType } from "@/loader";

@Component
export class SoftwareTypeBadge extends Mixins<EnumBadge<SoftwareType>>(EnumBadge) {
	public get variant(): BVariant {
		return BVariant.info;
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case SoftwareType.os:
				return createIconConfig(['fab', 'ubuntu']);
			case SoftwareType.driver:
				return createIconConfig(['fab', 'windows']);
			case SoftwareType.bios:
				return createIconConfig(['fal', 'microchip']);
			case SoftwareType.legal:
				return createIconConfig(['fal', 'balance-scale']);
			default:
				return createIconConfig(['fas', 'question']);
		}
	}

	public get title(): string {
		return SoftwareType.toString(this.value);
	}
}
