import dayjs from "dayjs";
import Vue from "vue";

String.nullable = function (value: any): string | null {
	return value === null || value === undefined ? null : String(value);
};

Boolean.nullable = function (value: any): boolean | null {
	return value === null || value === undefined ? null : Boolean(value);
};

Number.nullable = function (value: any): number | null {
	let n = Number(value);
	return value === null || value === undefined || Number.isNaN(n) ? null : n;
};

String.orDefault = function (value: any, defaultValue?: string): string {
	defaultValue = defaultValue === undefined ? "" : defaultValue;
	return value === null || value === undefined ? defaultValue : String(value);
};

Boolean.orDefault = function (value: any, defaultValue?: boolean): boolean {
	defaultValue = defaultValue === undefined ? false : defaultValue;
	return value === null || value === undefined ? defaultValue : Boolean(value);
};

Number.orDefault = function (value: any, defaultValue?: number): number {
	let n = Number(value);
	defaultValue = defaultValue === undefined ? 0 : defaultValue;
	return value === null || value === undefined || Number.isNaN(n) ? defaultValue : n;
};

export function enumOrDefault(value: string | number | null | undefined, defaultValue: string | number, values: (string | number)[]): string | number {
	return value && values.includes(value) ? value : defaultValue;
}

const PLURAL: { [prop: string]: string } = {
	"(quiz)$": "$1zes",
	"^(ox)$": "$1en",
	"([m|l])ouse$": "$1ice",
	"(matr|vert|ind)ix|ex$": "$1ices",
	"(x|ch|ss|sh)$": "$1es",
	"([^aeiouy]|qu)y$": "$1ies",
	"(hive)$": "$1s",
	"(?:([^f])fe|([lr])f)$": "$1$2ves",
	"(shea|lea|loa|thie)f$": "$1ves",
	"sis$": "ses",
	"([ti])um$": "$1a",
	"(tomat|potat|ech|her|vet)o$": "$1oes",
	"(bu)s$": "$1ses",
	"(alias)$": "$1es",
	"(octop)us$": "$1i",
	"(ax|test)is$": "$1es",
	"(us)$": "$1es",
	"([^s]+)$": "$1s"
};

const SINGULAR: { [prop: string]: string } = {
	"(quiz)zes$": "$1",
	"(matr)ices$": "$1ix",
	"(vert|ind)ices$": "$1ex",
	"^(ox)en$": "$1",
	"(alias)es$": "$1",
	"(octop|vir)i$": "$1us",
	"(cris|ax|test)es$": "$1is",
	"(shoe)s$": "$1",
	"(o)es$": "$1",
	"(bus)es$": "$1",
	"([m|l])ice$": "$1ouse",
	"(x|ch|ss|sh)es$": "$1",
	"(m)ovies$": "$1ovie",
	"(s)eries$": "$1eries",
	"([^aeiouy]|qu)ies$": "$1y",
	"([lr])ves$": "$1f",
	"(tive)s$": "$1",
	"(hive)s$": "$1",
	"(li|wi|kni)ves$": "$1fe",
	"(shea|loa|lea|thie)ves$": "$1f",
	"(^analy)ses$": "$1sis",
	"(analy|ba|diagno|parenthe|progno|synop|the)ses$": "$1$2sis",
	"([ti])a$": "$1um",
	"(n)ews$": "$1ews",
	"(h|bl)ouses$": "$1ouse",
	"(corpse)s$": "$1",
	"(us)es$": "$1",
	"s$": ""
};

const IRREGULAR: { [prop: string]: string } = {
	"move": "moves",
	"foot": "feet",
	"goose": "geese",
	"sex": "sexes",
	"child": "children",
	"man": "men",
	"tooth": "teeth",
	"person": "people"
};

const UNCOUNTABLE = [
	"sheep",
	"fish",
	"deer",
	"moose",
	"series",
	"species",
	"money",
	"rice",
	"information",
	"equipment"
];

/** Pluralize a string */
String.prototype.plural = function (revert: boolean = false): string {
	// save some time in the case that singular and plural are the same
	if (UNCOUNTABLE.indexOf(this.toLowerCase()) >= 0) {
		return <string>this;
	}

	// check for irregular forms
	let pattern: RegExp;
	let replace;
	for (var word in IRREGULAR) {
		if (revert) {
			pattern = new RegExp(IRREGULAR[word] + "$", "i");
			replace = word;
		} else {
			pattern = new RegExp(word + "$", "i");
			replace = IRREGULAR[word];
		}
		if (this.match(pattern)) {
			return this.replace(pattern, replace);
		}
	}

	var array = revert ? SINGULAR : PLURAL;
	// check for matches using regular expressions
	for (var reg in array) {
		pattern = new RegExp(reg, "i");

		if (this.match(pattern)) {
			return this.replace(pattern, array[reg]);
		}
	}

	return <string>this;
};

/** Singularize a string */
String.prototype.singular = function (): string {
	return this.plural(true);
};

/** Remove diacritics by normalization (NFD) */
String.prototype.removeDiacritics = function () {
	return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Split a string into words
 */
String.prototype.splitWords = function () {
	return this.match(/[A-Z]?[a-z0-9]+|[A-Z0-9]+/g) || [];
};

/**
 * Capitalize a string (first letter upper, all others lower)
 */
String.prototype.capitalize = function () {
	return this.length > 0 ? this[0].toUpperCase() + this.slice(1).toLowerCase() : "";
};

/**
 * PascalCase: Words lowercased with first letters capitalized
 */
String.prototype.pascalCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.capitalize()).join("");
};

/**
 * camelCase: Words lowercased with second and following words first letters capitalized
 */
String.prototype.camelCase = function () {
	return this.removeDiacritics().splitWords().map((w, index) => !index ? w.toLowerCase() : w.capitalize()).join("");
};

/**
 * snake_case: Words lowercased and separated by an underscore
 */
String.prototype.snakeCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.toLowerCase()).join("_");
};

/**
 * UPPER_SNAKE_CASE: Words uppercased and separated by an underscore
 */
String.prototype.upperSnakeCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.toUpperCase()).join("_");
};

/**
 * Capital_Snake_Case: Words lowercased with first letter capitalization and separated by an underscore
 */
String.prototype.capitalSnakeCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.capitalize()).join("_");
};

/**
 * kebab-case: Words lowercased and separated by a hyphen
 */
String.prototype.kebabCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.toLowerCase()).join("-");
};

/**
 * Train-Case: Words lowercased with first letter capitalization and separated by a hyphen
 */
String.prototype.trainCase = function () {
	return this.removeDiacritics().splitWords().map(w => w.capitalize()).join("-");
};

/**
 * Humanize: split words lowercased (the first is capitalized) and separated by a space.
 * Remove "fk_" prefix and "_id" suffix.
 */
String.prototype.humanize = function () {
	var value = this;
	if (value.match(/^fk_/i)) {
		value = value.replace(/^fk_/i, "");
		if (!value.match(/_id$/i)) {
			value += "_id";
		}
	}
	return value.removeDiacritics().splitWords().map((w, index) => !index ? w.capitalize() : w.toLowerCase()).join(" ");
};

/**
 * Format a string by replacing its placeholder (C format like `{0}`)
 */
String.prototype.format = function (...args: (string | number)[]): string {
	return this.replace(/{(\d+)}/g, (match, number) => args[Number(number)]?.toString() || match);
};

/**
 * PascalCase: Words lowercased with first letters capitalized
 */
String.pascalCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().capitalize()).join("");
};

/**
 * camelCase: Words lowercased with second and following words first letters capitalized
 */
String.camelCase = function (...values: string[]) {
	return values.map((w, index) => !index ? w.removeDiacritics().toLowerCase() : w.removeDiacritics().capitalize()).join("");
};

/**
 * snake_case: Words lowercased and separated by an underscore
 */
String.snakeCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().toLowerCase()).join("_");
};

/**
 * UPPER_SNAKE_CASE: Words uppercased and separated by an underscore
 */
String.upperSnakeCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().toUpperCase()).join("_");
};

/**
 * Capital_Snake_Case: Words lowercased and separated by an underscore.
 */
String.capitalSnakeCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().capitalize()).join("_");
};

/**
 * kebab-case: Words lowercased and separated by a hyphen
 */
String.kebabCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().toLowerCase()).join("-");
};

/**
 * Train-Case: Words lowercased with first letter capitalization and separated by a hyphen
 */
String.trainCase = function (...values: string[]) {
	return values.map(w => w.removeDiacritics().capitalize()).join("-");
};

/**
 * Capitalize
 */
String.capitalize = function (value: string) {
	return value.capitalize();
};

/**
 * Uppercase
 */
String.toUpperCase = function (value: string) {
	return value.toUpperCase();
};

/**
 * Lowercase
 */
String.toLowerCase = function (value: string) {
	return value.toLowerCase();
};

export type Scalar = string | number | boolean | null;

export function flagToArray<T extends number>(value: T | null | undefined): T[] {
	const result: T[] = [];
	while (value) {
		const bit: T = <T>(value & (~value + 1)); // Get the lowest active bit
		result.push(bit);
		value = <T>(value ^ bit); // Can't use shortcut ^= because of TS error 2322
	}
	return result;
}

export function flagToStrings<T extends number>(value: T | null | undefined, enumToString: (value: T) => string): string[] {
	return flagToArray(value).map(enumToString);
}

export function flagToString<T extends number>(value: T | null | undefined, enumToString: (value: T) => string, separator: string = ", ", last_separator: string = " et "): string {
	let values = flagToStrings(value, enumToString);
	let last = values.pop() || "";
	return values.length ? values.join(separator) + last_separator + last : last;
}

export function arrayToFlag<T extends number>(values: T[]): T {
	return values.reduce((sum, v) => <T>(sum | v), 0 as T);
}

export function flagMatch(value: number, needle: number): boolean {
	return (value & needle) !== 0;
}

export function checkValue(data: any, key: string, defaultValue: any, checkItemFunction?: Function, oldKeys?: string[]) {
	if (!data) {
		return;
	}
	oldKeys?.forEach(oldKey => {
		if (oldKey in data) {
			defaultValue = defaultValue || data[oldKey];
			Vue.delete(data, oldKey);
		}
	});

	if (data[key] === undefined) {
		Vue.set(data, key, typeof defaultValue === "function" ? defaultValue() : defaultValue);
	} else if (data[key] && checkItemFunction) {
		checkItemFunction(data[key]);
	}
}

export function checkDate(data: any): string | null {
	if (!data) {
		return null;
	}
	let d = dayjs(data);
	return d.isValid() ? d.toISODateTimeString() : null;
}

export function checkArrayValue(data: any, key: string, checkItemFunction?: Function, oldKeys?: string[]) {
	if (!data) {
		return;
	}
	let defaultValue: any[] = [];
	oldKeys?.forEach(oldKey => {
		if (oldKey in data) {
			defaultValue = defaultValue.length ? defaultValue : data[oldKey];
			Vue.delete(data, oldKey);
		}
	});
	if (!Array.isArray(data[key])) {
		Vue.set(data, key, defaultValue);
	} else if (checkItemFunction) {
		data[key].forEach((item: any, index: number, list: any[]) => {
			list[index] = checkItemFunction(item);
		});
	}
}
