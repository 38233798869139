























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { EditComponent } from '../generic/EditComponent';
import { ReadingCounterColumnSettings, ReadingCounterRowSettings, ReadingCounterSettings } from '@/loader';

@Component
export default class ReadingCounterColumn extends Mixins<EditComponent<ReadingCounterColumnSettings, ReadingCounterRowSettings>>(EditComponent) {
	@Prop({ type: Object, default: null })
	public settings!: ReadingCounterSettings;

	public get index(): number {
		return this.parent && this.item ? this.parent.columns.indexOf(this.item) : -1;
	}

	public get rowIndex(): number {
		return this.settings && this.parent ? this.settings.rows.indexOf(this.parent) : -1;
	}

	public get rowsCount(): number {
		return this.settings?.rows.length || 0;
	}

	public get maxColspan(): number {
		return this.parent ? this.parent.columns.length - this.index : 1;
	}

	public get maxRowspan(): number {
		return this.rowsCount - this.rowIndex;
	}

	public get canIncreaseColspan(): boolean {
		return this.index < ((this.parent?.columns.length || 0) - 1);
	}

	public get canDecreaseColspan(): boolean {
		return this.item && this.item.colspan > 1 || false;
	}

	public get canIncreaseRowspan(): boolean {
		return this.rowIndex < this.rowsCount - 1;
	}

	public get canDecreaseRowspan(): boolean {
		return this.item && this.item.rowspan > 1 || false;
	}

	public increaseColspan() {
		if (this.item && this.canIncreaseColspan) {
			this.item.colspan++;
			for (let i = 0; i < this.item.rowspan; i++) {
				this.settings.rows[this.rowIndex + i].columns[this.index + this.item.colspan - 1].merged = true;
				this.settings.rows[this.rowIndex + i].columns[this.index + this.item.colspan - 1].counterId = null;
				// this.settings.rows[this.rowIndex + i].columns.splice(this.index + this.item.colspan - 1, 1);
			}
			this.focusOn("colspan");
		}
	}

	public decreaseColspan() {
		if (this.item && this.canDecreaseColspan) {
			this.item.colspan--;
			for (let i = 0; i < this.item.rowspan; i++) {
				this.settings.rows[this.rowIndex].columns[this.index + this.item.colspan].merged = false;
				// this.settings.rows[this.rowIndex + i]?.columns.splice(this.index + this.item.colspan, 0, ReadingCounterColumnSettings.create());
			}
			this.focusOn("colspan");
		}
	}

	public increaseRowspan() {
		if (this.item && this.canIncreaseRowspan) {
			this.item.rowspan++;
			for (let i = 0; i < this.item.colspan; i++) {
				this.settings.rows[this.rowIndex + this.item.rowspan - 1].columns[this.index + i].merged = true;
				this.settings.rows[this.rowIndex + this.item.rowspan - 1].columns[this.index + i].counterId = null;
			}
			// this.settings.rows[this.rowIndex + this.item.rowspan - 1].columns.splice(this.index, this.item.colspan);
			this.focusOn("rowspan");
		}
	}

	public decreaseRowspan() {
		if (this.item && this.canDecreaseRowspan) {
			// this.settings.rows[this.rowIndex + this.item.rowspan - 1].columns.splice(this.index, 0, ...Array(this.item.colspan).fill().map(_ => ReadingCounterRowSettings.create()));
			this.item.rowspan--;
			for (let i = 0; i < this.item.colspan; i++) {
				this.settings.rows[this.rowIndex + this.item.rowspan].columns[this.index + i].merged = false;
			}
			this.focusOn("rowspan");
		}
	}

}
