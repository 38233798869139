import { CreateElement } from "vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { CategoryItem, Category, CategoryComponent, DocumentSelect } from "@/loader";

@Component
// @ts-ignore
export class CategorySelect<T extends Category> extends Mixins<CategoryComponent<T>, DocumentSelect<T>>(CategoryComponent, DocumentSelect) {
	@Prop({ type: String, default: "Aucune" })
	public emptyText!: string;

	@Prop({ type: Boolean, default: false })
	public displayCount!: boolean;

	public get options(): CategoryItem<T>[] {
		let result = this.categoriesItems.slice();
		if (this.includeWithParents.length) {
			result = result.filter(c => this.includeWithParents.includes(c._id));
		}
		if (!this.required) {
			result.unshift(this.emptyItem);
		}
		return result;
	}

	public optionSlot(h: CreateElement, option: CategoryItem<T>) {
		return [
			option.level ? h("span", { domProps: { innerHTML: "&mdash; ".repeat(option.level) } }) : null,
			option.category?.name || "?",
			this.displayCount && option.count ? h("b-badge", option.count.toString()) : null
		];
	}

	public mounted() {
		this.loadCategories();
	}
}
