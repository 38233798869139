








































import { Component, Mixins } from "vue-property-decorator";
import { EditList, PartCompatibility, HasCompatibilities } from "@/loader";
import PartCompatibilityRow from "./PartCompatibilityRow.vue";

@Component({
	components: {
		PartCompatibilityRow,
	}
})
export default class PartCompatibilityTable extends Mixins<EditList<PartCompatibility, HasCompatibilities>>(EditList) {
	public get array(): PartCompatibility[] {
		return this.parent ? this.parent.compatibilities.parts : [];
	}
	public set array(value: PartCompatibility[]) {
		if (this.parent) {
			this.parent.compatibilities.parts = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return PartCompatibility.create;
	}

	public get cloneFactory(): (base: PartCompatibility) => PartCompatibility {
		return PartCompatibility.clone;
	}
}
