import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { Machine, MachineGame, store } from "@/loader";

@Module({
	dynamic: true,
	store,
	namespaced: true,
	name: "machineGameModal"
})
export class MachineGameModalModule extends VuexModule {
	public visible: boolean = false;
	public machine: Machine | null = null;
	public machineGame: MachineGame | null = null;
	public original: MachineGame | null = null;

	@Mutation
	public show(payload?: { machine?: Machine | null, machineGame?: MachineGame | null }) {
		this.machine = payload?.machine || null;
		this.machineGame = payload?.machineGame || null;
		this.original = JSON.parse(JSON.stringify(this.machineGame));
		this.visible = true;
	}

	@Mutation
	public hide() {
		this.machine = null;
		this.machineGame = null;
		this.original = null;
		this.visible = false;
	}
}

export const machineGameModal = getModule(MachineGameModalModule, store);
