





















































import { Component, Mixins, Prop } from "vue-property-decorator";
import {
	DocumentList,
	Machine,
	Reading,
	Software,
	Counter,
	number,
	toaster,
	BvTableFieldArray,
} from '@/loader';

@Component
export default class ReadingsList extends Mixins<DocumentList<Reading>>(DocumentList) {
	@Prop({ type: String, required: true })
	public machineId!: string;

	public machine: Machine | null = null;
	public software: Software | null = null;
	public counters: Counter[] = [];
	public fields: BvTableFieldArray = [];

	public get canCreate() {
		return true;
	}

	public get startkey(): string {
		return Reading.getStartKey(this.machineId);
	}

	public get endkey(): string {
		return Reading.getEndKey(this.machineId);
	}

	public get type(): string {
		return Reading.TYPE;
	}

	public async mounted() {
		this.fields = [
			{
				key: "date",
				label: "Date",
				sortable: true,
				sortDirection: "desc",
				stickyColumn: true,
				isRowHeader: true,
			}
		];
		try {
			this.counters = await this.$db.allCounters();
			this.machine = await this.$db.getMachine(this.machineId);
			this.software = await this.$db.getSoftware(this.machine?.osId);
			if (this.software) {
				[...this.software.elecs, ...this.software.mecas].forEach(oc => {
					let c = this.counters.find(x => x._id === oc.counterId);
					if (!c) {
						return;
					}
					this.fields.push({
						key: c._id,
						label: c.name,
						formatter(value: any, key: string, item: Reading) {
							return number.format(item.values.find(x => x.counterId === c?._id)?.value || 0);
						}
					});
				});
			}
		} catch (error) {
			console.error(error);
			toaster.warn({ message: "Une erreur est survenue durant le chargement des relevés" });
			this.machine = null;
			this.software = null;
		}
	}
}
