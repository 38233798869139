






















































































































import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import { BvTableFieldArray } from "bootstrap-vue";
import { toaster, Game, GameVariant, EditList } from "@/loader";
import GameBetSelect from "./GameBetSelect";
import GameDenominationSelect from "./GameDenominationSelect";
import GameLineSelect from "./GameLineSelect";
import GameRateSelect from "./GameRateSelect";

@Component({
	components: {
		GameBetSelect,
		GameDenominationSelect,
		GameLineSelect,
		GameRateSelect,
	}
})
export default class GameVariantTable extends Mixins<EditList<GameVariant, Game>>(EditList) {
	public fields: BvTableFieldArray = [
		{
			key: "compatibility",
			label: "Compatible",
		},
		{
			key: "rates",
			label: "Taux",
		},
		{
			key: "denos",
			label: "Dénominations",
		},
		{
			key: "lines",
			label: "Lignes",
		},
		{
			key: "antebets",
			label: "Antebets",
		},
		{
			key: "maxbets",
			label: "Maxbets",
		},
		{
			key: "volatilities",
			label: "Volatilités",
		},
		{
			key: "action",
			label: "",
			tdClass: "action-td",
		}
	]

	public get array(): GameVariant[] {
		return this.parent ? this.parent.variants : [];
	}
	public set array(value: GameVariant[]) {
		if (this.parent) {
			this.parent.variants = value;
			this.changed = true;
		}
	}

	public get itemFactory(): Function | null {
		return GameVariant.create;
	}

	public get cloneFactory(): (base: GameVariant) => GameVariant {
		return GameVariant.clone;
	}

	public get focusOnAction() {
		return false;
	}
}
