












import { Vue, Component, Prop } from "vue-property-decorator";
import { Task, TaskAction } from '@/loader';

@Component
export default class MissionTaskSummary extends Vue {
	@Prop({ type: Array, required: true })
	public readonly items!: Task[];

	@Prop({ type: String, default: null })
	public readonly machineId!: string;

	public get action(): (t: Task) => string {
		return (t: Task) => TaskAction.toString(t.action);
	}

	public get tasks(): Task[] {
		return this.machineId ? this.items.filter(t => t.machineId === this.machineId) : this.items;
	}
}
