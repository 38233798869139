






































import { Vue, Component, Prop } from "vue-property-decorator";
import { Coordinate, CoordinateType } from '@/loader';

@Component
export default class CoordinateEdit extends Vue {
	@Prop({ type: Object, required: true })
	public coordinate!: Coordinate;

	@Prop({ type: [String, Object], default: "div" })
	public tag!: string | Component;

	public types = CoordinateType.LIST;

	public focused = {
		label: false,
		value: false,
		type: false,
	};

	public states = {
		label: null,
		value: null,
		type: null,
	};

	public invalidFeedbacks = {
		label: "Non valide",
		value: "Non valide",
		type: "Non valide",
	};

	public toggleFavorite() {
		this.coordinate.favorite = !this.coordinate.favorite;
		this.$emit('change', this.coordinate)
	}

	public formatPhone(value: string) {
		return (value || "").replace(/(?<!^)\+|[^\d]+/g, ' ')
	}

	public get type() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
			case CoordinateType.fax:
				return "tel";
			case CoordinateType.email:
				return "email";
			case CoordinateType.website:
				return "url";
			default:
				return "text";
		}
	}

	public get actionIcon() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
				return ["fas", "phone"];
			case CoordinateType.fax:
				return ["far", "fax"];
			case CoordinateType.email:
				return ["far", "envelope"];
			case CoordinateType.website:
				return ["fal", "globe"];
			default:
				return ["fas", "question"];
		}
	}

	public get actionLink() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
				return "tel:" + this.coordinate.value.replace(/[^0-9+]+/g, '');
			case CoordinateType.email:
				return "mail:" + this.coordinate.value;
			case CoordinateType.website:
				return this.coordinate.value;
			default:
				return null;
		}
	}

	public get formatter() {
		switch (this.coordinate.type) {
			case CoordinateType.phone:
			case CoordinateType.mobile:
			case CoordinateType.fax:
				return this.formatPhone;
			case CoordinateType.email:
			case CoordinateType.website:
			default:
				return null;
		}
	}
}
