











































import { Component, Prop, Mixins } from "vue-property-decorator";
import { SoftwareReplacement, Task, EditList, Machine, Mission, MachineSoftwareTask, ReplacementAction } from "@/loader";
import SoftwareReplacementRow from "./SoftwareReplacementRow.vue";

@Component({
	components: {
		SoftwareReplacementRow,
	}
})
export default class SoftwareReplacementTable extends Mixins<EditList<SoftwareReplacement, Task>>(EditList) {
	public dragOptions = {
		group: "softwareReplacementRows",
		handle: ".btn-move",
		draggale: ".software-replacement-row",
	}

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public get array(): SoftwareReplacement[] {
		return this.parent && MachineSoftwareTask.instanceOf(this.parent.data) ? this.parent.data.softwares : [];
	}
	public set array(value: SoftwareReplacement[]) {
		if (this.parent && MachineSoftwareTask.instanceOf(this.parent.data)) {
			this.parent.data.softwares = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return () => SoftwareReplacement.create(null, ReplacementAction.add);
	}

	public get cloneFactory(): (base: SoftwareReplacement) => SoftwareReplacement {
		return SoftwareReplacement.clone;
	}

	public get label() {
		return "Matériels";
	}
}
