import { BvListItem, Frequency } from "@/loader";
import { CreateElement } from "vue";
import { Vue, Component, Model } from "vue-property-decorator";

type BvListItemTitle = BvListItem & { title: string };

@Component
export class FrequencyCheckboxGroup extends Vue {
	@Model("input", { type: Number })
	public value!: Frequency;

	public get frequency(): Frequency[] {
		return Frequency.toArray(this.value);
	}

	public set frequency(value: Frequency[]) {
		this.$emit("input", Frequency.fromArray(value));
	}

	public get smallDisplay(): boolean {
		return this.$screen.lg ? this.$screen.width < 1614 : false;
	}

	public get stacked(): boolean {
		return !this.$screen.md;
	}

	public get options(): BvListItemTitle[] {
		let result: BvListItemTitle[] = [];
		for (let value of Object.values(Frequency)) {
			if (typeof value === "number") {
				result.push({
					value,
					text: this.smallDisplay ? Frequency.toShortString(value) : Frequency.toString(value),
					title: Frequency.toString(value),
				});
			}
		}
		return result;
	}

	public render(h: CreateElement) {


		return h(
			"b-form-checkbox-group",
			{
				class: ["w-100"],
				props: {
					buttons: true,
					buttonVariant: "outline-primary",
					stacked: this.stacked,
				},
			},
			this.options.map(o => {
				return h(
					"b-form-checkbox",
					{
						props: {
							value: o.value
						},
						attrs: {
							title: o.title
						},
					},
					o.text
				);
			})
		);
	}
}
