import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Part, servers, DocumentSelect, busy, Platform, AllDocsOptions, Compatibility } from '@/loader';

@Component
export class PartSelect extends Mixins<DocumentSelect<Part>>(DocumentSelect) {
	@Prop({ type: [String, Array], default: null })
	public categories!: string | string[] | null;

	@Prop({ type: String, default: null })
	public platformId!: string | null;

	public platform: Platform | null = null;

	public async loadOptions() {
		let options: AllDocsOptions | undefined = undefined;
		if (this.platformId) {
			this.platform = await this.$db.getPlatform(this.platformId);
			options = {
				keys: this.platform?.compatibilities.parts.length
					? this.platform.compatibilities.parts
						.filter(p => p.compatible !== Compatibility.incompatible)
						.map(p => p.partId)
					: undefined
			};
		}
		this.options = await this.$db.allParts(options);
	}

	public filterOptions(options: Part[]): Part[] {
		if (this.categories) {
			let cats = Array.isArray(this.categories) ? this.categories : [this.categories];
			if (cats.length) {
				options = options.filter(g => g.categoryId && cats.includes(g.categoryId));
			}
		}
		options = this._union(options, this.include, true);
		options = this._union(options, this.exclude, false);
		return options;
	}

	public mounted() {
		this.loadOptions();
	}

	public get editRoute(): string {
		return "part";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public createOption(option: any): any {
		busy.start(`Création du matériel "${option.name}" ...`);
		let item = Part.create(option.name);
		this.pushItem(item, option);
	}

	@Watch("platformId")
	public onPlatformIdChanged(newValue?: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadOptions();
		}
	}
}
