




































import { Vue, Component, Prop } from "vue-property-decorator";
import { AppSettings, Reading, Machine } from "@/loader";

@Component
export default class ReadingStatsTable extends Vue {
	@Prop({ type: Object, required: true })
	public readonly machine!: Machine;

	@Prop({ type: Object, required: true })
	public readonly item!: Reading;

	@Prop({ type: Array, required: true })
	public readonly previous!: Reading[];

	@Prop({ default: null })
	public readonly settings!: AppSettings | null;

	@Prop({ type: Number, default: 1 })
	public readonly previousCount!: number;

	public formatter = Reading.statFormat;
	public variant = Reading.statStatus;

	public get readings(): Reading[] {
		return [this.item, ...this.previous.slice(0, this.previousCount - 1)];
	}
}
