import { checkDate, checkValue } from "@/loader";
import { ConfigType } from "dayjs"

export interface ErpObject {
	erpId: number | null
	erpUpdateDate: ConfigType
}

export namespace ErpObject {
	export function instanceOf(value: any): value is ErpObject {
		return value && typeof value === "object" && "erpId" in value;
	}

	export function check(data: any): ErpObject {
		checkValue(data, "erpId", null);
		checkValue(data, "erpUpdateDate", null, checkDate);
		return data;
	}
}
