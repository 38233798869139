import { checkArrayValue, checkValue, CoordinateType } from "@/loader";

export interface Coordinate {
	type: CoordinateType
	label: string
	value: string
	favorite: boolean
}

export interface Coordinates {
	coordinates: Coordinate[]
}

export namespace Coordinates {
	export function instanceOf(value: any): value is Coordinates {
		return value && typeof value === "object" && "coordinates" in value && Array.isArray(value.coordinates);
	}

	export function check(data: any): Coordinates {
		checkArrayValue(data, "coordinates", Coordinate.check);
		return data;
	}
}

export namespace Coordinate {
	export function create(
		type: CoordinateType = CoordinateType.email,
		label: string = "",
		value: string = "",
		favorite: boolean = false
	): Coordinate {
		return {
			type,
			label,
			value,
			favorite,
		}
	}

	export function clone(original: Coordinate): Coordinate {
		return Object.assign({}, original);
	}

	export function check(data: any): Coordinate {
		checkValue(data, "type", CoordinateType.email);
		checkValue(data, "label", "");
		checkValue(data, "value", "");
		checkValue(data, "favorite", false);
		return data;
	}
}
