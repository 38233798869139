import { CreateElement } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { busy } from "@/loader";

@Component
export default class ActionRow extends Vue {
	@Prop({ type: Boolean, default: false })
	public iconOnly!: boolean;

	@Prop({ type: Boolean, default: false })
	public noIcon!: boolean;

	@Prop({ type: Boolean, default: false })
	public grouped!: boolean;

	@Prop({ type: String, default: "md" })
	public buttonSize!: "sm" | "md" | "lg";


	@Prop({ type: Boolean, default: false })
	public canCancel!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableCancel!: boolean;

	@Prop({ type: String, default: "Annuler" })
	public cancelLabel!: string;

	@Prop({ type: [String, Array], default: "undo" })
	public cancelIcon!: string | string[];

	@Prop({ type: String, default: "outline-secondary" })
	public cancelVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canClone!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableClone!: boolean;

	@Prop({ type: String, default: "Cloner" })
	public cloneLabel!: string;

	@Prop({ type: [String, Array], default: () => ['fal', 'clone'] })
	public cloneIcon!: string | string[];

	@Prop({ type: String, default: "outline-secondary" })
	public cloneVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableCreate!: boolean;

	@Prop({ type: String, default: "Nouveau" })
	public createLabel!: string;

	@Prop({ type: [String, Array], default: "plus" })
	public createIcon!: string | string[];

	@Prop({ type: String, default: "primary" })
	public createVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canDelete!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableDelete!: boolean;

	@Prop({ type: String, default: "Supprimer" })
	public deleteLabel!: string;

	@Prop({ type: [String, Array], default: "trash" })
	public deleteIcon!: string | string[];

	@Prop({ type: String, default: "danger" })
	public deleteVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canEdit!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableEdit!: boolean;

	@Prop({ type: String, default: "Modifier" })
	public editLabel!: string;

	@Prop({ type: [String, Array], default: () => ['far', 'edit'] })
	public editIcon!: string | string[];

	@Prop({ type: String, default: "primary" })
	public editVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canMove!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableMove!: boolean;

	@Prop({ type: String, default: "Déplacer" })
	public moveLabel!: string;

	@Prop({ type: [String, Array], default: () => ["fal", "arrows"] })
	public moveIcon!: string | string[];

	@Prop({ type: String, default: "outline-secondary" })
	public moveVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canRestore!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableRestore!: boolean;

	@Prop({ type: String, default: "Rétablir" })
	public restoreLabel!: string;

	@Prop({ type: [String, Array], default: () => ["fas", "trash-undo"] })
	public restoreIcon!: string | string[];

	@Prop({ type: String, default: "outline-primary" })
	public restoreVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canSave!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableSave!: boolean;

	@Prop({ type: String, default: "Sauvegarder" })
	public saveLabel!: string;

	@Prop({ type: [String, Array], default: "save" })
	public saveIcon!: string | string[];

	@Prop({ type: String, default: "success" })
	public saveVariant!: string;



	@Prop({ type: Boolean, default: false })
	public canTrash!: boolean;

	@Prop({ type: Boolean, default: false })
	public disableTrash!: boolean;

	@Prop({ type: String, default: "Mettre à la corbeille" })
	public trashLabel!: string;

	@Prop({ type: [String, Array], default: () => ["fas", "minus-circle"] })
	public trashIcon!: string | string[];

	@Prop({ type: String, default: "outline-danger" })
	public trashVariant!: string;


	public get isBusy(): boolean {
		return busy.status;
	}

	public render(h: CreateElement) {
		let buttons = [
			this.$slots.last || null,
			this.renderButton(h, this.canSave, this.disableSave, "save", this.saveIcon, this.saveLabel, this.saveVariant),
			this.renderButton(h, this.canEdit, this.disableEdit, "edit", this.editIcon, this.editLabel, this.editVariant),
			this.renderButton(h, this.canCreate, this.disableCreate, "create", this.createIcon, this.createLabel, this.createVariant),
			this.renderButton(h, this.canClone, this.disableClone, "clone", this.cloneIcon, this.cloneLabel, this.cloneVariant),
			this.renderButton(h, this.canMove, this.disableMove, "", this.moveIcon, this.moveLabel, this.moveVariant, "btn-move"),
			this.$slots.default || null,
			this.renderButton(h, this.canDelete, this.disableDelete, "delete", this.deleteIcon, this.deleteLabel, this.deleteVariant),
			this.renderButton(h, this.canTrash, this.disableTrash, "trash", this.trashIcon, this.trashLabel, this.trashVariant),
			this.renderButton(h, this.canRestore, this.disableRestore, "restore", this.restoreIcon, this.restoreLabel, this.restoreVariant),
			this.renderButton(h, this.canCancel, this.disableCancel, "cancel", this.cancelIcon, this.cancelLabel, this.cancelVariant),
			this.$slots.first || null,
		];

		if (this.grouped) {
			return h("b-button-group", { class: "action-group", props: { size: this.buttonSize } }, buttons);
		} else {
			return h("b-row", { class: "action-row" }, buttons);
		}
	}

	public renderButton(h: CreateElement, can: boolean, disabled: boolean, event: string | Function | null, icon: string | string[], label: string, variant: string, _class?: string) {
		if (!can) {
			return null;
		}

		let on: { [key: string]: Function | Function[] } = {};
		if (event) {
			on.click = typeof event === "function" ? event : () => this.$emit(event);
		}

		let button = h("b-button", {
			props: {
				disabled: disabled || this.isBusy,
				variant,
				size: this.buttonSize,
			},
			class: _class,
			attrs: {
				title: this.iconOnly ? label : null
			},
			on
		}, [
			!this.noIcon && icon ? h("fa", { props: { icon, fixedWidth: true } }) : null,
			!this.iconOnly ? label : null
		]);

		if (this.grouped) {
			return button;
		}
		return h("b-col", { props: { cols: "auto" } }, [button]);
	}
}
