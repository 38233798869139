import { Component, Mixins } from "vue-property-decorator";
import {
	createCategoryItem,
	CategoryItem,
	GameCategory,
	CategorySelect,
} from "@/loader";

@Component
export class GameCategorySelect extends Mixins<CategorySelect<GameCategory>>(CategorySelect) {
	public readonly emptyItem: CategoryItem<GameCategory> = createCategoryItem(GameCategory.create("Aucune"));

	public get startkey(): string {
		return GameCategory.STARTKEY;
	}

	public get endkey(): string {
		return GameCategory.ENDKEY;
	}

	public get query(): string {
		return "game/categories";
	}

	public mounted() {
		this.emptyItem._id = "";
	}
}
