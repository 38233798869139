import { BvListItem } from "@/loader";

export enum CounterType {
	unique = 1,
	variable = 2,
	heading = 3,
	electronic = 4,
	mechanical = 5
}

export namespace CounterType {
	export function toString(countertype: CounterType): string {
		switch (countertype) {
			case CounterType.unique: return "Unique";
			case CounterType.variable: return "Variable";
			case CounterType.heading: return "Entête";
			case CounterType.electronic: return "Électronique";
			case CounterType.mechanical: return "Mécanique";
			default: return "?";
		}
	}

	export function toShortString(countertype: CounterType): string {
		switch (countertype) {
			case CounterType.unique: return "Unique";
			case CounterType.variable: return "Var.";
			case CounterType.heading: return "Entête";
			case CounterType.electronic: return "Élec.";
			case CounterType.mechanical: return "Méca.";
			default: return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(CounterType)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<CounterType>value) }));

	export function isMeca(value: CounterType): boolean {
		return value === CounterType.mechanical;
	}

	export function isElec(value: CounterType): boolean {
		return value === CounterType.electronic;
	}
}
