



















import { Vue, Component, Prop } from "vue-property-decorator";
import { Server, servers } from "@/loader";

@Component
export default class ServerButton extends Vue {
	@Prop({ required: true })
	public server!: Server;

	@Prop({ default: "b-button" })
	public tag!: any;

	@Prop({ type: String, default: "outline-primary" })
	public variant!: string;

	@Prop({ default: 80 })
	public imgWidth!: any;

	public get activeServerName(): string {
		return servers.selected && servers.selected.name || "";
	}

	public get isActive(): boolean {
		return this.server.name === this.activeServerName;
	}

	public get config(): any {
		let conf: any = {}
		if (this.tag === "b-nav-item") {
			conf.linkClasses = ""
		} else {
			conf.class = "d-flex flex-column justify-content-center align-items-center"
		}
		return conf;
	}
}
