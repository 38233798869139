


























































































import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, MixTask } from "@/loader";
import CheckMachineTaskEditor from "../common/CheckMachineTaskEditor.vue";

@Component({
	components: {
		CheckMachineTaskEditor
	}
})
export default class MixTaskEditor extends Mixins<TaskDataEditorMixin<MixTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
		}
	}

	public get tabCount(): number[] {
		return [this.gamesCount, this.partsCount, this.softwaresCount, this.controllersCount, 1];
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire après un mix",
	}
}
