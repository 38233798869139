import { arrayToFlag, BvListItem, flagToArray, flagToString, flagToStrings } from "@/loader";

export enum Volatility {
	low = 1,
	low_medium = 2,
	medium = 4,
	medium_high = 8,
	high = 16
}

function volatilityToString(value: Volatility): string {
	switch (value) {
		case Volatility.low: return "Basse";
		case Volatility.low_medium: return "Basse/Moyenne";
		case Volatility.medium: return "Moyenne";
		case Volatility.medium_high: return "Moyenne/Haute";
		case Volatility.high: return "Haute";
		default: return "?";
	}
}

export namespace Volatility {
	export const LIST: BvListItem[] = Object.values(Volatility)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: volatilityToString(<Volatility>value) }));

	export function toArray(value: Volatility): Volatility[] {
		return flagToArray(value);
	}

	export function fromArray(values: Volatility[]): Volatility {
		return arrayToFlag(values);
	}

	export function toStrings(value: Volatility): string[] {
		return flagToStrings(value, volatilityToString);
	}

	export function toString(value: Volatility, separator: string = ", ", last_separator: string = " et "): string {
		return flagToString(value, volatilityToString, separator, last_separator);
	}
}
