import {
	ChangeCounterTask,
	CheckMachine,
	checkValue,
	cloneObject,
	CompanyTask,
	ControlTask,
	CouchDBDocument,
	DestroyTask,
	Event,
	Events,
	generateUUID,
	InstallationTask,
	RepairTask,
	LimitMachineTask,
	LocationTask,
	MachineControllerTask,
	MachineGameTask,
	MachinePartTask,
	TaskAction,
	MissionStatus,
	MixTask,
	ReadingTask,
	ReactivationTask,
	ReferenceTask,
	Replacement,
	ReserveTask,
	ResetCounterTask,
	SellTask,
	StockTask,
	TransferTask,
	UUID
} from "@/loader";

export type TaskData = null | InstallationTask | MixTask | RepairTask | ControlTask | ResetCounterTask | ChangeCounterTask | ReserveTask | TransferTask | DestroyTask | ReactivationTask | SellTask | StockTask;

export interface Task extends CouchDBDocument, Events {
	missionId: UUID
	machineId: UUID
	status: MissionStatus
	action: TaskAction
	description: string
	data: TaskData
}

export namespace Task {
	export const TYPE = "task";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function create(
		missionId: UUID,
		machineId: UUID = "",
		action: TaskAction = TaskAction.none,
		status: MissionStatus = MissionStatus.planned,
		data: TaskData = null,
		description: string = "",
		events: Event[] = []
	): Task {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			missionId,
			machineId,
			action,
			status,
			description,
			data: data || createData(action),
			events,
		}
	}

	export function isValidData(task: Task): boolean {
		switch (task.action) {
			case TaskAction.change_counter: return ChangeCounterTask.instanceOf(task.data);
			case TaskAction.control: return ControlTask.instanceOf(task.data);
			case TaskAction.destroy: return DestroyTask.instanceOf(task.data);
			case TaskAction.installation: return InstallationTask.instanceOf(task.data);
			case TaskAction.repair: return RepairTask.instanceOf(task.data);
			case TaskAction.mix: return MixTask.instanceOf(task.data);
			case TaskAction.reactivation: return ReactivationTask.instanceOf(task.data);
			case TaskAction.reserve: return ReserveTask.instanceOf(task.data);
			case TaskAction.reset_counter: return ResetCounterTask.instanceOf(task.data);
			case TaskAction.sell: return SellTask.instanceOf(task.data);
			case TaskAction.stock: return StockTask.instanceOf(task.data);
			case TaskAction.transfer: return TransferTask.instanceOf(task.data);
			default: return false;
		}
	}

	export function createData(action: TaskAction): TaskData {
		switch (action) {
			case TaskAction.change_counter: return ChangeCounterTask.create();
			case TaskAction.control: return ControlTask.create();
			case TaskAction.destroy: return DestroyTask.create();
			case TaskAction.installation: return InstallationTask.create();
			case TaskAction.repair: return RepairTask.create();
			case TaskAction.mix: return MixTask.create();
			case TaskAction.reactivation: return ReactivationTask.create();
			case TaskAction.reserve: return ReserveTask.create();
			case TaskAction.reset_counter: return ResetCounterTask.create();
			case TaskAction.sell: return SellTask.create();
			case TaskAction.stock: return StockTask.create();
			case TaskAction.transfer: return TransferTask.create();
			default: return null;
		}
	}

	export function check(data: any): Task {
		CouchDBDocument.check(data, TYPE);
		checkValue(data, "missionId", "");
		checkValue(data, "machineId", "");
		checkValue(data, "action", TaskAction.none);
		checkValue(data, "status", MissionStatus.planned);
		checkValue(data, "description", "");
		Events.check(data);

		switch (data.action) {
			case TaskAction.control:
				checkValue(data, "data", ControlTask.create, ControlTask.check);
				break;
			case TaskAction.change_counter:
				checkValue(data, "data", ChangeCounterTask.create, ChangeCounterTask.check);
				break;
			case TaskAction.destroy:
				checkValue(data, "data", DestroyTask.create, DestroyTask.check);
				break;
			case TaskAction.installation:
				checkValue(data, "data", InstallationTask.create, InstallationTask.check);
				break;
			case TaskAction.repair:
				checkValue(data, "data", RepairTask.create, RepairTask.check);
				break;
			case TaskAction.mix:
				checkValue(data, "data", MixTask.create, MixTask.check);
				break;
			case TaskAction.reactivation:
				checkValue(data, "data", ReactivationTask.create, ReactivationTask.check);
				break;
			case TaskAction.reserve:
				checkValue(data, "data", ReserveTask.create, ReserveTask.check);
				break;
			case TaskAction.reset_counter:
				checkValue(data, "data", ResetCounterTask.create, ResetCounterTask.check);
				break;
			case TaskAction.sell:
				checkValue(data, "data", SellTask.create, SellTask.check);
				break;
			case TaskAction.stock:
				checkValue(data, "data", StockTask.create, StockTask.check);
				break;
			case TaskAction.transfer:
				checkValue(data, "data", TransferTask.create, TransferTask.check);
				break;
		}

		return data;
	}

	export function clone(base: Task, missionId: UUID | null = null): Task {
		let default_data: Partial<Task> = { status: MissionStatus.planned, data: Task.cloneData(base.data) };
		if (missionId) {
			default_data.missionId = missionId;
		}
		return cloneObject(base, default_data);
	}

	export function cloneData(base: TaskData): TaskData {
		let data: any = {};
		if (LocationTask.instanceOf(base)) {
			data.location = "";
		}
		if (ReferenceTask.instanceOf(base)) {
			data.ref = "";
		}
		if (LimitMachineTask.instanceOf(base)) {
			data.limitCredit = 0;
			data.limitInsert = 0;
			data.limitJackpot = 0;
			data.limitPayment = 0;
		}
		if (CheckMachine.instanceOf(base)) {
			data.controlDone = false;
		}
		if (ReadingTask.instanceOf(base)) {
			data.reading = "";
		}
		if (MachineGameTask.instanceOf(base)) {
			data.games = base.games.map(Replacement.clone);
		}
		if (MachinePartTask.instanceOf(base)) {
			data.parts = base.parts.map(Replacement.clone);
		}
		if (MachineControllerTask.instanceOf(base)) {
			data.controllers = base.controllers.map(Replacement.clone);
		}
		return cloneObject(base, data);
	}

	export function isFinishable(task: Task): boolean {
		if (!task.data) {
			return false;
		}
		if (ReadingTask.instanceOf(task.data) && !task.data.reading) {
			return false;
		}
		if (CompanyTask.instanceOf(task.data) && !task.data.companyId) {
			return false;
		}
		if (LocationTask.instanceOf(task.data) && !task.data.location) {
			return false;
		}
		if (ReferenceTask.instanceOf(task.data) && !task.data.ref) {
			return false;
		}
		if (LimitMachineTask.instanceOf(task.data) && (
			!task.data.limitCredit
			|| !task.data.limitInsert
			|| !task.data.limitJackpot
			|| !task.data.limitPayment
		)) {
			return false;
		}
		if (CheckMachine.instanceOf(task.data) && !task.data.controlDone) {
			return false;
		}
		return true;
	}
}
