


























































import { Vue, Component, Prop } from "vue-property-decorator";
import { CategoryItem, Category, DEBUG, servers } from '@/loader';

@Component
export default class CategoryLeaf<T extends Category>  extends Vue {
	@Prop({ type: Object, required: true })
	public readonly item!: CategoryItem<T>;

	@Prop({ type: Number, default: 0 })
	public readonly level!: number;

	@Prop({ type: Array, default: () => [] })
	public readonly include!: CategoryItem<T>[];

	@Prop({ type: [Boolean, Array], default: false })
	public readonly opened!: boolean | string[];

	public expand: boolean = false;
	public disabled: boolean = false;

	public get leafs(): CategoryItem<T>[] {
		return this.item.children
			.filter(c => !this.include.length || this.include.includes(c._id))
			.sort((a, b) => (a.order || 0) - (b.order || 0));
	}
	public set leafs(value: CategoryItem<T>[]) {
		if (this.item && !this.include.length) {
			this.item.children = value;
			this.item.children.forEach((item, index) => {
				if (item.order !== index) {
					item.order = index;
					this.save(item);
				}
			})
		}
	}

	public select(item: CategoryItem<T> | null) {
		this.$emit("select", item);
	}

	public mounted() {
		if (Array.isArray(this.opened) && this.opened.find(x => x === this.category._id) || this.opened === true) {
			this.expand = true;
		}
	}

	public async save(item: CategoryItem<any>) {
		DEBUG && console.log("save", item._id, item.order);
		if (!servers.selected) {
			return;
		}
		if (!this.$pouch) {
			return;
		}

		try {
			let result = await this.$pouch.upsert(item._id, (i: CategoryItem<any>) => {
				if (i.order !== item.order) {
					i.order = item.order;
					return i;
				}
				return false;
			}, servers.selected.name);
			if (result.updated) {
				DEBUG && console.log("save ok", result);
				item._rev = result.rev;
			}
		} catch (error) {
			DEBUG && console.log("save error", error);
		}
	}
}
