import {
	checkArrayValue,
	checkValue,
	cloneObject,
	Compatibility,
	Compatible,
	ControllerType,
	CouchDBDocument,
	Deactivable,
	Denomination,
	ErpObject,
	Events,
	Frequency,
	generateUUID,
	HasCompatibilities,
	Identifiable,
	Named,
	nanoid,
	number,
	percent,
	UUID,
	Volatility,
} from "@/loader";

export interface GameRate extends Named {
	rate: number
	external: number
	isAverage: boolean
}

export interface GameController extends Named, Identifiable {
	type: ControllerType
	increment: number
	startup: number
	description: string | null
}

export interface GameFeature extends Named {
	frequencies: Frequency
	description: string | null
}

export interface GameVariant extends Compatible {
	frequencies: Frequency
	volatilities: Volatility
	denos: Denomination
	rates: GameRate[]
	lines: string[]
	antebets: string[]
	maxbets: string[]
	controllers: GameController[]
	features: GameFeature[]
	osId: UUID | null
	minVersion: string | null
	maxVersion: string | null
}

export interface Game extends CouchDBDocument, Events, Named, Deactivable, ErpObject, HasCompatibilities {
	type: "game"
	packId: string | null;
	categoryId: string | null;
	isPack: boolean
	multiDeno: boolean
	volatilities: Volatility
	denos: Denomination
	frequencies: Frequency
	brand: string | null
	ref: string | null
	rates: GameRate[]
	lines: string[]
	antebets: string[]
	maxbets: string[]
	controllers: GameController[]
	features: GameFeature[]
	variants: GameVariant[]
	games: UUID[]
}

export const GAME_LINES: string[] = [
	...Array.range(20, 10, 10), // 10, 20, ..., 200
].sort((a, b) => a - b).map(x => x.toString());

export const GAME_BETS: string[] = [
	...Array.range(9, 1, 1), // 1, 2, ..., 9
	...Array.range(9, 10, 10), // 10, 20, ..., 90
	...Array.range(20, 100, 20), // 100, 120, 140, ..., 480
	...Array.range(4, 150, 100), // 150, 250, 350, 450
	...Array.range(11, 500, 50), // 500, 550, 600, ..., 1000
].sort((a, b) => a - b).map(x => x.toString());

export namespace Game {
	export const TYPE = "game";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Game {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		name: string = "",
		brand: string | null = null,
		ref: string | null = null,
		packId: UUID | null = null,
		multiDeno: boolean = false,
		volatilities: Volatility = Volatility.low,
		denos: Denomination = Denomination.c1,
	): Game {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			packId,
			categoryId: null,
			isPack: !!packId,
			multiDeno,
			volatilities,
			denos,
			brand,
			ref,
			name,
			frequencies: 0,
			disabled: false,
			erpId: null,
			erpUpdateDate: null,
			rates: [],
			lines: [],
			antebets: [],
			maxbets: [],
			controllers: [],
			features: [],
			variants: [],
			games: [],
			...Events.create(),
			...HasCompatibilities.create(),
		}
	}

	export function check(data: any): Game {
		CouchDBDocument.check(data, TYPE);
		Named.check(data);
		Deactivable.check(data);
		Events.check(data);
		HasCompatibilities.check(data);

		checkValue(data, "packId", null);
		checkValue(data, "categoryId", null);
		checkValue(data, "isPack", false);
		checkValue(data, "multiDeno", false);
		checkValue(data, "volatilities", 0);
		checkValue(data, "denos", 0);
		checkValue(data, "brand", null);
		checkValue(data, "ref", null);
		checkValue(data, "frequencies", 0);

		checkArrayValue(data, "lines");
		checkArrayValue(data, "antebets");
		checkArrayValue(data, "maxbets");
		checkArrayValue(data, "games");
		checkArrayValue(data, "rates", GameRate.check);
		checkArrayValue(data, "controllers", GameController.check);
		checkArrayValue(data, "features", GameFeature.check);
		checkArrayValue(data, "variants", GameVariant.check);
		return data;
	}

	export function clone(base: Game): Game {
		return cloneObject(base, {
			rates: base.rates.map(GameRate.clone),
			features: base.features.map(GameFeature.clone),
			controllers: base.controllers.map(GameController.clone),
			variants: base.variants.map(GameVariant.clone),
			...HasCompatibilities.clone(base),
		});
	}
}

export namespace GameRate {
	export function instanceOf(value: any): value is GameRate {
		return Named.instanceOf(value) && "rate" in value && "external" in value;
	}

	export function create(
		name: string = "",
		rate: number = 0,
		external: number = 0,
		isAverage: boolean = false,
	): GameRate {
		return {
			name,
			rate,
			external,
			isAverage,
		}
	}

	export function clone(original: GameRate): GameRate {
		return Object.assign({}, original);
	}

	export function check(data: any): GameRate {
		checkValue(data, "name", "");
		checkValue(data, "rate", 0);
		checkValue(data, "external", 0);
		checkValue(data, "isAverage", false);
		return data;
	}

	export function toString(value?: GameRate | null): string {
		return value && value.rate ? `${percent.format(value.rate / 100)}${value.external ? ` + ${percent.format(value.external / 100)}` : ""}` : "";
		// return value ? `${value.name} (${percent.format(value.rate / 100)}${value.external ? ` + ${percent.format(value.external / 100)}` : ""})` : "";
	}
}

export namespace GameController {
	export function instanceOf(value: any): value is GameController {
		return Named.instanceOf(value) && "type" in value && "increment" in value && "startup" in value;
	}

	export function create(
		name: string = "",
		type: ControllerType = ControllerType.progressif,
		increment: number = 0,
		startup: number = 0,
		description: string | null = null,
	): GameController {
		return {
			id: nanoid(),
			name,
			type,
			startup,
			increment,
			description,
		}
	}

	export function clone(original: GameController): GameController {
		return Object.assign({}, original);
	}

	export function check(data: any): GameController {
		checkValue(data, "id", nanoid);
		checkValue(data, "name", "");
		checkValue(data, "type", ControllerType.progressif);
		checkValue(data, "increment", 0);
		checkValue(data, "startup", 0);
		checkValue(data, "description", null);
		return data;
	}

	export function toString(value?: GameController | null): string {
		return value ? `${value.name}: ${number.format(value.startup)} +${percent.format(value.increment / 100)}` : "";
	}
}

export namespace GameFeature {
	export function create(
		name: string = "",
		frequencies: Frequency = 0,
		description: string | null = null,
	): GameFeature {
		return {
			frequencies,
			name,
			description,
		}
	}

	export function clone(original: GameFeature): GameFeature {
		return Object.assign({}, original);
	}

	export function check(data: any): GameFeature {
		checkValue(data, "name", "");
		checkValue(data, "frequencies", 0);
		checkValue(data, "description", null);
		return data;
	}

	export function toString(value?: GameController | null): string {
		return value ? `${value.name}: ${number.format(value.startup)} +${percent.format(value.increment / 100)}` : "";
	}
}

export namespace GameVariant {
	export function create(
		compatible: Compatibility = Compatibility.compatible,
		frequencies: Frequency = 0,
		volatilities: Volatility = 0,
		denos: Denomination = 0,
		rates: GameRate[] = [],
		lines: string[] = [],
		antebets: string[] = [],
		maxbets: string[] = [],
	): GameVariant {
		return {
			compatible,
			frequencies,
			volatilities,
			denos,
			rates,
			lines,
			antebets,
			maxbets,
			controllers: [],
			features: [],
			osId: "",
			minVersion: "",
			maxVersion: "",
		}
	}

	export function clone(original: GameVariant): GameVariant {
		return Object.assign({}, original);
	}

	export function check(data: any): GameVariant {
		Compatible.check(data);

		checkValue(data, "frequencies", 0);
		checkValue(data, "volatilities", 0);
		checkValue(data, "denos", 0);
		checkValue(data, "lines", []);
		checkValue(data, "antebets", []);
		checkValue(data, "maxbets", []);
		checkValue(data, "osId", "");
		checkValue(data, "minVersion", "");
		checkValue(data, "maxVersion", "");

		checkArrayValue(data, "rates", GameRate.check);
		checkArrayValue(data, "controllers", GameController.check);
		checkArrayValue(data, "features", GameFeature.check);
		return data;
	}
}
