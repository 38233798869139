import {
	checkValue,
	cloneObject,
	ConfigStatus,
	Events,
	Identifiable,
	nanoid,
	Software,
	SoftwareReplacement,
	SoftwareType,
	UUID
} from "@/loader"


export interface MachineSoftware extends Identifiable, Events {
	softwareId: UUID
	favorite: boolean
	definition: SoftwareType
	status: ConfigStatus
	version: string | null
}

export namespace MachineSoftware {
	export function create(
		softwareId: string = "",
		definition: SoftwareType = SoftwareType.none,
		status: ConfigStatus = ConfigStatus.draft,
		version: string | null = null,
		favorite: boolean = false,
	): MachineSoftware {
		return {
			id: nanoid(),
			definition,
			softwareId,
			status,
			favorite,
			version,
			events: [],
		}
	}

	export function clone(base: MachineSoftware | SoftwareReplacement): MachineSoftware {
		let result = cloneObject(base, {
			id: nanoid(),
			status: ConfigStatus.draft,
		});
		if (SoftwareReplacement.instanceOf(result)) {
			// @ts-ignore
			delete result.action;
			// @ts-ignore
			delete result.oldId;
		}
		return result;
	}

	export function check(data: any): MachineSoftware {
		checkValue(data, "id", nanoid);
		checkValue(data, "definition", SoftwareType.none);
		checkValue(data, "softwareId", "");
		checkValue(data, "favorite", false);
		checkValue(data, "status", ConfigStatus.draft);
		checkValue(data, "version", null);
		Events.check(data);
		return data;
	}

	export function toString(value: MachineSoftware, software: Software | null): string {
		return [
			software?.name,
			value.version
		].filter(x => !!x).join(" ");
	}

	export function instanceOf(value: any): value is MachineSoftware {
		return Identifiable.instanceOf(value) && "softwareId" in value;
	}

	export function modifyFromReplacement(ms: MachineSoftware, sr: SoftwareReplacement) {
		ms.softwareId = sr.softwareId;
		ms.definition = sr.definition;
		ms.version = sr.version;
	}
}
