import { MachinePartTask, PartReplacement, ReadingTask } from "@/loader";

export interface RepairTask extends ReadingTask, MachinePartTask {
}

export namespace RepairTask {
	export function instanceOf(value: any): value is RepairTask {
		return ReadingTask.instanceOf(value)
			&& MachinePartTask.instanceOf(value);
	}

	export function check(data: any): RepairTask {
		ReadingTask.check(data);
		MachinePartTask.check(data);
		return data;
	}

	export function create(
		parts: PartReplacement[] = []
	): RepairTask {
		return {
			parts,
			reading: "",
		};
	}
}
