













































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import dayjs from 'dayjs';
import {
	EditView,
	Mission,
	MissionStatus,
	TaskAction,
	toaster,
	servers,
	EventType,
	Task,
	DEBUG,
	Company,
	User,
	isOnline,
	MachineStatus,
	Event,
	Machine,
	busy,
	Reading,
} from '@/loader';
import MissionEditor from "../../../component/mission/MissionEditor.vue";
import MissionHeader from "../../../component/mission/MissionHeader.vue";
import ReadingsTable from "../../../component/reading/ReadingsTable.vue";
import TasksTable from "../../../component/task/TasksTable.vue";

import pdfMake from "@/plugin/pdfmake";
import { generateTaskReport } from "../../../component/task/TasksReport";

@Component({
	components: {
		MissionEditor,
		MissionHeader,
		ReadingsTable,
		TasksTable,
	}
})
export default class MissionView extends Mixins<EditView<Mission>>(EditView) {
	public MissionStatus = MissionStatus;
	public date: string | null = null;
	public selectedTasks: Task[] = [];
	public company: Company | null = null;
	public users: User[] = [];
	public userToString = User.toString;
	public nextControlDate: string = "";

	public get canEdit(): boolean {
		return !this.isDone && !this.isCancelled;
	}

	public get canCreate(): boolean {
		return this.canUpdateStatus;
	}

	public get isActive(): boolean {
		return this.item ? dayjs().isBetween(this.item.start, this.item.end || this.item.start, "day", "[]") : false;
	}

	public get isPlanned() {
		return this.item?.status === MissionStatus.planned;
	}

	public get isOngoing() {
		return this.item?.status === MissionStatus.ongoing;
	}

	public get isDone() {
		return this.item?.status === MissionStatus.done;
	}

	public get isCancelled() {
		return this.item?.status === MissionStatus.cancelled;
	}

	public get canUpdateStatus(): boolean {
		return this.item && this.canEdit && !this.isNew && !this.changed || false;
	}

	public get canClose(): boolean {
		return this.item && this.canUpdateStatus && MissionStatus.FINISHABLE.includes(this.item.status) || false;
	}

	public get canTrash(): boolean {
		return this.item && this.canUpdateStatus && MissionStatus.CANCELLABLE.includes(this.item.status) || false;
	}

	public get itemFactory(): Function | null {
		return Mission.create;
	}

	public get cloneFactory(): (base: Mission) => Mission {
		return Mission.clone;
	}

	public get checkDocFunction(): Function | null {
		return Mission.check;
	}

	public get routeName(): string {
		return "mission";
	}

	public async abandon() {
		if (!this.item || !this.canTrash) {
			toaster.forbidden({ operation: "Abondonner" });
			return;
		}
		if (this.changed) {
			toaster.saveFirst();
			return;
		}

		this.item.status = MissionStatus.cancelled;
		this.focusOn("status");

		await this.save(EventType.cancelled);
	}

	public async close() {
		const operation = "Terminer";
		if (!(await isOnline())) {
			toaster.noConnection({ operation })
		}
		if (!this.item) {
			toaster.noItem({ operation, itemType: "mission" });
			return;
		}
		if (!this.canClose) {
			toaster.forbidden({ operation });
			return;
		}
		if (this.changed) {
			toaster.saveFirst();
			return;
		}
		busy.start("Vérification de l'intervention");
		const tasks = await this.$db.queryTasksByMission(this.item._id);
		const nb_unfinished = tasks.filter(t => t.status !== MissionStatus.done).length;
		if (nb_unfinished) {
			toaster.invalidWorkflow({ operation, reason: nb_unfinished > 1 ? `${nb_unfinished} tâches doivent d'abord être terminées` : "1 tâche doit d'abord être terminée" })
			busy.stop();
			return;
		}

		const oldStatus = this.item.status;
		const oldEnd = this.item.end;
		let task_error = false;
		this.item.status = MissionStatus.done;
		this.item.end = dayjs().format("YYYY-MM-DD");
		this.focusOn("status");
		let i = 0;
		for (let t of tasks) {
			busy.setText(`Fin de la tâche ${++i}/${tasks.length}...`);
			let machine = await this.$db.getMachine(t.machineId);
			if (!machine) {
				return;
			}

			Machine.applyTaskToMachine(t, machine, servers.author);
			if (!(await this.$db.save(machine))) {
				task_error = true;
			}
		}

		this.item.events.push(Event.create(
			servers.author,
			EventType.done,
			"",
			this.original,
			this.item
		));

		busy.setText(`Fin de l'intervention...`);
		if (!task_error && await this.$db.save(this.item)) {
			this.original = Object.assign({}, this.item);
			this.clean();

			if (tasks.some(t => t.action === TaskAction.control)) {
				this.nextControlDate = dayjs(this.item.end).add(120, "days").format("YYYY-MM-DD");
				this.$bvModal.show("next_control");
			} else {
				this.$router.push({ name: "planning" });
			}
		} else {
			this.item.status = oldStatus;
			this.item.end = oldEnd;
			this.item.events.pop();
		}
		busy.stop();
	}

	public addTask() {
		if (!this.item) {
			toaster.noItem({ operation: "Ajouter une tâche" });
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Ajouter une tâche" });
			return;
		}
		Task.create(this.item._id);
	}

	public async planNextControl() {
		const operation = "Planifier la prochaine visite";
		if (!this.item) {
			toaster.noItem({ operation, itemType: "mission" });
			return;
		}

		const date = dayjs(this.nextControlDate);
		let mission = Mission.create(
			this.item.companyId,
			this.item.techs,
			MissionStatus.planned,
			date.format("YYYY-MM-DD"),
			null,
			"Visite de contrôle"
		);
		mission.events.push(Event.create(servers.author, EventType.planned));
		if (await this.$db.save(mission)) {
			const machines = await this.$db.queryMachinesByOwner(this.item.companyId);
			machines
				.filter(m => m.status === MachineStatus.installed)
				.map(m => Task.create(
					mission._id,
					m._id,
					TaskAction.control,
				))
				.forEach(t => this.$db.save(t));
			this.$router.push({ name: "planning" });
		} else {
			toaster.error({ operation, error: "La plannification de la prochaine visite a échoué, veuillez la créer manuellement." });
		}
	}

	public async generateReport() {
		const operation = "Générer le rapport";
		if (!this.selectedTasks.length) {
			toaster.invalidWorkflow({ operation, reason: "au moins une tâche doit être sélectionnée" });
			return;
		}
		if (!this.selectedTasks.every(t => t.status === MissionStatus.done)) {
			toaster.invalidWorkflow({ operation, reason: "toutes les tâches sélectionnées doivent être validées" });
			return;
		}

		// get machines
		let ids = this.selectedTasks.map(t => t.machineId);
		let machines = await this.$db.allMachines({ keys: ids });
		// get readings
		let readings: Reading[] = [];
		machines.forEach(async m => readings.push(...(await this.$db.allReadings(m._id))));
		// get games
		ids = [
			...machines.map(m => m.games.map(mg => mg.packId)).flat(),
			...machines.map(m => m.games.map(mg => mg.gameId)).flat()
		].filter((v, i, a) => !!v && a.indexOf(v) === i);
		let games = await this.$db.allGames({ keys: ids });

		// generate doc
		const def = generateTaskReport(this.selectedTasks, machines, readings, games, await this.$db.getAppSettings());
		DEBUG && console.log(JSON.parse(JSON.stringify(def)));
		if (def) {
			pdfMake.createPdf(def).open();
		}
	}

	public async loadRelations() {
		this.company = await this.$db.getCompany(this.item?.companyId);
		this.users = await this.$db.allUsers(this.item?.techs);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("item")
	public onItemChanged(newValue: Mission | null, oldValue?: Mission | null) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}
}
