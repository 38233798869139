import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DataSelect, MachinePart, Part, UUID, Machine } from "@/loader";

interface MachinePartData {
	id: UUID
	data: MachinePart | null
	part: Part | null
	level: number
}

@Component
export class MachinePartSelect extends Mixins(DataSelect) {
	@Prop({ type: String, default: null })
	public machineId!: string | null;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Function, default: (option: MachinePart) => option.id })
	public reduce!: Function;

	@Prop({ type: [String, Array], default: () => [] })
	public categories!: string | string[];

	// @ts-ignore
	public readonly emptyItem: MachinePartData = {
		id: "",
		data: null,
		part: null,
		level: 0,
	};

	public get localCategories(): string[] {
		return !Array.isArray(this.categories) ? [this.categories] : this.categories;
	}

	public parts: Part[] = [];

	public async loadOptions() {
		let machineParts = (this.machine || (await this.$db.getMachine(this.machineId || "")))?.parts || [];
		let partIds = this.getPartIds(machineParts);
		partIds = partIds.filter((id, i, a) => id && a.indexOf(id) === i);
		this.parts = await this.$db.allParts({ keys: partIds });
		this.options = machineParts.map(mp => this.createMachinePartData(mp)).flat();
	}

	public getPartIds(machineParts: MachinePart[]) {
		let result: UUID[] = [];
		machineParts.forEach(mp => {
			result.push(mp.partId);
			result.push(...this.getPartIds(mp.children));
		});
		return result;
	}

	public filterOptions(options: MachinePartData[]): MachinePartData[] {
		let result = options.slice();
		if (this.localCategories.length) {
			result = result.filter(x => this.localCategories.includes(x.data?.categoryId || ""));
		}
		return result;
	}

	public createMachinePartData(mp: MachinePart, level: number = 0): MachinePartData[] {
		let result: MachinePartData[] = [];
		result.push({
			id: mp.id,
			data: mp,
			part: this.parts.find(g => g._id === mp.partId) || null,
			level
		});
		if (mp.children.length) {
			result.push(...mp.children.map(c => this.createMachinePartData(c, level + 1)).flat());
		}
		return result;
	}

	public mounted() {
		this.loadOptions();
	}

	public getOptionKey(option: MachinePartData): string {
		return option.id;
	}

	public getOptionLabel(option: MachinePartData): string {
		return (
			"—".repeat(option.level) + " "
			+ (option?.data ? MachinePart.toString(option.data, option.part) : this.emptyText)
		).trim();
	}

	public optionSlot(h: CreateElement, option: MachinePartData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: MachinePartData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	@Watch("machineId")
	public onMachineIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	@Watch("machine")
	public onMachineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	// public get editRoute(): string {
	// 	return "machine";
	// }

	// public get canEditOption(): boolean {
	// 	return servers.isAdmin || servers.isDirector || servers.isManager;
	// }
}
