


















































































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import {
	Server,
	servers,
	SyncStatus,
	getServer,
	Mission,
} from "@/loader";
import LoginForm from "../component/user/LoginForm.vue";
import MissionListItem from "../component/mission/MissionListItem.vue";

@Component({
	components: {
		LoginForm,
		MissionListItem,
	},
	inheritAttrs: true,
})
export default class ServerHome extends Vue {
	@Prop({ type: String, required: true })
	public serverName!: string;

	public today: Mission[] = []
	public techId: string = "";
	public upcoming: Mission[] = [];
	public upcomingFrequency: ManipulateType = "week";
	public frequencies = [
		{
			value: "day",
			text: "Voir demain"
		},
		{
			value: "week",
			text: "Voir la semaine à venir"
		},
		{
			value: "month",
			text: "Voir le mois à venir"
		},
	];

	public get canSelectUser() {
		return !servers.isTech || (servers.user?.roles?.length || 0) > 1;
	}

	public get server(): Server | null {
		return getServer(this.serverName);
	}

	public get isDisconnected(): boolean {
		return SyncStatus.OFF.includes(this.server?.status || 0);
	}

	public stopSync() {
		if (!this.server || !this.server.sync) {
			return;
		}
		this.server.sync.cancel();
		this.server.sync = null;
		this.server.active = false;
		this.server.status = SyncStatus.disconnected;
	}

	public startSync() {
		if (!this.server || this.server.sync) {
			return;
		}
		this.server.sync = this.$pouch.sync(this.server.name, this.server.url);
	}

	public async loadMissions(start: Dayjs, end?: Dayjs) {
		if (!servers.selected || !servers.user?._id) {
			return;
		}
		let techId = this.canSelectUser ? this.techId : servers.user._id;
		if (techId) {
			return await this.$db.queryMissionsByTechDates(techId, start, end);
		} else {
			return await this.$db.queryMissionsByDates(start, end);
		}
	}

	public async loadToday() {
		if (!servers.selected || !servers.user?._id) {
			this.today = [];
			return;
		}

		try {
			let today = dayjs().toISODateString();

			this.today = [];
			if (this.techId) {
				this.today.push(... await this.$db.queryMissionsByTechDate(this.techId, today));
			} else {
				this.today.push(... await this.$db.queryMissionsByDate(today));
			}
			this.today = this.today.sort((a, b) => dayjs(a.start).diff(b.start, "d") || dayjs(a.end).diff(b.end, "d"));
		} catch (error) {
			console.error(error);
		}
	}

	public async loadUpcoming() {
		if (!servers.selected || !servers.user?._id) {
			this.upcoming = [];
			return;
		}

		let techId = this.canSelectUser ? this.techId : servers.user._id;
		let start = dayjs().add(1, "d");
		let end = dayjs().add(1, this.upcomingFrequency);
		if (techId) {
			this.upcoming = await this.$db.queryMissionsByTechDates(techId, start, end);
		} else {
			this.upcoming = await this.$db.queryMissionsByDates(start, end);
		}
	}

	public async mounted() {
		this.loadToday();
		this.loadUpcoming();
	}

	@Watch("upcomingFrequency")
	public onUpcomingFrequencyChanged(newValue: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadUpcoming();
		}
	}

	@Watch("techId")
	public onTechIdChanged(newValue: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadToday();
			this.loadUpcoming();
		}
	}
}
