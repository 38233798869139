import { Component, Mixins } from "vue-property-decorator";
import { EnumSelect, Compatibility, BvListItem } from "@/loader";

@Component
export class CompatibilitySelect extends Mixins<EnumSelect<Compatibility>>(EnumSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return Compatibility.LIST;
	}
}
