import Vue from "vue";
import Vuex from "vuex";
// import VuexPersistence from "vuex-persist";
import { IStoreState } from "@/loader";

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence<IStoreState>({
// 	storage: window.localStorage,
// 	modules: ["db", "settings", "auth"]
// });

export const store = new Vuex.Store<IStoreState>({
	// plugins: [vuexLocal.plugin],
});
