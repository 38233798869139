import {
	ControllerReplacement,
	GameReplacement,
	LimitMachineTask,
	LocationTask,
	MachineControllerTask,
	MachineGameTask,
	MachinePartTask,
	MachineSoftwareTask,
	PartReplacement,
	ReadingTask,
	ReferenceTask,
	SoftwareReplacement,
} from "@/loader";


export interface MixTask extends ReadingTask, LimitMachineTask, ReferenceTask, LocationTask, MachineGameTask, MachinePartTask, MachineControllerTask, MachineSoftwareTask {
}

export namespace MixTask {
	export function instanceOf(value: any): value is MixTask {
		return ReadingTask.instanceOf(value)
			&& LimitMachineTask.instanceOf(value)
			&& ReferenceTask.instanceOf(value)
			&& LocationTask.instanceOf(value)
			&& MachineGameTask.instanceOf(value)
			&& MachinePartTask.instanceOf(value)
			&& MachineControllerTask.instanceOf(value)
			&& MachineSoftwareTask.instanceOf(value);
	}

	export function check(data: any): MixTask {
		LocationTask.check(data);
		ReferenceTask.check(data);
		LimitMachineTask.check(data);
		ReadingTask.check(data);
		MachineGameTask.check(data);
		MachinePartTask.check(data);
		MachineControllerTask.check(data);
		MachineSoftwareTask.check(data);
		return data;
	}

	export function create(
		ref: string = "",
		location: string = "",
		games: GameReplacement[] = [],
		parts: PartReplacement[] = [],
		controllers: ControllerReplacement[] = [],
		softwares: SoftwareReplacement[] = [],
	): MixTask {
		return {
			ref,
			location,
			games,
			parts,
			controllers,
			softwares,
			reading: "",
			limitCredit: 0,
			limitInsert: 0,
			limitJackpot: 0,
			limitPayment: 0,
		};
	}
}
