import Vue, { VueConstructor } from "vue";
import { isRemote } from "pouchdb-utils";
import PouchDB from "pouchdb-browser";
import dayjs, { ConfigType } from "dayjs";
import {
	AppSettings,
	AuthResponse,
	Company,
	Counter,
	DateSearch,
	DEBUG,
	Game,
	GameCategory,
	GlobalSettings,
	AllDocsOptions,
	Machine,
	MachineConfig,
	MachinePart,
	MachineStatus,
	MachineType,
	Mission,
	Software,
	Part,
	PartCategory,
	Platform,
	Reading,
	servers,
	Task,
	User,
	ValueUnit,
	Server,
	Settings,
	getServer,
	CouchDBDocument,
	toaster,
} from "@/loader";

export type DatabaseOption =
	PouchDB.MemoryAdapter.MemoryAdapterConfiguration
	| PouchDB.LocalStorageAdapter.LocalStorageAdapterConfiguration
	| PouchDB.LevelDbAdapter.LevelDbAdapterConfiguration
	| PouchDB.FruitDOWNAdapter.FruitDOWNAdapterConfiguration
	| PouchDB.AdapterCordovaSqlite.Configuration
	| PouchDB.HttpAdapter.HttpAdapterConfiguration
	| PouchDB.AdapterWebSql.Configuration
	| PouchDB.IdbAdapter.IdbAdapterConfiguration
	| PouchDB.Configuration.DatabaseConfiguration;

export interface PouchVueOptions {
	pouch?: PouchDB.Static;
	defaultDB?: string;
	debug?: string;
	optionsDB?: any;
}

export interface Attachment {
	id: PouchDB.Core.AttachmentId
	data: PouchDB.Core.AttachmentData
	type: string
}

let vue: Vue | null = null,
	pouch: PouchDB.Static | null = null,
	defaultDB: string = "",
	defaultUsername: string | null = null,
	defaultPassword: string | null = null,
	databases: Record<string, PouchDB.Database> = {},
	optionsDB: PouchVueOptions = {};

export class PouchAPI {
	private vm!: Vue;

	constructor(vm: Vue) {
		this.vm = vm;

		if (defaultDB) {
			this.makeInstance(defaultDB);
		}
	}

	private fetchSession(db: PouchDB.Database = databases[defaultDB]) {
		return new Promise<{}>(resolve => {
			db
				.getSession()
				.then(session => {
					db
						.getUser(session.userCtx.name)
						.then(userData => {
							let userObj = Object.assign(
								{},
								session.userCtx,
								userData
							);
							resolve({
								user: userObj,
								hasAccess: true,
							});
						})
						.catch(error => {
							resolve(error);
						});
				})
				.catch(error => {
					resolve(error);
				});
		});
	}

	private login(db = databases[defaultDB]) {
		return new Promise(resolve => {

			db
				.logIn(defaultUsername || "", defaultPassword || "")
				.then(user => {
					db
						.getUser(user.name)
						.then(userData => {
							let userObj = Object.assign(
								{},
								user,
								userData
							);
							resolve({
								user: userObj,
								hasAccess: true,
							});
						})
						.catch(error => {
							resolve(error);
						});
				})
				.catch(error => {
					resolve(error);
				});
		});
	}

	public makeInstance(db: string, options = {}) {
		// Merge the plugin optionsDB options with those passed in
		// when creating pouch dbs.
		// Note: default opiontsDB options are passed in when creating
		// both local and remote pouch databases. E.g. modifying fetch()
		// in the options is only useful for remote Dbs but will be passed
		// for local pouch dbs too if set in optionsDB.
		// See: https://pouchdb.com/api.html#create_database

		let _options: PouchVueOptions & DatabaseOption = Object.assign(
			{},
			optionsDB,
			options
		)
		if (!pouch) {
			throw new Error("pouch-vue not installed!");
		}

		databases[db] = new pouch(db, _options);
		this.registerListeners(databases[db]);
	}

	private registerListeners(db: PouchDB.Database) {
		db.on("created", (name: string) => {
			this.vm.$emit("pouchdb-db-created", {
				db: name,
				ok: true,
			});
		});
		db.on("destroyed", (name: string) => {
			this.vm.$emit("pouchdb-db-destroyed", {
				db: name,
				ok: true,
			});
		});
	}

	public connect(username: string, password: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return new Promise(resolve => {
			defaultUsername = username;
			defaultPassword = password;

			if (!isRemote(databases[db])) {
				resolve({
					message: "database is not remote",
					error: "bad request",
					status: 400,
				});
				return;
			}

			this.login(databases[db]).then(res => {
				resolve(res);
			});
		});
	}

	public createUser(username: string, password: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.signUp(username, password)
			.then(() => {
				return this.vm.$pouch.connect(username, password, db);
			})
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public putUser(username: string, metadata: any = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.putUser(username, {
				metadata,
			})
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public deleteUser(username: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.deleteUser(username)
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public changePassword(username: string, password: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.changePassword(username, password)
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public changeUsername(oldUsername: string, newUsername: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.changeUsername(oldUsername, newUsername)
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public signUpAdmin(adminUsername: string, adminPassword: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.signUpAdmin(adminUsername, adminPassword)
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public deleteAdmin(adminUsername: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db]
			.deleteAdmin(adminUsername)
			.catch(error => {
				return new Promise(resolve => {
					resolve(error);
				});
			});
	}

	public disconnect(db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return new Promise(resolve => {
			defaultUsername = null;
			defaultPassword = null;

			if (!isRemote(databases[db])) {
				resolve({
					message: "database is not remote",
					error: "bad request",
					status: 400,
				});
				return;
			}

			databases[db]
				.logOut()
				.then(res => {
					resolve({
						ok: res.ok,
						user: null,
						hasAccess: false,
					});
				})
				.catch(error => {
					resolve(error);
				});
		});
	}

	public destroy(db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].destroy().then(() => {
			if (db !== defaultDB) {
				delete databases[db];
			}
		});
	}

	public defaults(options: DatabaseOption = {}) {
		if (!pouch) {
			throw new Error("PouchDB is not installed");
		}
		pouch.defaults(options);
	}

	public close(db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].close().then(() => {
			if (db !== defaultDB) {
				delete databases[db];
			}
		});
	}

	public getSession(db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		if (!isRemote(databases[db])) {
			return new Promise(resolve => {
				resolve({
					message: "database is not remote",
					error: "bad request",
					status: 400,
				});
			});
		}
		return this.fetchSession(databases[db]);
	}

	public sync(localDB: string, remoteDB: string = defaultDB, options: DatabaseOption = {}) {
		if (!pouch) {
			throw new Error("PouchDB is not installed");
		}
		if (!databases[localDB]) {
			this.makeInstance(localDB);
		}
		if (!databases[remoteDB]) {
			this.makeInstance(remoteDB);
		}
		if (!defaultDB) {
			defaultDB = remoteDB;
		}

		let _options = Object.assign(
			{},
			{
				live: true,
				retry: true,
				back_off_function: (delay: number) => {
					if (delay === 0) {
						return 1000;
					}
					return delay * 3;
				},
			},
			options
		);

		let sync = pouch
			.sync(databases[localDB], databases[remoteDB], _options)
			.on("paused", err => {
				if (err) {
					this.vm.$emit("pouchdb-sync-error", {
						db: localDB,
						error: err,
					});
					return;
				}
				else {

					this.vm.$emit("pouchdb-sync-paused", {
						db: localDB,
						paused: true,
					});
				}
			})
			.on("change", info => {
				this.vm.$emit("pouchdb-sync-change", {
					db: localDB,
					info: info,
				});
			})
			.on("active", () => {
				this.vm.$emit("pouchdb-sync-active", {
					db: localDB,
					active: true,
				});
			})
			.on("denied", err => {
				this.vm.$emit("pouchdb-sync-denied", {
					db: localDB,
					error: err,
				});
			})
			.on("complete", info => {
				this.vm.$emit("pouchdb-sync-complete", {
					db: localDB,
					info: info,
				});
			})
			.on("error", err => {
				this.vm.$emit("pouchdb-sync-error", {
					db: localDB,
					error: err,
				});
			});

		return sync;
	}

	public push(localDB: string, remoteDB: string = defaultDB, options: PouchDB.Replication.ReplicateOptions = {}) {
		if (!databases[localDB]) {
			this.makeInstance(localDB);
		}
		if (!databases[remoteDB]) {
			this.makeInstance(remoteDB);
		}
		if (!defaultDB) {
			defaultDB = remoteDB;
		}

		// let _options: PouchDB.Replication.ReplicateOptions = Object.assign(
		// 	{},
		// 	{
		// 		live: true,
		// 		retry: true,
		// 		back_off_function: (delay: number) => {
		// 			if (delay === 0) {
		// 				return 1000;
		// 			}
		// 			return delay * 3;
		// 		},
		// 	},
		// 	options
		// );

		let rep = databases[localDB].replicate
			.to(databases[remoteDB], options)
			.on("paused", err => {
				if (err) {
					this.vm.$emit("pouchdb-push-error", {
						db: localDB,
						error: err,
					});
					return;
				}
				else {
					this.vm.$emit("pouchdb-push-paused", {
						db: localDB,
						paused: true,
					});
				}
			})
			.on("change", info => {
				this.vm.$emit("pouchdb-push-change", {
					db: localDB,
					info: info,
				});
			})
			.on("active", () => {
				this.vm.$emit("pouchdb-push-active", {
					db: localDB,
					active: true,
				});
			})
			.on("denied", err => {
				this.vm.$emit("pouchdb-push-denied", {
					db: localDB,
					error: err,
				});
			})
			.on("complete", info => {
				this.vm.$emit("pouchdb-push-complete", {
					db: localDB,
					info: info,
				});
			})
			.on("error", err => {
				this.vm.$emit("pouchdb-push-error", {
					db: localDB,
					error: err,
				});
			});

		return rep;
	}

	public pull(localDB: string, remoteDB: string = defaultDB, options: PouchDB.Replication.ReplicateOptions = {}) {
		if (!databases[localDB]) {
			this.makeInstance(localDB);
		}
		if (!databases[remoteDB]) {
			this.makeInstance(remoteDB);
		}
		if (!defaultDB) {
			defaultDB = remoteDB;
		}

		// let _options = Object.assign(
		// 	{},
		// 	{
		// 		live: true,
		// 		retry: true,
		// 		back_off_function: (delay: number) => {
		// 			if (delay === 0) {
		// 				return 1000;
		// 			}
		// 			return delay * 3;
		// 		},
		// 	},
		// 	options
		// );

		let rep = databases[localDB].replicate
			.from(databases[remoteDB], options)
			.on("paused", err => {
				if (err) {
					this.vm.$emit("pouchdb-pull-error", {
						db: localDB,
						error: err,
					});
					return;
				}
				else {
					this.vm.$emit("pouchdb-pull-paused", {
						db: localDB,
						paused: true,
					});
				}
			})
			.on("change", info => {
				this.vm.$emit("pouchdb-pull-change", {
					db: localDB,
					info: info,
				});
			})
			.on("active", () => {
				this.vm.$emit("pouchdb-pull-active", {
					db: localDB,
					active: true,
				});
			})
			.on("denied", err => {
				this.vm.$emit("pouchdb-pull-denied", {
					db: localDB,
					error: err,
				});
			})
			.on("complete", info => {
				this.vm.$emit("pouchdb-pull-complete", {
					db: localDB,
					info: info,
				});
			})
			.on("error", err => {
				this.vm.$emit("pouchdb-pull-error", {
					db: localDB,
					error: err,
				});
			});

		return rep;
	}

	public changes(options: PouchDB.Core.ChangesOptions = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		let _options: PouchDB.Core.ChangesOptions = Object.assign(
			{},
			{
				live: true,
				retry: true,
				back_off_function: (delay: number) => {
					if (delay === 0) {
						return 1000;
					}
					return delay * 3;
				},
			},
			options
		);

		let changes = databases[db]
			.changes(_options)
			.on("change", info => {
				this.vm.$emit("pouchdb-changes-change", {
					db: db,
					info: info,
				});
			})
			.on("complete", info => {
				this.vm.$emit("pouchdb-changes-complete", {
					db: db,
					info: info,
				});
			})
			.on("error", err => {
				this.vm.$emit("pouchdb-changes-error", {
					db: db,
					error: err,
				});
			});

		return changes;
	}

	public get(docId: string, options: PouchDB.Core.GetOptions = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db].get(docId, options);
	}

	public put(object: any, options: PouchDB.Core.PutOptions = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db].put(object, options);
	}

	public post(object: any, options: PouchDB.Core.Options = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db].post(object, options);
	}

	public remove(object: PouchDB.Core.RemoveDocument, options: PouchDB.Core.Options = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		return databases[db].remove(object, options);
	}

	public query<T extends {}, U extends {}>(fun: string | PouchDB.Map<U, T> | PouchDB.Filter<U, T>, options: PouchDB.Query.Options<U, T> = {}, db: string = defaultDB): Promise<PouchDB.Query.Response<T>> {
		if (!databases[db]) {
			this.makeInstance(db);
		}
		// @ts-ignore
		return databases[db].query(fun, options);
	}

	public find<T extends {}>(options: PouchDB.Find.FindRequest<T>, db: string = defaultDB): Promise<PouchDB.Find.FindResponse<T>> {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return <Promise<PouchDB.Find.FindResponse<T>>>databases[db].find(options);
	}

	public createIndex(index: PouchDB.Find.CreateIndexOptions, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].createIndex(index);
	}

	public allDocs(options: AllDocsOptions = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		let _options: AllDocsOptions = Object.assign(
			{},
			{ include_docs: true },
			options
		);

		return databases[db].allDocs(_options);
	}

	public bulkDocs(docs: PouchDB.Core.PutDocument<{}>[], options: PouchDB.Core.BulkDocsOptions = {}, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].bulkDocs(docs, options);
	}

	public compact(options: PouchDB.Core.CompactOptions = {}, db = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].compact(options);
	}

	public viewCleanup(db = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].viewCleanup();
	}

	public info(db = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].info();
	}

	public putAttachment(docId: string, rev: string, attachment: Attachment, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].putAttachment(
			docId,
			attachment.id,
			rev ? rev : "",
			attachment.data,
			attachment.type
		);
	}

	public getAttachment(docId: string, attachmentId: string, db = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].getAttachment(docId, attachmentId);
	}

	public deleteAttachment(docId: string, attachmentId: string, docRev: string, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].removeAttachment(
			docId,
			attachmentId,
			docRev
		);
	}

	public upsert<T extends {}>(docId: string, diffFun: PouchDB.UpsertDiffCallback<T>, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].upsert(docId, diffFun);
	}

	public putIfNotExists<T extends {}>(doc: PouchDB.Core.Document<T>, db: string = defaultDB) {
		if (!databases[db]) {
			this.makeInstance(db);
		}

		return databases[db].putIfNotExists(doc);
	}
}

const vuePouch: Vue = {
	/* Creates a property in 'data' with 'null' value for each pouch property
	 * defined on the component.  This way the user does not have to manually
	 * define a data property for the reactive databases/selectors.
	 *
	 * This partial 'data' object is mixed into the components along with
	 * the rest of the API (but is empty unless the component has a 'pouch'
	 * option).
	 */
	// @ts-ignore
	data(vm: VueConstructor<Vue>) {
		// @ts-ignore Property '$options' does not exist on type 'VueConstructor<Vue>'.ts(2339)
		let pouchOptions = vm.$options.pouch;
		if (typeof pouchOptions === "undefined" || pouchOptions === null) return {};
		if (typeof pouchOptions === "function") pouchOptions = pouchOptions(vm);
		return Object.keys(pouchOptions).reduce((accumulator: any, currentValue) => {
			accumulator[currentValue] = null;
			return accumulator
		}, {});
	},

	// lifecycle hooks for mixin

	// now that the data object has been observed and made reactive
	// the api can be set up
	created() {
		if (!vue) {
			console.warn("pouch-vue not installed!");
			return;
		}

		let vm = this;

		vm._liveFeeds = {};

		let $pouch = new PouchAPI(vm);

		// add non reactive api
		vm.$pouch = $pouch;
		//add non reactive property
		vm.$databases = databases;
		// Add non-reactive property
		vm.$db = new PouchDatabase($pouch);

		let pouchOptions = this.$options.pouch;

		if (!pouchOptions) {
			return;
		}

		if (typeof pouchOptions === "function") {
			pouchOptions = pouchOptions();
		}

		Object.keys(pouchOptions).map(key => {
			let pouchFn = pouchOptions[key];
			if (typeof pouchFn !== "function") {
				pouchFn = () => {
					return pouchOptions[key];
				};
			}

			// if the selector changes, modify the liveFeed object
			//
			vm.$watch(
				pouchFn,
				config => {
					// if the selector is now giving a value of null or undefined, then return
					// the previous liveFeed object will remain
					if (!config) {
						vm.$emit("pouchdb-livefeed-error", {
							db: key,
							config: config,
							error: "Null or undefined selector",
						});

						return;
					}


					let selector, sort, skip, limit, first: any;

					if (config.selector) {
						selector = config.selector;
						sort = config.sort;
						skip = config.skip;
						limit = config.limit;
						first = config.first;
					} else {
						selector = config;
					}

					// the database could change in the config options
					// so the key could point to a database of a different name
					let databaseParam = config.database || key;
					let db: PouchDB.Database | null = null;

					if (typeof databaseParam === "object") {
						db = databaseParam;
					} else if (typeof databaseParam === "string") {
						if (!databases[databaseParam]) {
							vm.$pouch.makeInstance(databaseParam);
						}
						db = databases[databaseParam];
					}
					if (!db) {
						vm.$emit("pouchdb-livefeed-error", {
							db: key,
							error: "Null or undefined database",
						});
						return;
					}
					if (key in vm._liveFeeds) {
						vm._liveFeeds[key].cancel();
					}
					let aggregateCache: any[] = [];

					// the LiveFind plugin returns a liveFeed object
					vm._liveFeeds[key] = db
						.liveFind({
							selector: selector,
							sort: sort,
							skip: skip,
							limit: limit,
							aggregate: true,
						})
						.on("update", (update, aggregate) => {
							if (first && aggregate)
								aggregate = aggregate[0];

							vm.$data[key] = aggregateCache = aggregate;

							vm.$emit("pouchdb-livefeed-update", {
								db: key,
								// @ts-ignore db: Object is possibly "null"
								name: db.name,
							});

						})
						.on("ready", () => {
							vm.$data[key] = aggregateCache;

							vm.$emit("pouchdb-livefeed-ready", {
								db: key,
								// @ts-ignore db: Object is possibly "null"
								name: db.name,
							});
						})
						.on("cancelled", function () {
							vm.$emit("pouchdb-livefeed-cancel", {
								db: key,
								// @ts-ignore db: Object is possibly "null"
								name: db.name,
							});
						})
						.on("error", function (err) {
							vm.$emit("pouchdb-livefeed-error", {
								db: key,
								// @ts-ignore db: Object is possibly "null"
								name: db.name,
								error: err,
							});
						});
				},
				{
					immediate: true,
				}
			);
		});
	},
	// tear down the liveFeed objects
	beforeDestroy() {
		Object.keys(this._liveFeeds).map(lfKey => {
			this._liveFeeds[lfKey].cancel();
		});
	},
};


function prepareAllDocsOptions(default_: AllDocsOptions, options: AllDocsOptions = {}): AllDocsOptions {
	if ("key" in options || "keys" in options || "startkey" in options || "endkey" in options) {
		if ("key" in default_) {
			// @ts-ignore
			delete default_.key;
		}
		if ("keys" in default_) {
			// @ts-ignore
			delete default_.keys;
		}
		if ("startkey" in default_) {
			// @ts-ignore
			delete default_.startkey;
		}
		if ("endkey" in default_) {
			// @ts-ignore
			delete default_.endkey;
		}
	}
	return Object.assign(default_, options);
}

export class PouchDatabase {
	constructor(private $pouch: PouchAPI) {
	}

	public async getDoc<T>(id?: string | null, options: PouchDB.Core.GetOptions = {}, checker: (data: any) => T = (x) => x): Promise<T | null> {
		if (!servers.selected || !this.$pouch || !id) {
			return null;
		}
		try {
			let result = await this.$pouch.get(id, options, servers.selected.name);
			DEBUG && console.log("getDoc", { id, options, result });
			return checker(result);
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	public async allDocs<T>(options: AllDocsOptions, checker: (data: any) => T = (x) => x): Promise<T[]> {
		if (!servers.selected || !this.$pouch) {
			return [];
		}
		try {
			let result = await this.$pouch.allDocs(options, servers.selected.name);
			DEBUG && console.log("allDocs", { options, result });
			return result.rows.map(r => checker(r.doc));
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	public async view<T extends {}>(fun: string, options: PouchDB.Query.Options<{}, {}>, checker: (data: any) => T = (x) => x): Promise<T[]> {
		if (!servers.selected || !this.$pouch) {
			return [];
		}
		try {
			let result = await this.$pouch.query(fun, options, servers.selected.name);
			DEBUG && console.log("query", { fun, options, result });
			return result.rows.map(r => checker(r.doc));
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	public async save(doc: CouchDBDocument) {
		if (!servers.selected || !this.$pouch) {
			toaster.noServer();
			return false;
		}
		try {
			let result = await this.$pouch.put(doc, {}, servers.selected.name);
			DEBUG && console.log("save", { doc, result });
			if (result.ok) {
				doc._rev = result.rev;
				return true;
			}
		} catch (error) {
			toaster.error({ error })
			console.error(error);
		}
		return false;
	}

	public async remove(doc: CouchDBDocument) {
		if (!servers.selected || !this.$pouch) {
			toaster.noServer();
			return false;
		}
		try {
			let result = await this.$pouch.remove(doc, {}, servers.selected.name);
			DEBUG && console.log("remove", { doc, result });
			if (result.ok) {
				return true;
			}
		} catch (error) {
			toaster.error({ error })
			console.error(error);
		}
		return false;
	}

	public async getAppSettings(): Promise<AppSettings | null> {
		try {
			return await this.getDoc(AppSettings.KEY, {}, AppSettings.check);
		} catch (error) {
			return null;
		}
	}

	public async getGlobalSettings(): Promise<GlobalSettings | null> {
		try {
			let result = await this.$pouch.get(GlobalSettings.KEY);
			DEBUG && console.log("getGobalSettings", { _id: GlobalSettings.KEY, result });
			return GlobalSettings.check(result);
		} catch (error) {
			console.error(error);
			return GlobalSettings.create();
		}
	}

	public async saveGlobalSettings(doc: GlobalSettings | null) {
		if (!this.$pouch || !doc) {
			return false;
		}
		try {
			let result = await this.$pouch.put(doc);
			DEBUG && console.log("saveGlobalSettings", { result });
			if (result.ok) {
				doc._rev = result.rev;
				return true;
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	public async getServerSettings(server: Server | string | null): Promise<Settings | null> {
		server = getServer(server);
		if (!server || !servers.account) {
			return null;
		}

		try {
			let result = await this.$pouch.get(server.settings._id, {}, servers.account.name);
			DEBUG && console.log("getServerSettings", { _id: server.settings._id, result });
			return Settings.check(result);
		} catch (error) {
			console.error(error, server);
			return null;
		}
	}

	public async saveServerSettings(doc: Settings) {
		if (!servers.account || !this.$pouch) {
			return false;
		}
		try {
			let result = await this.$pouch.put(doc, {}, servers.account.name);
			DEBUG && console.log("saveServerSettings", { doc, result });
			if (result.ok) {
				doc._rev = result.rev;
				return true;
			}
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	public async getCounter(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Counter | null> {
		try {
			return await this.getDoc(id, options, Counter.check);
		} catch (error) {
			return null;
		}
	}

	public async allCounters(options?: AllDocsOptions): Promise<Counter[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Counter.ENDKEY : Counter.STARTKEY,
				endkey: options?.descending ? Counter.STARTKEY : Counter.ENDKEY,
				include_docs: true,
			}, options), Counter.check);
		} catch (error) {
			return [];
		}
	}

	public async getMachine(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Machine | null> {
		try {
			return await this.getDoc(id, options, Machine.check);
		} catch (error) {
			return null;
		}
	}

	public async allMachines(options?: AllDocsOptions): Promise<Machine[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Machine.ENDKEY : Machine.STARTKEY,
				endkey: options?.descending ? Machine.STARTKEY : Machine.ENDKEY,
				include_docs: true,
			}, options), Machine.check);
		} catch (error) {
			return [];
		}
	}

	public async getSoftware(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Software | null> {
		try {
			return await this.getDoc(id, options, Software.check);
		} catch (error) {
			return null;
		}
	}

	public async allSoftwares(options?: AllDocsOptions): Promise<Software[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Software.ENDKEY : Software.STARTKEY,
				endkey: options?.descending ? Software.STARTKEY : Software.ENDKEY,
				include_docs: true,
			}, options), Software.check);
		} catch (error) {
			return [];
		}
	}

	public async getPart(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Part | null> {
		try {
			return await this.getDoc(id, options, Part.check);
		} catch (error) {
			return null;
		}
	}

	public async allParts(options?: AllDocsOptions): Promise<Part[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Part.ENDKEY : Part.STARTKEY,
				endkey: options?.descending ? Part.STARTKEY : Part.ENDKEY,
				include_docs: true,
			}, options), Part.check);
		} catch (error) {
			return [];
		}
	}

	public async getGame(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Game | null> {
		try {
			return await this.getDoc(id, options, Game.check);
		} catch (error) {
			return null;
		}
	}

	public async allGames(options?: AllDocsOptions): Promise<Game[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Game.ENDKEY : Game.STARTKEY,
				endkey: options?.descending ? Game.STARTKEY : Game.ENDKEY,
				include_docs: true,
			}, options), Game.check);
		} catch (error) {
			return [];
		}
	}

	public async getPlatform(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Platform | null> {
		try {
			return await this.getDoc(id, options, Platform.check);
		} catch (error) {
			return null;
		}
	}

	public async allPlatforms(options?: AllDocsOptions): Promise<Platform[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Platform.ENDKEY : Platform.STARTKEY,
				endkey: options?.descending ? Platform.STARTKEY : Platform.ENDKEY,
				include_docs: true,
			}, options), Platform.check);
		} catch (error) {
			return [];
		}
	}

	public async getCompany(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Company | null> {
		try {
			return await this.getDoc(id, options, Company.check);
		} catch (error) {
			return null;
		}
	}

	public async allCompanies(options?: AllDocsOptions): Promise<Company[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Company.ENDKEY : Company.STARTKEY,
				endkey: options?.descending ? Company.STARTKEY : Company.ENDKEY,
				include_docs: true,
			}, options), Company.check);
		} catch (error) {
			return [];
		}
	}

	public async getMission(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Mission | null> {
		try {
			return await this.getDoc(id, options, Mission.check);
		} catch (error) {
			return null;
		}
	}

	public async allMissions(options?: AllDocsOptions): Promise<Mission[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Mission.ENDKEY : Mission.STARTKEY,
				endkey: options?.descending ? Mission.STARTKEY : Mission.ENDKEY,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async getReading(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Reading | null> {
		try {
			return await this.getDoc(id, options, Reading.check);
		} catch (error) {
			return null;
		}
	}

	public async allReadings(machineId: string, date?: DateSearch, options?: AllDocsOptions): Promise<Reading[]> {
		let opts: PouchDB.Query.Options<{}, {}> = { include_docs: true };
		if (date?.date) {
			opts.key = Reading.getId(machineId, date.date);
		} else {
			opts.startkey = options?.descending ? Reading.getEndKey(machineId, date) : Reading.getStartKey(machineId, date);
			opts.endkey = options?.descending ? Reading.getStartKey(machineId, date) : Reading.getEndKey(machineId, date);
		}

		try {
			return await this.allDocs(prepareAllDocsOptions(opts, options), Reading.check);
		} catch (error) {
			return [];
		}
	}

	public async getTask(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<Task | null> {
		try {
			return await this.getDoc(id, options, Task.check);
		} catch (error) {
			return null;
		}
	}

	public async allTasks(options?: AllDocsOptions): Promise<Task[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? Task.ENDKEY : Task.STARTKEY,
				endkey: options?.descending ? Task.STARTKEY : Task.ENDKEY,
				include_docs: true,
			}, options), Task.check);
		} catch (error) {
			return [];
		}
	}

	public async getPartCategory(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<PartCategory | null> {
		try {
			return await this.getDoc(id, options, PartCategory.check);
		} catch (error) {
			return null;
		}
	}

	public async allPartCategories(options?: AllDocsOptions): Promise<PartCategory[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? PartCategory.ENDKEY : PartCategory.STARTKEY,
				endkey: options?.descending ? PartCategory.STARTKEY : PartCategory.ENDKEY,
				include_docs: true,
			}, options), PartCategory.check);
		} catch (error) {
			return [];
		}
	}

	public async getGameCategory(id?: string | null, options?: PouchDB.Core.GetOptions): Promise<GameCategory | null> {
		try {
			return await this.getDoc(id, options, GameCategory.check);
		} catch (error) {
			return null;
		}
	}

	public async allGameCategories(options?: AllDocsOptions): Promise<GameCategory[]> {
		try {
			return await this.allDocs(prepareAllDocsOptions({
				startkey: options?.descending ? GameCategory.ENDKEY : GameCategory.STARTKEY,
				endkey: options?.descending ? GameCategory.STARTKEY : GameCategory.ENDKEY,
				include_docs: true,
			}, options), GameCategory.check);
		} catch (error) {
			return [];
		}
	}

	public async queryCompaniesByOwner(companyId: string | string[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("company/by_owner", prepareAllDocsOptions({
				key: typeof companyId === "string" && companyId ? companyId : undefined,
				keys: Array.isArray(companyId) && companyId.length ? companyId : undefined,
				include_docs: true,
			}, options), Company.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMachinesByOwner(companyId: string | string[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("machine/by_owner", prepareAllDocsOptions({
				key: typeof companyId === "string" && companyId ? companyId : undefined,
				keys: Array.isArray(companyId) && companyId.length ? companyId : undefined,
				include_docs: true,
			}, options), Machine.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMachinesBySerial(serial: string | string[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("machine/by_serial", prepareAllDocsOptions({
				key: typeof serial === "string" && serial ? serial : undefined,
				keys: Array.isArray(serial) && serial.length ? serial : undefined,
				include_docs: true,
			}, options), Machine.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMachinesByStatus(status: MachineStatus | MachineStatus[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("machine/by_status", prepareAllDocsOptions({
				key: status !== null && !Array.isArray(status) && Object.values(MachineStatus).includes(status) ? status : undefined,
				keys: Array.isArray(status) && status.length ? status : undefined,
				include_docs: true,
			}, options), Machine.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByCompany(companyId: string | string[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("mission/by_company", prepareAllDocsOptions({
				key: typeof companyId === "string" && companyId ? companyId : undefined,
				keys: Array.isArray(companyId) && companyId.length ? companyId : undefined,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByTechCompany(techId: string, companyId: string | string[], options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("mission/by_tech_company", prepareAllDocsOptions({
				key: typeof companyId === "string" ? [techId, companyId] : undefined,
				keys: Array.isArray(companyId) && companyId.length ? companyId.map(m => [techId, m]) : undefined,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByTech(techId: string | string[] | null = null, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("mission/by_tech", prepareAllDocsOptions({
				key: typeof techId === "string" && techId ? techId : undefined,
				keys: Array.isArray(techId) && techId.length ? techId : undefined,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByMachine(machineId: string | string[], options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("mission/by_machine", prepareAllDocsOptions({
				key: typeof machineId === "string" && machineId ? machineId : undefined,
				keys: Array.isArray(machineId) && machineId.length ? machineId : undefined,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByTechMachine(techId: string, machineId: string | string[], options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			return await this.view("mission/by_tech_machine", prepareAllDocsOptions({
				key: typeof machineId === "string" && machineId ? [techId, machineId] : undefined,
				keys: Array.isArray(machineId) && machineId.length ? machineId.map(m => [techId, m]) : undefined,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByDate(start: ConfigType, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			let key = [dayjs(start).toISODateString(), null];
			return await this.view("mission/by_date", prepareAllDocsOptions({
				key,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByDates(start: ConfigType, end: ConfigType, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			if (!end) {
				end = start;
			}
			let startkey = [dayjs(start).toISODateString(), null];
			let endkey = [dayjs(end).toISODateString(), {}];
			return await this.view("mission/by_date", prepareAllDocsOptions({
				startkey: options.descending ? endkey : startkey,
				endkey: options.descending ? startkey : endkey,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByTechDate(techId: string, start: ConfigType, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			let key = [techId, dayjs(start).toISODateString(), null];
			return await this.view("mission/by_tech_date", prepareAllDocsOptions({
				key,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryMissionsByTechDates(techId: string, start: ConfigType, end: ConfigType, options: PouchDB.Query.Options<{}, {}> = {}) {
		try {
			if (!end) {
				end = start;
			}
			let startkey = [techId, dayjs(start).toISODateString(), null];
			let endkey = [techId, dayjs(end).toISODateString(), {}];
			return await this.view("mission/by_tech_date", prepareAllDocsOptions({
				startkey: options.descending ? endkey : startkey,
				endkey: options.descending ? startkey : endkey,
				include_docs: true,
			}, options), Mission.check);
		} catch (error) {
			return [];
		}
	}

	public async queryTasksByMission(missionId?: string | null, options: PouchDB.Query.Options<{}, {}> = {}) {
		if (!missionId) {
			return [];
		}
		try {
			return await this.view("task/by_mission", prepareAllDocsOptions({
				key: missionId,
				include_docs: true,
			}, options), Task.check);
		} catch (error) {
			return [];
		}
	}

	public async queryTasksByMachine(machineId?: string | null, options: PouchDB.Query.Options<{}, {}> = {}) {
		if (!machineId) {
			return [];
		}
		try {
			return await this.view("task/by_machine", prepareAllDocsOptions({
				key: machineId,
				include_docs: true,
			}, options), Task.check);
		} catch (error) {
			return [];
		}
	}

	public async queryReadingsByMission(missionId?: string | null, options: PouchDB.Query.Options<{}, {}> = {}) {
		if (!missionId) {
			return [];
		}
		try {
			return await this.view("reading/by_mission", prepareAllDocsOptions({
				key: missionId,
				include_docs: true,
			}, options), Reading.check);
		} catch (error) {
			return [];
		}
	}

	public async queryReadingsByTask(taskId?: string | null, options: PouchDB.Query.Options<{}, {}> = {}) {
		if (!taskId) {
			return [];
		}
		try {
			return await this.view("reading/by_task", prepareAllDocsOptions({
				key: taskId,
				include_docs: true,
			}, options), Reading.check);
		} catch (error) {
			return [];
		}
	}

	public async getUser(id?: string | null): Promise<User | null> {
		if (!servers.selected || !id) {
			return null;
		}

		try {
			let response = await fetch(servers.selected.auth, {
				method: "POST",
				body: JSON.stringify({ action: "get", id }),
				headers: {
					Authorization: `Bearer ${servers.selected.settings.token}`
				}
			});
			let result: AuthResponse = await response.json();
			DEBUG && console.log("getUser", { id, result });
			if (result.error) {
				throw result.error;
			}
			return User.check(result.user);
		} catch (error) {
			console.error(error);
			return null;
		}
	}

	public async allUsers(ids?: string[], roles?: string[]): Promise<User[]> {
		if (!servers.selected) {
			return [];
		}

		try {
			let response = await fetch(servers.selected.auth, {
				method: "POST",
				body: JSON.stringify({ action: "list", ids, roles }),
				headers: {
					Authorization: `Bearer ${servers.selected.settings.token}`
				}
			});
			let result: AuthResponse = await response.json();
			DEBUG && console.log("allUsers", { ids, roles, result });
			if (result.error) {
				throw result.error;
			}
			return (result.users || []).map(u => User.check(u));
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	public async createMachine(
		serial: string,
		name: string = "",
		definition: MachineType = MachineType.mas,
		status: MachineStatus = MachineStatus.stocked,
		config: MachineConfig = 0,
		unit: ValueUnit = ValueUnit.credit,
		ref: string | null = null,
		location: string | null = null,
		ownerId: string = "",
		platformId: string = "",
		osId: string = "",
		limitCredit: number | null = null,
		limitInsert: number | null = null,
		limitJackpot: number | null = null,
		limitPayment: number | null = null,
	): Promise<Machine> {
		let result = Machine.create(
			serial,
			name,
			definition,
			status,
			config,
			unit,
			ref,
			location,
			ownerId,
			platformId,
			osId,
			limitCredit,
			limitInsert,
			limitJackpot,
			limitPayment
		);
		(await this.allPartCategories()).filter(c => !c.parentId).forEach(c => {
			result.parts.push(MachinePart.create("", "", c._id));
		});
		return result;
	}

	public async cloneMachine(serial: string, base: Machine): Promise<Machine> {
		let result = Machine.clone(serial, base);
		(await this.allPartCategories()).filter(c => !c.parentId).forEach(c => {
			if (!result.parts.find(p => p.categoryId === c._id)) {
				result.parts.push(MachinePart.create("", "", c._id));
			}
		});
		return result;
	}
}

export const PouchVue = {
	install: (Vue: any, options: PouchVueOptions = {}) => {
		vue = Vue;

		({ pouch = PouchDB, defaultDB = '', optionsDB = {} } = options);

		// In PouchDB v7.0.0 the debug() API was moved to a separate plugin.
		// var pouchdbDebug = require('pouchdb-debug');
		// PouchDB.plugin(pouchdbDebug);
		if (options.debug === '*') pouch.debug.enable('*');

		Vue.mixin(vuePouch);
	},
};
