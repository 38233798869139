


















































































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
	PartReplacement,
	PartCategory,
	Part,
	MachinePart,
	ReplacementAction,
	ReplacementRow
} from "@/loader";

@Component
export default class PartReplacementRow extends Mixins<ReplacementRow<PartReplacement>>(ReplacementRow) {
	public category: PartCategory | null = null;
	public oldPart: Part | null = null;
	public newPart: Part | null = null;
	public invalidFeedbacks = {
		action: "L'action est requise",
		oldId: "Un élément doit être sélectionné",
		partId: "Un élément doit être sélectionné",
		serial: "",
	}

	public get states() {
		return {
			action: this.item && this.focused.action ? true : null,
			oldId: this.item && this.focused.oldId && ReplacementAction.NEED_OLD.includes(this.item.action) ? !!this.item.oldId : null,
			partId: this.item && this.focused.partId && ReplacementAction.NEED_NEW.includes(this.item.action) ? !!this.item.partId : null,
			categoryId: this.item && this.focused.categoryId ? !!this.item.categoryId : null,
			serial: this.item && this.focused.serial ? true : null,
		}
	}

	public get array(): MachinePart[] {
		return this.machine?.parts || [];
	}

	public get oldMachinePart(): MachinePart | null {
		return this.array.find(x => x.id === this.item?.oldId) || null;
	}

	public get categoryId(): string | null {
		return this.oldMachinePart?.categoryId || this.item?.categoryId || null;
	}

	public get isPartDifferent(): boolean {
		return this.oldMachinePart && this.item && this.oldMachinePart.partId !== this.item.partId || false;
	}

	public get isSerialDifferent(): boolean {
		return this.oldMachinePart && this.item && (this.oldMachinePart.serial || "") != (this.item.serial || "") || false;
	}

	public get isCategoryDifferent(): boolean {
		return this.oldMachinePart && this.item && this.oldMachinePart.categoryId !== this.item.categoryId || false;
	}

	public mounted() {
		this.loadOldRelations();
	}

	public async loadOldRelations(value?: MachinePart | null) {
		value = value || this.oldMachinePart;
		this.oldPart = await this.$db.getPart(value?.partId);
	}

	public async loadCategory(value?: string | null) {
		value = value || this.categoryId;
		this.category = await this.$db.getPartCategory(value);
	}

	@Watch("oldMachinePart")
	public onOldMachinePartChanged(newValue: MachinePart | null, oldValue?: MachinePart | null) {
		if (this.item && newValue !== oldValue) {
			this.loadOldRelations(newValue);
		}
	}

	@Watch("categoryId")
	public onCategoryIdChanged(newValue: string | null, oldValue?: string | null) {
		if (this.item && newValue !== oldValue) {
			this.loadCategory(newValue);
		}
	}
}
