




import { Vue, Component } from "vue-property-decorator";
import AccountView from "./AccountView.vue";

@Component({
	components: {
		AccountView,
	}
})
export default class ProfilView extends Vue {
}
