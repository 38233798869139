import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { Mission, MissionStatus, store, Task } from "@/loader";

@Module({
	dynamic: true,
	store,
	namespaced: true,
	name: "missionModal"
})
export class MissionModalModule extends VuexModule {
	public visible: boolean = false;
	public mission: Mission | null = null;
	public task: Task | null = null;
	public tasks: Task[] = [];
	public companyId: string | null = null;
	public machineId: string | null = null;

	@Mutation
	public show(payload?: { companyId?: string | null, machineId?: string | null, task?: Task, tasks?: Task[] }) {
		this.companyId = payload?.companyId || null;
		this.machineId = payload?.machineId || null;
		this.task = payload?.task || null;
		this.tasks = payload?.tasks || [];
		this.visible = true;
	}

	@Mutation
	public hide() {
		this.companyId = null;
		this.machineId = null;
		this.mission = null;
		this.tasks = [];
		this.task = null;
		this.visible = false;
	}

	@Mutation
	public createNewMission() {
		this.mission = Mission.create(this.companyId);
		this.tasks.forEach(t => { t.missionId = this.mission?._id || ""; });
		if (this.task) {
			this.task.missionId = this.mission._id;
		}
	}

	@Mutation
	public setMission(mission: Mission | null) {
		this.mission = mission;
		this.tasks.forEach(t => { t.missionId = this.mission?._id || ""; });
		if (this.task) {
			this.task.missionId = this.mission?._id || "";
		}
		if (this.tasks.length) {
			this.tasks.forEach(t => {
				let match = this.mission?.tasks.find(t2 =>
					t2.action === t.action
					&& t2.machineId === t.machineId &&
					MissionStatus.FINISHABLE.includes(t2.status)
				)
				if (!match && this.mission) {
					this.mission.tasks.push(t);
				}
			});
		}
	}
}

export const missionModal = getModule(MissionModalModule, store);
