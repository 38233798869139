




























































































import { Vue, Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
	EditComponent,
	MachinePart,
	Machine,
	ConfigStatus,
	PartCategory,
	Part,
	Software,
	servers,
	PartCategorySelect,
} from "@/loader";

const DELETABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const ACTIVABLE_STATUS = [ConfigStatus.draft, ConfigStatus.planned];
const PLANNING_STATUS = [ConfigStatus.draft, ConfigStatus.disabled];

@Component
export default class MachinePartRow extends Mixins<EditComponent<MachinePart, Machine>>(EditComponent) {
	public category: PartCategory | null = null;
	public part: Part | null = null;
	public software: Software | null = null;
	public useEditMode: boolean = true;
	public ConfigStatus = ConfigStatus;

	public get canDelete(): boolean {
		return this.canEdit && !this.editMode && DELETABLE_STATUS.includes(this.item?.status);
	}

	public get canDisable(): boolean {
		return this.canEdit && !this.editMode && this.item?.status === ConfigStatus.active;
	}

	public get canActivate(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get canPlan(): boolean {
		return this.canEdit && !this.editMode && ACTIVABLE_STATUS.includes(this.item?.status);
	}

	public get partRoute() {
		return this.part ? {
			name: "part",
			params: {
				id: this.part._id,
				serverName: servers.selected?.name,
			},
			query: {
				redirect: this.$route.fullPath,
				redirectTitle: `Machine ${this.parent?.serial || ""}`
			}
		} : undefined;
	}

	public get categoryId() {
		return this.item?.categoryId;
	}

	public async loadRelations() {
		this.part = await this.$db.getPart(this.item?.partId);
		this.category = await this.$db.getPartCategory(this.item?.categoryId);
		this.software = await this.$db.getSoftware(this.item?.softwareId);
	}

	public mounted() {
		this.loadRelations();
	}

	@Watch("editMode")
	public editModeChanged(newValue: boolean | null, oldValue?: boolean | null) {
		if (!newValue && newValue !== oldValue) {
			this.loadRelations();
		}
	}

	@Watch("item")
	public itemChanged(newValue: MachinePart | null, oldValue?: MachinePart | null) {
		if (newValue !== oldValue) {
			this.loadRelations();
		}
	}

	@Watch("categoryId")
	public async categoryIdChanged(newValue: string | null, oldValue?: string | null) {
		if (this.item && newValue !== oldValue) {
			this.category = await this.$db.getPartCategory(newValue);
			this.item.favorite = this.category?.favorite || false;
			this.focusOn("favorite");
		}
	}
}
