import { BvListItem } from "@/loader";


export enum Role {
	_admin = "_admin",
	admin = "admin",
	director = "director",
	manager = "manager",
	accounting = "accounting",
	tech = "tech"
}

export namespace Role {
	export const ADMIN: Role[] = [
		Role._admin,
		Role.admin,
	];
	export const RH: Role[] = [
		Role._admin,
		Role.admin,
		Role.director,
		Role.accounting,
	];

	export function toString(role: Role): string {
		switch (role) {
			case Role.accounting: return "Comptabilité";
			case Role.director: return "Directeur";
			case Role.manager: return "Manager";
			case Role.admin:
			case Role._admin:
				return "Administrateur";
			case Role.tech:
			default:
				return "Technicien";
		}
	}

	export const LIST: BvListItem[] = Object.values(Role)
		.filter(v => typeof v === "string")
		.map(value => ({ value, text: toString(<Role>value) }));
}
