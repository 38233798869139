import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Game, servers, DocumentSelect, busy, Platform, Compatibility } from '@/loader';

@Component
export class GameSelect extends Mixins<DocumentSelect<Game>>(DocumentSelect) {
	@Prop({ type: String, default: null })
	public packId!: string | null;

	@Prop({ type: String, default: null })
	public platformId!: string | null;

	@Prop({ type: Boolean, default: false })
	public hidePacks!: boolean;

	@Prop({ type: Boolean, default: false })
	public hideGames!: boolean;

	@Prop({ type: [String, Array], default: null })
	public categories!: string | string[] | null;

	public pack: Game | null = null;
	public platform: Platform | null = null;

	public async loadOptions() {
		this.options = await this.$db.allGames();
	}

	public async loadRelations() {
		this.pack = await this.$db.getGame(this.packId || "");
		this.platform = await this.$db.getPlatform(this.platformId || "");
	}

	public filterOptions(options: Game[]): Game[] {
		if (this.pack) {
			options = options.filter(g => this.pack?.games.includes(g._id) || (this.showEmpty && g === this.emptyItem));
		} else if (this.platform) {
			let platformGames = this.platform.compatibilities.games
				.filter(pg => !!pg && pg.compatible !== Compatibility.incompatible)
				.unique()
				.sort();
			options = options.filter(g =>
				platformGames.some(pg =>
					pg.gameId === g._id
					|| pg.packId === g._id
				) || (this.showEmpty && g === this.emptyItem)
			);
		}
		if (this.hidePacks) {
			options = options.filter(g => !g.isPack || (this.showEmpty && g === this.emptyItem));
		}
		if (this.hideGames) {
			options = options.filter(g => g.isPack || (this.showEmpty && g === this.emptyItem));
		}
		if (this.categories) {
			let cats = Array.isArray(this.categories) ? this.categories : [this.categories];
			if (cats.length) {
				options = options.filter(g => (g.categoryId && cats.includes(g.categoryId)) || (this.showEmpty && g === this.emptyItem));
			}
		}
		options = this._union(options, this.include, true);
		options = this._union(options, this.exclude, false);
		return options;
	}

	public mounted() {
		this.loadOptions();
		this.loadRelations();
	}

	public get editRoute(): string {
		return "game";
	}

	public get canEditOption(): boolean {
		return servers.isAdmin || servers.isDirector || servers.isManager;
	}

	public createOption(option: any): any {
		busy.start(`Création du jeu "${option.name}" ...`);
		let item = Game.create(option.name);
		this.pushItem(item, option);
	}

	@Watch("platformId")
	public onPlatformIdChanged(newValue?: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadRelations();
			// this.options = this.localOptions.slice();
		}
	}

	@Watch("packId")
	public onPackIdChanged(newValue?: string, oldValue?: string) {
		if (newValue !== oldValue) {
			this.loadRelations();
			// this.options = this.localOptions.slice();
		}
	}
}
