import { Component, Mixins } from "vue-property-decorator";
import { Editor } from "@/loader";

@Component
export class EditObject<T extends Object> extends Mixins(Editor) {
	public item: T | null = null;
	public original: T | null = null;
	public focused: { [prop in keyof T]?: boolean; } = {};
	public invalidFeedbacks: { [prop in keyof T]?: string; } = {};

	public get states(): { [prop in keyof T]?: boolean | null; } {
		return {};
	}
}
