



























import { Component, Mixins } from "vue-property-decorator";
import { TaskDataEditorMixin, ReserveTask } from "@/loader";

@Component
export default class ReserveTaskEditor extends Mixins<TaskDataEditorMixin<ReserveTask>>(TaskDataEditorMixin) {
	public get states() {
		return {
			reading: this.focused.reading && this.item ? !!this.item.reading : null,
			companyId: this.focused.companyId && this.item ? !!this.item.companyId : null,
		}
	}

	public invalidFeedbacks = {
		reading: "Une relève est nécessaire avant une mise en réserve",
		companyId: "Une destination doit être renseignée",
	}
}
