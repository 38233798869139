




































































































import { Vue, Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DEBUG, EditComponent, MachinePart, Machine, ConfigStatus, servers, Software } from "@/loader";

@Component
export default class MachinePartEditor extends Mixins<EditComponent<MachinePart, Machine>>(EditComponent) {
	@Prop({ type: Boolean, default: false })
	public canCreate!: boolean;

	public ConfigStatus = ConfigStatus;
	public software: Software | null = null;

	public setStatus(status: ConfigStatus) {
		if (this.item) {
			this.item.status = status;
			this.changed = true;
		}
	}

	public setItem(value: MachinePart | null) {
		this.item = value;
		this.setSoftware();
	}

	public async setSoftware() {
		this.software = await this.$db.getSoftware(this.item?.softwareId);
	}

	@Watch("item", { deep: true })
	public loadSoftware(newValue: MachinePart | null, oldValue?: MachinePart | null) {
		if (this.software?._id !== newValue?.softwareId) {
			this.setSoftware();
		}
	}
}
