

































































import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import { GameRate, EditComponent, Game } from "@/loader";

@Component
export default class GameRateRow extends Mixins<EditComponent<GameRate, Game>>(EditComponent) {
	@Prop({ type: Number, default: 0 })
	public index!: number;

	public invalidFeedbacks = {
		name: "Le nom ne peut pas être vide",
		rate: "Le taux ne peut pas être vide",
	}

	public get states() {
		return {
			name: this.item && this.focused.name ? !!this.item.name : null,
			rate: this.item && this.focused.rate ? !!this.item.rate : null,
		}
	}
}
