import { Deletable, Sortable } from "@/loader";

// IE & Edge don't support Array.flat yet
if (!Array.prototype.flat) {
	Array.prototype.flat = function (depth?: number) {
		if ((depth || 7) <= 0) {
			return this;
		}
		// @ts-ignore
		return this.reduce((result, item) => result.concat(Array.isArray(item) ? item.flat((depth || 7) - 1) : item), []);
	};
}

Array.prototype.unique = function<T>(): T[] {
	let found: any = {};
	return this.filter(v => found.hasOwnProperty(v) ? false : (found[v] = true));
};

Array.prototype.moveUp = function<T extends Sortable> (item: T): T[] {
	let prev: Sortable | undefined = this.find(i => i.order === ((item.order || this.length) - 1));
	if (prev) {
		prev.order = (prev.order || 0) + 1;
		item.order = (item.order || 1) - 1;
		this.sortByOrder();
	}
	return this;
};

Array.prototype.moveDown = function<T extends Sortable> (item: T): T[] {
	let next: Sortable | undefined = this.find(i => i.order === ((item.order || 0) + 1));
	if (next) {
		next.order = (next.order || 1) - 1;
		item.order = (item.order || 0) + 1;
		this.sortByOrder();
	}
	return this;
};

Array.prototype.sortByOrder = function <T extends Sortable>(): T[] {
	return this.sort((a, b) => {
		let x = a && a.order || 0;
		let y = b && b.order || 0;
		return x > y ? 1 : x < y ? -1 : 0;
	});
};

Array.prototype.remove = function <T>(item: T, force?: boolean): T[] {
	let index = this.indexOf(item);
	if (index > -1) {
		// @ts-ignore
		if (Deletable.instanceOf(item) && Number(item.id) > 0 && !force) {
			// @ts-ignore
			item.delete = true;
			if (Sortable.instanceOf(item)) {
				// @ts-ignore
				item.order = item.delete ? 99 : this.length - 1 - this.filter(i => i.delete).length;
			}
		} else {
			this.splice(index, 1);
		}

		// if (Sortable.isSortableArray(this)) {
		// 	this.reorder();
		// }
	}
	return this;
};

Array.prototype.removeMany = function <T>(items: T[], force?: boolean): T[] {
	items.forEach(i => this.remove(i, force));
	return this;
};

Array.prototype.reorder = function <T extends Sortable>(first: number = 0): T[] {
	this.forEach((i, index) => {
		if (Deletable.instanceOf(i) && i._deleted) {
			return;
		}
		i.order = first + index;
	});
	return this;
};

/**
 * Get the next available order
 */
Array.prototype.nextOrder = function<T extends Sortable>() {
	return Math.max(...this.filter(i => !Deletable.instanceOf(i) || !i._deleted).map((i: T) => i.order === null || i.order === undefined ? -1 : i.order), -1) + 1;
};

Array.range = function(size: number, startAt: number = 0, step: number = 1): number[] {
    return Array.from(Array(size).keys()).map(i => (i * step) + startAt);
}
