import { CouchDBDocument, DEBUG, SyncEvent } from "@/loader";
import { Vue, Component } from "vue-property-decorator";

interface IdChecker {
	_id: string
	_rev: string
	db: string
	callback: (e: SyncEvent, doc: CouchDBDocument) => void
}

function isIdChecker(obj: any): obj is IdChecker {
	return !!obj._id && obj._rev !== undefined && obj.db !== undefined && !!obj.callback;
}

interface TypeChecker {
	type: string
	db: string
	callback: (e: SyncEvent, doc: CouchDBDocument) => void
}

function isTypeChecker(obj: any): obj is TypeChecker {
	return !!obj.type && obj.db !== undefined && !!obj.callback;
}

@Component
export class ConflictCheckerMixin extends Vue {
	public conflictCheckers: (IdChecker | TypeChecker)[] = [];

	public created() {
		this.$root.$on("pouchdb-sync-change", this.onSyncChange);
	}

	public beforeDestroy() {
		this.$root.$off("pouchdb-sync-change", this.onSyncChange);
	}

	public clearConflictChecker() {
		this.conflictCheckers = [];
	}

	public addConflictChecker(checker: Partial<IdChecker | TypeChecker>) {
		if (!isIdChecker(checker) && ! isTypeChecker(checker)) {
			return;
		}
		this.conflictCheckers.push(checker);
	}

	public removeConflictChecker(key: string | null | undefined, db: string | null | undefined) {
		if (!key) {
			return;
		}
		let index = this.conflictCheckers.findIndex(x =>
			(isIdChecker(x) && x._id === key && x.db === db)
			|| (isTypeChecker(x) && x.type === key && x.db === db)
		);
		if (index) {
			this.conflictCheckers.splice(index, 1);
		}
	}

	public onSyncChange(e: SyncEvent) {
		this.conflictCheckers.forEach(c => {
			let doc = e.info?.change.docs.find(d =>
				e.db === c.db
				&& (
					(isIdChecker(c) && d._id === c._id && d._rev !== c._rev)
					|| (isTypeChecker(c) && d.type === c.type)
				)
			);
			if (doc) {
				DEBUG && console.log("conflict checker", { event: e, checker: c, doc });
				c.callback(e, doc);
			}
		});
	}

}
