

























































































































import { Component, Watch, Mixins } from "vue-property-decorator";
import {
	User,
	servers,
	Role,
	DEBUG,
	AuthResponse,
	Event,
	EventType,
	toaster,
	Address,
	EditView,
Coordinate
} from "@/loader";
import AddressesCard from "../../component/address/AddressesCard.vue";
import CoordinatesCard from "../../component/coordinate/CoordinatesCard.vue";

@Component({
	components: {
		AddressesCard,
		CoordinatesCard,
	}
})
export default class UserView extends Mixins<EditView<User>>(EditView) {
	public roles = Role.LIST;

	public invalidFeedbacks = {
		firstName: "Prénom requis",
		lastName: "Nom requis",
		email: "Email invalide",
		roles: "Au moins un rôle est requis",
	};

	public get states() {
		return {
			firstName: this.focused.firstName && this.item ? !!this.item.firstName : null,
			lastName: this.focused.lastName && this.item ? !!this.item.lastName : null,
			email: this.focused.email && this.item ? !!this.item.email : null,
			roles: this.focused.roles && this.item ? !!this.item.roles.length : null,
		};
	};

	public get canEdit() {
		return servers.online && (this.isRH || this.isProfil) || false;
	}

	public get isProfil() {
		return servers.selected && servers.selected.settings.user && servers.selected.settings.user._id === this.id;
	}

	public get isRH() {
		return servers.isRH;
	}

	public get fullName() {
		return this.item ? `${this.item.firstName} ${this.item.lastName}` : 'Utilisateur';
	}

	public removeAddress(address: Address) {
		if (!this.item || !this.canEdit || !address) {
			toaster.invalidOperation();
			return;
		}
		this.item.addresses = this.item.addresses.remove(address);
		this.changed = true;
	}

	public removeCoordinate(coordinate: Coordinate) {
		if (!this.item || !this.canEdit || !coordinate) {
			toaster.invalidOperation();
			return;
		}
		this.item.coordinates = this.item.coordinates.remove(coordinate);
		this.changed = true;
	}

	public toTitleCase(value?: string | null) {
		let values = (value || "").split(/[^a-zA-Z0-9À-ž]+/g);
		return values.map(w => w.capitalize()).join("-");
	}

	public toUpperCase(value?: string | null) {
		return (value || "").toUpperCase();
	}

	public async cancel(): Promise<boolean> {
		const operation = "Sauvegarder";
		if (!this.canEdit) {
			toaster.forbidden({ operation });
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation, itemType: "utilisateur" });
			return false;
		}
		if (!this.changed) {
			toaster.noChange();
			return false;
		}
		const server = servers.selected;
		if (!server) {
			return false;
		}

		try {
			let response: Response = await fetch(server.auth, {
				method: "POST",
				body: JSON.stringify({
					action: "get",
					id: this.item._id
				}),
				headers: {
					Authorization: `Bearer ${server.settings.token}`
				}
			});
			let result = await response.json();
			if (result.code !== 200) {
				throw result;
			}
			this.item = User.check(result.user);
			this.changed = false;

			if (this.isProfil) {
				servers.modifyServer({ user: this.item, token: result.token, server });
				await this.$db.saveServerSettings(server.settings);
			}
			return true;
		} catch (error) {
			console.error(error);
			toaster.error({ error });
			return false;
		}
	}

	public async save(eventType?: EventType, eventComment: string = "") {
		const operation = "Sauvegarder";
		if (!this.canEdit) {
			toaster.forbidden({ operation });
			return false;
		}
		if (!this.item) {
			toaster.noItem({ operation, itemType: "utilisateur" });
			return false;
		}
		if (!this.changed) {
			toaster.noChange();
			return false;
		}
		const server = servers.selected;
		if (!server) {
			return false;
		}

		this.item.events.push(Event.create(
			servers.author,
			eventType || (this.isNew ? EventType.created : EventType.modified),
			eventComment
		));

		try {
			let response = await fetch(server.auth, {
				method: "POST",
				body: JSON.stringify({
					action: "update",
					user: this.item
				}),
				headers: {
					Authorization: `Bearer ${server.settings.token}`
				}
			});
			let result = await response.json();
			if (result.code !== 200) {
				throw result;
			}
			this.item = User.check(result.user);
			this.changed = false;

			if (this.isProfil) {
				servers.modifyServer({ user: this.item, token: result.token, server });
				await this.$db.saveServerSettings(server.settings);
			}
			return true;
		} catch (error) {
			console.error(error);
			toaster.error({ error });
		}
		return false;
	}

	@Watch('id', { immediate: true })
	public async idChanged(newValue: string, oldValue?: string) {
		if (newValue !== oldValue) {
			if (newValue === 'new') {
				this.item = User.create("", "");
			} else if (servers.selected) {
				try {
					let response = await fetch(servers.selected.auth, {
						method: "POST",
						body: JSON.stringify({
							action: "get",
							id: newValue
						}),
						headers: {
							Authorization: `Bearer ${servers.selected.settings.token}`
						}
					});
					let result: AuthResponse = await response.json();
					this.item = User.check(result.user || {});
				} catch (error) {
					DEBUG && console.error(error);
				}
			}
		}
	}
}
