import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { User, DocumentSelect } from '@/loader';
import { CreateElement, VNode } from "vue";

@Component
export class UserSelect extends Mixins<DocumentSelect<User>>(DocumentSelect) {
	@Prop({ type: Boolean, default: false })
	public tech!: boolean;

	@Prop({ type: Boolean, default: false })
	public manager!: boolean;

	@Prop({ type: Boolean, default: false })
	public accounting!: boolean;

	@Prop({ type: Boolean, default: false })
	public director!: boolean;

	//@ts-ignore
	public readonly emptyItem: User = {
		_id: "",
		_rev: "",
		type: "user",
		lastName: "Aucun",
		firstName: "",
		disabled: false,
	};

	public async loadOptions() {
		let roles = [];
		if (this.tech) {
			roles.push("tech");
		}
		if (this.manager) {
			roles.push("manager");
		}
		if (this.accounting) {
			roles.push("accounting");
		}
		if (this.director) {
			roles.push("director");
		}

		this.options = (await this.$db.allUsers(undefined, roles))
			.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
	}

	public mounted() {
		this.loadOptions();
	}

	public getOptionLabel(option: User): string {
		return option ? (option.firstName + " " + option.lastName).trim() : "Aucun";
	}

	public optionSlot(h: CreateElement, option: User): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: User): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	@Watch("emptyText", { immediate: true })
	public onEmptyTextChanged(newValue: string, oldValue: string) {
		if (newValue !== oldValue) {
			this.emptyItem.lastName = newValue;
		}
	}
}
