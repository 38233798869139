import { Component, Mixins } from "vue-property-decorator";
import { EnumSelect, CoordinateType, BvListItem } from "@/loader";

@Component
export class CoordinateTypeSelect extends Mixins<EnumSelect<CoordinateType>>(EnumSelect) {
	// @ts-ignore
	public get options(): BvListItem[] {
		return CoordinateType.LIST;
	}
}
