import { BvListItem } from "@/loader";

export enum ConfigStatus {
	draft = 0,
	active = 1,
	planned = 2,
	disabled = 3,
}

export namespace ConfigStatus {
	export function toString(configStatus: ConfigStatus): string {
		switch (configStatus) {
			case ConfigStatus.disabled: return "Désactivée";
			case ConfigStatus.active: return "Active";
			case ConfigStatus.planned: return "Plannifiée";
			case ConfigStatus.draft: return "Brouillon";
			default:
				return "?";
		}
	}

	export function partToString(configStatus: ConfigStatus): string {
		switch (configStatus) {
			case ConfigStatus.disabled: return "Désinstallée";
			case ConfigStatus.active: return "Installée";
			case ConfigStatus.planned: return "Plannifiée";
			case ConfigStatus.draft: return "Brouillon";
			default:
				return "?";
		}
	}

	export const LIST: BvListItem[] = Object.values(ConfigStatus)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<ConfigStatus>value) }));

	export function checkWorkflow(from: ConfigStatus, to: ConfigStatus): boolean {
		switch (to) {
			case ConfigStatus.disabled: return from !== to;
			case ConfigStatus.active: return from !== to;
			case ConfigStatus.planned: return [ConfigStatus.draft, ConfigStatus.disabled].includes(from);
			case ConfigStatus.draft: return from !== to;
			default:
				return false;
		}
	}
}
