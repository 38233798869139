import { BvListItem } from "@/loader";

export enum ReplacementAction {
	none = 0,
	add = 1,
	replace = 2,
	remove = 3,
	move = 4,
	modify = 5,
}

export namespace ReplacementAction {
	export function toString(replacementAction: ReplacementAction): string {
		switch (replacementAction) {
			case ReplacementAction.add: return "Ajouter";
			case ReplacementAction.replace: return "Remplacer";
			case ReplacementAction.modify: return "Modifier";
			case ReplacementAction.remove: return "Enlever";
			case ReplacementAction.move: return "Déplacer";
			case ReplacementAction.none:
			default:
				return "Aucune";
		}
	}

	export function toSlug(value: ReplacementAction) {
		switch (value) {
			case ReplacementAction.add: return "add";
			case ReplacementAction.replace: return "replace";
			case ReplacementAction.modify: return "modify";
			case ReplacementAction.remove: return "remove";
			case ReplacementAction.move: return "move";
			case ReplacementAction.none:
			default:
				return "none";
		}
	}

	export const LIST: BvListItem[] = Object.values(ReplacementAction)
		.filter(value => typeof value === "number")
		.map(value => ({ value, text: toString(<ReplacementAction>value) }));

	export const ACTIONS_WITH_OLD = [ReplacementAction.none, ReplacementAction.modify, ReplacementAction.move, ReplacementAction.remove, ReplacementAction.replace];
	export const ACTIONS_WITHOUT_OLD = [ReplacementAction.none, ReplacementAction.add];

	export const NEED_OLD = [ReplacementAction.modify, ReplacementAction.move, ReplacementAction.remove, ReplacementAction.replace];
	export const NEED_NEW = [ReplacementAction.add, ReplacementAction.modify, ReplacementAction.move, ReplacementAction.replace];
	export const HIDE_NEW = [ReplacementAction.remove];
	export const HIDE_OLD = [ReplacementAction.add];
}
