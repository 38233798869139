import { CreateElement, VNode } from "vue";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { DataSelect, MachineGame, Game, UUID, Machine, ConfigStatus, DEBUG } from "@/loader";

interface MachineGameData {
	id: UUID
	data: MachineGame | null
	game: Game | null
	pack: Game | null
}

@Component
export class MachineGameSelect extends Mixins(DataSelect) {
	@Prop({ type: String, default: null })
	public machineId!: string | null;

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Function, default: (option: MachineGameData) => option.id })
	public reduce!: Function;

	@Prop({ type: [Number, Array], default: () => [] })
	public status!: ConfigStatus | ConfigStatus[];

	public games: Game[] = [];

	// @ts-ignore
	public readonly emptyItem: MachineGameData = {
		id: "",
		data: null,
		game: null,
		pack: null,
	};

	// public get gameIds(): string[] {
	// 	if (!this.machine) {
	// 		return [];
	// 	}
	// 	let ids = this.machine.games.map(mg => mg.gameId);
	// 	ids.push(...this.machine.games.filter(mg => mg.packId).map(mg => mg.packId || ""));
	// 	ids = ids.filter((id, i, a) => a.indexOf(id) === i);
	// 	return ids;
	// }

	public async loadOptions() {
		let machineGames = (this.machine || (await this.$db.getMachine(this.machineId || "")))?.games || [];
		let gameIds = machineGames.map(mg => mg.gameId);
		gameIds.push(...machineGames.filter(mg => mg.packId).map(mg => mg.gameId));
		gameIds = gameIds.filter((id, i, a) => a.indexOf(id) === i);
		this.games = await this.$db.allGames({ keys: gameIds });
		DEBUG && console.log("MachineGameSelect.loadOptions", machineGames);
		this.options = machineGames.map<MachineGameData>(mg => ({
			id: mg.id,
			data: mg,
			game: this.games.find(g => g._id === mg.gameId) || null,
			pack: mg.packId && this.games.find(g => g._id === mg.packId) || null,
		}));
	}

	public filterOptions(options: MachineGameData[]): MachineGameData[] {
		if ((Array.isArray(this.status) && this.status.length) || !!this.status) {
			let status = Array.isArray(this.status) ? this.status : [this.status];
			options = options.filter(mg => (this.showEmpty && mg === this.emptyItem) || (mg.data && status.includes(mg.data.status)));
		}
		return options;
	}

	public mounted() {
		this.loadOptions();
	}

	public getOptionKey(option: MachineGameData): string {
		return option.id;
	}

	public getOptionLabel(option: MachineGameData): string {
		return option?.data ? MachineGame.toString(option.data, option.game, option.pack) : this.emptyText;
	}

	public optionSlot(h: CreateElement, option: MachineGameData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	public selectedOptionSlot(h: CreateElement, option: MachineGameData): string | VNode | (string | VNode | null)[] {
		return this.getOptionLabel(option);
	}

	@Watch("machine")
	public onMachineChanged(newValue: Machine | null, oldValue?: Machine | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	@Watch("machineId")
	public onMachineIdChanged(newValue: string | null, oldValue?: string | null) {
		if (newValue != oldValue) { // because null !== undefined
			this.loadOptions();
		}
	}

	// public get editRoute(): string {
	// 	return "machine";
	// }

	// public get canEditOption(): boolean {
	// 	return servers.isAdmin || servers.isDirector || servers.isManager;
	// }
}
