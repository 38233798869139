import { Component, Mixins, Prop } from "vue-property-decorator";
import { BVariant, ConfigStatus, createIconConfig, EnumBadge, IconConfig } from "@/loader";

@Component
export class ConfigStatusBadge extends Mixins<EnumBadge<ConfigStatus>>(EnumBadge) {
	@Prop({ type: Boolean, default: false })
	public isPart!: boolean;

	public get variant(): BVariant {
		switch (this.value) {
			case ConfigStatus.active:
				return BVariant.success;
			case ConfigStatus.planned:
				return BVariant.warning;
			case ConfigStatus.disabled:
				return BVariant.danger;
			case ConfigStatus.draft:
			default:
				return BVariant.info;
		}
	}

	public get icon(): IconConfig {
		switch (this.value) {
			case ConfigStatus.active:
				return createIconConfig(['fas', 'check']);
			case ConfigStatus.planned:
				return createIconConfig(['fal', 'calendar-alt']);
			case ConfigStatus.disabled:
				return createIconConfig(['fas', 'trash']);
			case ConfigStatus.draft:
				return createIconConfig(['far', 'edit']);
			default:
				return createIconConfig(['fas', 'question-circle']);
		}
	}

	public get title(): string {
		return this.isPart ? ConfigStatus.partToString(this.value) : ConfigStatus.toString(this.value);
	}
}
