











































import { Component, Prop, Mixins } from "vue-property-decorator";
import { PartReplacement, Task, EditList, Machine, Mission, MachinePartTask, ReplacementAction } from "@/loader";
import PartReplacementRow from "./PartReplacementRow.vue";

@Component({
	components: {
		PartReplacementRow,
	}
})
export default class PartReplacementTable extends Mixins<EditList<PartReplacement, Task>>(EditList) {
	public dragOptions = {
		group: "partReplacementRows",
		handle: ".btn-move",
		draggale: ".part-replacement-row",
	}

	@Prop({ type: Object, default: null })
	public machine!: Machine | null;

	@Prop({ type: Object, default: null })
	public mission!: Mission | null;

	public get array(): PartReplacement[] {
		return this.parent && MachinePartTask.instanceOf(this.parent.data) ? this.parent.data.parts : [];
	}
	public set array(value: PartReplacement[]) {
		if (this.parent && MachinePartTask.instanceOf(this.parent.data)) {
			this.parent.data.parts = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return () => PartReplacement.create(null, ReplacementAction.add);
	}

	public get cloneFactory(): (base: PartReplacement) => PartReplacement {
		return PartReplacement.clone;
	}

	public get label() {
		return "Matériels";
	}
}
