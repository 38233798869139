



































































import { Vue, Component, Mixins, Ref } from "vue-property-decorator";
import {
	EditList,
	MachineController,
	Machine,
	ConfigStatus,
	nanoid,
	toaster,
	GameController,
} from "@/loader";
import MachineControllerRow from "./MachineControllerRow.vue";
import GameControllerSelect from "../game/GameControllerSelect";

@Component({
	inheritAttrs: true,
	components: {
		MachineControllerRow,
		GameControllerSelect,
	}
})
export default class MachineControllersTable extends Mixins<EditList<MachineController, Machine>>(EditList) {
	public dragOptions = {
		group: "machineControllers",
		handle: ".btn-move",
		draggale: ".machine-part-list-item",
	}
	public status: ConfigStatus[] = [];
	public newController: GameController | null = null;

	@Ref()
	public machineControllers!: MachineControllerRow | MachineControllerRow[];

	public get array(): MachineController[] {
		if (this.status.length) {
			return this.parent?.controllers.filter(mc => this.status.includes(mc.status)) || [];
		}
		return this.parent?.controllers || [];
	}
	public set array(value: MachineController[]) {
		if (this.parent && !this.status.length) {
			this.parent.controllers = value;
			this.changed = true;
		}
	}

	public get itemFactory() {
		return MachineController.create;
	}

	public get cloneFactory(): (base: MachineController) => MachineController {
		return MachineController.clone;
	}

	public get statusOptions() {
		let array = this.parent?.controllers || [];
		return Object.values(ConfigStatus)
			.filter(value => typeof value === "number")
			.map(value => ({
				value,
				text: `${ConfigStatus.toString(<ConfigStatus>value)} (${array.filter(x => x.status === <ConfigStatus>value).length})`,
				disabled: !array.some(x => x.status === <ConfigStatus>value)
			}));
	}

	public editAll() {
		if (!this.machineControllers) {
			return;
		}
		if (!this.canEdit) {
			toaster.forbidden({ operation: "Modifier" });
			return;
		}
		if (Array.isArray(this.machineControllers)) {
			this.machineControllers.forEach(mp => mp.editMode = true);
		} else {
			this.machineControllers.editMode = true;
		}
	}

	public createNew() {
		if (!this.canEdit || !this.parent) {
			toaster.forbidden({ operation: "Nouveau" });
			return;
		}

		if (!this.itemFactory) {
			toaster.error({ message: "Item factory undefined" });
			return;
		}

		this.clean();
		let item: MachineController;
		if (this.newController) {
			item = MachineController.createFromGame(this.newController);
		} else {
			item = this.itemFactory();
		}
		this.array.push(item);
		this.$emit("created", item);
		this.changed = true;

		this.$nextTick().then(() => {
			if (this.machineControllers) {
				if (Array.isArray(this.machineControllers)) {
					if (this.machineControllers.length) {
						this.machineControllers[this.machineControllers.length - 1].editMode = true;
					}
				} else {
					this.machineControllers.editMode = true;
				}
			}
		});
	}
}
