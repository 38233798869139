import {
	CouchDBDocument,
	generateUUID,
	Events,
	CounterType,
	checkValue,
	Named,
	Deactivable,
	MachineConfig,
	cloneObject,
} from "@/loader";

export interface Counter extends CouchDBDocument, Events, Named, Deactivable {
	type: "counter"
	definition: CounterType
	condition: MachineConfig
}

export namespace Counter {
	export const TYPE = "counter";
	export const STARTKEY = TYPE + CouchDBDocument.PREFIX_SEPARATOR;
	export const ENDKEY = TYPE + CouchDBDocument.ENDKEY_SUFFIX;

	export function instanceOf(value: any): value is Counter {
		return CouchDBDocument.instanceOf(value) && value.type === TYPE;
	}

	export function create(
		definition: CounterType = CounterType.electronic,
		name: string = "",
		condition: MachineConfig = MachineConfig.none,
	): Counter {
		return {
			_id: generateUUID(TYPE),
			_rev: "",
			type: TYPE,
			definition,
			name,
			condition,
			disabled: false,
			events: [],
		}
	}

	export const clone: (base: Counter, default_data?: Partial<Counter>) => Counter = cloneObject;

	export function check(data: any): Counter {
		CouchDBDocument.check(data, TYPE);
		Named.check(data);
		Deactivable.check(data);
		Events.check(data);
		checkValue(data, "definition", CounterType.electronic);
		checkValue(data, "condition", MachineConfig.none);
		return data;
	}
}
