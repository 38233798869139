



























import { Vue, Component } from "vue-property-decorator";

@Component
export default class ParcDashboard extends Vue {
	public variant = "outline-secondary";

}
