












import { Vue, Component, Prop } from "vue-property-decorator";
import { Event, EventValue, EventType } from '@/loader';
import dayjs from 'dayjs';

@Component
export default class EventCard extends Vue {
	@Prop({ type: Object, required: true })
	public item!: Event;

	public get date() {
		return typeof this.item.date === "object" ? dayjs(this.item.date.$d) : dayjs(this.item.date);
	}

	public get title(): string {
		return EventType.toString(this.item.type);
	}

	public get author(): string {
		return this.item.author || "";
	}

	public get dateText(): string {
		return this.date.isBefore(dayjs().subtract(1, "day")) ? "Le " + this.date.format("ddd L LTS") : this.date.fromNow().capitalize();
	}

	public get description(): string {
		return this.item.comment ? "&laquo; " + this.item.comment.replace(/\r?\n/g, "<br/>") + " &raquo;" : "";
	}
}
