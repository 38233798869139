















































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { SoftwareCounter, EditComponent, Software, UUID } from "@/loader";

@Component
export default class SoftwareCounterRow extends Mixins<EditComponent<SoftwareCounter, Software>>(EditComponent) {
	@Prop({ type: Boolean, default: false })
	public elec!: boolean;

	@Prop({ type: Boolean, default: false })
	public meca!: boolean;

	public invalidFeedbacks = {
		counterId: "Le compteur ne peut pas être vide",
		name: "Le libellé ne peut pas être vide",
		conditions: "",
		relations: "",
	}

	public get states() {
		return {
			counterId: this.item && this.focused.counterId ? !!this.item.counterId : null,
			name: this.item && this.focused.name ? !!this.item.name : null,
			conditions: this.item && this.focused.conditions ? true : null,
			relations: this.item && this.focused.relations ? true : null,
		}
	}

	public get elecs(): UUID[] {
		return this.parent ? this.parent.elecs.map(c => c.counterId) : ["none"];
	}

	// public get conditions(): MachineConfig[] {
	// 	return MachineConfig.toArray(this.item?.conditions);
	// }
	// public set conditions(value: MachineConfig[]) {
	// 	if (this.item) {
	// 		this.item.conditions = MachineConfig.fromArray(value);
	// 	}
	// }
}
